import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';

import FieldPropertyGrid from './FieldPropertyGrid';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { IsMobile } from '../Util/MobileDetector';
import ProgressIndicator from './ProgressIndicator';
import API from '../Util/api';
import { Field } from '../Model/Field';
import { GetField, UpdateField } from '../Util/Field';
import {
  GetFieldListItemsPathForApi,
} from '../Util/api';
import { GetAllFieldsPromise } from '../Util/Fields';

const styles = theme => ({
});

class FieldPropertiesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      Field: !props.field.ID ? {...Field} : props.field,
      ShowSelectionListItemsUploadedPopover: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  handleChangeFieldProperty = (updateFunc) => {
    let field = {...this.state.Field};
    updateFunc(field);
    this.setState({Field: field});
    UpdateField(this.props.organizationId, this.props.projectId, field)
      .catch(this.handleApiError);
    if (this.props.onFieldRevised) {
      this.props.onFieldRevised({...field});
    }
  }

  handleUploadSelectionListItems = fieldID => event => {
    this.setState({ShowProgressIndicatorImmediately: true});
    let files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.file = file;
      reader.onload = (function (file, organizationId, projectId, fieldID, scope) {
        return function (e) {
          let binaryStr = reader.result;
          return API.post(GetFieldListItemsPathForApi(organizationId, projectId, fieldID),
            binaryStr,
            {
              params: { type: "file" },
              // onUploadProgress: e => scope.handleUploadProgress(file.name, e),
            }
          )
            .then(resp => {
              scope.setState({
                ShowProgressIndicatorImmediately: false,
                ShowSelectionListItemsUploadedPopover: true,
              });
              setTimeout(() => scope.setState({ ShowSelectionListItemsUploadedPopover: false }), 1500);
            })
            .catch(err => { console.log(err);scope.handleApiError(err);});
        };
      })(file, this.props.organizationId, this.props.projectId, fieldID, this);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleDownloadSelectionListItems = fieldID => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.get(`${GetFieldListItemsPathForApi(this.props.organizationId, this.props.projectId, fieldID)}?getAllAsFlatFile=true`,
      {
        params: { type: "file" },
        // onUploadProgress: e => scope.handleUploadProgress(file.name, e),
      }
    )
      .then(resp => {
        const link = document.createElement('a');
        link.href = "data:application/octet-stream," + encodeURI(resp.data);
        link.download = "Selection List Items.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ShowProgressIndicatorImmediately: false});
      })
      .catch(this.handleApiError);
  }

  handleShowProgressIndicatorImmediately = show => {
    this.setState({ShowProgressIndicatorImmediately: show});
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicator: false, ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.setState({ShowProgressIndicatorImmediately:true});
    
    GetAllFieldsPromise(this.props.organizationId, this.props.projectId)
      .then(resp => {
        const fieldList = resp.data;
        
        this.setState({ 
          Fields: fieldList.Fields,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
      if (this.props.open) {
        GetField(this.props.organizationId, this.props.projectId, this.props.field.ID)
          .then(Field => {
            this.setState({Field});
          })
          .catch(this.handleApiError);
      } else {
        this.setState({Field:{...Field}});
      }
    }
  }

  render() {
    const {
      open,
      Field,
      Fields,
      ShowSelectionListItemsUploadedPopover,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      // classes,
      // theme,
      organizationId,
      projectId,
      disallowFieldTypeChange,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={this.handleClose}>
          CLOSE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        open={open}
        onClose={this.handleClose}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description">
        // <DialogTitle id="dialog-title">
        //   <span>{Field.Name}</span>
        // </DialogTitle>
        >
        <DialogContent>
          {progressIndicator}
          
          <FieldPropertyGrid
            organizationId={organizationId}
            projectId={projectId}
            Field={Field}
            FieldID={Field.ID}
            Fields={Fields}
            dense={!IsMobile()}
            onChangeFieldProperty={this.handleChangeFieldProperty}
            onDownloadSelectionListItems={() => this.handleDownloadSelectionListItems(Field.ID)}
            onUploadSelectionListItems={this.handleUploadSelectionListItems(Field.ID)}
            showSelectionListItemsUploadedPopover={ShowSelectionListItemsUploadedPopover}
            onShowProgressIndicatorImmediately={this.handleShowProgressIndicatorImmediately}
            onApiError={this.handleApiError}
            formTemplateFieldType={undefined}
            disallowFieldTypeChange={disallowFieldTypeChange}
          />
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

FieldPropertiesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onFieldRevised: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  disallowFieldTypeChange: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(FieldPropertiesDialog);
