import API, {
  GetProjectMembersPathForApi,
} from '../Util/api';
import {
  GetProjectMemberPath,
} from '../Util/PathHelper';
import {
  GetUserValue,
} from '../Util/Properties';

import debounce from 'es6-promise-debounce';

export const HandleRouteToProjectMember = (props, projectId, projectMemberId, openInNewTab) => {
  const uri = GetProjectMemberPath(projectId, projectMemberId);
  if (openInNewTab) {
    window.open(uri, "n1_projectMemberContent")
  } else {
    props.history.push(uri, { ...props.location.state });  
  }
}

export const GetProjectMemberListValuesPromise = debounce((organizationId, projectId, filter) => {
  let params = {
    sortType: "Meta_text_kw256lc[Name].keyword",
  };
  if (filter) {
    params.fullTextFilter = filter;
  }
  
  return API.get(GetProjectMembersPathForApi(organizationId, projectId), { params })
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      return resp.data.ProjectMembers
        .map(pm => {
          return {
            projectMember: pm,
            value: pm.ID,
            label: GetUserValue(pm.UserEmail, (pm.UserName) ? `${pm.UserName} (${pm.UserEmail})` : pm.UserEmail),
          };
        });
    });
}, 150);