import React from 'react';

import { ValidateEmail } from '../../Util/Regex';
import TaskDialogGrid from '../../Components/TaskDialogGrid';

import {
  HandleSubTaskAdded,
  HandleSubTaskAddedAfter,
  HandleSubTaskChanged,
  HandleStartMoveSubTask,
  HandleMoveSubTask,
  HandleAbortMoveSubTask,
  HandleEndMoveSubTask,
  HandleStakeholdersValueChange,
} from '../../Util/Task';
import {
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../../Util/Tags';

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange) {
  
  if (!processElement) {
    return null;
  }

  const getTask = () => {
  	const assignmentOption = processElement[onGetListValuesAndLabelsPropertyName("AssignmentUserEmail", false)];
    const milestoneOption = processElement[onGetListValuesAndLabelsPropertyName("TaskMilestoneID", false)];
    const stateOption = processElement[onGetListValuesAndLabelsPropertyName("TaskStateID", false)];
    const Tags = processElement.Data["Tags"];
    const AssetItemTags = processElement.Data["AssetItemTags"];
    return {
	  	Name: processElement.Data.Name,
	  	Description: processElement.Data.Description,
      DescriptionRichTextJson: processElement.Data.DescriptionRichTextJson,
      DescriptionStateUrl: processElement.StateUrl,
      Priority: processElement.Data.Priority || 0,
	  	AssignmentUserEmail: assignmentOption && assignmentOption.value,
	  	AssignmentUserName: assignmentOption && assignmentOption.label,
      DueInMultiplier: processElement.Data.DueInMultiplier,
      DueInInterval: processElement.Data.DueInInterval,
      DurationMultiplier: processElement.Data.DurationMultiplier,
      DurationInterval: processElement.Data.DurationInterval,
      Stakeholders: processElement.Data.Stakeholders,
      SubTasks: processElement.Data.SubTasks,
      TaskMilestoneID: milestoneOption && milestoneOption.value,
      TaskMilestoneName: milestoneOption && milestoneOption.label,
      TaskStateID: stateOption && stateOption.value,
      TaskStateName: stateOption && stateOption.label,
      Tags,
      AssetItemTags,
	  };
  }

  const handleAssignmentValueChange = selectedOption => {
    onSelectionListValueChange("AssignmentUserEmail", false)(selectedOption);
  }

  const handleAssignmentCreateOption = value => {
    if (!value) {
      return;
    }
    if (!ValidateEmail(value)) {
      onApiError("E-mail address is not valid.");
      return;
    }
    value = value.trim();
    const listValue = {value:value,label:value};
    handleAssignmentValueChange(listValue);
  }

  const handleMilestoneValueChange = selectedOption => {
    onSelectionListValueChange("TaskMilestoneID", false)(selectedOption);
  }

  const handleStateValueChange = selectedOption => {
    onSelectionListValueChange("TaskStateID", false)(selectedOption);
  }

  const handleTagListValuesChanged = selectedOptions => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(selectedOptions);
    onChangeHandlers.setAnyProperty("Tags", true, Tags);
    onChangeHandlers.setAnyProperty("AssetItemTags", true, AssetItemTags);
  }

  const handleStakeholdersValueChange = selectedOptions => {
    HandleStakeholdersValueChange(
      {},
      onGetLocalState("StakeholderProjectMembers"), 
      updatedTask => onChangeHandlers.setAnyProperty("Stakeholders", true, updatedTask.Stakeholders),
    )(selectedOptions);
  }

  const handleStringChange = propertyName => eventOrValue => {
		onChangeHandlers.handleTextFieldChange(propertyName, true)(eventOrValue);
  }

  const handleRichTextChange = (propertyName, richTextAsJsonString, htmlPropertyName, html, plainTextPropertyName, plainText) => {
    onChangeHandlers.handleTextFieldChange(propertyName, true)(richTextAsJsonString);
    if (htmlPropertyName) {
      onChangeHandlers.handleTextFieldChange(htmlPropertyName, true)(html);
    }
    if (plainTextPropertyName) {
      onChangeHandlers.handleTextFieldChange(plainTextPropertyName, true)(plainText);
    }
  }

  const handleNumberChange = propertyName => value => {
    onChangeHandlers.setNumberProperty(propertyName, true, value);
  }

  const handleDurationChange = (multiplier, interval) => {
    if (typeof multiplier === "string") {
      multiplier = parseFloat(multiplier);
    }
    if (multiplier) {
      onChangeHandlers.setNumberProperty("DurationMultiplier", true, multiplier);
      onChangeHandlers.handleTextFieldChange("DurationInterval", true)(interval);
    } else {
      onChangeHandlers.setNumberProperty("DurationMultiplier", true, 0);
      onChangeHandlers.handleTextFieldChange("DurationInterval", true)("");
    }
  }

  const handleSubTaskFunction = f => (...props) => {
    f(
      processElement.Data.SubTasks,

      (id) => {
        return processElement[id];
      },
      
      (subTasks, finalizeSubTasksState, otherState) => {
        if (subTasks) {
          onChangeHandlers.setAnyProperty("SubTasks", true, subTasks);
        }
        if (otherState) {
          Object.keys(otherState).forEach(k => {
            onChangeHandlers.setAnyProperty(k, false, otherState[k]);
          });
        }
      },

    )
    (...props);
  }

  const handleSubTaskAdded = (...props) => {
    handleSubTaskFunction(HandleSubTaskAdded)(...props);
  }

  const handleSubTaskAddedAfter = (...props) => {
    handleSubTaskFunction(HandleSubTaskAddedAfter)(...props);
  }

  const handleSubTaskChanged = (...props) => {
    handleSubTaskFunction(HandleSubTaskChanged)(...props);
  }

  const handleStartMoveSubTask = (...props) => {
    handleSubTaskFunction(HandleStartMoveSubTask)(...props);
  }

  const handleMoveSubTask = (...props) => {
    handleSubTaskFunction(HandleMoveSubTask)(...props);
  }

  const handleAbortMoveSubTask = (...props) => {
    handleSubTaskFunction(HandleAbortMoveSubTask)(...props);
  }

  const handleEndMoveSubTask = (...props) => {
    handleSubTaskFunction(HandleEndMoveSubTask)(...props);
  }

  return (
    <TaskDialogGrid
      organizationId={organizationId}
      projectId={projectId}
      processId={processElement.ProcessID}
      processElementId={processElement.ID}
      Task={getTask()}
      dragIsActive={processElement["SubTaskDragIsActive"] || false}
      onStringChange={handleStringChange}
      onRichTextChange={handleRichTextChange}
      onNumberChange={handleNumberChange}
      onDurationChange={handleDurationChange}
      onDateTimeChange={() => {}}
      onAssignmentValueChange={handleAssignmentValueChange}
      onAssignmentCreateOption={handleAssignmentCreateOption}
      onMilestoneValueChange={handleMilestoneValueChange}
      onStateValueChange={handleStateValueChange}
      onTagListValuesChanged={handleTagListValuesChanged}
      stakeholderProjectMembers={onGetLocalState("StakeholderProjectMembers")}
      onSetStakeholderProjectMembers={StakeholderProjectMembers => onSetLocalState({StakeholderProjectMembers})}
      onStakeholdersValueChange={handleStakeholdersValueChange}
      onSubTaskAdded={handleSubTaskAdded}
      onSubTaskAddedAfter={handleSubTaskAddedAfter}
      onSubTaskChanged={handleSubTaskChanged}
      onStartMoveSubTask={handleStartMoveSubTask}
      onMoveSubTask={handleMoveSubTask}
      onAbortMoveSubTask={handleAbortMoveSubTask}
      onEndMoveSubTask={handleEndMoveSubTask}
      hideName // When the tasks are created from this process element, the process element's name will be used
      isWorkflow
      showDueIn
      onApiError={onApiError}
      onAlert={onAlert}
    />
  );
}