import _objectSpread from '@babel/runtime/helpers/esm/objectSpread2';
import _applyDecoratedDescriptor from '@babel/runtime/helpers/esm/applyDecoratedDescriptor';
import {
  extension,
  ExtensionPriority,
  command,
  NodeExtension,
  ExtensionTag,
  isElementDomNode,
  omitExtraAttributes,
} from '@remirror/core';

var MERGEDATAPLACEHOLDERID_DATA_ATTRIBUTE = 'data-remirror-mergeDataPlaceholderId';
var MERGEDATAPLACEHOLDERDATA_DATA_ATTRIBUTE = 'data-remirror-mergeDataPlaceholderData';

function getPlaceholderFromLabel(label) {
  return "<<"+label+">>";
}

var _dec, _dec2, _class, _class2;
var MergeDataPlaceholderExtension = (_dec = extension({
  defaultPriority: ExtensionPriority.Low,
}), _dec2 = command(), _dec(_class = (_class2 = class MergeDataPlaceholderExtension extends NodeExtension {
  get name() {
    return 'mergeDataPlaceholder';
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.LastNodeCompatible];
  }

  createNodeSpec(extra, override) {
    var _override$parseDOM;

    return _objectSpread(_objectSpread({
      selectable: true,
      content: "text*",
      marks: "_",
      inline: true,
      atom: true,
    }, override), {}, {
      attrs: _objectSpread(_objectSpread({}, extra.defaults()), {}, {
        id: "",
        data: "",
      }),
      parseDOM: [{
        tag: "span[".concat(MERGEDATAPLACEHOLDERID_DATA_ATTRIBUTE),
        getAttrs: node => {
          if (!isElementDomNode(node)) {
            return;
          }

          var id = node.getAttribute(MERGEDATAPLACEHOLDERID_DATA_ATTRIBUTE);
          var data = node.getAttribute(MERGEDATAPLACEHOLDERDATA_DATA_ATTRIBUTE);
          return _objectSpread(_objectSpread({}, extra.parse(node)), {}, {
            id,
            data,
          });
        }
      }, ...((_override$parseDOM = override.parseDOM) !== null && _override$parseDOM !== void 0 ? _override$parseDOM : [])],
      toDOM: node => {
        var _ref = omitExtraAttributes(node.attrs, extra),
            id = _ref.id,
            data = _ref.data;

        return ['span', {
          contentEditable: false,
          [MERGEDATAPLACEHOLDERID_DATA_ATTRIBUTE]: id,
          [MERGEDATAPLACEHOLDERDATA_DATA_ATTRIBUTE]: JSON.stringify(data),
        }, 0];
      }
    });
  }

  insertMergeDataPlaceholder(id, label, data) {
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    return props => {
      if (!id || !label || !data) {
        // Nothing to do here parameters are missing.
        return false;
      }

      const state = props.state;
      const schema = state.schema;
      const textNode = schema.text(getPlaceholderFromLabel(label));
      return this.store.commands.insertNode.original(this.type, {
        attrs: {
          id,
          data,
        },
        content: textNode,
        selection: options.selection,
      })(props);
    };
  }
  
}, (_applyDecoratedDescriptor(_class2.prototype, "insertMergeDataPlaceholder", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "insertMergeDataPlaceholder"), _class2.prototype)), _class2)) || _class);

export { MergeDataPlaceholderExtension };