import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../../Context/Global.context';
// import GetMoreButton from '../../Util/GetMoreButton';
import ProgressIndicator from '../../Components/ProgressIndicator';
import {
  GetUserValue,
} from '../../Util/Properties';

import API from '../../Util/api';
import debounce from 'es6-promise-debounce';
import { IsMobile } from '../../Util/MobileDetector';

import {
  GetProjectMemberPathForApi,
} from '../../Util/api';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "80%" : undefined,
  },
  dialogTitle: {
  },
  dialogContent: {
  },
  dialogActions: {
  },
  contentContainer: {
    marginTop:theme.spacing(2),
  },
});

class ProjectMemberDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      ProjectMember: props.ProjectMember,
      ShowProgressIndicator: false,
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleUpdateProjectMemberBoolean(id, boolean) {
    let projectMember = {...this.state.ProjectMember};
    projectMember[id] = boolean;
    this.updateProjectMember(projectMember);
  }

  updateProjectMember = ProjectMember => {
    this.setState({ProjectMember});
    this.handleUpdateServerProjectMember(ProjectMember);
    if (ProjectMember && this.props.onProjectMemberRevised) {
      this.props.onProjectMemberRevised({...ProjectMember});
    }
  }

  handleUpdateServerProjectMember = debounce(projectMember => {
    if (!this.props.ProjectMember) {
      return;
    }
    if (!projectMember) {
      projectMember = {...this.state.ProjectMember};
    }

    API.put(GetProjectMemberPathForApi(projectMember.OrganizationID, projectMember.ProjectID,
      projectMember.ID), projectMember)
      .then(resp => {
        this.setState({
          // ProjectMember: resp.data,
        });
      })
      .catch(this.handleApiError);
  }, 250);

  isCurrentUserSameAsProjectMember() {
    return this.state.ProjectMember.UserEmail === this.context.UserPreferences.UserEmail;
  }

  canUpdateIsDisabled() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsProjectMember()
      // Member is an org admin
      || this.state.ProjectMember.OrganizationMemberIsAdmin
      // Member is an admin
      || this.state.ProjectMember.IsAdmin
    ) { return false; }
    return true;
  }

  canUpdateIsAdmin() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsProjectMember()
      // Member is an org admin
      || this.state.ProjectMember.OrganizationMemberIsAdmin
      // Member is disabled
      || this.state.ProjectMember.Disabled
      // Member is read-only
      || this.state.ProjectMember.IsReadOnly
    ) { return false; }
    return true;
  }

  canUpdateIsReadOnly() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsProjectMember()
      // Member is an org admin
      || this.state.ProjectMember.OrganizationMemberIsAdmin
      // Member is disabled
      || this.state.ProjectMember.Disabled
      // Member is an admin
      || this.state.ProjectMember.IsAdmin
    ) { return false; }
    return true;
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicator: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        open: this.props.open !== false,
      }
      if (this.props.open) {
        if (this.props.ProjectMember) {
          stateToUpdate.ProjectMember = this.props.ProjectMember;
        } else {
          stateToUpdate.ProjectMember = null;
        }
      } else {
        stateToUpdate.ProjectMember = null;
      }
      this.setState(stateToUpdate);
    }
  }

  render() {
    const {
      open,
      ProjectMember,
      ShowProgressIndicator,
    } = this.state;
    const {
      classes,
      // theme,
      showProgressIndicatorImmediately,
    } = this.props;
    const {
      CompletedGET,
    } = this.context;

    if (!CompletedGET.UserPreferences) {
      return null;
    }

    const dialogActions = (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const progressIndicator = (ShowProgressIndicator || showProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately={showProgressIndicatorImmediately} />
      )
      : null;

    const content = (
      <React.Fragment>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={ProjectMember.Disabled || false}
                  disabled={!this.canUpdateIsDisabled()}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateProjectMemberBoolean("Disabled", e.target.checked)}
                />
              }
              label="Disabled" />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={ProjectMember.IsAdmin || false}
                  disabled={!this.canUpdateIsAdmin()}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateProjectMemberBoolean("IsAdmin", e.target.checked)} 
                />
              }
              label={"Project Manager"} />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={ProjectMember.IsStakeholder || false}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateProjectMemberBoolean("IsStakeholder", e.target.checked)} 
                />
              }
              label={"Stakeholder"} />
          </Grid>
        </Grid>

      </React.Fragment>
    );

    return (
      <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="sm"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {GetUserValue(ProjectMember.UserEmail, ProjectMember.UserName, "profile")}
          
          {progressIndicator}
          
          <div className={classes.contentContainer}>
            {content}
          </div>

        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

ProjectMemberDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  ProjectMember: PropTypes.object,
  onClose: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onProjectMemberRevised: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ProjectMemberDialog);