import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
} from '../Util/Properties';
import {
  GetTaskSubscriptionIconButton,
} from '../Util/Task';

class TaskSubscriptionCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      TaskSubscription: {...this.props.TaskSubscription},
    }
  }

  handleSubscribeAction = (actionType, id) => {
    if (!this.props.onAction) {
      return;
    }
    this.props.onAction(actionType, id, true)
      .then(taskSubscriptions => {
        if (taskSubscriptions && taskSubscriptions.length) {
          this.setState({TaskSubscription: taskSubscriptions[0]});
        }
      });
  }

  handlePostActionData = data => {
    // Updated subscription
    if (data.TaskSubscriptions) {
      let subscriptionFinder = data.TaskSubscriptions.filter(ds => ds.TaskID === this.props.TaskSubscription.TaskID);
      if (subscriptionFinder.length) {
        this.setState({TaskSubscription: subscriptionFinder[0]});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postActionData
      && this.props.postActionData !== prevProps.postActionData) {
      this.handlePostActionData(this.props.postActionData);
    }
  }

  render() {
    const {
      TaskSubscription,
    } = this.state;
    const { 
      // sensitiveFields,
      noSelect,
      selected,
      onSelect,
      onCardAction,
      disableCardActions,
      style,
    } = this.props;

    let metadata = [];

    if (TaskSubscription.TaskResult === "Complete") {
      metadata.push(["", "Completed Task"]);
    }
    if (TaskSubscription.TaskResult === "Deny") {
      metadata.push(["", "Deleted Task"]);
    }

    metadata.push(
      ["Project", TaskSubscription.ProjectName],
      ["Created On", GetDateValue(TaskSubscription.CreatedOn)],
      ["Task Created On", GetDateValue(TaskSubscription.TaskCreatedOn)],
    );

    const subscriptionIconButton = GetTaskSubscriptionIconButton(TaskSubscription, this.handleSubscribeAction);
    
    return (
      <Card
        style={style}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={256}
        name={TaskSubscription.TaskName}
        thumbnailUrl={TaskSubscription.TaskPrimaryDocumentThumbnailUrl}
        metadata={metadata}
        hoverActionItems={!disableCardActions
          ? [
            subscriptionIconButton,
          ]
          : undefined
        }
      />
    );
  }
}

TaskSubscriptionCard.propTypes = {
  TaskSubscription: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  disableCardActions: PropTypes.bool,
}

export default TaskSubscriptionCard;