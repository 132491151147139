import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SelectControl from '../Components/SelectControl';

import RestoreIcon from '@material-ui/icons/Restore';

// import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';
import DocumentEditor from '../Components/DocumentEditor';

import classNames from 'classnames';
import axios from 'axios';
import API, {
  GetDocumentRevisionsPathForApi,
  GetDocumentRevisionContentPathForApi,
  GetDocumentRevisionContentRestorePathForApi,
  GetDocumentUploadsPathForApi,
  GetUploadBinaryPromise,
  GetFieldsPathForApi,
} from '../Util/api';
import {
  GetDocumentContentPackagePromise,
  GetDocumentContentPackageUri,
} from '../Util/Document';
import {
  GetPaperSizeOptions,
  GetPrintStyles,
  GetPrintContainerStyle,
  GetValidPaperSizeString,
  GetValidPaperOrientationString,
  IsPaperMarginValueAllowed,
} from '../Util/DocumentEditor';
import NumericTextField from '../Components/NumericTextField';

import ProgressIndicator from '../Components/ProgressIndicator';
import debounce from 'es6-promise-debounce';
import HtmlDiff from 'htmldiff-js';
// import { IsMobile } from '../Util/MobileDetector';
import {
  GetDateValue,
} from '../Util/Properties';

import { IsMobile } from '../Util/MobileDetector';
import MultiUseDialog from '../Components/MultiUseDialog';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  revisionsDialogRoot: {
      "& ins.diffmod": {
        backgroundColor:green[200],
      },
      "& ins.diffins": {
        backgroundColor:green[100],
      },
      "& del.diffmod": {
        backgroundColor:red[200],
      },
      "& del.diffdel": {
        backgroundColor:red[100],
      },
  },
  revisionsDialogPaper: {
    // height:(!IsMobile()) ? "80%" : undefined,
  },
  revisionsDialogTitle: {
  },
  revisionsDialogContent: {
  },
  revisionsDialogActions: {
  },
  revisionsDialogContentContainer: {
    display:"flex",
    height:"100%",
  },
  revisionsDialogRevisionsPane: {
    width:300,
    overflowY:"auto",
  },
  revisionsDialogDiff: {
    overflowY:"auto",
    padding:theme.spacing(2),
    backgroundColor:"white",
    color:"black",
    width:"100%",
  },
});

class DocumentEditorHost extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      DocumentRevisions: [],
      SelectedRevisionID: null,
      RevisionsCursor: null,
      ForceEditorReloadForAllCollaboratorsTimestamp: null,
      ShowGetMoreRevisionsButton: false,
      ShowRevisionsDialog: false,
      ShowPrintSetupDialog: false,
      PrintSetupChanged: false,
      ShowRevisionRestoreConfirmationDialog: false,
      SelectedRevisionIDForRestore: null,
      RevisionRestoreActive: false,
      DocumentRevisionIDForContentLoad: null,
      PaperSize: GetValidPaperSizeString(props.documentPackage.Document.PaperSize),
      PaperOrientation: GetValidPaperOrientationString(props.documentPackage.Document.PaperOrientation),
      PaperMarginLeft: props.documentPackage.Document.PaperMarginLeft.toString(),
      PaperMarginRight: props.documentPackage.Document.PaperMarginRight.toString(),
      PaperMarginTop: props.documentPackage.Document.PaperMarginTop.toString(),
      PaperMarginBottom: props.documentPackage.Document.PaperMarginBottom.toString(),
      ShowDialogProgressIndicatorImmediately: false,
    }

    this.HtmlDiffRef = React.createRef();
   
    this.OnGetHtml = () => "";
    this.OnSetCollaboratorDestroyAndReloadRequest = () => {};
  }

  handleLoadDocumentContent = () => {
    const uri = GetDocumentContentPackageUri(this.props.organizationId, this.props.projectId,
      this.props.documentPackage.Document.ID, false, this.props.userAssignmentContext, this.props.documentFolderId, 
      this.props.approvalId, this.props.taskId);
        
    // If we recently restored a revision, the new revision ID will be used and ultimately cleared
    return GetDocumentContentPackagePromise(uri, false, false, null, false, false, null, true, 
      this.state.DocumentRevisionIDForContentLoad)
      .then(dcp => {
        if (dcp && dcp.Url) {
          return axios.get(dcp.Url, { responseType: 'arraybuffer' });
        }
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({DocumentRevisionIDForContentLoad: null});
      });
  }

  loadDocumentRevisions = isGetMore => {
    this.setState({ShowDialogProgressIndicatorImmediately:true});
    
    let params = {
      cursor: (isGetMore) ? this.state.RevisionsCursor : undefined,
    };

    return API.get(GetDocumentRevisionsPathForApi(this.props.organizationId, this.props.projectId, 
      this.props.documentPackage.Document.ID), { params })
      .then(resp => {
        const loadedRevisions = resp.data.DocumentRevisions;
        const DocumentRevisions = (isGetMore)
          ? [...this.state.DocumentRevisions].concat(loadedRevisions)
          : loadedRevisions;
        this.setState({
          DocumentRevisions,
          ShowGetMoreRevisionsButton: loadedRevisions.length === resp.data.PageSize,
          RevisionsCursor: resp.data.Cursor,
          ShowDialogProgressIndicatorImmediately: false,
        });
        return DocumentRevisions;
      })
      .catch(this.handleApiError);
  }

  getSignedUrlForDocumentRevisionHtml = revisionId => {
    return API.get(GetDocumentRevisionContentPathForApi(this.props.organizationId, this.props.projectId, 
      this.props.documentPackage.Document.ID, revisionId),
      { params: { useHtmlObjectName: true }})
      .then(resp => {
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  getIsDisabled = () => {
    return this.props.documentPackage.Document.IsMarkedForPurge
      || Boolean(this.props.userAssignmentContext);
  }

  handleYjsUpdate = debounce((yDoc, incrementRevision, onReservationObtained) => {
    if (this.getIsDisabled()) {
      return Promise.resolve({});
    }
    // console.log("handleYjsUpdate");

    // Persist YJS document
    return GetUploadBinaryPromise(yDoc, null, GetDocumentUploadsPathForApi(this.props.organizationId, this.props.projectId), 
      { 
        documentId: this.props.documentPackage.Document.ID,
        incrementRevision,
      },
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("ydoc progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      },
      onReservationObtained,
    )
    // .catch(this.handleApiError);
  }, 1000)

  handleHtmlUpdate = debounce((signedUrl, html) => {
    // console.log("handleHtmlUpdate");
    
    // For in-browser printing
    this.handleSetPrintContent(html);

    // Persist Content
    let utf8Encode = new TextEncoder();
    const byteArray = utf8Encode.encode(html);
    return GetUploadBinaryPromise(byteArray, signedUrl, null,
      {
        documentId: this.props.documentPackage.Document.ID,
        useHtmlObjectName: true,
      }, 
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("html progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // This will cause the document to be updated, thus the search document with the latest document content
        if (!Boolean(this.props.userAssignmentContext)) {
          this.props.onUpdateDocument({...this.props.documentPackage}, false);
        }

        // console.log("complete");
        return { resp: null, err: null, };
      }
    )
    // .catch(this.handleApiError);
  }, 1000)

  handleRemirrorStateUpdate = debounce((signedUrl, state) => {
    // console.log("handleRemirrorStateUpdate");
    
    // Persist Content
    let utf8Encode = new TextEncoder();
    const byteArray = utf8Encode.encode(state);
    return GetUploadBinaryPromise(byteArray, signedUrl, null,
      {
        documentId: this.props.documentPackage.Document.ID,
        useObject2Name: true,
      }, 
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("remirror state progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      }
    )
    // .catch(this.handleApiError);
  }, 1000)

  setRevisionsVisibility = ShowRevisionsDialog => {
    this.setState({ShowRevisionsDialog});
    if (ShowRevisionsDialog) {
      this.initRevisionsDialog();
    } else {
      this.setState({
        SelectedRevisionID: null,
        ShowDialogProgressIndicatorImmediately:false,
      });
    }
  }

  initRevisionsDialog = () => {
    this.loadDocumentRevisions()
      .then(revisions => {
        this.showRevision(revisions[0]);
      });
  }

  handleEditorLoaded = (onGetHtml, onSetCollaboratorDestroyAndReloadRequest) => {
    this.OnGetHtml = onGetHtml;
    this.OnSetCollaboratorDestroyAndReloadRequest = onSetCollaboratorDestroyAndReloadRequest;
  }

  setPrintSetupVisibility = ShowPrintSetupDialog => {
    this.setState({ShowPrintSetupDialog});
    if (!ShowPrintSetupDialog) {
      if (this.state.PrintSetupChanged) {
        this.OnSetCollaboratorDestroyAndReloadRequest();
        this.handleSetPrintContent(this.OnGetHtml());
      }
      this.setState({PrintSetupChanged:false});
    }
  }

  handlePaperOrientationChange = e => {
    const PaperOrientation = GetValidPaperOrientationString(e.target.value);
    this.setState({PaperOrientation, PrintSetupChanged: true});

    let documentPackage = {...this.props.documentPackage};
    documentPackage.Document.PaperOrientation = PaperOrientation;
    this.props.onUpdateDocument(documentPackage);
    this.setPrintContainerStyle();
  }

  handlePaperSizeChange = size => {
    const PaperSize = GetValidPaperSizeString(size);
    this.setState({PaperSize, PrintSetupChanged: true});

    let documentPackage = {...this.props.documentPackage};
    documentPackage.Document.PaperSize = PaperSize;
    this.props.onUpdateDocument(documentPackage);
    this.setPrintContainerStyle();
  }

  handlePaperMarginChange = property => e => {
    this.setState({[property]: e.target.value, PrintSetupChanged: true});

    let documentPackage = {...this.props.documentPackage};
    documentPackage.Document[property] = parseFloat(e.target.value);
    this.props.onUpdateDocument(documentPackage);
    this.setPrintContainerStyle();
  }

  handleGetPrintStyles = () => {
    const {
      PaperSize,
      PaperOrientation,
      PaperMarginLeft,
      PaperMarginRight,
      PaperMarginTop,
      PaperMarginBottom,
    } = this.state;
    return GetPrintStyles(PaperSize, PaperOrientation,
      PaperMarginLeft, PaperMarginRight, PaperMarginTop, PaperMarginBottom);
  }

  handleSetPrintContent = html => {
    const printRoot = document.getElementById('printRoot');
    if (printRoot) {
      printRoot.innerHTML = html;
      this.setPrintContainerStyle();
    }
  }

  setPrintContainerStyle = () => {
    const printRootContainer = document.getElementById('printRootContainer');
    if (printRootContainer) {
      if (printRootContainer.classList && printRootContainer.classList.length) {
        printRootContainer.classList.forEach(c => {
          if (c.startsWith("print-")) {
            printRootContainer.classList.remove(c);
          }
        });
      }
      printRootContainer.classList.add(GetPrintContainerStyle(this.state.PaperSize, this.state.PaperOrientation));
    }
  }

  showRevision = documentRevision => {
    if (this.state.SelectedRevisionID === documentRevision.ID) {
      return;
    }

    // console.log(documentRevision);
    this.setState({
      ShowDialogProgressIndicatorImmediately:true,
      SelectedRevisionID: documentRevision.ID,
    });
    // Load the current and its prior revision. If it's the first one, combine with the current document.
    const currentRevisionIndex = this.state.DocumentRevisions.indexOf(documentRevision);

    const getPriorRevision = currentRevisionIndex => {
      const priorRevision = this.state.DocumentRevisions[currentRevisionIndex + 1];
      return this.getSignedUrlForDocumentRevisionHtml(priorRevision.ID)
        .then(priorRevisionUrl => {
          return axios.get(priorRevisionUrl);
        });
    }

    this.getSignedUrlForDocumentRevisionHtml(documentRevision.ID)
      .then(currentRevisionUrl => {
        return axios.get(currentRevisionUrl)
          .then(currentRevisionResp => {
            // console.log("Current", currentRevisionResp.data);
            if (documentRevision.Revision === 0) {
              if (this.HtmlDiffRef) {
                this.HtmlDiffRef.innerHTML = currentRevisionResp.data;
              }
            } else {
              return getPriorRevision(currentRevisionIndex)
                .then(priorRevisionResp => {
                  // console.log("Prior", priorRevisionResp.data);
                  if (this.HtmlDiffRef) {
                    this.HtmlDiffRef.innerHTML = HtmlDiff.execute(priorRevisionResp.data, currentRevisionResp.data);
                  }
                })
                .catch(err => {
                  if (err.response && err.response.status === 404) {
                    if (this.HtmlDiffRef) {
                      this.HtmlDiffRef.innerHTML = currentRevisionResp.data;
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject(err);
                });
            }
          })
          .catch(err => {
            if (err.response && err.response.status === 404) {
              if (documentRevision.Revision > 0) {
                return getPriorRevision(currentRevisionIndex)
                  .then(priorRevisionResp => {
                    if (this.HtmlDiffRef) {
                      this.HtmlDiffRef.innerHTML = priorRevisionResp.data;
                    }
                    return Promise.resolve();
                  });
              }
              return Promise.resolve();
            }
            return Promise.reject(err);
          })
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowDialogProgressIndicatorImmediately:false});
      });
  }

  handleSetRevisionRestoreConfirmationVisibility = ShowRevisionRestoreConfirmationDialog => {
    this.setState({ShowRevisionRestoreConfirmationDialog});
  }

  handleBeginRestoreRevision = SelectedRevisionIDForRestore => {
    this.setState({ SelectedRevisionIDForRestore });
    this.handleSetRevisionRestoreConfirmationVisibility(true);
  }

  handleRestoreRevision = () => {
    // console.log("restoring");
    this.handleSetRevisionRestoreConfirmationVisibility(false);

    if (!this.state.SelectedRevisionIDForRestore) {
      return;
    }

    this.setState({
      RevisionRestoreActive:true,
      ShowDialogProgressIndicatorImmediately:true,
    });

    const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));
    API.get(GetDocumentRevisionContentRestorePathForApi(this.props.organizationId, this.props.projectId, 
      this.props.documentPackage.Document.ID, this.state.SelectedRevisionIDForRestore))
      .then(resp => {
        // console.log(resp);
        if (resp && resp.data) {
          this.setState({ DocumentRevisionIDForContentLoad: resp.data.ID });
        }
      })
      .catch(this.handleApiError)
      .finally(async () => {
        this.setState({
          RevisionRestoreActive:false,
          ForceEditorReloadForAllCollaboratorsTimestamp: new Date(),
        });
        // This sleep + setRevisionsVisibility doesn't seem to work correctly on the 2nd+ attempts
        // when collaborators are present. So we need to try something different.
        // this.setRevisionsVisibility(false);

        await sleep(3000);
        window.location.reload();
      });
  }

  handleGetFieldsForMerge = debounce((filter) => {
    return API.get(GetFieldsPathForApi(this.props.organizationId, this.props.projectId), { params: { filter }})
      .then(resp => {
        return resp.data.Fields
          .filter(f => f.Type !== "FieldType_Image");
      })
      .catch(this.handleApiError);
  }, 250)

  handleApiError = err => {
    this.setState({
      ShowDialogProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err);
    setTimeout(() => this.handleApiError(null), 1);
  }

  render() {
    const {
      DocumentRevisions,
      SelectedRevisionID,
      ShowRevisionsDialog,
      ForceEditorReloadForAllCollaboratorsTimestamp,
      ShowPrintSetupDialog,
      ShowGetMoreRevisionsButton,
      ShowRevisionRestoreConfirmationDialog,
      RevisionRestoreActive,
      PaperSize,
      PaperOrientation,
      PaperMarginLeft,
      PaperMarginRight,
      PaperMarginTop,
      PaperMarginBottom,
      // ApiError,
      ShowDialogProgressIndicatorImmediately,
    } = this.state;
    const {
      UserPreferences,
    } = this.context;
    const {
      organizationId,
      projectId,
      documentPackage,
      classes,
      theme,
      onSetOtherAppBarContent,
      hostWidth,
    } = this.props;

    if (!UserPreferences) {
      return null;
    }

    // console.log("host render");

    const dialogProgressIndicator = (ShowDialogProgressIndicatorImmediately)
      ? <ProgressIndicator constrained showImmediately={ShowDialogProgressIndicatorImmediately} />
      : null;

    const revisionsDialogActions = (
      <DialogActions className={classes.revisionsDialogActions}>
        <Button onClick={() => this.setRevisionsVisibility(false)}>
          CLOSE
        </Button>
      </DialogActions>
    );

    // This skips revision 0, which should always be empty
    let documentRevisionListItems = DocumentRevisions.map((r, i) => {
      const selected = SelectedRevisionID === r.ID;
      const restoreButton = (selected && i > 0)
        ? (
          <Tooltip title="Restore this revision" onClick={() => this.handleBeginRestoreRevision(r.ID)}>
            <IconButton size="small">
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        ) : null;
      return (r.Revision > 0)
        ? (
          <ListItem key={`k_${r.ID}`}
            onClick={() => this.showRevision(r)}
            selected={selected}
            button
          >
            <ListItemText>
              {GetDateValue(r.CreatedOn)}
            </ListItemText>
            {restoreButton}
          </ListItem>
        )
        : null
    });
    if (ShowGetMoreRevisionsButton) {
      documentRevisionListItems.push(
        <ListItem key="k_getMore"
          onClick={() => this.loadDocumentRevisions(true)}
          button
        >
          GET MORE
        </ListItem>
      );
    }

    const confirmRestoreRevisionDialogDetails = {
      Open: ShowRevisionRestoreConfirmationDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Restore this revision?",
      IsConfirmation: true,
      BodyText:"The content of this revision will be copied to a new revision.",
      ConfirmCallback: this.handleRestoreRevision,
      CancelCallback: () => this.handleSetRevisionRestoreConfirmationVisibility(false),
      CloseCallback: () => this.handleSetRevisionRestoreConfirmationVisibility(false),
    };

    let revisionsDialogContent;
    if (ShowRevisionsDialog) {
      revisionsDialogContent = (
        <List>
          {documentRevisionListItems}
        </List>
      );
    }

    const revisionsDialog = (ShowRevisionsDialog) 
    ? (
      <Dialog
        // fullScreen={IsMobile()}
        fullScreen
        // fullWidth
        maxWidth="xl"
        open={ShowRevisionsDialog}
        classes={{
          root: classes.revisionsDialogRoot,
          paper:classes.revisionsDialogPaper,
        }}
        onClose={() => this.setRevisionsVisibility(false)}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className={classes.revisionsDialogTitle}>
          {`Revisions for ${documentPackage.Document.Name}`}
        </DialogTitle>
        <DialogContent className={classes.revisionsDialogContent}>
          {dialogProgressIndicator}
          <MultiUseDialog Details={confirmRestoreRevisionDialogDetails} />
          
          <div className={classes.revisionsDialogContentContainer}>
            <div className={classes.revisionsDialogRevisionsPane}>
              {revisionsDialogContent}
            </div>
            <div className={classNames("remirror-theme", classes.revisionsDialogDiff)}>
              <div className={classNames("remirror-editor-wrapper")}>
                <div className={classNames("ProseMirror", "remirror-editor", "remirror-a11y-dark")}>
                  <div ref={instance => this.HtmlDiffRef = instance} />
                </div>
              </div>
            </div>
          </div>

        </DialogContent>
        {revisionsDialogActions}
      </Dialog>
    ) : null;

    const printSetupDialog = (ShowPrintSetupDialog) 
    ? (
      <Dialog
        fullScreen={IsMobile()}
        // fullWidth
        maxWidth="xs"
        open={ShowPrintSetupDialog}
        // classes={{
        //   root: classes.printSetupDialogRoot,
        //   paper:classes.printSetupDialogPaper,
        // }}
        onClose={() => this.setPrintSetupVisibility(false)}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Print Setup
        </DialogTitle>
        <DialogContent>
          {dialogProgressIndicator}
          
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SelectControl
                id="select_paperSize"
                label="Paper size"
                hideEmpty
                options={GetPaperSizeOptions()} 
                value={PaperSize}
                onValueChange={this.handlePaperSizeChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption">Orientation</Typography>
              <RadioGroup row
                name="orientation"
                value={PaperOrientation}
                onChange={this.handlePaperOrientationChange}
              >
                <FormControlLabel
                  value="portrait"
                  control={
                    <Radio 
                      size="small" 
                      name="orientation_portrait"
                    />
                  }
                  label={<Typography>Portrait</Typography>}
                />
                <FormControlLabel
                  value="landscape"
                  control={
                    <Radio 
                      size="small" 
                      name="orientation_landscape"
                    />
                  }
                  label={<Typography>Landscape</Typography>}
                />
              </RadioGroup>
            </Grid>
            <Grid item>
              <Typography variant="caption">Margin (inches)</Typography>
              <Grid container spacing={2} style={{marginTop:theme.spacing(1),maxWidth:240}}>
                <Grid item xs={6}>
                  <NumericTextField
                    id="printMarginLeft"
                    label="Left"
                    value={PaperMarginLeft}
                    onValueChange={this.handlePaperMarginChange("PaperMarginLeft")}
                    isAllowedFunc={IsPaperMarginValueAllowed}
                    decimalPlaces={3}
                    hideClearButton
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <NumericTextField
                    id="printMarginRight"
                    label="Right"
                    value={PaperMarginRight}
                    onValueChange={this.handlePaperMarginChange("PaperMarginRight")}
                    isAllowedFunc={IsPaperMarginValueAllowed}
                    decimalPlaces={3}
                    hideClearButton
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <NumericTextField
                    id="printMarginLeft"
                    label="Top"
                    value={PaperMarginTop}
                    onValueChange={this.handlePaperMarginChange("PaperMarginTop")}
                    isAllowedFunc={IsPaperMarginValueAllowed}
                    decimalPlaces={3}
                    hideClearButton
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <NumericTextField
                    id="printMarginLeft"
                    label="Bottom"
                    value={PaperMarginBottom}
                    onValueChange={this.handlePaperMarginChange("PaperMarginBottom")}
                    isAllowedFunc={IsPaperMarginValueAllowed}
                    decimalPlaces={3}
                    hideClearButton
                   />
                 </Grid>
               </Grid>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setPrintSetupVisibility(false)}>
            CLOSE
          </Button>
      </DialogActions>
      </Dialog>
    ) : null;

    const documentEditor = (!RevisionRestoreActive)
      ? (
        <DocumentEditor
          organizationId={organizationId}
          projectId={projectId}
          uniqueIdForCollab={documentPackage.Document.ID}
          disabled={this.getIsDisabled()}
          // parentScrollTop={ScrollTop}
          autoFocus
          userName={UserPreferences.UserName}
          userEmail={UserPreferences.UserEmail}
          onLoadDocumentContent={this.handleLoadDocumentContent}
          onEditorLoaded={this.handleEditorLoaded}
          onSetPrintContent={this.handleSetPrintContent}
          onSetOtherAppBarContent={onSetOtherAppBarContent}
          forceEditorReloadForAllCollaboratorsTimestamp={ForceEditorReloadForAllCollaboratorsTimestamp}
          onShowRevisions={() => this.setRevisionsVisibility(true)}
          onShowPrintSetup={() => this.setPrintSetupVisibility(true)}
          onGetPrintStyles={this.handleGetPrintStyles}
          onYjsUpdate={this.handleYjsUpdate}
          onHtmlUpdate={this.handleHtmlUpdate}
          onTextUpdate={() => Promise.resolve({ resp: null, err: null })}
          onRemirrorStateUpdate={this.handleRemirrorStateUpdate}
          onGetFieldsPromise={this.handleGetFieldsForMerge}
          onApiError={this.handleApiError}
          hostWidth={hostWidth}
        />
      ) : null;

    return (
      <React.Fragment>
        {documentEditor}
        {revisionsDialog}
        {printSetupDialog}
      </React.Fragment>
    );
  }
}

DocumentEditorHost.propTypes = {
  hostWidth: PropTypes.number,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  userAssignmentContext: PropTypes.string,
  documentFolderId: PropTypes.string,
  approvalId: PropTypes.string,
  taskId: PropTypes.string,
  documentPackage: PropTypes.object.isRequired,
  onUpdateDocument: PropTypes.func.isRequired,
  onSetOtherAppBarContent: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(DocumentEditorHost);
