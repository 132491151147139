import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';

import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import AsyncSelectControl from '../Components/AsyncSelectControl';

import API, {
  GetProjectPathForApi,
  GetProjectMembersPathForApi,
} from '../Util/api';
import {
  GetUserValue,
} from '../Util/Properties';
import UiCore from '../Components/UiCore';
import {
  GetProjectAccessGridItems,
} from '../Util/Project';
import {
  GetProjectMemberListValuesPromise,
} from '../Util/ProjectMembers';

const styles = theme => ({
  contentContainer: {
    background:theme.palette.background.paper,
    padding: theme.spacing(3),
    minHeight: "100%",
  },
  profileGrid: {
  },
  stakeholdersGrid: {
    alignItems: "center",
  },
  stakeholderContainer: {
    display:"flex",
    "&:hover $stakeholderRemove": {
      visibility:"visible",
    },
  },
  stakeholderRemove: {
    visibility:"hidden",
  },
  infoIcon: {
    // position:"absolute",
    // bottom: theme.spacing(1),
    // right: theme.spacing(1),
    opacity:0.5,
  },
});

class ProjectProfile extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Project: JSON.parse(JSON.stringify(props.project)),
      ProjectAccess: JSON.parse(JSON.stringify(props.project.Access)),
      StakeholderProjectMembers: [],
      ShowAddStakeholderDialog: false,
      StakeholderProjectMemberListValuesToAdd: [],
      ShowAccessChangeConfirmationDialog: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleSetAccessChangeConfirmationVisibility = ShowAccessChangeConfirmationDialog => {
    this.setState({ ShowAccessChangeConfirmationDialog });
  }

  handleChangeProjectAccess = () => {
    this.handleSetAccessChangeConfirmationVisibility(false);
    const originalProject = {...this.state.Project};
    const updatedProject = {
      ...this.state.Project,
      Access: this.state.ProjectAccess,
    };
    this.setState({
      Project: JSON.parse(JSON.stringify(updatedProject)),
      ShowProgressIndicatorImmediately: true,
    });
    const params = {
      timezoneOffsetMinutes: (new Date()).getTimezoneOffset(),
    };
    API.put(GetProjectPathForApi(this.props.organizationId, this.state.Project.ID), updatedProject, { params })
      .then(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
        window.location.reload();
      })
      .catch(err => {
        this.setState({
          Project: JSON.parse(JSON.stringify(originalProject)),
        });
        this.handleApiError(err);
      });
  }

  handleAssignmentsChange = propertyName => e => {
    let ProjectAccess = {...this.state.ProjectAccess};
    ProjectAccess.Assignments[propertyName] = e.target.value;
    this.setState({ ProjectAccess });
  }

  handleMemberAccessChange = propertyName => e => {
    let ProjectAccess = {...this.state.ProjectAccess};
    ProjectAccess.MemberAccess[propertyName] = e.target.value;
    this.setState({ ProjectAccess });
  }

  loadStakeholderProjectMembers = () => {
    API.get(GetProjectMembersPathForApi(this.props.organizationId, this.state.Project.ID), 
      { params: { getAll: true }})
      .then(resp => {
        this.setState({
          StakeholderProjectMembers:
            resp.data.ProjectMembers
              .filter(pm => pm.IsStakeholder),
        });
      })
      .catch(this.handleApiError);
  }

  handleSetShowAddStakeholderDialogVisibility = ShowAddStakeholderDialog => {
    this.setState({ShowAddStakeholderDialog});
  }

  handleGetProjectMemberListValuesPromise = filter => {
    return GetProjectMemberListValuesPromise(this.props.organizationId, this.state.Project.ID, filter)
      .then(listValues => {
        return listValues
          .filter(lv => !this.state.StakeholderProjectMembers.find(spm => spm.ID === lv.value));
      })
      .catch(this.handleApiError);
  }

  handleAddStakeholderProjectMemberListValues = StakeholderProjectMemberListValuesToAdd => {
    this.setState({StakeholderProjectMemberListValuesToAdd});
  }

  updateProjectMembers = (projectMembersToUpdate, onProjectMembersUpdated) => {
    this.setState({ShowProgressIndicatorImmediately:true});
    
    API.put(GetProjectMembersPathForApi(this.props.organizationId, this.state.Project.ID),
      projectMembersToUpdate)
      .then(resp => {
        if (onProjectMembersUpdated) {
          onProjectMembersUpdated(resp.data);
        }
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleAddStakeholderProjectMembers = () => {
    this.handleSetShowAddStakeholderDialogVisibility(false);
    const projectMembersToUpdate = this.state.StakeholderProjectMemberListValuesToAdd
      .map(lv => ({
        ...lv.projectMember,
        IsStakeholder: true,
      }));
    return this.updateProjectMembers(
      projectMembersToUpdate,
      updatedProjectMembers => {
        this.setState({
          StakeholderProjectMembers: [...this.state.StakeholderProjectMembers, ...updatedProjectMembers],
          StakeholderProjectMemberListValuesToAdd:[],
        });
      },
    );
  }

  handleDeleteStakeholderProjectMember = projectMemberId => {
    const stakeholderProjectMember = this.state.StakeholderProjectMembers.find(pm => pm.ID === projectMemberId);
    if (stakeholderProjectMember) {
      stakeholderProjectMember.IsStakeholder = false;
      return this.updateProjectMembers(
        [stakeholderProjectMember],
        updatedProjectMembers => {
          this.setState({
            StakeholderProjectMembers: [...this.state.StakeholderProjectMembers]
              .filter(pm => pm.ID !== projectMemberId),
          });
        },
      );
    }
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.setState({
        ApiError:err,
        ShowProgressIndicatorImmediately: false,
      });
      this.props.onApiError(err);
    }
  }

  handleAlert = details => {
    this.setState({Alert:details});
    if (this.props.onAlert) {
      this.props.onAlert(details);
    }
  }

  componentDidMount() {
    this.loadStakeholderProjectMembers();
  }

  render() {
    const {
      Project,
      ProjectAccess,
      StakeholderProjectMembers,
      ApiError,
      Alert,
      ShowAddStakeholderDialog,
      StakeholderProjectMemberListValuesToAdd,
      ShowProgressIndicatorImmediately,
      ShowAccessChangeConfirmationDialog,
    } = this.state;
    const {
      // organizationId,
      theme,
      classes,
      returnContentOnly,
    } = this.props;

    const accessChangeConfirmationDialogDetails = {
      Open:ShowAccessChangeConfirmationDialog,
      IsConfirmation:true,
      Title:"Change permissions?",
      BodyText:"Are you sure you want to change this project's permissions? It will take some time to propagate the change to all items in the project.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetAccessChangeConfirmationVisibility(false),
      CloseCallback:() => this.handleSetAccessChangeConfirmationVisibility(false),
      ConfirmCallback:() => this.handleChangeProjectAccess(),
    };

    const projectAccessGridItems = GetProjectAccessGridItems(
      classes,
      ProjectAccess,
      this.handleAssignmentsChange,
      this.handleMemberAccessChange,
      4,
    );

    const changeProjectAccessButtonGridItem = (JSON.stringify(Project.Access) !== JSON.stringify(ProjectAccess))
      ? (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => this.handleSetAccessChangeConfirmationVisibility(true)}
          >
          UPDATE
          </Button>
        </Grid>
      ): null;

    const accessGrid = (
      <Grid container spacing={3} className={classes.profileGrid}>
        {projectAccessGridItems}
        {changeProjectAccessButtonGridItem}
      </Grid>
    );

    const stakeholderGridItems = StakeholderProjectMembers
      .map(pm => (
        <Grid item key={`stakeholder_${pm.ID}`}>
          <div className={classes.stakeholderContainer}>
            {GetUserValue(pm.UserEmail, pm.UserName)}
            <Tooltip title="Remove stakeholder">
              <IconButton size="small" aria-label="Delete" className={classes.stakeholderRemove}
                onClick={() => this.handleDeleteStakeholderProjectMember(pm.ID)}>
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      ));
    stakeholderGridItems.push(
      <Grid item key="stakeholder_add">
        <Tooltip title="Add stakeholder">
          <IconButton aria-label="Add"
            onClick={() => this.handleSetShowAddStakeholderDialogVisibility(true)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    );

    const stakeholdersGrid = (
      <Grid container spacing={3} className={classes.stakeholdersGrid}>
        {stakeholderGridItems}
      </Grid>
    );

    const addStakeholderDialogDetails = {
      Open:ShowAddStakeholderDialog,
      Title: "Add Stakeholders",
      DialogWidth: "sm",
      FullWidth: true,
      IsConfirmation: true,
      ConfirmLabel: "ADD",
      BodyContent: (
        <AsyncSelectControl label="Project members" 
          // floatingOptions
          isMulti
          autoFocus
          onGetOptionsFilterPromise={this.handleGetProjectMemberListValuesPromise}
          listValues={StakeholderProjectMemberListValuesToAdd}
          onValueChange={this.handleAddStakeholderProjectMemberListValues}
          notClearable
        />
      ),
      CancelCallback:() => this.handleSetShowAddStakeholderDialogVisibility(false),
      CloseCallback:() => this.handleSetShowAddStakeholderDialogVisibility(false),
      ConfirmCallback:() => this.handleAddStakeholderProjectMembers(),
    };

    const progressIndicator = (ShowProgressIndicatorImmediately) ? (
      <ProgressIndicator showImmediately />
    ) : null;

    const content = (
      <Container maxWidth="md" className={classes.contentContainer}>
        {progressIndicator}
        <MultiUseDialog Details={accessChangeConfirmationDialogDetails} />
        <MultiUseDialog Details={addStakeholderDialogDetails} />
        <Typography variant="h6" style={{marginBottom:theme.spacing(2)}}>
          Project permissions
        </Typography>
        {accessGrid}
        <Divider style={{marginTop:theme.spacing(2)}}/>
        <Typography variant="h6" style={{marginTop:theme.spacing(3),marginBottom:theme.spacing(2)}}>
          Project stakeholders
        </Typography>
        {stakeholdersGrid}
      </Container>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title="Project Profile"
        apiError={ApiError}
        alert={Alert}
        content={content}
      />
    );
  }
}

ProjectProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  onApiError: PropTypes.func,
  onAlert: PropTypes.func,
  returnContentOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ProjectProfile);