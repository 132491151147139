import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AsyncSelectControl from '../../Components/AsyncSelectControl';
import DocumentEditor, {
  BasicToolKeys,
} from '../../Components/DocumentEditor';
import {
  HandleGetProcessElementMergeFieldsPromise,
} from '../../Util/DocumentEditor';
import debounce from 'es6-promise-debounce';
import axios from 'axios';

import {
  GetProcessElementEmailBodyUploadsPathForApi,
  GetUploadBinaryPromise,
} from '../../Util/api';

import ProcessElementDialogHelper from '../../Util/ProcessElementDialogs';

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption, onSetCloseDialogFunc, context, theme, classes, constants) {
    
  if (!processElement) {
    return null;
  }

  const dh = new ProcessElementDialogHelper(location, history, organizationId, projectId, processElement, processElementConnections_Input,
    onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty, 
    onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
    onSelectionListValueChange, onSelectionListCreateOption);

  const handleLoadBodyContent = () => {
    if (processElement.StateUrl) {
      return axios.get(processElement.StateUrl, { responseType: 'arraybuffer' })
        .catch(err => {});
    } else {
      return Promise.resolve({});
    }
  };

  const handleBodyYjsUpdate = debounce((yDoc, incrementRevision, onReservationObtained) => {
    // console.log("handleBodyYjsUpdate");

    // Persist YJS document
    return GetUploadBinaryPromise(yDoc, null, 
      GetProcessElementEmailBodyUploadsPathForApi(organizationId, projectId, processElement.ProcessID, processElement.ID),
      { incrementRevision, },
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("ydoc progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      },
      onReservationObtained,
    )
    // .catch(this.handleApiError);
  }, 1000);

  const handleBodyHtmlUpdate = debounce((signedUrl, html) => {
    // console.log("handleBodyHtmlUpdate");
    // Persist Content
    let utf8Encode = new TextEncoder();
    const byteArray = utf8Encode.encode(html);
    return GetUploadBinaryPromise(byteArray, signedUrl, null,
      {
        useHtmlObjectName: true,
      }, 
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("html progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      }
    )
    // .catch(this.handleApiError);
  }, 1000);

  const handleBodyTextUpdate = debounce((signedUrl, text) => {
    // console.log("handleBodyTextUpdate");
    // Persist Content
    let utf8Encode = new TextEncoder();
    const byteArray = utf8Encode.encode(text);
    return GetUploadBinaryPromise(byteArray, signedUrl, null,
      {
        useHtmlObjectName: true,
      }, 
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("text progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      }
    )
    // .catch(this.handleApiError);
  }, 1000);

  const handleBodyRemirrorStateUpdate = debounce((signedUrl, state) => {
    // console.log("handleBodyRemirrorStateUpdate");
    
    // Persist Content
    let utf8Encode = new TextEncoder();
    const byteArray = utf8Encode.encode(state);
    return GetUploadBinaryPromise(byteArray, signedUrl, null,
      {
        useObject2Name: true,
      }, 
      {}, 
      (totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
        // console.log("remirror state progress", totalSize, completedSize, completedAtServer, completionResponse, completionError);
      },
      () => {
        // console.log("complete");
        return { resp: null, err: null, };
      }
    )
    // .catch(this.handleApiError);
  }, 1000);

  const setAppendApprovalActionsBool = value => {
    onChangeHandlers.setBoolProperty("AppendApprovalActions", true, value);
    // Force attachment when approval actions appended
    if (value) {
      onChangeHandlers.setBoolProperty("AttachDocument", true, true);
    }
  }

  const appendApprovalActions_onChange = e => {
    setAppendApprovalActionsBool(e.target.checked);
  }

  let hasApprovalNotificationConnection = 
    processElementConnections_Input
    && processElementConnections_Input.filter(c => 
      c.SourceProcessElementSubtype === "" // Support for pre-task ProcessElementConnections - treat SourceProcessElementSubtype as if they are Approval
        || c.SourceProcessElementSubtype === "Approval").length > 0
    && processElementConnections_Input.filter(c => c.SourcePortType === "notifications").length > 0;

  let hasTaskConnection = 
    processElementConnections_Input
    && processElementConnections_Input.filter(c => c.SourceProcessElementSubtype === "Task").length > 0;
  let hasTaskNotificationConnection = 
    hasTaskConnection
    && processElementConnections_Input.filter(c => c.SourcePortType === "notifications").length > 0;
  let hasTaskDueDateNotificationConnection = 
    hasTaskConnection
    && processElementConnections_Input.filter(c => c.SourcePortType === "deadlineNotifications").length > 0;

  let gridItems = [];
  if (hasApprovalNotificationConnection
    || hasTaskNotificationConnection
    || hasTaskDueDateNotificationConnection) {
    gridItems.push(
      <Grid key="email_to" item xs={12}>
        <AsyncSelectControl label="To" 
          // forceShrinkLabel
          floatingOptions
          onGetOptionsFilterPromise={() => Promise.resolve()} 
          onValueChange={() => {}}
          listValues={{ value: null, label: "Task assignee" }}
          isMulti
          disabled
        />
      </Grid>
    );
  } else {
    gridItems.push(
      <Grid key="email_to" item xs={12}>
        {dh.GetEmailAddressControl("To", "To", true, true, true, true, true)}
      </Grid>
    );
  }

  if (processElement.Data.Subject === undefined) {
    let defaultSubject = "";
    if (hasApprovalNotificationConnection) {
      defaultSubject = "Approval Assignment";
    } else if (hasTaskNotificationConnection) {
      defaultSubject = "Task Assignment";
    } else if (hasTaskDueDateNotificationConnection) {
      defaultSubject = "Task Reminder";
    }
    if (defaultSubject) {
      setTimeout(() => onChangeHandlers.handleTextFieldChange("Subject", true)(defaultSubject), 1); // setTimeout avoids an issue/warning about render behavior
    }
  }

  if (processElement.Data.Body === undefined) {
    let defaultBody = "";
    if (hasApprovalNotificationConnection) {
      defaultBody = "An approval item has been assigned to you.";
    } else if (hasTaskNotificationConnection) {
      defaultBody = "A task has been assigned to you.";
    } else if (hasTaskDueDateNotificationConnection) {
      defaultBody = "A task due date is approaching.";
    }
    if (defaultBody) {
      setTimeout(() => onChangeHandlers.handleTextFieldChange("Body", true)(defaultBody), 1); // setTimeout avoids an issue/warning about render behavior
    }
  }

  gridItems.push(
    <Grid key="email_cc" item xs={12}>
      {dh.GetEmailAddressControl("CC", "CC", true, true, true, true, true)}
    </Grid>,
    <Grid key="email_subject" item xs={12}>
      <TextField
        variant="outlined"
        label="Subject"
        value={processElement.Data.Subject}
        onChange={onChangeHandlers.handleTextFieldChange("Subject", true)}
        fullWidth
      />
    </Grid>,
  );

  // Set some options if at least one input connection's source port is approval notifications
  let appendApprovalActions = false;
  if (hasApprovalNotificationConnection) {
    if (processElement.Data.AppendApprovalActions === undefined) {
     // default true
     setTimeout(() => setAppendApprovalActionsBool(true), 1); // setTimeout avoids an issue/warning about render behavior
    }
    appendApprovalActions = (processElement.Data.AppendApprovalActions === undefined // default true
      || processElement.Data.AppendApprovalActions) ;

    gridItems.push(
      <Grid key="email_appendApprovalActions" item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={appendApprovalActions}
              onChange={appendApprovalActions_onChange}
            />
          }
          label="Add approve/decline actions" />
      </Grid>
    );
  }

  if (!hasTaskConnection) {
    gridItems.push(
      <Grid key="email_attachment" item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              disabled={hasApprovalNotificationConnection && processElement.Data.AppendApprovalActions}
              checked={appendApprovalActions
                || (processElement.Data.AttachDocument !== undefined
                  && processElement.Data.AttachDocument)}
              onChange={onChangeHandlers.handleCheckboxChange("AttachDocument", true)}
            />
          }
          label="Attach document (when applicable)" />
      </Grid>
    );
  }

  const toolKeys = [...BasicToolKeys, "mergeData"];

  gridItems.push(
    <Grid key="email_body" item xs={12} style={{maxHeight:500,marginTop:-8}} className={classes.documentEditorContainer}>
      <DocumentEditor
        organizationId={organizationId}
        projectId={projectId}
        uniqueIdForCollab={processElement.ID}
        userEmail={context.UserPreferences.UserEmail}
        userName={context.UserPreferences.UserName}
        label="Body"
        onLoadDocumentContent={handleLoadBodyContent}
        onYjsUpdate={handleBodyYjsUpdate}
        onHtmlUpdate={handleBodyHtmlUpdate}
        onTextUpdate={handleBodyTextUpdate}
        onRemirrorStateUpdate={handleBodyRemirrorStateUpdate}
        onApiError={onApiError}
        maxHeadingLevels={3}
        desktopToolKeys={toolKeys}
        mobileToolKeys={toolKeys}
        desktopToolStyle={{
          paddingLeft: 0,
          backgroundColor: theme.palette.background.paper,
        }}
        editorContainerStyle={{
          backgroundColor: constants.documentEditorBackground,
        }}
        onGetFieldsPromise={filter => HandleGetProcessElementMergeFieldsPromise(organizationId, projectId, filter)}
        mergeValueKey="ID"
        mergeLabelKey="Name"
      />
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      {gridItems}
    </Grid>
  );
}