import { GetFieldForFilterAndSort } from '../Util/Field';
import {
  TextFieldOperators,
} from '../Util/Search';

const FormShareCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[AssignmentUserEmail].keyword",
		"Assigned to",
		"FieldType_Text",
		null,
	    [
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	        value: "notEquals",
	        label: "does not equal",
	      },
	    ],
	    "addressBookItemsList",
	    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProjectName].keyword",
		"Project",
		"FieldType_Text",
		"Meta_text_kw256lc[ProjectName]",
		null,
	    "projectList",
	    true,
	    true, // SortOnly - filter doesn't make sense because there's no need when in /projects and /workspace is by folder
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw50lc[Tag].keyword",
		"Tags",
		"FieldType_Text",
		"Meta_text_kw50lc[Tag]",
		null,
	  	"tags",
	  	true,
	  	false,
	  	null,
	  	null,
	  	false,
	  	TextFieldOperators,
	  	true,
	),
];

export default FormShareCollectionFields;