import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import NoSsr from '@material-ui/core/NoSsr';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import CancelIcon from '@material-ui/icons/Cancel';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const handleChange = e => {
    if (props.selectProps.onInputChange && e && e.target) {
      props.selectProps.onInputChange(e.target.value);
    }
  }

  return (
    <TextField
    //  ref={instance => (props.selectProps.connectDropTarget && props.selectProps.connectDragSource) 
    //    ? props.selectProps.connectDropTarget(props.selectProps.connectDragSource(ReactDOM.findDOMNode(instance)))
    //    : null
      // }
      variant="outlined"
      style={{opacity:(props.selectProps.disabled)?0.5:1}}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
      onKeyPress={props.selectProps.onKeyPress}
      onChange={handleChange}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper}
      style={{ 
        position: (props.selectProps.floatingOptions) ? "absolute" :"static",// What was this for: (!props.selectProps.isMulti) ? "static" : "absolute"
      }}
      {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={(props.data.noClear) ? null : props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      onClick={(props.selectProps.onCanClickValue && props.selectProps.onValueClick && props.selectProps.onCanClickValue(props.data))
        ? e => {
          e.preventDefault();
          e.stopPropagation();
          props.selectProps.onValueClick(e, props.data);
        }
        : undefined
      }
    />
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  if (props.data.divider) {
    return (
      <Divider />
    );
  }
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      style={{
        fontSize: props.selectProps.compact ? 14 : undefined,
      }}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps} component="div">
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}
    style={{
      flexWrap: (props.selectProps.noFlexWrap) ? "nowrap" : undefined,
      color: props.selectProps.valueColor,
      marginTop: (props.selectProps.compact) ? 6 : undefined,
      marginBottom: (props.selectProps.compact) ? 6 : undefined,
      paddingTop: (props.selectProps.compact) ? 0 : undefined,
      overflow: (props.selectProps.compact) ? "visible" : (props.selectProps.noFlexWrap) ? "hidden" : undefined,
      maxHeight: (props.selectProps.isMulti)
        ? "inherit"
        : (props.selectProps.singleLineHeight)
            ? 27
            : undefined,
    }}>{props.children}</div>;
}

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height:'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    // The following margin items are necessary for variants outlined/filled
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2) - 3,
    marginLeft: theme.spacing(2) - 2,
    /* These two properties, width:0 and overflow:hidden must remain to fix 
    an issue with the react-select component growing to infinity as users type in the input */
    // Not needed anymore? Tested a few locations 11/2021 and seems to be better without these two.
    // width: 0,
    // overflow: "hidden",
    /* This is a further fix, which prevents the valueContainer from having a large height after a value is in place */
    /* This doesn't appear to be needed now, and, furthermore, it breaks the ability for the height to grow with multi-select fields */ 
    // maxHeight: 27,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
  },
  paper: {
    // The following is necessary for variants outlined/filled
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AsyncSelectControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0,
      field: '',
      inputValue: "",
      UpdateID: null,
    }
  }

  // Workaround to enable home/end keys on input instead of options
  // https://github.com/JedWatson/react-select/issues/3562
  handleKeyDown = (evt)=>{
    switch(evt.key){
      case "Home": evt.preventDefault();
        if(evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0,0);
        break;
      case "End": evt.preventDefault();
        const len = evt.target.value.length;
        if(evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len,len);
        break;
      default:
        break;
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt, this.state.inputValue);
    }
  };

  handleReload = () => {
    if (this.props.autoReloadOnValueChange) {
      this.setState({UpdateID: new Date()});
    }
  }

  handleCreateOption = newValue => {
    this.handleReload();
    this.props.onCreateOption(newValue);
  }

  handleValueChange = selectedOptions => {
    this.handleReload();
    this.props.onValueChange(selectedOptions);
  }

  componentDidMount() {
  }

  render() {
    const { 
      classes,
      theme,
      placeholder,
      label,
      value,
      listValues,
      onGetOptionsFilterPromise,
      autoFocus,
      helperText,
      isMulti,
      compact,
      noFlexWrap,
      disabled,
      onCreateOption,
      onKeyPress,
      onCanClickValue,
      onValueClick,
      notClearable,
      allowCreateWhileLoading,
      key,
      updateId,
      connectRef,
      floatingOptions,
      tabIndex,
      menuIsOpen,
      openMenuOnFocus,
    } = this.props;
    const {
      UpdateID,
    } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      clearIndicator: base => ({
        ...base,
        cursor: "pointer",
      }),
      dropdownIndicator: base => ({
        ...base,
        cursor: "pointer",
        color: (disabled) ? theme.palette.text.disabled : "inherit",
      }),
      indicatorSeparator: base => ({
        ...base,
        backgroundColor: (disabled) ? theme.palette.text.disabled : "inherit",
      }),
    };

    return (onCreateOption)
      ? (
        <NoSsr>
          <AsyncCreatableSelect
            autoFocus={autoFocus}
            key={UpdateID || updateId || key} // This is a hack to force the component to update its list when the value changes
            classes={classes}
            styles={selectStyles}
            tabIndex={tabIndex}
            textFieldProps={{
              className: classes.textField,
              label,
              InputLabelProps: {
                shrink: true,
                disabled,
              },
              helperText,
            }}
            menuIsOpen={menuIsOpen}
            openMenuOnFocus={openMenuOnFocus}
            valueColor={(disabled) ? theme.palette.text.disabled : "inherit"}
            floatingOptions={floatingOptions}
            onCanClickValue={onCanClickValue}
            onValueClick={onValueClick}
            ref={instance => (connectRef) ? connectRef(instance) : null}
            isDisabled={disabled}
            placeholder={(disabled) ? "" : (placeholder !== undefined) ? placeholder : "Select or enter new..."}
            loadOptions={onGetOptionsFilterPromise}
            defaultOptions={true}
            cacheOptions={false}
            isClearable={!notClearable}
            components={components}
            value={value || listValues}
            onChange={this.handleValueChange}
            onInputChange={inputValue => this.setState({inputValue})}
            onCreateOption={this.handleCreateOption}
            formatCreateLabel={inputValue => `Add "${inputValue}"`}
            createOptionPosition="first"
            isMulti={isMulti}
            compact={compact}
            noFlexWrap={noFlexWrap}
            allowCreateWhileLoading={allowCreateWhileLoading}
            onKeyDown={event => this.handleKeyDown(event)}
            onKeyPress={onKeyPress}
          />
        </NoSsr>
      )
      : (
        <NoSsr>
          <AsyncSelect
            autoFocus={autoFocus}
            key={updateId || key} // This is a hack to force the component to update its list when the value changes
            classes={classes}
            styles={selectStyles}
            tabIndex={tabIndex}
            textFieldProps={{
              className: classes.textField,
                label,
                InputLabelProps: {
                shrink: true,
                disabled
              },
              helperText,
            }}
            menuIsOpen={menuIsOpen}
            openMenuOnFocus={openMenuOnFocus}
            valueColor={(disabled) ? theme.palette.text.disabled : "inherit"}
            floatingOptions={floatingOptions}
            onCanClickValue={onCanClickValue}
            onValueClick={onValueClick}
            isDisabled={disabled}
            placeholder={(disabled) ? "" : placeholder}
            loadOptions={onGetOptionsFilterPromise}
            defaultOptions={true}
            cacheOptions={false}
            isClearable={!notClearable}
            helperText={helperText}
            components={components}
            onInputChange={inputValue => this.setState({inputValue})}
            value={value || listValues}
            onChange={this.handleValueChange}
            isMulti={isMulti}
            compact={compact}
            noFlexWrap={noFlexWrap}
            onKeyDown={event => this.handleKeyDown(event)}
            onKeyPress={onKeyPress}
          />
        </NoSsr>
      );
  }
}

AsyncSelectControl.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  label: PropTypes.string,
  compact: PropTypes.bool,
  tabIndex: PropTypes.number,
  noFlexWrap: PropTypes.bool,
  onGetOptionsFilterPromise: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onValueClick: PropTypes.func,
  onCanClickValue: PropTypes.func,
  autoReloadOnValueChange: PropTypes.bool,
  updateId: PropTypes.any,
  key: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(AsyncSelectControl);