export function GetOrganizationManagementPath() {
	return "/organizationManagement";
}

export function GetOrganizationManagementOrganizationPath(organizationId) {
	return `${GetOrganizationManagementPath()}/${organizationId}`;
}

export function GetOrganizationProfilePath(organizationId) {
	return `${GetOrganizationManagementOrganizationPath(organizationId)}/profile`;
}

export function GetOrganizationMembersPath(organizationId) {
	return `${GetOrganizationManagementOrganizationPath(organizationId)}/members`;
}

export function GetOrganizationMemberPath(organizationId, memberId) {
	return `${GetOrganizationMembersPath(organizationId)}/${memberId}`;
}

export function GetOrganizationSubscriptionPath(organizationId) {
	return `${GetOrganizationManagementOrganizationPath(organizationId)}/subscription`;
}

function getOrganizationCollectionPath(organizationId, collectionId) {
	return `/organizations/${organizationId}/${collectionId}`;
}

function getOrganizationCollectionItemPath(organizationId, collectionId, collectionItemId) {
	return `${getOrganizationCollectionPath(organizationId, collectionId)}/${collectionItemId}`;
}

export function GetOrganizationProjectsPath(organizationId) {
	return getOrganizationCollectionPath(organizationId, "projects");
}

export function GetOrganizationProjectPath(organizationId, projectId) {
	return getOrganizationCollectionItemPath(organizationId, "projects", projectId);
}

export function GetProjectsPath() {
	return "/projects";
}

export function GetProjectPath(projectId) {
	return `${GetProjectsPath()}/${projectId}`;
}

export function GetProjectCollectionPath(projectId, collectionId) {
	return `${GetProjectPath(projectId)}/${collectionId}`;
}

export function GetProjectCollectionItemPath(projectId, collectionId, collectionItemId) {
	return `${GetProjectCollectionPath(projectId, collectionId)}/${collectionItemId}`;
}

export function GetProjectTagsPath(projectId) {
	return `${GetProjectSettingsPath(projectId)}/tags`;
}

export function GetProjectTagPath(projectId, tagId) {
	return `${GetProjectTagsPath(projectId)}/${tagId}`;
}

export function GetProjectMembersPath(projectId, addNew) {
	return `${GetProjectSettingsPath(projectId)}/members${(addNew) ? "?addNew=1" : ""}`;
}

export function GetProjectMemberPath(projectId, projectMemberId) {
	return `${GetProjectMembersPath(projectId)}/${projectMemberId}`;
}

export function GetProjectCommentsPath(projectId) {
	return GetProjectCollectionPath(projectId, "comments");
}

export function GetProjectTasksPath(projectId, addNew) {
	return `${GetProjectCollectionPath(projectId, "tasks")}${(addNew) ? "?addNew=1" : ""}`;
}

export function GetProjectTaskPath(projectId, taskId) {
	return GetProjectCollectionItemPath(projectId, "tasks", taskId);
}

export function GetProjectTasksManagementPath(projectId) {
	return `${GetProjectTasksPath(projectId)}/management`;
}

export function GetProjectCompletedTasksPath(projectId) {
	return `${GetProjectTasksManagementPath(projectId)}/completedTasks`;
}

export function GetProjectCompletedTaskPath(projectId, taskId) {
	return `${GetProjectCompletedTasksPath(projectId)}/${taskId}`;
}

export function GetProjectDeletedTasksPath(projectId) {
	return `${GetProjectTasksManagementPath(projectId)}/deletedTasks`;
}

export function GetProjectDeletedTaskPath(projectId, taskId) {
	return `${GetProjectDeletedTasksPath(projectId)}/${taskId}`;
}

export function GetProjectApprovalsPath(projectId) {
	return GetProjectCollectionPath(projectId, "approvals");
}

export function GetProjectApprovalPath(projectId, approvalId) {
	return GetProjectCollectionItemPath(projectId, "approvals", approvalId);
}

export function GetProjectAssetItemsPath(projectId, assetId) {
	return GetProjectCollectionPath(projectId, `assetItems_${assetId}`);
}

export function GetProjectAssetItemPath(projectId, assetId, assetItemId) {
	return GetProjectCollectionItemPath(projectId, `assetItems_${assetId}`, assetItemId);
}

export function GetProjectAssetItemsArchivedPath(projectId, assetId) {
	return GetProjectCollectionPath(projectId, `assetItems_${assetId}/management/archivedAssetItems`);
}

export function GetProjectAssetItemArchivedPath(projectId, assetId, assetItemId) {
	return GetProjectCollectionItemPath(projectId, `assetItems_${assetId}/management/archivedAssetItems`, assetItemId);
}

export function GetProjectSettingsPath(projectId) {
	return GetProjectCollectionPath(projectId, "settings");
}

export function GetProjectFieldsPath(projectId) {
	return `${GetProjectSettingsPath(projectId)}/fields`;
}

export function GetProjectFieldPath(projectId, fieldId) {
	return `${GetProjectFieldsPath(projectId)}/${fieldId}`;
}

export function GetProjectAssetsPath(projectId) {
	return `${GetProjectSettingsPath(projectId)}/assets`;
}

export function GetProjectAssetPath(projectId, assetId) {
	return `${GetProjectAssetsPath(projectId)}/${assetId}`;
}

export function GetProjectTaskMilestonesPath(projectId) {
	return `${GetProjectSettingsPath(projectId)}/taskMilestones`;
}

export function GetProjectTaskMilestonePath(projectId, taskMilestoneId) {
	return `${GetProjectTaskMilestonesPath(projectId)}/${taskMilestoneId}`;
}

export function GetProjectTaskStatesPath(projectId) {
	return `${GetProjectSettingsPath(projectId)}/taskStates`;
}

export function GetProjectTaskStatePath(projectId, taskStateId) {
	return `${GetProjectTaskStatesPath(projectId)}/${taskStateId}`;
}

export function GetProjectFormTemplatesPath(projectId, addNew) {
	return `${GetProjectSettingsPath(projectId)}/formTemplates${(addNew) ? "?addNew=1" : ""}`;
}

export function GetProjectFormsPath(projectId, addNew) {
	return `${GetProjectPath(projectId)}/forms${(addNew) ? "?addNew=1" : ""}`;
}

export function GetProjectFoldersPath(projectId) {
	return GetProjectCollectionPath(projectId, "folders");
}

export function GetProjectDocumentsPath(projectId, addNew, createRootFolder) {
	let params = {};
	if (addNew) {
		params.addNew = true;
	}
	if (createRootFolder) {
		params.createRootFolder = true;
	}
	let qs = "";
	if (Object.keys(params).length) {
		qs = "?" + Object.keys(params).map(k => { return `${k}=${params[k]}`; }).join("&");
	}
	return `${GetProjectCollectionPath(projectId, "documents")}${qs}`;
}

export function GetProjectDocumentFoldersPath(projectId) {
	return GetProjectCollectionPath(projectId, "documents/documentFolders");
}

export function GetProjectDocumentFolderPath(projectId, documentFolderIdsInOrder) {
	return `${GetProjectDocumentFoldersPath(projectId)}/${documentFolderIdsInOrder.join("/")}`;
}

export function GetProjectProcessesPath(projectId, addNew) {
	return `${GetProjectSettingsPath(projectId)}/processes${(addNew) ? "?addNew=1" : ""}`;
}

export function GetProjectNeedSignaturePath(projectId) {
	return GetProjectCollectionPath(projectId, "signatures");
}

export function GetProjectFollowedDocumentsPath(projectId) {
	return GetProjectCollectionPath(projectId, "followedDocuments");
}

export function GetDocumentDetailPath(organizationId, projectId, documentId) {
	return `${GetOrganizationProjectPath(organizationId, projectId)}/documents/${documentId}`;
}

export function GetPublicDocumentDetailInlinePath(pathPrefix, documentId) {
	return `${pathPrefix}/documents/${documentId}`;
}

export function GetPublicDocumentFoldersPath(organizationId, projectId, documentId) {
	return `/public/folders/${organizationId}/${projectId}`;
}

export function GetPublicDocumentFoldersDocumentPrefix(propsLocation) {
	return propsLocation.pathname.split("/documents/")[0];
}

export function GetPublicDocumentDetailPath(organizationId, projectId, documentId) {
	return `/public/documents/${organizationId}/${projectId}/${documentId}`;
}

export function GetFormTemplateDesignerPath(organizationId, projectId, formTemplateId) {
	return `${GetOrganizationProjectPath(organizationId, projectId)}/formTemplates/${formTemplateId}`;
}

export function GetTaskPath(organizationId, projectId, taskId) {
	return `${GetOrganizationProjectPath(organizationId, projectId)}/tasks/${taskId}`;
}

export function GetProcessDesignerPath(organizationId, projectId, processId) {
	return `${GetOrganizationProjectPath(organizationId, projectId)}/processes/${processId}`;
}

export function GetUserApiKeysPath() {
	return "/user/apiKeys";
}

export function GetUserProfilePath() {
	return "/user/profile";
}

export function GetUserApiKeyPath(userApiKeyId) {
	return `${GetUserApiKeysPath()}/${userApiKeyId}`;
}

export function GetOrganizationLink(organizationId, path) {
	if (path.charAt(0) !== "/") {
		path = `/${path}`;
	}
	return `/organizations/${organizationId}/link${path}`;
}

export function GetHomePath() {
	return "/home";
}

export function GetDashboardPath() {
	return "/dashboard";
}

export function GetSearchPath() {
	return "/search";
}

export function GetWorkspacePath() {
	return "/workspace";
}

export function GetSupportPath() {
	return "/support";
}

export function GetSupportUpcomingEventsPath() {
	return `${GetSupportPath()}/upcomingEvents`;
}

export function GetSupportStatsPath() {
	return `${GetSupportPath()}/stats`;
}

export function GetSupportOrganizationsPath() {
	return `${GetSupportPath()}/organizations`;
}

export function GetSupportOrganizationsRequiringSubscriptionPath() {
	return `${GetSupportPath()}/organizationsRequiringSubscription`;
}

export function GetSupportOrganizationDetailPath(organizationId) {
	return `${GetSupportOrganizationsPath()}/${organizationId}`;
}

export function GetSupportUsersPath() {
	return `${GetSupportPath()}/users`;
}

export function GetSupportUserActivityPath() {
	return `${GetSupportPath()}/userActivity`;
}

export function GetSupportErrorEventsPath() {
	return `${GetSupportPath()}/errorEvents`;
}

export function GetWorkspaceApprovalsPath() {
	return`${GetWorkspacePath()}/approvals`;
}

export function GetWorkspaceApprovalPath(approvalId) {
	return `${GetWorkspaceApprovalsPath()}/${approvalId}`;
}

export function GetWorkspaceApprovalsProjectsPath() {
	return `${GetWorkspaceApprovalsPath()}/projects`;
}

export function GetWorkspaceApprovalsProjectPath(projectId) {
	return `${GetWorkspaceApprovalsProjectsPath()}/${projectId}`;
}

export function GetWorkspaceTasksPath() {
	return `${GetWorkspacePath()}/tasks`;
}

export function GetWorkspaceTaskPath(taskId) {
	return `${GetWorkspaceTasksPath()}/${taskId}`;
}

export function GetWorkspaceTasksProjectsPath() {
	return `${GetWorkspaceTasksPath()}/projects`;
}

export function GetWorkspaceTasksProjectPath(projectId) {
	return `${GetWorkspaceTasksProjectsPath()}/${projectId}`;
}

export function GetWorkspaceTasksProjectTaskPath(projectId, taskId) {
	return `${GetWorkspaceTasksProjectPath(projectId)}/${taskId}`;
}

export function GetWorkspaceSignaturesPath() {
	return `${GetWorkspacePath()}/signatures`;
}

export function GetWorkspaceDocumentFoldersPath(projectId) {
	return `${GetWorkspacePath()}/documents/projects/${projectId}/documentFolders`;
}

export function GetWorkspaceDocumentFolderPath(projectId, documentFolderIdsInOrder) {
	return `${GetWorkspaceDocumentFoldersPath(projectId)}/${documentFolderIdsInOrder.join("/")}`;
}


export const AppleAppStoreUrl = "https://apps.apple.com/app/nucleus-one-teams-management-by-ademero/1627526884";
export const AndroidAppStoreUrl = "https://play.google.com/store/apps/details?id=com.ademero.nucleus_one_mobile";
export const DiscordInviteUrl = "https://discord.com/invite/TSHVRVscGT";
export const YouTubeChannelUrl = "https://www.youtube.com/@nucleusone1069";
export const KnowledgeBaseUrl = "https://nucleus.one/learn";
export const QuoraAnswersUrl = "https://nucleusone.quora.com";