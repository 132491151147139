import React from 'react';
// import { GetTextWithUrlsAsAnchors } from './Regex';

import {
  GetProjectTaskPath,
  GetProjectCompletedTaskPath,
  GetProjectDeletedTaskPath,
  GetWorkspaceTaskPath,
} from './PathHelper';
import {
  GetBodyHtmlWithModifiedAnchors,
} from './Regex';
import axios from 'axios';
import API,
{
  GetTaskActionsPathForApi,
  GetUserOrganizationProjectTaskActionsPathForApi,
  GetProjectTaskSubscriptionsPathForApi,
  ProcessApiCallsGroupedByProjectForItems,
} from './api';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

export const HandleRouteToTask = (props, projectId, taskId, isProjectMember, openInNewTab,
  forCompletedTasks, forDeniedTasks) => {
    const uri = (isProjectMember)
      ? (forCompletedTasks)
        ? GetProjectCompletedTaskPath(projectId, taskId)
        : (forDeniedTasks)
          ? GetProjectDeletedTaskPath(projectId, taskId)
          : GetProjectTaskPath(projectId, taskId)
      : GetWorkspaceTaskPath(taskId);
    if (openInNewTab) {
      window.open(uri, "n1_taskContent")
    } else {
      props.history.push(uri, { ...props.location.state });  
    }
  }

export const GetTaskWithAssignmentOption = (option, currentTask) => {
    if (option && option.value) {
      currentTask.AssignmentUserEmail = option.value;
      currentTask.AssignmentUserName = "";
    } else {
      currentTask.AssignmentUserEmail = "";
      currentTask.AssignmentUserName = "";
    }
    return currentTask;
  }

export const GetFormattedDescriptionPromise = (task, theme, classes) => {
  if (!task.DescriptionHtmlUrl) {
    return Promise.resolve(null);
  }
  return axios.get(task.DescriptionHtmlUrl)
    .then(resp => {
      if (resp && resp.data) {
        return (
          <div
            className={classes.TaskDescription}
            dangerouslySetInnerHTML={{
              __html: GetBodyHtmlWithModifiedAnchors(resp.data, theme),
            }}
          >
          </div>
        );
      }
      return null;
    });
}

export const GetDueOnStyle = (dueOn, keyOverride) => {
  const taskDueOn = new Date(dueOn);
  const isPastDue = taskDueOn > 0 && taskDueOn < new Date();
  const isDueLessThan24Hours = taskDueOn > 0 && new Date(taskDueOn.getTime() - 60*60*24*1000) < new Date();
  return {
    [(keyOverride) ? keyOverride : "color"]:(taskDueOn > 0)
      ? (isPastDue)
        ? red[500]
        : (isDueLessThan24Hours)
          ? yellow[500]
          : green[500]
      : undefined,
  };
}

export function CompleteTasks(tasks, accessByProjectMembership) {
  const getUri = (organizationId, projectId) => {
    return (accessByProjectMembership)
      ? `${GetTaskActionsPathForApi(organizationId, projectId)}/complete`
      : `${GetUserOrganizationProjectTaskActionsPathForApi(organizationId, projectId)}/complete`; 
  }
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.post(getUri(itemsByProject.OrganizationID, itemsByProject.ProjectID),
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function AssignTasks(tasks, AssignmentUserEmail, AssignmentUserName) {
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.post(
      `${GetTaskActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/assign`,
        {
          IDs: itemsByProject.Items.map(i => i.ID),
          AssignmentUserEmail,
          AssignmentUserName,
        }));
}

export function RestoreTasks(tasks) {
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.post(
      `${GetTaskActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/restore`,
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function DenyTasks(tasks) {
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.post(
      `${GetTaskActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/deny`,
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function CreateTaskSubscriptions(tasks) {
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.post(
      GetProjectTaskSubscriptionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID), 
      { TaskIDs: itemsByProject.Items.map(i => i.ID) }));
}

export function DeleteTaskSubscriptions(tasks) {
  return ProcessApiCallsGroupedByProjectForItems(tasks, 
    itemsByProject => API.delete(
      GetProjectTaskSubscriptionsPathForApi(
      itemsByProject.OrganizationID, itemsByProject.ProjectID),
      { data: { TaskIDs: itemsByProject.Items.map(i => i.ID) } }));
}