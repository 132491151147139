const getProjectMemberPackage = (context, projectId) => {
  return context.ProjectMembershipPackages
    .find(p => p.Project.ID === projectId);
}

export const IsUserProjectMember = (context, projectId) => {
  return Boolean(getProjectMemberPackage(context, projectId));
}

export const IsUserProjectAdmin = (context, projectId) => {
  const projectMemberPackage = getProjectMemberPackage(context, projectId);
 return projectMemberPackage && projectMemberPackage.ProjectMember.IsAdmin;
}