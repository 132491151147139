import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import AccountCircle from '@material-ui/icons/AccountCircle';

import API, {
  GetUserOrganizationsPathForApi,
  GetOrganizationMembershipPackagesPathForApi,
  GetOrganizationPermissionsPathForApi,
  GetProjectMembershipPackagesPathForApi,
  GetUserPreferencesPathForApi,
  GetSupportAdminPathForApi,
  GetUserHasUnreadOrganizationNotificationsPathForApi,
  GetPublicAvatarImagePathForApi,
} from '../Util/api';
import {
  GetReloadItemsFlag,
  SetReloadItemsFlag,
  ClearReloadItemsFlag,
} from '../Util/Items';
import {
  GetUserValue,
} from '../Util/Properties';
import {
  GetSingleUserPreference_Bool,
  SaveSingleUserPreference_Bool,
} from '../Util/SingleUserPreference';
import debounce from 'es6-promise-debounce';

export const GlobalContext = React.createContext();
export const GlobalContextConsumer = GlobalContext.Consumer;

class GlobalContextProvider extends Component {
  constructor(props) {
    super(props);

    const defaultAvatarSize = 38;

    this.initialState = {
      CompletedGET: {},
      UserPreferences: {},
      UserAvatar: (<AccountCircle style={{width:defaultAvatarSize,height:defaultAvatarSize}} />),
      UserHasCustomAvatar: false,
      ActiveOrganizationPermissions: {},
      OrganizationMembershipPackages: [],
      ProjectMembershipPackages: [],
      UserOrganizations: [],
      UserIsSupportAdmin: false,
      UserHasUnreadNotification: false,
      ViewedWelcomeDialog: true,
      ApiError: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.UniqueID = new Date();
    this.ResetContextUniqueID = null;
    this.ResetContextCheckIntervalID = null;
  }

  getUserPreferences = () => {
    return API.get(GetUserPreferencesPathForApi())
      .then(resp => {
        this.setState({
          UserPreferences: resp.data,
        });
        return resp.data;
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, UserPreferences: true}});
      });
  }

  saveUserPreferences(userPreferences) {
    userPreferences = {...this.state.UserPreferences, ...userPreferences};
    if (!userPreferences) {
      return Promise.resolve();
    }

    return API.put(GetUserPreferencesPathForApi(), userPreferences)
      .then(resp => {
        return resp.data;
      });
  }
  
  saveUserPreferencesProperty = namesAndValues => {
    let userPreferences = {...this.state.UserPreferences};
    for (let i = 0; i < Object.keys(namesAndValues).length; i++) {
      let key = Object.keys(namesAndValues)[i];
      userPreferences[key] = namesAndValues[key];
    }
    this.setState({ UserPreferences: userPreferences });
    return this.saveUserPreferences(userPreferences);
  }

  handleSaveUserPreferences_ActiveOrganization = organizationId => {
    if (!organizationId) {
      return Promise.resolve({});
    }
    const getPromise = userOrganizations => {
      const userOrgFinder = userOrganizations.filter(uo => uo.OrganizationID === organizationId);
      if (!userOrgFinder.length) {
        return Promise.resolve({});
      }
      const userOrg = userOrgFinder[0];
      return this.saveUserPreferencesProperty({
        ActiveOrganizationID: userOrg.OrganizationID, 
        ActiveOrganizationName: userOrg.OrganizationName,
        ActiveOrganizationMember: userOrg.IsOrganizationMember,
      });
    }

    if (this.state.CompletedGET.UserOrganizations
      && this.state.UserOrganizations.filter(uo => uo.OrganizationID === organizationId).length) {
      return getPromise([...this.state.UserOrganizations]);
    } else {
      return this.getUserOrganizations()
        .then(userOrganizations => {
          return getPromise(userOrganizations);
        });
    }
  }

  handleSaveUserPreferences_ActiveProject = projectId => {
    if (!projectId) {
      return Promise.resolve({});
    }
    return this.saveUserPreferencesProperty({
      ActiveProjectID: projectId, 
    });
  }

  handleGetAvatar = userPreferences => {
    if (!userPreferences) {
      userPreferences = {...this.state.UserPreferences};
    }

    if (userPreferences) {
      return API.get(GetPublicAvatarImagePathForApi(userPreferences.UserEmail), { responseType: "arraybuffer" })
        .then(resp =>  {
          const UserHasCustomAvatar = Boolean(resp.data && resp.data.byteLength);
          let avatarSrc = null; // srcOverride === null will prevent user menu avatar from reloading on page changes
          if (UserHasCustomAvatar) {
            const content = btoa(String.fromCharCode(...new Uint8Array(resp.data)));
            avatarSrc = `data:${resp.headers["content-type"]};base64,${content}`;
          }
          const UserAvatar = 
              GetUserValue(userPreferences.UserEmail, userPreferences.UserName, "lg", true, avatarSrc, {}, {}, true);
            this.setState({
              UserHasCustomAvatar,
              UserAvatar,
            });
          return UserAvatar;
        });
    } else {
      return Promise.resolve(null);
    }
  }

  getActiveOrganizationPermissions = (activeOrganizationId, saveToState) => {
    if (!activeOrganizationId && this.state.HasUserPreferences) {
      activeOrganizationId = this.state.UserPreferences.ActiveOrganizationID;  
    }
    if (!activeOrganizationId) {
      return Promise.resolve({});
    }
    return API.get(GetOrganizationPermissionsPathForApi(activeOrganizationId))
      .then(resp => {
        if (saveToState) {
          this.setState({
            ActiveOrganizationPermissions: resp.data,
          });
        }
        return resp.data;
      })
      .catch(err => {
        // Ignore a 403/404, which is an indication the current user is not a organization member
        if (!err.response || (err.response.status !== 404 && err.response.status !== 403)) {
          return Promise.reject(err);
        }
        return Promise.resolve({});
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, ActiveOrganizationPermissions: true}});
      });
  }

  getOrganizationMembershipPackages = () => {
    return API.get(GetOrganizationMembershipPackagesPathForApi())
      .then(resp => { 
        this.setState({
          OrganizationMembershipPackages: resp.data.OrganizationMembershipPackages,
        });
        return resp.data.OrganizationMembershipPackages;
      })
      .catch(err => {
        // Ignore a 403/404, which is an indication the current user is not a organization member
        if (!err.response) {// || (err.response.status !== 404 && err.response.status !== 403)) {
          return Promise.reject(err);
        }
        return Promise.resolve([]);
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, OrganizationMembershipPackages: true}});
      });
  }

  getProjectMembershipPackages = organizationId => {
    return API.get(GetProjectMembershipPackagesPathForApi(organizationId))
      .then(resp => { 
        this.setState({
          ProjectMembershipPackages: resp.data.ProjectMembershipPackages,
        });
        return resp.data.ProjectMembershipPackages;
      })
      .catch(err => {
        // Ignore a 403/404, which is an indication the current user is not a organization member
        if (!err.response) {// || (err.response.status !== 404 && err.response.status !== 403)) {
          return Promise.reject(err);
        }
        return Promise.resolve([]);
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, ProjectMembershipPackages: true}});
      });
  }

  getUserOrganizations = () => {
    return API.get(GetUserOrganizationsPathForApi())
      .then(resp => {
        this.setState({
          UserOrganizations: resp.data,
        });
        return resp.data;
      })
      .catch(err => {
        if (!err.response) {
          return Promise.reject(err);
        }
        return Promise.resolve([]);
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, UserOrganizations: true}});
      });
  }

  updateUserOrganizationName = (organizationId, name) => {
    const UserOrganizations = [...this.state.UserOrganizations];
    const orgFinder = UserOrganizations.filter(uo => uo.OrganizationID === organizationId);
    if (orgFinder.length) {
      orgFinder[0].OrganizationName = name;
      this.setState({UserOrganizations});
    }
  }

  getUserIsSupportAdmin = () => {
    return API.get(GetSupportAdminPathForApi())
      .then(resp => {
        this.setState({
          UserIsSupportAdmin: resp.data,
        });
        return resp.data;
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, UserIsSupportAdmin: true}});
      });
  }

  getUserHasUnreadNotification = debounce((activeOrganizationId) => {
    if (!activeOrganizationId) {
      this.setState({
        CompletedGET: { ...this.state.CompletedGET, UserHasUnreadNotification: true, },
        UserHasUnreadNotification: false,
      });
      return Promise.resolve(false);
    }
    return API.get(GetUserHasUnreadOrganizationNotificationsPathForApi(activeOrganizationId))
      .then(resp => {
        this.setState({
          UserHasUnreadNotification: (resp && resp.data !== undefined) ? resp.data : false,
        });
        return resp.data;
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, UserHasUnreadNotification: true}});
      });
  }, 500);

  // This must be set as a cookie since it is used across multiple browser windows/tabs
  getResetContextFlag = () => {
    return this.props.cookies.get("resetContext");
  }
  setResetContextFlag = uniqueId => {
    this.ResetContextUniqueID = uniqueId;
    this.props.cookies.set("resetContext", true, { path: "/" });
    // Set a timeout to force clear the flag after all additional tabs (if any) would have already done so
    setTimeout(() => {
      this.clearResetContextFlag();
    }, 3000);
  }
  clearResetContextFlag = () => {
    // We execute via timeout to ensure all all tabs see the call to reload
    setTimeout(() => {
      this.props.cookies.remove("resetContext", { path: "/", sameSite: "lax" });
      this.ResetContextUniqueID = null;
    }, 1000);

  }
  checkResetContextFlag = () => {
    // console.log("checkResetContextFlag", this.UniqueID);
    if (this.getResetContextFlag()) {
      // Avoid reload in the same source browser tab
      if (this.ResetContextUniqueID !== this.UniqueID) {
        // console.log("reloading");
        this.clearResetContextFlag();
        this.handleReset();
      } else {
        // console.log("skipping");
      }
    }
    this.ResetContextCheckIntervalID = setTimeout(this.checkResetContextFlag, 1000);
  }

  handleReset = () => {
    this.setState({
      ...this.initialState,
    });
    this.initiateContext();
    // this.setResetContextFlag(this.UniqueID); // For other tabs
  }

  handleGetViewedWelcomeDialog = queryLeadData => {
    // Postpone until lead data gathered
    if (queryLeadData) {
      return this.setState({ViewedWelcomeDialog:true});
    }
    if (localStorage.getItem("viewedWelcomeDialog")) {
      return this.setState({ViewedWelcomeDialog:true});
    }
    GetSingleUserPreference_Bool("ViewedWelcomeDialog")
      .then(ViewedWelcomeDialog => {
        this.setState({ViewedWelcomeDialog});
      })
  }

  handleSetViewedWelcomeDialog = ViewedWelcomeDialog => {
    this.setState({ViewedWelcomeDialog});
    SaveSingleUserPreference_Bool("ViewedWelcomeDialog", ViewedWelcomeDialog);
  }

  handleSetViewedWelcomeDialogThisSession = () => {
    this.setState({ViewedWelcomeDialog:true});
    localStorage.setItem("viewedWelcomeDialog", true);
  }

  initiateContext = () => {
    this.getUserOrganizations()
      .catch(ApiError => this.setState({ApiError}));
    this.getUserPreferences()
      .then(userPreferences => {
        this.handleGetViewedWelcomeDialog(userPreferences.QueryLeadData);
        this.handleGetAvatar(userPreferences);
        this.getUserHasUnreadNotification(userPreferences.ActiveOrganizationID);
        if (userPreferences.ActiveOrganizationMember) {
          // Ensure anything listed within this condition is CompletedGET handled in the else {} below
          // for workspace-only users where ActiveOrganizationMember will be false
          this.getActiveOrganizationPermissions(userPreferences.ActiveOrganizationID, true)
            .catch(ApiError => this.setState({ApiError}));
          this.getProjectMembershipPackages(userPreferences.ActiveOrganizationID)
            .catch(ApiError => this.setState({ApiError}));
        } else {
          this.setState({
            CompletedGET:{
              ...this.state.CompletedGET,
              ActiveOrganizationPermissions: true,
              ProjectMembershipPackages: true,
            }
          });
        }
      })
      .catch(ApiError => this.setState({ApiError}));
    this.getOrganizationMembershipPackages()
      .catch(ApiError => this.setState({ApiError}));
    this.getUserIsSupportAdmin();
    
  }

  componentDidMount() {
    this.initiateContext();
    // this.checkResetContextFlag();
  }

  componentWillUnmount() {
    // clearTimeout(this.ResetContextCheckIntervalID);
  }

  render() {
    const { children } = this.props;
    
    return (
      <GlobalContext.Provider
        value={{
          ApiError: this.state.ApiError,
          
          Reset: this.handleReset,
          
          CompletedGET: this.state.CompletedGET,

          UserPreferences: this.state.UserPreferences,
          GetUserPreferences: this.getUserPreferences,
          SaveUserPreferences_ActiveOrganization: this.handleSaveUserPreferences_ActiveOrganization,
          SaveUserPreferences_ActiveProject: this.handleSaveUserPreferences_ActiveProject,
          SaveUserPreferences_SelectedTwainDevice: value1 => 
            this.saveUserPreferencesProperty({SelectedTwainDevice: value1}), 
          SaveUserPreferences_DisableTwainDeviceSoftware: value1 => 
            this.saveUserPreferencesProperty({DisableTwainDeviceSoftware: value1}),

          UserAvatar: this.state.UserAvatar,
          GetAvatar: this.handleGetAvatar,
          UserHasCustomAvatar: this.state.UserHasCustomAvatar,

          UserIsSupportAdmin: this.state.UserIsSupportAdmin,
          
          UserHasUnreadNotification: this.state.UserHasUnreadNotification,
          GetUserHasUnreadNotification: this.getUserHasUnreadNotification,

          ActiveOrganizationPermissions: this.state.ActiveOrganizationPermissions,
          GetActiveOrganizationPermissions: () => this.getActiveOrganizationPermissions(null, true),

          OrganizationMembershipPackages: this.state.OrganizationMembershipPackages,
          GetOrganizationMembershipPackages: this.getOrganizationMembershipPackages,

          ProjectMembershipPackages: this.state.ProjectMembershipPackages,
          GetProjectMembershipPackages: this.getProjectMembershipPackages,

          UserOrganizations: this.state.UserOrganizations,
          GetUserOrganizations: this.getUserOrganizations,
          UpdateUserOrganizationName: this.updateUserOrganizationName,

          GetReloadItemsFlag: () => GetReloadItemsFlag(this.props),
          SetReloadItemsFlag: () => SetReloadItemsFlag(this.props),
          ClearReloadItemsFlag: () => ClearReloadItemsFlag(this.props),

          ViewedWelcomeDialog: this.state.ViewedWelcomeDialog,
          SetViewedWelcomeDialog: this.handleSetViewedWelcomeDialog,
          SetViewedWelcomeDialogThisSession: this.handleSetViewedWelcomeDialogThisSession,
        }}
      >
        { children }
      </GlobalContext.Provider>
    );
  }
}

export default withCookies(GlobalContextProvider);