import API, {
  GetProcessElementMergeFieldsPathForApi,
} from './api';

import debounce from 'es6-promise-debounce';

// Some of DocumentEditorStyles (like "& pre") borrowed from ProseMirror site.css included on Basic Example page
export const GetDocumentEditorStyles = theme => {
	const commonMarginBlock = {
		marginBlockStart: "0.5em",
		marginBlockEnd: "0.25em",
	};
	return {
    "& li p": {
	  	...commonMarginBlock,
	    marginBlockStart: "0.25em",
	  },
	  "& h1": {
	  	...commonMarginBlock,
	  },
	  "& h2": {
	  	...commonMarginBlock,
	  },
	  "& h3": {
	  	...commonMarginBlock,
	  },
	  "& p": {
      color:"black",
	  	fontSize:16,
	  	...commonMarginBlock,
	  },
	  "& ul": {
	  	...commonMarginBlock,
	  },
	  // "& a": {
	  // 	color:"black",
	  // },
	  "& pre": {
      marginLeft: 20,
      padding:8,
      backgroundColor:"#555",
      color:"white",
      borderRadius: 4,
    },
    "& code": {
	  },
	};
}

const PaperOrientations = [
  "portrait",
  "landscape",
];

const PaperSizes = [
	{ value: "letter", label: "Letter", short: "8.5in", long: "11in", },
  { value: "legal", label: "Legal", short: "8.5in", long: "14in", },
  { value: "tabloid", label: "Tabloid", short: "11in", long: "17in", },
  { value: "statement", label: "Statement", short: "5.5in", long: "8.5in", },
  { value: "executive", label: "Executive", short: "7.25in", long: "10.5in", },
  { value: "folio", label: "Folio", short: "8.5in", long: "13in", },
  { value: "a3", label: "A3", short: "297mm", long: "420mm", },
  { value: "a4", label: "A4", short: "210mm", long: "297mm", },
  { value: "a5", label: "A5", short: "148mm", long: "210mm", },
  { value: "b4", label: "B4", short: "257mm", long: "364mm", },
  { value: "b5", label: "B5", short: "182mm", long: "257mm", },
];

export const GetPaperSizeOptions = () => {
  return PaperSizes.map(s => {
    return { value: s.value, label: s.label };
  })
}

export const GetValidPaperOrientationString = o => {
  let paperOrientation = PaperOrientations[0];
  let paperOrientationFinder = PaperOrientations.filter(po => po === o);
  if (paperOrientationFinder.length) {
    return paperOrientationFinder[0];
  }
  return paperOrientation;
}

export const getValidPaperSize = s => {
  let paperSize = PaperSizes[0];
  let paperSizeFinder = PaperSizes.filter(ps => ps.value === s);
  if (paperSizeFinder.length) {
    return paperSizeFinder[0];
  }
  return paperSize;
}

export const GetValidPaperSizeString = s => {
  let paperSize = PaperSizes[0];
  let paperSizeFinder = PaperSizes.filter(ps => ps.value === s);
  if (paperSizeFinder.length) {
    return paperSizeFinder[0].value;
  }
  return paperSize.value;
}

export const IsPaperMarginValueAllowed = values => {
  const { floatValue } = values;
  return (floatValue >= 0.0 && floatValue <= 10.0);
}

export const GetPrintStyles = (size, orientation, marginLeft, marginRight, marginTop, marginBottom) => {
  let pageStyles = "";
  let mediaPrintStyles = "";
  const addStyles = (s, o) => {
    const paperSize = getValidPaperSize(s);
    pageStyles += ` @page ${s}-${o} { size: ${(o === "portrait") ? paperSize.short : paperSize.long} ${(o === "portrait") ? paperSize.long : paperSize.short};`;
    pageStyles += ` margin-left: ${marginLeft}in; margin-right: ${marginRight}in; margin-top: ${marginTop}in; margin-bottom: ${marginBottom}in;`;
    pageStyles += ` }`;
    mediaPrintStyles += ` .print-${s}-${o} { page: ${s}-${o}; }`;
  };
  addStyles(size, orientation);
  // PaperSizes.forEach(s => {
  //   PaperOrientations.forEach(o => {
  //     addStyles(s.value, o);
  //   });
  // });
  return `${pageStyles} @media print { ${mediaPrintStyles} }`;
}

export const GetPrintContainerStyle = (s, o) => {
  return `print-${s}-${o}`;
}

export const HandleGetProcessElementMergeFieldsPromise = debounce((organizationId, projectId, filter) => {
  return API.get(GetProcessElementMergeFieldsPathForApi(organizationId, projectId), { params: { filter } })
    .then(resp => {
      return resp.data;
    });
}, 250);