import React from 'react';
import Grid from '@material-ui/core/Grid';
import AsyncSelectControl from '../../Components/AsyncSelectControl';
import ProcessElementDialogHelper from '../../Util/ProcessElementDialogs';

const ParticipationTypes = [
  { value: "Any", label: "Any assignee" },
  { value: "All", label: "All assignees"},
  { value: "Majority", label: "Majority of assignees"},
];

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption) {
  
  if (!processElement) {
    return null;
  }

  const dh = new ProcessElementDialogHelper(location, history, organizationId, projectId, processElement, processElementConnections_Input,
      onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty, 
      onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
      onSelectionListValueChange, onSelectionListCreateOption);

  let handleGetParticipationListValuesPromise = filter => {
    // Initial loading will not have the name/label. Set it here.
    let listValueId = [onGetListValuesAndLabelsPropertyName("ParticipationType", false)];
    if (processElement[listValueId] && processElement[listValueId].value) {
      if (!processElement[listValueId].label) {
        const matchingType = ParticipationTypes.find(p => p.value === processElement[listValueId].value);
        if (matchingType) {
          processElement[listValueId].label = matchingType.label;
        }
      } 
    } else {
      // Default value
      onSelectionListValueChange("ParticipationType", false)(ParticipationTypes.filter(p => p.value === "Any")[0]);
    }
    return Promise.resolve(ParticipationTypes);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {dh.GetActionContentForEmailAddressControlAndPreferredItemType("AssignmentUserEmail", "Assigned to",
          true, true, true, true, true, true)}
      </Grid>
      <Grid item>
        <AsyncSelectControl label="Participation required" 
          // forceShrinkLabel
          notClearable
          onGetOptionsFilterPromise={handleGetParticipationListValuesPromise} 
          listValues={processElement[onGetListValuesAndLabelsPropertyName("ParticipationType", false)]}
          onValueChange={onSelectionListValueChange("ParticipationType", false)}
        />
      </Grid>
    </Grid>
  );
}