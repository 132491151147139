import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

// Parse search input to extract quoted phrases and individual words
const parseSearchInput = (input) => {
  if (!input || input.trim() === '') return [];
  
  const terms = [];
  let inQuote = false;
  let quoteChar = '';
  let currentTerm = '';
  
  // Process each character in the input
  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    
    // Handle quote characters
    if ((char === '"' || char === "'") && (i === 0 || input[i-1] !== '\\')) {
      if (!inQuote) {
        // Starting a quoted phrase
        inQuote = true;
        quoteChar = char;
        // If we have collected any non-quoted text, process it
        if (currentTerm.trim()) {
          // Split non-quoted text into individual words
          currentTerm.trim().split(/\s+/).forEach(word => {
            if (word) terms.push(word.toLowerCase());
          });
          currentTerm = '';
        }
      } else if (char === quoteChar) {
        // Ending a quoted phrase
        inQuote = false;
        if (currentTerm.trim()) {
          terms.push(currentTerm.trim().toLowerCase());
        }
        currentTerm = '';
      } else {
        // A different quote character inside a quote - treat as regular text
        currentTerm += char;
      }
    } else if (inQuote) {
      // Within quotes, add all characters to the current term
      currentTerm += char;
    } else if (/\s/.test(char)) {
      // Outside quotes, space terminates a word
      if (currentTerm.trim()) {
        terms.push(currentTerm.trim().toLowerCase());
      }
      currentTerm = '';
    } else {
      // Regular character outside quotes
      currentTerm += char;
    }
  }
  
  // Handle any remaining term
  if (currentTerm.trim()) {
    if (inQuote) {
      // If we're still in a quote (unclosed quote), treat as a complete term
      terms.push(currentTerm.trim().toLowerCase());
    } else {
      // Handle remaining non-quoted text as individual words
      currentTerm.trim().split(/\s+/).forEach(word => {
        if (word) terms.push(word.toLowerCase());
      });
    }
  }
  
  return terms.filter(term => term.length > 0);
};

const searchInTextLayers = (containerElement, searchTerm, debug = false) => {
  if (!containerElement || !searchTerm) return [];
  
  const results = [];
  
  // Parse the search input to get all terms
  const searchTerms = parseSearchInput(searchTerm);
  
  // If no valid search terms, return empty results
  if (searchTerms.length === 0) return [];
  
  // Get all pages
  const pages = containerElement.querySelectorAll('.react-pdf__Page');
  if (debug) console.log(`Searching across ${pages.length} pages`);
  
  let totalTextElements = 0;
  
  // Search on each page
  pages.forEach((page, pageIndex) => {
    const pageNumber = parseInt(page.getAttribute('data-page-number') || '0');
    if (isNaN(pageNumber)) return;
    
    // Get text layer
    const textLayer = page.querySelector('.react-pdf__Page__textContent');
    if (!textLayer) {
      if (debug) console.log(`No text layer found for page ${pageNumber}`);
      return;
    }
    
    // Get all text spans
    const textElements = textLayer.querySelectorAll('span');
    totalTextElements += textElements.length;
    
    if (!textElements || textElements.length === 0) {
      if (debug) console.log(`No text elements found in text layer for page ${pageNumber}`);
      return;
    }
    
    // APPROACH 1: Search in individual spans (catches most cases)
    textElements.forEach((element, elementIndex) => {
      const text = element.textContent || '';
      const textLower = text.toLowerCase();
      
      // For each search term
      searchTerms.forEach(searchTermLower => {
        let startIndex = 0;
        let index;
        
        // Find all occurrences in this text element
        while ((index = textLower.indexOf(searchTermLower, startIndex)) !== -1) {
          try {
            // Get the bounding client rect for the element
            const elementRect = element.getBoundingClientRect();
            const textLayerRect = textLayer.getBoundingClientRect();
            
            // Calculate approximate position of the matched text
            const charWidth = elementRect.width / Math.max(text.length, 1);
            const matchLeft = elementRect.left + (index * charWidth);
            const matchWidth = searchTermLower.length * charWidth;
            
            results.push({
              pageIndex: pageIndex,
              pageNumber: pageNumber,
              text: text.substring(index, index + searchTermLower.length),
              itemIndex: elementIndex,
              charIndex: index,
              left: matchLeft - textLayerRect.left,
              top: elementRect.top - textLayerRect.top,
              width: matchWidth,
              height: elementRect.height,
              element: element,
              page: page,
              textLayer: textLayer,
              searchTerm: searchTermLower
            });
          } catch (error) {
            console.error('Error creating search result:', error);
          }
          
          // Move to after this occurrence
          startIndex = index + searchTermLower.length;
        }
      });
    });
    
    // APPROACH 2: Search across spans for terms that might cross element boundaries
    // Get full text content and create a map from character index to span element
    let fullPageText = '';
    const charToElementMap = [];
    
    textElements.forEach((element) => {
      const text = element.textContent || '';
      const startIdx = fullPageText.length;
      
      for (let i = 0; i < text.length; i++) {
        fullPageText += text[i];
        charToElementMap.push({
          element,
          charIndex: i,
          fullTextIndex: startIdx + i
        });
      }
    });
    
    // Search in the full page text
    const fullPageTextLower = fullPageText.toLowerCase();
    searchTerms.forEach(searchTermLower => {
      let startIndex = 0;
      let index;
      
      while ((index = fullPageTextLower.indexOf(searchTermLower, startIndex)) !== -1) {
        // We found a match in the full text. Now we need to map it back to elements.
        const startChar = charToElementMap[index];
        const endChar = charToElementMap[Math.min(index + searchTermLower.length - 1, charToElementMap.length - 1)];
        
        if (startChar && endChar && startChar.element === endChar.element) {
          // This match is within a single element (we already found it in approach 1)
          startIndex = index + searchTermLower.length;
          continue;
        }
        
        if (startChar && endChar) {
          try {
            // This match spans multiple elements
            const startElement = startChar.element;
            const endElement = endChar.element;
            
            const startRect = startElement.getBoundingClientRect();
            const endRect = endElement.getBoundingClientRect();
            const textLayerRect = textLayer.getBoundingClientRect();
            
            // Create a bounding box encompassing all elements
            const left = Math.min(startRect.left, endRect.left);
            const top = Math.min(startRect.top, endRect.top);
            const right = Math.max(startRect.right, endRect.right);
            const bottom = Math.max(startRect.bottom, endRect.bottom);
            
            results.push({
              pageIndex: pageIndex,
              pageNumber: pageNumber,
              text: fullPageText.substring(index, index + searchTermLower.length),
              itemIndex: -1, // Spans multiple elements
              charIndex: startChar.charIndex,
              left: left - textLayerRect.left,
              top: top - textLayerRect.top,
              width: right - left,
              height: bottom - top,
              element: startElement, // Use the first element for reference
              page: page,
              textLayer: textLayer,
              searchTerm: searchTermLower,
              isMultiElementMatch: true
            });
          } catch (error) {
            console.error('Error creating multi-element search result:', error);
          }
        }
        
        startIndex = index + searchTermLower.length;
      }
    });
  });
  
  if (debug) {
    console.log(`Search complete: found ${results.length} results across ${totalTextElements} text elements`);
  }
  
  return results;
};

// Function to position search results
const positionSearchResults = (searchResults, containerElement, scale) => {
  if (!containerElement || !searchResults || searchResults.length === 0) {
    return [];
  }
  
  const containerRect = containerElement.getBoundingClientRect();
  
  const positionedResults = searchResults.map(result => {
    try {
      if (!result.page || !result.textLayer || !result.element) return null;
      
      // Get updated positions
      const pageRect = result.page.getBoundingClientRect();
      const textLayerRect = result.textLayer.getBoundingClientRect();
      const elementRect = result.element.getBoundingClientRect();
      
      // Calculate offsets relative to container
      const pageOffsetLeft = pageRect.left - containerRect.left + containerElement.scrollLeft;
      const pageOffsetTop = pageRect.top - containerRect.top + containerElement.scrollTop;
      
      // Get element position
      const elementLeft = elementRect.left - textLayerRect.left;
      
      // Calculate character width (approx)
      const elementText = result.element.textContent || '';
      const charWidth = elementRect.width / Math.max(elementText.length, 1);
      
      // Calculate the position of just the matched text within the element
      const matchLeft = elementLeft + (result.charIndex * charWidth);
      const matchWidth = result.searchTerm.length * charWidth;
      
      // Final position calculation - just for the matched text
      return {
        ...result,
        left: pageOffsetLeft + matchLeft,
        top: pageOffsetTop + (elementRect.top - textLayerRect.top),
        width: matchWidth,
        height: elementRect.height
      };
    } catch (error) {
      console.error('Error positioning search result:', error);
      return null;
    }
  }).filter(Boolean);
  
  return positionedResults;
};

// Scroll to a specific search result
const scrollToSearchResult = (result, containerElement) => {
  if (!result || !containerElement || !result.page) return;
  
  try {
    // Get updated positions
    const containerRect = containerElement.getBoundingClientRect();
    const elementRect = result.element.getBoundingClientRect();
    
    // Calculate scroll position to center the result
    const scrollTop = containerElement.scrollTop + 
                      (elementRect.top - containerRect.top) - 
                      (containerRect.height / 2) + 
                      (elementRect.height / 2);
    
    // Smooth scroll to the result
    containerElement.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    });
  } catch (err) {
    console.error('Error scrolling to search result:', err);
  }
};

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none', // Let events pass through to the PDF
    zIndex: 90,
  },
  searchContainer: {
    position: 'fixed',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: fade(theme.palette.background.paper, 0.9),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
    maxWidth: 400,
    minWidth: 400,
    transition: 'all 0.3s ease',
    pointerEvents: 'auto', // Make the search box interactive
  },
  searchField: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  resultCount: {
    marginRight: theme.spacing(1),
  },
  searchHighlight: {
    position: 'absolute',
    backgroundColor: fade(theme.palette.warning.light, 0.4),
    borderRadius: '2px',
    pointerEvents: 'none',
    transition: 'transform 0.1s ease',
    zIndex: 500,
    border: '1px solid rgba(255, 193, 7, 0.5)',
  },
  currentHighlight: {
    position: 'absolute',
    backgroundColor: fade(theme.palette.secondary.main, 0.6),
    borderRadius: '2px',
    border: `2px solid ${theme.palette.secondary.main}`,
    pointerEvents: 'none',
    zIndex: 501,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(156, 39, 176, 0.4)',
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(156, 39, 176, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(156, 39, 176, 0)',
    },
  },
  searchButton: {
    position: 'fixed',
    backgroundColor: fade(theme.palette.background.paper, 0.9),
    zIndex: 100,
    transition: 'all 0.3s ease',
    pointerEvents: 'auto', // Make the button interactive
    boxShadow: theme.shadows[2],
    borderRadius: '50%',
    padding: '8px',
    '&:hover': {
      backgroundColor: fade(theme.palette.background.paper, 1),
      boxShadow: theme.shadows[4],
    },
  },
}));

const EnhancedPDFSearch = ({ 
  pdfDocument, 
  containerRef, 
  scale = 1.0, 
  debug = false, 
  initialSearchTerm = "",
  allPagesRendered = false
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [positionedResults, setPositionedResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [showSearch, setShowSearch] = useState(Boolean(initialSearchTerm));
  const [containerBounds, setContainerBounds] = useState({ top: 0, left: 0 });
  const searchBarRef = useRef(null);
  const debounceTimeout = useRef(null);
  const initialSearchAttempts = useRef(0);
  const maxSearchAttempts = 5; // Try up to 5 times
  
  // Update container bounds when the container reference changes or when scrolled
  useEffect(() => {
    if (containerRef?.current) {
      const updateContainerBounds = () => {
        const rect = containerRef.current.getBoundingClientRect();
        setContainerBounds({
          top: rect.top,
          left: rect.left
        });
      };
      
      // Initial update
      updateContainerBounds();
      
      const container = containerRef.current;
      
      // Use throttled handlers for better performance
      const handleScroll = () => {
        if (!window.requestAnimationFrame) {
          setTimeout(updateContainerBounds, 16); // ~60fps
        } else {
          requestAnimationFrame(updateContainerBounds);
        }
      };
      
      const handleResize = handleScroll;
      
      // Listen for scroll events on both document and container
      document.addEventListener('scroll', handleScroll, { passive: true });
      container.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize);
      
      return () => {
        document.removeEventListener('scroll', handleScroll, { passive: true });
        container.removeEventListener('scroll', handleScroll, { passive: true });
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [containerRef]);

  // Perform search
  const performSearch = useCallback((term, isRetry = false) => {
    if (!term || !containerRef?.current) {
      if (!isRetry) {
        setSearchResults([]);
        setPositionedResults([]);
      }
      return 0; // Return count of results for retry logic
    }

    if (!isRetry) {
      setIsSearching(true);
      setCurrentResultIndex(0);
    }

    try {
      // Use the improved search function
      const results = searchInTextLayers(containerRef.current, term, debug);
      
      if (debug) {
        console.log(`Search for "${term}" found ${results.length} results${isRetry ? ' (retry attempt)' : ''}`);
      }
      
      // Only update if we found more results or this is the first search
      if (!isRetry || results.length > searchResults.length) {
        setSearchResults(results);
        
        // Position results
        if (results.length > 0) {
          const positioned = positionSearchResults(results, containerRef.current, scale);
          setPositionedResults(positioned);
          
          if (debug) {
            console.log(`Positioned ${positioned.length} results`);
          }
        } else {
          setPositionedResults([]);
        }
      }
      
      return results.length;
    } catch (error) {
      console.error('Error during search:', error);
      if (!isRetry) {
        setSearchResults([]);
        setPositionedResults([]);
      }
      return 0;
    } finally {
      if (!isRetry) {
        setIsSearching(false);
      }
    }
  }, [containerRef, debug, scale, searchResults.length]);

  const performInitialSearch = useCallback(() => {
    if (!initialSearchTerm || !containerRef?.current || !allPagesRendered) {
      return;
    }
    
    initialSearchAttempts.current += 1;
    
    // Start with a longer initial delay
    const baseDelay = 800;
    const attemptDelay = baseDelay * Math.pow(1.5, initialSearchAttempts.current - 1);
    
    if (debug) {
      console.log(`Search attempt ${initialSearchAttempts.current}/${maxSearchAttempts} with delay ${attemptDelay}ms`);
    }
    
    setTimeout(() => {
      const resultCount = performSearch(initialSearchTerm, initialSearchAttempts.current > 1);
      
      // If we found results, or we've tried enough times, stop retrying
      if (resultCount > 0 || initialSearchAttempts.current >= maxSearchAttempts) {
        if (debug) {
          console.log(`Search complete: found ${resultCount} results after ${initialSearchAttempts.current} attempts`);
        }
      } else {
        // Try again with a longer delay
        performInitialSearch();
      }
    }, attemptDelay);
  }, [initialSearchTerm, containerRef, allPagesRendered, debug, performSearch]);

  useEffect(() => {
    if (initialSearchTerm && containerRef?.current && allPagesRendered && initialSearchAttempts.current === 0) {
      if (debug) {
        console.log("All pages rendered, beginning search attempts");
      }
      performInitialSearch();
    }
  }, [initialSearchTerm, containerRef, allPagesRendered, debug, performInitialSearch]);

  // Scroll to current result when it changes
  useEffect(() => {
    if (positionedResults.length > 0 && containerRef?.current && currentResultIndex >= 0) {
      const safeIndex = Math.min(currentResultIndex, positionedResults.length - 1);
      if (safeIndex >= 0) {
        const currentResult = positionedResults[safeIndex];
        if (currentResult) {
          scrollToSearchResult(currentResult, containerRef.current);
        }
      }
    }
  }, [currentResultIndex, positionedResults, containerRef]);

  // Update highlight positions
  const updatePositions = useCallback(() => {
    if (containerRef?.current && searchResults.length > 0) {
      try {
        const positioned = positionSearchResults(searchResults, containerRef.current, scale);
        if (positioned && positioned.length > 0) {
          setPositionedResults(positioned);
          if (debug) {
            console.log(`Positioned ${positioned.length} results`);
          }
        }
      } catch (error) {
        console.error('Error positioning search results:', error);
      }
    }
  }, [containerRef, debug, scale, searchResults]);

  // Update highlight positions when container is scrolled or results change
  useEffect(() => {
    if (containerRef?.current && searchResults.length > 0) {
      updatePositions();
      
      const container = containerRef.current;
      let scrollTimeout;
      
      const handleScroll = () => {
        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          updatePositions();
        }, 200);
      };
      
      container.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleScroll, { passive: true });
      
      return () => {
        if (scrollTimeout) clearTimeout(scrollTimeout);
        container.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, [searchResults, containerRef, updatePositions]);

  // Toggle search panel visibility
  const toggleSearch = () => {
    const newShowSearch = !showSearch;
    setShowSearch(newShowSearch);
    
    if (!newShowSearch) {
      setSearchTerm('');
      setSearchResults([]);
      setPositionedResults([]);
    } else {
      setTimeout(() => {
        searchBarRef.current?.querySelector('input')?.focus();
      }, 100);
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Debounce search term input
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    
    if (!searchTerm) {
      setSearchResults([]);
      setPositionedResults([]);
      return;
    }
    
    debounceTimeout.current = setTimeout(() => {
      performSearch(searchTerm);
    }, 300);
    
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm, performSearch]);

  // Navigate to next result
  const nextResult = () => {
    if (positionedResults.length === 0) return;
    
    setCurrentResultIndex(prevIndex => 
      prevIndex + 1 >= positionedResults.length ? 0 : prevIndex + 1
    );
  };

  // Navigate to previous result
  const prevResult = () => {
    if (positionedResults.length === 0) return;
    
    setCurrentResultIndex(prevIndex => 
      prevIndex - 1 < 0 ? positionedResults.length - 1 : prevIndex - 1
    );
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      nextResult();
    }
  };

  // Clear search
  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    setPositionedResults([]);
    setCurrentResultIndex(0);
  };

  // Render search highlights
  const renderHighlights = () => {
    if (!positionedResults.length) return null;
    
    // Make sure current index is valid
    const safeIndex = Math.min(currentResultIndex, positionedResults.length - 1);
    if (safeIndex < 0) return null;
    
    const currentResult = positionedResults[safeIndex];
    
    // Render regular highlights
    const regularHighlights = positionedResults
      .filter((result, index) => index !== safeIndex)
      .map(result => (
        <div
          key={`highlight-${result.pageIndex}-${result.itemIndex}-${result.charIndex}`}
          className={classes.searchHighlight}
          style={{
            left: `${result.left}px`,
            top: `${result.top}px`,
            width: `${result.width}px`,
            height: `${result.height}px`,
          }}
          title={result.text}
        />
      ));
    
    // Render current highlight
    const currentHighlight = (
      <div
        key={`current-highlight-${currentResult.pageIndex}-${currentResult.itemIndex}-${currentResult.charIndex}`}
        className={classes.currentHighlight}
        style={{
          left: `${currentResult.left}px`,
          top: `${currentResult.top}px`,
          width: `${currentResult.width}px`,
          height: `${currentResult.height}px`,
        }}
        title={currentResult.text}
      />
    );
    
    return (
      <>
        {regularHighlights}
        {currentHighlight}
      </>
    );
  };

  return (
    <>
      {/* Floating overlay for search controls */}
      <div className={classes.overlay}>
        {!showSearch ? (
          <Tooltip title="Search in document">
            <IconButton 
              className={classes.searchButton}
              onClick={toggleSearch}
              style={{
                top: `${containerBounds.top + 32}px`,
                left: `${containerBounds.left + 24}px`
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper 
            className={classes.searchContainer} 
            ref={searchBarRef}
            style={{
              top: `${containerBounds.top + 32}px`,
              left: `${containerBounds.left + 24}px`
            }}
          >
            <TextField
              className={classes.searchField}
              variant="outlined"
              size="small"
              placeholder="Search in document"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isSearching ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SearchIcon fontSize="small" />
                    )}
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end" style={{marginLeft:8}}>
                    <IconButton size="small" onClick={clearSearch}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            
            <div className={classes.buttonsContainer}>
              <div className={classes.navigationButtons}>
                {positionedResults.length > 0 && (
                  <>
                    <Typography variant="body2" className={classes.resultCount}>
                      {currentResultIndex + 1} of {positionedResults.length}
                    </Typography>
                    <IconButton 
                      size="small" 
                      onClick={prevResult} 
                      disabled={positionedResults.length === 0}
                      aria-label="Previous result"
                    >
                      <NavigateBeforeIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      size="small" 
                      onClick={nextResult} 
                      disabled={positionedResults.length === 0}
                      aria-label="Next result"
                    >
                      <NavigateNextIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </div>
              
              <IconButton size="small" onClick={toggleSearch} aria-label="Close search">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </Paper>
        )}
      </div>
      
      {/* Render highlights over the PDF */}
      {renderHighlights()}
    </>
  );
};

export default EnhancedPDFSearch;