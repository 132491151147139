import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as ProjectsIconSvg } from '../SVG/projects.svg';
import { ReactComponent as TasksIconSvg } from '../SVG/tasks.svg';
import { ReactComponent as SupportIconSvg } from '../SVG/support.svg';
import { ReactComponent as SuperscriptIconSvg } from '../SVG/superscript.svg';
import { ReactComponent as SubscriptIconSvg } from '../SVG/subscript.svg';
import { ReactComponent as FolderMoveIconSvg } from '../SVG/folderMove.svg';
import { ReactComponent as MergeFieldIconSvg } from '../SVG/mergeField.svg';
import { ReactComponent as SignedIconSvg } from '../SVG/signed.svg';

import { ReactComponent as YouTubeIconSvg } from '../SVG/youtube.svg';
import { ReactComponent as DiscordIconSvg } from '../SVG/discord-mark-blue.svg';
import { ReactComponent as QuoraIconSvg } from '../SVG/quora.svg';

import CompleteIcon from '@material-ui/icons/Done';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import FolderIcon from '@material-ui/icons/Folder';
import SharedFolderIcon from '@material-ui/icons/FolderShared';
import OrganizationIcon from '@material-ui/icons/Domain';
import ProfileIcon from '@material-ui/icons/Subject';
// import ProjectsIcon from '@material-ui/icons/DeveloperBoard';
// import TasksIcon from '@material-ui/icons/AssignmentOutlined';
import ApprovalsIcon from '@material-ui/icons/ThumbsUpDown';
import InboxIcon from '@material-ui/icons/Inbox';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SubscriptionsIcon from '@material-ui/icons/Bookmarks';
import SignaturesIcon from '@material-ui/icons/LabelImportant';
import FormsIcon from '@material-ui/icons/ViewHeadline';
import FieldsIcon from '@material-ui/icons/TextFields';
import MembersIcon from '@material-ui/icons/Group';
import AssetsIcon from '@material-ui/icons/Category';
import OrganizationSubscriptionIcon from '@material-ui/icons/Payment';
import CaptureIcon from '@material-ui/icons/CloudUpload';
import TwainCaptureIcon from '@material-ui/icons/Scanner';
// import DocumentsIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import ProcessesIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import CommentsIcon from '@material-ui/icons/Comment';
import TaskMilestonesIcon from '@material-ui/icons/Landscape';
import TaskStatesIcon from '@material-ui/icons/SyncAlt';
import ArchivedIcon from '@material-ui/icons/Archive';
import TagsIcon from '@material-ui/icons/Label';
import ShareIcon from '@material-ui/icons/Share';

export const WorkspaceIcon = props => {
  return (
    <LaptopMacIcon {...props} />
  );
}

export const ProjectsIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={ProjectsIconSvg}
      viewBox="0 0 400 600"
    />
  );
};

export const SupportIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={SupportIconSvg}
    />
  );
};

export const TasksIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={TasksIconSvg}
      viewBox="0 0 400 600"
    />
  );
};

export const SuperscriptIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={SuperscriptIconSvg}
      viewBox="0 0 400 600"
    />
  );
};

export const SubscriptIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={SubscriptIconSvg}
      viewBox="0 0 400 600"
    />
  );
};

export const YouTubeIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={YouTubeIconSvg}
      viewBox="0 -24 160 160"
    />
  );
};

export const DiscordIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={DiscordIconSvg}
      viewBox="0 -16 128 128"
    />
  );
};

export const QuoraIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={QuoraIconSvg}
      viewBox="0 0 128 128"
    />
  );
};

export const FolderMoveIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={FolderMoveIconSvg}
    />
  );
};

export const MergeIcon = props => {
  return (
    <SvgIcon
      {...props}
      style={{transform:"rotate(90deg)"}}
      component={MergeFieldIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export const SignedIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={SignedIconSvg}
    />
  );
};

export const GetIconById = (id, theme) => {
  let icon;
  if (!id) {
    id = "folderNode";
  }
  if (theme && theme.iconURLs && theme.iconURLs[id]) {
    return (
      <img src={theme.iconURLs[id]} alt="" style={{height:24}} />
    );
  }
  switch (id) {
    case "folderNode":
      icon = <FolderIcon />;
      break;
    case "organizations":
      icon = <OrganizationIcon />;
      break;
    case "profile":
      icon = <ProfileIcon />;
      break;
    case "projects":
      icon = <ProjectsIcon />;
      break;
    case "inbox":
      icon = <InboxIcon />;
      break;
    case "notifications":
      icon = <NotificationsIcon />;
      break;
    case "fileCapture":
      icon = <CaptureIcon />;
      break;
    case "deviceCapture":
      icon = <TwainCaptureIcon />;
      break;
    case "documents":
      icon = <FolderIcon />;
      break;
    case "sharedFolder":
      icon = <SharedFolderIcon />;
      break;
    case "fields":
      icon = <FieldsIcon />;
      break;
    case "assets":
      icon = <AssetsIcon />;
      break;
    case "assetItems":
      icon = <AssetsIcon />;
      break;
    case "archivedAssets":
      icon = <ArchivedIcon />;
      break;
    case "archivedAssetItems":
      icon = <ArchivedIcon />;
      break;
    case "forms":
      icon = <FormsIcon />;
      break;
    case "formTemplates":
      icon = <FormsIcon />;
      break;
    case "signatures":
      icon = <SignaturesIcon />;
      break;
    case "recycleBin":
      icon = <DeleteIcon />;
      break;
    case "followedDocuments":
    case "followedTasks":
      icon = <SubscriptionsIcon />;
      break;
    case "approvals":
      icon = <ApprovalsIcon />;
      break;
    case "tasks":
      icon = <TasksIcon />;
      break;
    case "projectComments":
      icon = <CommentsIcon />;
      break;
    case "completedTasks":
      icon = <CompleteIcon />;
      break;
    case "deletedTasks":
      icon = <DeleteIcon />;
      break;
    case "processes":
      icon = <ProcessesIcon />;
      break;
    case "members":
      icon = <MembersIcon />;
      break;
    case "organizationSubscription":
      icon = <OrganizationSubscriptionIcon />;
      break;
    case "documentFolder":
    case "documentFolders":
      icon = <FolderIcon />;
      break;
    case "settings":
      icon = <SettingsIcon />;
      break;
    case "taskMilestones":
      icon = <TaskMilestonesIcon />;
      break;
    case "taskStates":
      icon = <TaskStatesIcon />;
      break;
    case "tags":
      icon = <TagsIcon />;
      break;
    case "sharedForms":
      icon = <ShareIcon />;
      break;
    default:
      break;
  }
  return icon;
}