import React from 'react';
import Grid from '@material-ui/core/Grid';
// import AsyncSelectControl from '../../Components/AsyncSelectControl';
import ProcessElementDialogHelper from '../../Util/ProcessElementDialogs';

// const ParticipationTypes = [
//   { value: "Any", label: "Any role member" },
//   { value: "All", label: "All role members"},
//   { value: "Majority", label: "Majority of role members"},
// ];

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption) {
  
  if (!processElement) {
    return null;
  }

  const dh = new ProcessElementDialogHelper(location, history, organizationId, projectId, processElement, processElementConnections_Input,
      onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty, 
      onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
      onSelectionListValueChange, onSelectionListCreateOption);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {dh.GetActionContentForEmailAddressControlAndPreferredItemType("AssignmentUserEmail", "Assigned to",
          false, true, true, true, true, true)}
      </Grid>
    </Grid>
  );
}