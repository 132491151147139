import React from 'react';

import BaseCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import CompleteIcon from '@material-ui/icons/CheckCircle';
import { SignedIcon } from '../Util/Icons';
import SyncedIcon from '@material-ui/icons/SwapVerticalCircle';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import { GetContentTypeLabelFromSearchResult } from '../Util/SearchResults';
import {
  GetExtensionAndOverlayColorFromFilename,
} from '../Util/Document';

const iconShadow = "drop-shadow(1px 1px 1px black)";
const styles = theme => ({
  card: {
    position:"relative",
    "&:hover $cardActionsContainer": { 
      display:"flex",
    },
    "&:hover $cardActionsContainerMinimal": { 
      display:"flex",
    },
    "&:hover $cardActionsHover": { 
      display:"flex",
    },
  },
  cardContent: {
    height:"100%",
    overflowWrap: "break-word",
  },
  cardActionsContainer: {
    position:"sticky",
    bottom:0,
    left:0,
    height: theme.spacing(8),
    marginBottom:-1 * theme.spacing(8),
    display:"none",
    backgroundColor:theme.palette.background.cardActionBar,
    overflow:"hidden",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardActionsContainerMinimal: {
    position:"absolute",
    bottom:0,
    right:0,
    height: theme.spacing(5),
    width: theme.spacing(5),
    alignItems:"center",
    justifyContent:"center",
    borderTopLeftRadius:8,
    display:"none",
    backgroundColor:theme.palette.background.cardActionBar,
    overflow:"hidden",
  },
  cardActionsTranslucent: {
    pointerEvents:"none",
    opacity:0.8,
  },
  cardSelected: {
    display:"flex",
  },
  cardActionsStatic: {
    pointerEvents:"all",
    opacity:1,
  },
  cardActionsHover: {
    pointerEvents:"all",
    display:"none",
    justifyContent: "flex-end",
    opacity:1,
  },
  cardActionsDivider: {
    flexGrow:1,
  },
  thumbnailGridItem: {
    overflow:"hidden",
    marginBottom:-4,
  },
  metadataGridItem: {
    overflow:"hidden",
  },
  thumbnailGridItemContent: {
    position:"relative",
  },
  thumbnailImage: {
    width:"100%",
  },
  extensionOverlay: {
    position:"absolute",
    right:0,
    top:0,
    color:"#fff",
    padding:theme.spacing(1),
    paddingTop:theme.spacing(1)/2,
    paddingBottom:theme.spacing(1)/2,
    borderBottomLeftRadius:theme.spacing(1),
    borderTopRightRadius:theme.spacing(1),
  },
  completeIcon: {
    color:green[400],
    filter:iconShadow,
  },
  syncedIcon: {
    color:yellow[500],
    filter:iconShadow,
  },
  decorationIconsGrid: {
    position:"absolute",
    alignItems:"flex-end",
    top:theme.spacing(2.5),
    right:theme.spacing(2),
  },
  signedIcon: {
    color:green[400],
    filter:iconShadow,
    position:"absolute",
    top:theme.spacing(4),
    right:4,
  },
  pendingSignaturesIcon: {
    color:yellow[700],
    filter:iconShadow,
    position:"absolute",
    top:theme.spacing(4),
    right:4,
  },
  metadataContainer: {
    marginTop:theme.spacing(1),
    position:"relative",
  },
  metadataName: {
    fontWeight:500,
    marginRight:6,
    whiteSpace:"nowrap",
  },
  metadataValue: {
    overflowWrap: "anywhere",
  },
  property: {
    fontSize:12,
  },
  propertyContainer: {
    display:"flex",
    alignItems:"center",
  },
});

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleCardActionAreaClick = e => {
    if (e.ctrlKey) {
      this.handleSelect(e);
    } else {
      if (this.props.onCardAction) {
        this.props.onCardAction();
      }
    }
  }

  handleCardActionsClick = e => {
    if (e.ctrlKey) {
      this.handleSelect(e);
    }
  }

  handleSelect = e => {
    if (!this.props.noSelect && this.props.onSelect) {
      this.props.onSelect();
    }
  }

  render() {
    const { 
      classes,
      theme,
      style,
      hoverActionItems,
      selected,
      height,
      minHeight,
      maxHeight,
      checkboxOnly,
      smallName,
      noThumbnail,
      disableRipple,
      translucentActionBar,
      onCardAction,
      name,
      nameStyle,
      metadata,
      thumbnailUrl,
      isSigned,
      isComplete,
      isSynced,
      syncSourceName,
      nameFontWeight,
      pendingSignatures,
      fileName,
      description,
      noSelect,
      onSelect,
      isSearchResults,
      showContentType,
      // showSearchScore,
      contentItem,
      extraContent,
    } = this.props;

    let cardActions;
    if (hoverActionItems || onSelect) {
      let cardActionsContainerClassNames = classNames(
        (checkboxOnly)
          ? classes.cardActionsContainerMinimal
          : classes.cardActionsContainer
      );
      if (selected) {
        cardActionsContainerClassNames = classNames(cardActionsContainerClassNames, classes.cardSelected);
      }
      if (translucentActionBar) {
        cardActionsContainerClassNames = classNames(cardActionsContainerClassNames, classes.cardActionsTranslucent);
      }
      const checkbox = (!noSelect)
        ? (
          <Checkbox
            color="secondary"
            checked={selected}
            style={{
              padding: (checkboxOnly) ? 0 : undefined,
            }}
            onClick={this.handleSelect} />
        ) : null;
      cardActions = (checkboxOnly)
        ? (
          <CardActions className={cardActionsContainerClassNames}>
            {checkbox}
          </CardActions>
        )
        : (
          <CardActions className={cardActionsContainerClassNames}
            onClick={this.handleCardActionsClick}
            // style={{ opacity: (isDragging) && 0 }}
            >
            <div className={classes.cardActionsStatic}>
              {checkbox}
            </div>
            <div className={classes.cardActionsDivider}>
            </div>
            <div className={classes.cardActionsHover}>
              {hoverActionItems}
            </div>
          </CardActions>
        );
    }

    const contentMaxHeight = (height) ? height - 24 : (maxHeight) ? maxHeight - 24 : undefined;

    let thumbnailGridItem = null;
    if (thumbnailUrl && !noThumbnail) {
      let extensionOverlay;
      if (fileName) {
        const {
          extension,
          overlayColor,
        } = GetExtensionAndOverlayColorFromFilename(fileName);
        if (extension) {
          extensionOverlay = (
            <Typography
              variant="caption"
              className={classes.extensionOverlay}
              style={{backgroundColor:overlayColor}}>
              {extension.toUpperCase()}
            </Typography>
          );
        }
      }

      const signedIcon = (isSigned)
        ? (
          <Tooltip title="Signed">
            <SignedIcon className={classes.signedIcon} />
          </Tooltip>
        ) : null;

      const pendingSignaturesIcon = (pendingSignatures)
        ? (
          <Tooltip title="Pending signature(s)">
            <SignedIcon className={classes.pendingSignaturesIcon} />
          </Tooltip>
        ) : null;

      thumbnailGridItem = (
        <Grid item xs={4} className={classes.thumbnailGridItem}
          style={{
            maxHeight: contentMaxHeight,
          }}
        >
          <div className={classes.thumbnailGridItemContent}>
            <img src={thumbnailUrl}
              className={classes.thumbnailImage}
              style={{
                border: (extensionOverlay) ? "1px solid #ddd" : undefined,
                borderTopRightRadius: (extensionOverlay) ? theme.spacing(1) : undefined,
              }}
              alt=""
            />
            {extensionOverlay}
            {signedIcon}
            {pendingSignaturesIcon}
          </div>
        </Grid>
      );
    }

    let descriptionContent = (description)
      ? (
        <Typography color="textSecondary"
          component="div"
          style={{
            marginTop:theme.spacing(2),
          }}
        >
          {description}
        </Typography>
      ) : null;

    let metadataContent = [];
    if (metadata && metadata.length) {
      metadata.forEach((m, index) => {
        if (!m[1]) {
          return;
        }
        let nameSpan = (m[0]) ? (<span className={classes.metadataName}>{m[0]}:</span>) : null;
        metadataContent.push(
          <Typography key={`m${index}`} className={classes.property} color="textSecondary" component="span">
            <span className={classes.propertyContainer} style={m[2]}>
              {nameSpan}
              <span className={classes.metadataValue} style={m[3]}>{m[1]}</span>
            </span>
          </Typography>
        );
      });
    }

    let addlContent = [];
    if (isSearchResults || showContentType) {
      if (contentItem && contentItem.ContentType) {
        addlContent.push(
          <Typography key={`m_contentType`} className={classes.property} style={{fontSize:14}} color="textSecondary">
            <span className={classes.metadataName} style={{fontWeight:600}}>{GetContentTypeLabelFromSearchResult(contentItem, true)}</span>
          </Typography>
        );
      }
    }
    // if (isSearchResults || showSearchScore) {
    //   if (contentItem && contentItem.Score) {
    //     addlContent.push(
    //       <Typography key={`m_score`} className={classes.property} color="textSecondary">
    //         <span><span className={classes.metadataName}>Score</span>:</span> <span>{contentItem.Score}</span>
    //       </Typography>
    //     );
    //   }
    // }

    let decorationIconsGridItems = [];
    if (isComplete) {
      decorationIconsGridItems.push(
        <Grid item key="i_complete">
          <Tooltip title="Complete">
            <CompleteIcon className={classes.completeIcon} />
          </Tooltip>
        </Grid>
      );
    }
    if (isSynced) {
      decorationIconsGridItems.push(
        <Grid item key="i_synced">
          <Tooltip title={`Synced${(syncSourceName) ? " from " + syncSourceName : ""}`}>
            <SyncedIcon className={classes.syncedIcon} />
          </Tooltip>
        </Grid>
      );
    }
    const decorationIconsGrid = (decorationIconsGridItems.length) ? (
      <Grid container direction="column" spacing={1} className={classes.decorationIconsGrid}>
        {decorationIconsGridItems}
      </Grid>
    ) : null;

    let primaryContent = (name)
      ? (
        <Typography variant={(smallName) ? "h6" : "h5"} component="h2"
          style={{
            lineHeight: (smallName) ? 1 : undefined,
            whiteSpace: (smallName) ? "nowrap" : undefined,
            fontSize: (smallName) ? 16 : undefined,
            ...nameStyle,
            fontWeight: (nameFontWeight) ? nameFontWeight : undefined,
          }}
        >
          {name}
        </Typography>
      ) : null;

    let mainContent = (
      <React.Fragment>
        {primaryContent}
        <div className={classes.metadataContainer}>
          {addlContent}
          {metadataContent}
          {descriptionContent}
        </div>
      </React.Fragment>
    );

    const cardContentMinHeight = (contentMaxHeight < 200) ? contentMaxHeight : Math.max(minHeight || 0, 200);
    let gridOrMainContent = (thumbnailGridItem)
      ? (
        <Grid container spacing={2}>
          <Grid item xs={8}
            className={classes.metadataGridItem}
            style={{
              minHeight: cardContentMinHeight,
              maxHeight: contentMaxHeight,
              overflow: "hidden",
            }}
          >
            {mainContent}
          </Grid>
          {thumbnailGridItem}
        </Grid>
      )
      : (
        <div style={{
          minHeight: cardContentMinHeight,
          maxHeight: contentMaxHeight,
          overflow: "hidden",
          position: "relative",
        }}>
          {mainContent}
          {extraContent}
        </div>
      );

    let cardContent = (
      <CardContent
        className={classes.cardContent}
        style={{
            height,
            maxHeight,
          }}
        onClick={e => { if (!onCardAction && e.ctrlKey) { this.handleSelect(e) } }}
      >
        {gridOrMainContent}
        {decorationIconsGrid}
      </CardContent>
    );

    let cardInnerContent = (onCardAction)
      ? (
        <CardActionArea
          disableRipple={disableRipple}
          onClick={this.handleCardActionAreaClick}>
          {cardContent}
        </CardActionArea>
      )
      : cardContent;

    return (
      <BaseCard className={classes.card} style={style}>
        {cardInnerContent}
        {cardActions}
      </BaseCard>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  height: PropTypes.number,
  checkboxOnly: PropTypes.bool,
  smallName: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  description: PropTypes.object,
  noThumbnail: PropTypes.bool,
  thumbnailStyle: PropTypes.object,
  // name: PropTypes.object or string,
  nameStyle: PropTypes.object,
  metadata: PropTypes.array,
  nameFontWeight: PropTypes.number,
  onCardAction: PropTypes.func,
  disableRipple: PropTypes.bool,
  hoverActionItems: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  isSearchResults: PropTypes.bool,
  showContentType: PropTypes.bool,
  // showSearchScore: PropTypes.bool,
  isSigned: PropTypes.bool,
  isComplete: PropTypes.bool,
  isSynced: PropTypes.bool,
  syncSourceName: PropTypes.string,
  pendingSignatures: PropTypes.bool,
  contentItem: PropTypes.object,
  contentTypeLabel: PropTypes.string,
  extraContent: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(Card);