import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import TaskSubscriptionCollectionFields from '../Model/TaskSubscriptionCollectionFields';
import TaskSubscriptionCard from '../Components/TaskSubscriptionCard';

import {
  HandleRouteToTask,
} from '../Util/Tasks';
import {
  GetDateValue,
} from '../Util/Properties';
import {
  GetProjectTaskSubscriptionsPathForApi,
} from '../Util/api';

export class TaskSubscriptions_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, 
    organizationId, projectId, isProjectMember) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.IsProjectMember = isProjectMember;

    this.PageTitle = "Followed Tasks";
    this.ContentUri = GetProjectTaskSubscriptionsPathForApi(organizationId, projectId);
    this.CollectionName = "FollowedTasks";
    this.ItemsName = "TaskSubscriptions";
    this.ItemName = "Followed Task";
    this.DefaultViewType = "Card";
    this.AllowSelect = true;
    this.HideSensitiveFields = true;
  }

  HandleGetCollectionFieldsPromise = () => {
    return Promise.resolve(TaskSubscriptionCollectionFields);
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'TaskName', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'TaskCreatedOn', sortId: 'Meta_date_str256[ItemCreatedOn].string', numeric: false, label: 'Task Created On' },
    );
    // headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, items));
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <TaskSubscriptionCard
                TaskSubscription={i}
                sensitiveFields={sensitiveFields}
                onCardAction={() => HandleRouteToTask(this.props, this.ProjectID,
                  i.TaskID, this.IsProjectMember, false, 
                  i.TaskResult === "Complete",
                  i.TaskResult === "Deny")}
                onSelect={() => onSelect(i.ID)}
                onAction={onAction}
                selected={selectedIDs.indexOf(i.ID) > -1}
                postActionData={postActionData}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => HandleRouteToTask(this.props, this.ProjectID,
                i.TaskID, this.IsProjectMember, false,
                i.TaskResult === "Complete",
                i.TaskResult === "Deny")}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.TaskName}
              </TableCell>
              {projectNameCell}
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.TaskCreatedOn)}</TableCell>
              
            </ItemTableRow>
          );
        }) 
      : [];
  }
}