import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { DropTarget } from 'react-dnd';

import { withStyles } from '@material-ui/core/styles';
import { /*lighten,*/ fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import orange from '@material-ui/core/colors/orange';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/HelpOutline';

import ProcessElementDialog from '../../Components/Workflow/ProcessElementDialog';
import mxConstants_CustomAdditions from '../../../Model/mxConstants_CustomAdditions';
import ProcessElementSubtypes, { GetProcessElementSubtypeByProcessElement } from '../../../Model/ProcessElementSubtypes';

import UiCore from '../../../Components/UiCore';
import DraggableListItem from '../../../Components/DraggableListItem';
import MultiUseDialog from '../../../Components/MultiUseDialog';
import VideoHelpDialog from '../../../Components/VideoHelpDialog';

import API, {
  GetProcessesPathForApi,
  GetProcessPathForApi,
  GetProcessElementsPathForApi,
  GetProcessElementPathForApi,
  GetProcessElementConnectionsPathForApi,
  GetProcessElementConnectionPathForApi,
  GetPublicVideoPath,
} from '../../../Util/api';
import {
  GetProjectProcessesPath,
} from '../../../Util/PathHelper';
import Model from './Util/Workflow';
import { IsMobile } from '../../../Util/MobileDetector';

import mxgraph from 'mxgraph';

// This prevents synchronous loading of two language resource files (graph.txt and editor.txt) (sync AJAX is deprecated)
// Later we may need to use mxResources.loadResources() later per top of mxResources API documentation
window.mxLoadResources = false;

const {
  mxGraph,
  mxConstants,
  mxRubberband,
  mxKeyHandler,
  mxClient,
  mxUtils,
  mxEdgeHandler,
  mxEvent,
  // mxConnectionHandler,
  mxConstraintHandler,
  // mxCircleLayout,
  // mxFastOrganicLayout,
  // mxMorphing,
  // mxStackLayout,
  mxImage,
  // mxImageShape,
  mxPoint,
  mxConnectionConstraint,
  mxCellState,
  // mxShape,
  mxEllipse,
  mxPolyline,
  mxPerimeter,
  mxRhombus,
  // mxRectangle,
  mxRectangleShape,
  // mxResources,
  mxCellRenderer,
} = mxgraph();

const tooltipBaseStyle = {
  border:"0.5px solid #888",
  borderRadius:4,
  padding: 4,
  paddingTop: 2,
  paddingBottom: 2,
  fontSize:14,
};

const styles = theme => ({
  contentContainer: {
    height:"100%",
    display:"flex",
    userSelect:"none",
  },
  elementGrid: {
    padding:theme.spacing(2),
    paddingTop: 0,
  },
  destructiveButton: {
    color: "#E53935",
    borderColor: "#E53935",
  },
  toolPane: {
    overflowY:"auto",
    width:300,
  },
  toolPaneTopBar: {
    position:"sticky",
    top:0,
    left:0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor:theme.palette.background.toolBar,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex:2,
    display:"flex",
    alignItems:"center",
  },
  toolBoxTitleGrid: {
    flexGrow: 1,
    paddingLeft:11,
    alignItems:"center",
  },
  alertGrid: {
    position:"absolute",
    zIndex:10,
    bottom:theme.spacing(4),
    right:theme.spacing(5),
    width:"max-content",
  },
  alert: {
    backgroundColor: fade(red[500],0.85),
    borderRadius:4,
    padding:4,
    paddingLeft:8,
    paddingRight:8,
  },
  overlayActionTooltip: {
    ...tooltipBaseStyle,
  },
  overlayActionIcon: {
    strokeWidth:0.25,
    stroke:"black",
  },
});

const divTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    } 

    switch (monitor.getItemType()) {
      case "DraggableListItem":
        const draggableListItem = monitor.getItem();
        draggableListItem.onDrop(monitor.getClientOffset(), draggableListItem.Data);
        break;
      default:
        break;
    }
    
    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

// Snaps to fixed points
// mxConstraintHandler.prototype.intersects = function(icon, point, source, existingEdge)
// {
//   return (!source || existingEdge) || mxUtils.intersects(icon.bounds, point);
// };

// Special case: Snaps source of new connections to fixed points
// Without a connect preview in connectionHandler.createEdgeState mouseMove
// and getSourcePerimeterPoint should be overriden by setting sourceConstraint
// sourceConstraint to null in mouseMove and updating it and returning the
// nearest point (cp) in getSourcePerimeterPoint (see below)
// let mxConnectionHandlerUpdateEdgeState = mxConnectionHandler.prototype.updateEdgeState;
// mxConnectionHandler.prototype.updateEdgeState = function(pt, constraint)
// {
//   if (pt != null && this.previous != null)
//   {
//         let constraints = this.graph.getAllConnectionConstraints(this.previous);
//         let nearestConstraint = null;
//         let dist = null;
//    
//         for (let i = 0; i < constraints.length; i++)
//         {
//             let cp = this.graph.getConnectionPoint(this.previous, constraints[i]);
//            
//             if (cp != null)
//             {
//                 let tmp = (cp.x - pt.x) * (cp.x - pt.x) + (cp.y - pt.y) * (cp.y - pt.y);
//            
//                 if (dist == null || tmp < dist)
//                 {
//                   nearestConstraint = constraints[i];
//                     dist = tmp;
//                 }
//             }
//         }
//        
//         if (nearestConstraint != null)
//         {
//           this.sourceConstraint = nearestConstraint;
//         }
//         
//         // In case the edge style must be changed during the preview:
//         // this.edgeState.style['edgeStyle'] = 'orthogonalEdgeStyle';
//         // And to use the new edge style in the new edge inserted into the graph,
//         // update the cell style as follows:
//         //this.edgeState.cell.style = mxUtils.setStyle(this.edgeState.cell.style, 'edgeStyle', this.edgeState.style['edgeStyle']);
//   }
// 
//   mxConnectionHandlerUpdateEdgeState.apply(this, arguments);
// };

// Override to remove connection points on edges
mxPolyline.prototype.constraints = null;

const imageWidthHeight = 18;

// Replaces the default port/connection point image
mxConstraintHandler.prototype.pointImage = new mxImage("/mxgraph/customImages/arrowDownward.svg", imageWidthHeight, imageWidthHeight);

// Overrides the image that is used for a given constraint
let img_thumbUp = new mxImage("/mxgraph/customImages/thumbUp.svg", imageWidthHeight, imageWidthHeight);
let img_thumbDown = new mxImage("/mxgraph/customImages/thumbDown.svg", imageWidthHeight, imageWidthHeight);
let img_notifications = new mxImage("/mxgraph/customImages/notifications.svg", imageWidthHeight, imageWidthHeight);
let img_deadlines = new mxImage("/mxgraph/customImages/deadlines.svg", imageWidthHeight, imageWidthHeight);
// let img_arrowBack = new mxImage("/mxgraph/customImages/arrowBack.svg", imageWidthHeight, imageWidthHeight);
// let img_arrowForward = new mxImage("/mxgraph/customImages/arrowForward.svg", imageWidthHeight, imageWidthHeight);
// let img_arrowUpward = new mxImage("/mxgraph/customImages/arrowUpward.svg", imageWidthHeight, imageWidthHeight);
let img_arrowDownward = new mxImage("/mxgraph/customImages/arrowDownward2.svg", imageWidthHeight, imageWidthHeight);
// let img_jack = new mxImage("/mxgraph/customImages/jack.svg", imageWidthHeight, imageWidthHeight);
// let img_plug = new mxImage("/mxgraph/customImages/plug.svg", imageWidthHeight, imageWidthHeight);
mxConstraintHandler.prototype.getImageForConstraint = function(state, constraint, point) {
  switch (constraint.name) {
    case mxConstraintNames.APPROVE:
      return img_thumbUp;
    case mxConstraintNames.DECLINE:
      return img_thumbDown;
    case mxConstraintNames.NOTIFICATIONS:
      return img_notifications;
    case mxConstraintNames.DEADLINE_NOTIFICATIONS:
      return img_deadlines;
    case mxConstraintNames.INPUT:
      return img_arrowDownward;
    case mxConstraintNames.OUTPUT:
      return img_arrowDownward;
    default:
      return this.pointImage;    
  }
};

// Override to define per-shape connection points
mxGraph.prototype.getAllConnectionConstraints = function(terminal, source) {
  if (terminal != null && terminal.shape != null)
  {
    if (terminal.shape.stencil != null)
    {
      return terminal.shape.stencil.constraints;
    }
    else if (terminal.shape.constraints != null)
    {
      return terminal.shape.constraints;
    }
  }

  return null;
};

// Set constraints for all shapes
// mxShape.prototype.constraints = [
//   new mxConnectionConstraint(new mxPoint(0.25, 0.00), true), // top left
//   new mxConnectionConstraint(new mxPoint(0.50, 0.00), true), // top
//   new mxConnectionConstraint(new mxPoint(0.75, 0.00), true), // top right
//   new mxConnectionConstraint(new mxPoint(0.00, 0.25), true), // left top
//   new mxConnectionConstraint(new mxPoint(0.00, 0.50), true), // left
//   new mxConnectionConstraint(new mxPoint(0.00, 0.75), true), // left bottom
//   new mxConnectionConstraint(new mxPoint(1.00, 0.25), true), // right top
//   new mxConnectionConstraint(new mxPoint(1.00, 0.50), true), // right
//   new mxConnectionConstraint(new mxPoint(1.00, 0.75), true), // right bottom
//   new mxConnectionConstraint(new mxPoint(0.25, 1.00), true), // bottom left
//   new mxConnectionConstraint(new mxPoint(0.50, 1.00), true), // bottom
//   new mxConnectionConstraint(new mxPoint(0.75, 1.00), true), // bottom right
// ];

// Define Constraint Points
const topPoint = new mxPoint(0.50, 0.00);
const bottomPoint = new mxPoint(0.50, 1.00);
const leftPoint = new mxPoint(0.00, 0.50);
const rightPoint = new mxPoint(1.00, 0.50);

// Define Edge Styles using Constraint Points
const edgeStyle_entry_top = {
  [mxConstants.STYLE_ENTRY_X]: topPoint.x,
  [mxConstants.STYLE_ENTRY_Y]: topPoint.y,
};
const edgeStyle_exit_bottom = {
  [mxConstants.STYLE_EXIT_X]: bottomPoint.x,
  [mxConstants.STYLE_EXIT_Y]: bottomPoint.y,
};
const edgeStyle_exit_left = {
  [mxConstants.STYLE_EXIT_X]: leftPoint.x,
  [mxConstants.STYLE_EXIT_Y]: leftPoint.y,
};
const edgeStyle_exit_right = {
  [mxConstants.STYLE_EXIT_X]: rightPoint.x,
  [mxConstants.STYLE_EXIT_Y]: rightPoint.y,
};

// Synonyms
const pointSynonyms = {
  declinePoint: leftPoint,
  approvePoint: bottomPoint,
  notificationsPoint: rightPoint,
  deadlineNotificationsPoint: leftPoint,
}
const edgeStyle_exit_decline = edgeStyle_exit_left;
const edgeStyle_exit_approve = edgeStyle_exit_bottom;
const edgeStyle_exit_notifications = edgeStyle_exit_right;
const edgeStyle_exit_deadlineNotifications = edgeStyle_exit_left;

// Define constraints
const mxConstraintNames = {
  "OUTPUT": "output",
  "INPUT": "input",
  "APPROVE": "approve",
  "DECLINE": "decline",
  "NOTIFICATIONS": "notifications",
  "DEADLINE_NOTIFICATIONS": "deadlineNotifications",
}
const inputConstraint = new mxConnectionConstraint(topPoint, true);
inputConstraint.name = mxConstraintNames.INPUT;
const outputConstraint = new mxConnectionConstraint(bottomPoint, true);
outputConstraint.name = mxConstraintNames.OUTPUT;
const approveConstraint = new mxConnectionConstraint(pointSynonyms.approvePoint, true);
approveConstraint.name = mxConstraintNames.APPROVE;
const declineConstraint = new mxConnectionConstraint(pointSynonyms.declinePoint, true);
declineConstraint.name = mxConstraintNames.DECLINE;
const notificationsConstraint = new mxConnectionConstraint(pointSynonyms.notificationsPoint, true);
notificationsConstraint.name = mxConstraintNames.NOTIFICATIONS;
const deadlineNotificationsConstraint = new mxConnectionConstraint(pointSynonyms.deadlineNotificationsPoint, true);
deadlineNotificationsConstraint.name = mxConstraintNames.DEADLINE_NOTIFICATIONS;

// Combine native mxConstants with custom constants for us
Object.keys(mxConstants_CustomAdditions).forEach(c => {
  mxConstants[c] = mxConstants_CustomAdditions[c];
});

// Define and register custom shapes and their constraints and styles
const IncompleteStyleSuffix = "_INCOMPLETE";
const InvalidConnectionByPropertiesStyleSuffix = "_INVALIDCONNECTIONBYPROPERTIES";
let stylesByShape = [];
let allShapes = [];
class mxShape_Trigger extends mxEllipse {}
mxCellRenderer.registerShape(mxConstants.SHAPE_TRIGGER, mxShape_Trigger);
mxShape_Trigger.prototype.constraints = [
  outputConstraint,
];
allShapes.push({name:mxConstants.SHAPE_TRIGGER, shape:mxShape_Trigger});
stylesByShape.push({[mxConstants.SHAPE_TRIGGER]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_TRIGGER,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.EllipsePerimeter,
}});

class mxShape_Action_General extends mxRectangleShape {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_GENERAL, mxShape_Action_General);
mxShape_Action_General.prototype.constraints = [
  inputConstraint,
  outputConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_GENERAL, shape:mxShape_Action_General});
stylesByShape.push({[mxConstants.SHAPE_ACTION_GENERAL]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_GENERAL,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RectanglePerimeter,
  [mxConstants.STYLE_ROUNDED]: true,
}});

class mxShape_Action_General_InputOnly extends mxRectangleShape {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY, mxShape_Action_General_InputOnly);
mxShape_Action_General_InputOnly.prototype.constraints = [
  inputConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY, shape:mxShape_Action_General_InputOnly});
stylesByShape.push({[mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RectanglePerimeter,
  [mxConstants.STYLE_ROUNDED]: true,
}});

class mxShape_Action_General_WithNotification extends mxRectangleShape {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION, mxShape_Action_General_WithNotification);
mxShape_Action_General_WithNotification.prototype.constraints = [
  inputConstraint,
  outputConstraint,
  notificationsConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION, shape:mxShape_Action_General_WithNotification});
stylesByShape.push({[mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RectanglePerimeter,
  [mxConstants.STYLE_ROUNDED]: true,
}});

class mxShape_Action_Notification_InputOnly extends mxRectangleShape {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY, mxShape_Action_Notification_InputOnly);
mxShape_Action_Notification_InputOnly.prototype.constraints = [
  inputConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY, shape:mxShape_Action_Notification_InputOnly});
stylesByShape.push({[mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RectanglePerimeter,
  [mxConstants.STYLE_ROUNDED]: true,
}});

class mxShape_Action_Approval extends mxRhombus {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_APPROVAL, mxShape_Action_Approval);
mxShape_Action_Approval.prototype.constraints = [
  inputConstraint,
  approveConstraint,
  declineConstraint,
  notificationsConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_APPROVAL, shape:mxShape_Action_Approval});
stylesByShape.push({[mxConstants.SHAPE_ACTION_APPROVAL]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_APPROVAL,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RhombusPerimeter,
}});

class mxShape_Action_Task extends mxRectangleShape {}
mxCellRenderer.registerShape(mxConstants.SHAPE_ACTION_TASK, mxShape_Action_Task);
mxShape_Action_Task.prototype.constraints = [
  inputConstraint,
  outputConstraint,
  deadlineNotificationsConstraint,
  notificationsConstraint,
];
allShapes.push({name:mxConstants.SHAPE_ACTION_TASK, shape:mxShape_Action_Task});
stylesByShape.push({[mxConstants.SHAPE_ACTION_TASK]: {
  [mxConstants.STYLE_SHAPE]: mxConstants.SHAPE_ACTION_TASK,
  [mxConstants.STYLE_PERIMETER]: mxPerimeter.RectanglePerimeter,
  [mxConstants.STYLE_ROUNDED]: true,
}});

const constraintMouseOverTolerance = 12;
const getMouseOverConstraint = (me, state, constraint) => {
  // console.log("getMouseOverConstraint", me.graphX, me.graphY, state.x, state.y, constraint);
  const constraintX = state.x + (constraint.point.x * state.width);
  const constraintY = state.y + (constraint.point.y * state.height);
  // console.log(constraint, constraintX, constraintY, constraint.name);
  const atConstraintX = Math.abs(me.graphX - constraintX) <= constraintMouseOverTolerance;
  const atConstraintY = Math.abs(me.graphY - constraintY) <= constraintMouseOverTolerance;
  const isOverConstraint = (atConstraintX && atConstraintY);
  return {
    isOverConstraint,
    shape: state.shape,
    constraint,
    x: (isOverConstraint) ? constraintX : null,
    y: (isOverConstraint) ? constraintY : null,
  };
}

class ProcessDesigner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Process: null,
      Elements: [],
      TotalConnectedTriggers: 0,
      TotalIncompleteElements: 0,
      TotalInvalidConnectionsByProperties: 0,
      ShowDeleteProcessConfirmation: false,
      ShowDeleteCellsConfirmation: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicator: false,
      OverlayActionButtonsAreVisible: false,
      OverlayActionButtonPoints: {
        Configure: new mxPoint(0, 0),
        ElementInfo: new mxPoint(0, 0),
        Delete: new mxPoint(0, 0),
      },
      OverlayActionProcessElementId: null,
      OverlayAction: null,
      ConstraintTooltip: null,
      CellsToDelete: null,
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: false,
      ShowConnectionExampleDialog: false,
      OverlayActionCellName: "",
      ShowVideoHelpDialog: false,
    }

    this.isDirty = false;
    this.isClearAllElements = false;
    this.graph = null;
  }

  // **************************
  // API calls below this point
  // **************************

  loadProcess() {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.get(GetProcessPathForApi(this.props.match.params.organizationID, this.props.match.params.projectID,
      this.props.match.params.processID))
      .then(resp => {
        this.setState({Process:resp.data, ShowProgressIndicatorImmediately: false});
        return resp.data;
      });
  }

  updateProcess(process) {
    return API.put(GetProcessPathForApi(this.props.match.params.organizationID, this.props.match.params.projectID,
      this.props.match.params.processID), process)
      .then(resp => {
        this.setState({Process:resp.data});
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  handleDeleteProcess = () => {
    this.setState({ ShowProgressIndicatorImmediately: true });
    return API.delete(GetProcessesPathForApi(this.props.match.params.organizationID, this.props.match.params.projectID),
      { data: { Processes:[this.state.Process] } })
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        this.props.history.push(GetProjectProcessesPath(this.props.match.params.projectID));
        return resp;
      })
      .catch(this.handleApiError);
  }

  loadProcessElements() {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.get(GetProcessElementsPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID),
      { params: { getAll: true }})
      .then(resp => {
        this.setState({Elements:resp.data.ProcessElements, ShowProgressIndicatorImmediately: false});
        return resp.data;
      });
  }

  createProcessElement(elementSubtype, graphX, graphY) {
    let newElement = Model.GetNewElement(elementSubtype, graphX, graphY);

    return API.post(GetProcessElementsPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID), [newElement])
      .then(resp => {
        let elements = [...this.state.Elements];
        elements.push(resp.data[0]);
        this.setState({Elements: elements});
        return resp.data[0];
      });
  }

  updateProcessElement(element) {
    return API.put(GetProcessElementPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID, element.ID), element)
      .then(resp => {
        return resp.data;
      });
  }

  handleUpdateProcessElement = updatedProcessElement => {
    if (!updatedProcessElement) {
      return null;
    }
    return this.updateProcessElement(updatedProcessElement)
      .then(processElement => {
        let elements = [...this.state.Elements].filter(e => e.ID !== processElement.ID);
        elements.push(processElement);
        this.setState({Elements:elements});
        let model = this.graph.getModel();
        model.beginUpdate();
        try {
          let cell = model.getCell(processElement.ID);
          if (cell) {
            model.setValue(cell, processElement.Name);
          }
        }
        finally {
          model.endUpdate();
        }
        return processElement;
      });
  }

  deleteProcessElement(element) {
    if (!element) {
      return Promise.reject("No element provided");
    }
    return API.delete(GetProcessElementsPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID),
      { data: { ProcessElements:[element] } })
      .then(resp => {
        return resp;
      });
  }

  createProcessElementConnection(sourceVertex, targetVertex, edgeStyle, name) {
    let sourcePortType = this.getSourcePortTypeBySourceVertexAndEdgeStyle(this.getBaseStyleFromVertex(sourceVertex), edgeStyle);
    let targetPortType = this.getTargetPortTypeByTargetVertexAndEdgeStyle(this.getBaseStyleFromVertex(targetVertex), edgeStyle);
    let newElementConnection = Model.GetNewElementConnection(targetVertex.id, sourcePortType, targetPortType, name);

    return API.post(GetProcessElementConnectionsPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID, sourceVertex.id),
      [newElementConnection])
      .then(resp => {
        let elements = Model.AddConnectionsToElementsAndReturnElements([...this.state.Elements], sourceVertex.id, resp.data);
        this.setState({Elements:elements});
        return resp.data[0];
      });
  }

  updateProcessElementConnection(elementConnection, processElementID) {
    return API.put(GetProcessElementConnectionPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID, processElementID, elementConnection.ID),
      elementConnection)
      .then(resp => {
        return resp.data;
      });
  }

  deleteProcessElementConnection(elementID, elementConnectionID) {
    return API.delete(GetProcessElementConnectionsPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID, this.props.match.params.processID, elementID),
      { data: { IDs:[elementConnectionID] } })
      .then(resp => {
        return null;
      });
  }

  // **********************************
  // mxGraph functions below this point
  // **********************************

  getTempId() {
    return (new Date()).getTime();
  }

  handleCreateCell = (clientOffset, elementSubtype) => {
    let model = this.graph.getModel();
    let parent = this.graph.getDefaultParent();
    
    let point = this.graph.getPointForEvent(new MouseEvent('click', { clientX: clientOffset.x, clientY: clientOffset.y}));
    let graphX = Math.max(0, point.x - (elementSubtype.Width / 2));
    let graphY = Math.max(0, point.y - (elementSubtype.Height / 2));
    
    let vertex = null;
    model.beginUpdate();
    try {
      vertex = this.graph.insertVertex(parent, this.getTempId(), elementSubtype.Name, graphX, graphY, elementSubtype.Width, elementSubtype.Height);
      vertex.notPersisted = true;
      if (elementSubtype.VertexShapeAndStyle) {
        // Default to the incomplete style now.
        // This will be changed below if it doesn't have any required items.
        let styleName = elementSubtype.VertexShapeAndStyle;
        if (elementSubtype.RequiredJsonKeys && elementSubtype.RequiredJsonKeys.length) {
          styleName += IncompleteStyleSuffix;
        }
        model.setStyle(vertex, styleName);
      }
    } finally {
      model.endUpdate();
      this.focusGraphContainer(this.graph);
    }

    let elementCells = this.graph.getChildVertices(this.graph.getDefaultParent());
    if (elementCells) {
      this.setAlertTrackingState(elementCells);
      if (elementCells.length === 2) {
        this.handleSetConnectionExampleVisibility(true)();
      }
    }

    this.createProcessElement(elementSubtype, graphX, graphY)
      .then(element => {
        model.beginUpdate();
        try {
          vertex.setId(element.ID);
          vertex.notPersisted = false;
          model.cells[vertex.getId()] = vertex;
        }
        finally {
          model.endUpdate();
        }
      })
      .catch(err => {
        model.beginUpdate();
        try {
          this.graph.removeCells([vertex], true);
        }
        finally {
          model.endUpdate();
        }
        this.handleApiError(err);
      });
  }

  handleCellsMoved(sender, evt) {
    if (!evt.properties.cells || !evt.properties.cells.length) {
      return;
    }
    let elements = [...this.state.Elements];
    for (let i = 0; i < evt.properties.cells.length; i++) {
      let cell = evt.properties.cells[i];
      if (cell.moveIsFailReset) {
        continue;
      }
      // Currently handling only vertex movements
      if (!cell.vertex) {
        continue;
      }
      if (cell.geometry.x < 0) {
        cell.geometry.x = 0;
      }
      if (cell.geometry.y < 0) {
        cell.geometry.y = 0;
      }
      let element = Model.GetElementById(elements, cell.id);
      if (!element) {
        continue;
      }
      element.GraphX = cell.geometry.x;
      element.GraphY = cell.geometry.y;
      this.updateProcessElement(element)
        .catch(err => {
          let model = this.graph.getModel();
          model.beginUpdate();
          try {
            cell.moveIsFailReset = true;
            this.graph.moveCells([cell], -evt.properties.dx, -evt.properties.dy);
            cell.moveIsFailReset = false;
          }
          finally {
            model.endUpdate();
          }
          this.handleApiError(err);
        });
    }
    this.setState({ Elements: elements });
  }

  handleCellLabelChanged(sender, evt) {
    if (!evt.properties.cell) {
      return;
    }
    let cell = evt.properties.cell;
    let elements = [...this.state.Elements];
    let handleError = (err) => {
      let model = this.graph.getModel();
      model.beginUpdate();
      try {
        let cellToReset = model.getCell(cell.id);
        model.setValue(cellToReset, evt.properties.old);
      }
      finally {
        model.endUpdate();
      }
      this.handleApiError(err);
    }
    // Vertex
    if (cell.vertex) {
      let element = Model.GetElementById(elements, cell.id);
      element.Name = cell.value;
      this.updateProcessElement(element)
        .catch(err => {
          handleError(err);
        });
      this.setState({ Elements: elements });
    }
    // Edge
    else if (cell.edge) {
      let elementConnection = Model.GetElementConnectionById(elements, cell.id);
      elementConnection.Name = cell.value;
      this.updateProcessElementConnection(elementConnection, cell.source.id)
        .catch(err => {
          handleError(err);
        });
      this.setState({ Elements: elements });
    }
  }

  async handleCellsRemoved(sender, evt) {
    if (this.isClearAllElements) {
      return;
    }
    if (!evt.properties.cells || !evt.properties.cells.length) {
      return;
    }

    this.setState({ ShowProgressIndicator: true })

    let preChangeElements = [...this.state.Elements];
    let elementsToRemoveFromStateById = [];
    let restoreAllCellsAndHandleError = (err) => {
      for (let j = 0; j < evt.properties.cells.length; j++) {
        let deletedCell = evt.properties.cells[j];
        let model = this.graph.getModel();
        model.beginUpdate();
        try {
          let restoredCell = null;
          // Vertex
          if (deletedCell.vertex) {
            restoredCell = this.graph.insertVertex(deletedCell.parent, deletedCell.id, deletedCell.value, deletedCell.geometry.x, deletedCell.geometry.y, deletedCell.geometry.width, deletedCell.geometry.height);
          }
          // Edge
          if (deletedCell.edge) {
            let sourceVertex = model.getCell(deletedCell.source.id);
            let targetVertex = model.getCell(deletedCell.target.id);
            restoredCell = this.graph.insertEdge(deletedCell.parent, deletedCell.id, deletedCell.value, sourceVertex, targetVertex);
          }
          restoredCell.setStyle(deletedCell.style);
        }
        finally {
          model.endUpdate();
        }
      }
      this.handleApiError(err);
    };

    // Iterate deleted cells
    for (let i = 0; i < evt.properties.cells.length; i++) {
      let deletedCell = evt.properties.cells[i];
      // Skip if the cell isn't persisted to the back-end
      if (deletedCell.notPersisted) {
        continue;
      }
      // Hide any overlays
      this.setOverlayActionButtonsByCellState(null);
      this.setConstraintTooltipByMouseOverConstraint(null);

      let killLoop = false;
      // Vertex
      if (deletedCell.vertex) {
        let element = Model.GetElementById(preChangeElements, deletedCell.id);
        await this.deleteProcessElement(element)
          .then(resp => {
            elementsToRemoveFromStateById.push(element.ID);
          })
          .catch(err => {
            killLoop = true;
            restoreAllCellsAndHandleError(err);
          });
      }
      // Edge
      if (deletedCell.edge) {
        // Skip the user-drawn edges, as these are recreated after validation
        if (deletedCell.isUserDrawn) {
          continue;
        }
        let model = this.graph.getModel();
        let sourceVertex = model.getTerminal(deletedCell, true);
        let sourceElement = Model.GetElementById(preChangeElements, sourceVertex.id);
        // An edge was deleted directly; We delete the persisted connection
        if (sourceElement) {
          await this.deleteProcessElementConnection(sourceVertex.id, deletedCell.id)
            .then(resp => {
              if (!sourceElement.Connections) {
                sourceElement.Connections = [];
              } else {
                sourceElement.Connections = sourceElement.Connections.filter(e => e.ID !== deletedCell.id);
              }
              // Refresh target-vertex style; it may have become complete or incomplete due to loss of edge connection
              let targetVertex = model.getTerminal(deletedCell, false);
              let targetElement = Model.GetElementById(this.state.Elements, targetVertex.id);
              if (targetElement) {
                model.beginUpdate();
                try {
                  this.setVertexStyleByProcessElementCompletion(model, targetVertex, targetElement);
                }
                finally {
                  model.endUpdate();
                }
              }
            })
            .catch(err => {
              killLoop = true;
              restoreAllCellsAndHandleError(err);
            });
        }
        // An edge was deleted indirectly via vertex deletion; We need to at least update any past-target-vertex styles
        else {
          // Refresh target-vertex style; it may have become complete or incomplete due to loss of edge connection
          let targetVertex = model.getTerminal(deletedCell, false);
          let targetElement = Model.GetElementById(preChangeElements, targetVertex.id);
          if (targetElement) {
            model.beginUpdate();
            try {
              this.setVertexStyleByProcessElementCompletion(model, targetVertex, targetElement);
            }
            finally {
              model.endUpdate();
            }
          }
        }
      }
      if (killLoop) {
        break;
      }
    }

    let updateElementsState = false;
    let updatedElements = [...this.state.Elements];
    elementsToRemoveFromStateById.forEach(eId => {
      if (updatedElements.filter(e => e.ID === eId).length) {
        updatedElements = updatedElements.filter(e => e.ID !== eId);
        updateElementsState = true;
      }
    });
    let stateToUpdate = {ShowProgressIndicator: false};
    if (updateElementsState) {
      stateToUpdate = {...stateToUpdate, Elements: updatedElements};
    }
    this.setState(stateToUpdate);
    this.setAlertTrackingState();
  }

  handleCellClicked(sender, evt) {
    this.focusGraphContainer(this.graph);
  }

  handleCreateEdge(sender, evt) {
    if (!evt.properties.cell) {
      return;
    }
    let model = this.graph.getModel();
    let parent = this.graph.getDefaultParent();
    let edge = evt.properties.cell;
    edge.isUserDrawn = true;
    let sourceVertex = model.getTerminal(edge, true);
    let targetVertex = model.getTerminal(edge, false);
    
    // Get proper style to ensure correct constraint/port is used
    let properStyle = this.getProperEdgeCellStyleNameByConnection(edge, sourceVertex, targetVertex);
    edge.style = properStyle; // This is necessary for later validation
    if (this.isEdgeConnectionValid(edge, properStyle)) {
      let label = this.getDefaultEdgeLabelBySourceAndEdgeStyle(edge.source, properStyle);
      const newEdge = this.createNewEdge(model, parent, sourceVertex, targetVertex, properStyle, label);

      this.createProcessElementConnection(sourceVertex, targetVertex, properStyle, label)
        .then(elementConnection => {
          model.beginUpdate();
          try {
            newEdge.setId(elementConnection.ID);
            newEdge.notPersisted = false;
            // Refresh target-vertex style; it may have become complete or incomplete due to edge connection
            let targetElement = Model.GetElementById(this.state.Elements, targetVertex.id);
            if (targetElement) {
              this.setVertexStyleByProcessElementCompletion(model, targetVertex, targetElement);
            }
          }
          finally {
            model.endUpdate();
          }
        })
        .catch(err => {
          model.beginUpdate();
          try {
            this.graph.removeCells([newEdge]);
          }
          finally {
            model.endUpdate();
          }
          this.handleApiError(err);
        });
    }
    model.beginUpdate();
    try {
      this.graph.removeCells([edge]);
    }
    finally {
      model.endUpdate();
    }
  }

  createNewEdge = (model, parent, sourceVertex, targetVertex, properStyle, label) => {
    let newEdge = null;
    model.beginUpdate();
    try {
      newEdge = this.graph.insertEdge(parent, this.getTempId(), label, sourceVertex, targetVertex);
      newEdge.notPersisted = true;
      const elements = [...this.state.Elements];
      const sourceElement = Model.GetElementById(elements, sourceVertex.id);
      const targetElement = Model.GetElementById(elements, targetVertex.id);
      this.setEdgeStyle(model, newEdge, properStyle, sourceElement, targetElement);
    }
    finally {
      model.endUpdate();
    }
    return newEdge;
  }

  handleEdgeConnectionChanged(sender, evt) {
    if (!evt.properties.previous) {
      return;
    }
    let model = this.graph.getModel();
    let parent = this.graph.getDefaultParent();
    let edge = evt.properties.edge;
    edge.isUserDrawn = true;
    let sourceVertex = this.graph.getModel().getTerminal(edge, true);
    let targetVertex = this.graph.getModel().getTerminal(edge, false);
    let originalVertex = evt.properties.previous;
    let newVertex = evt.properties.terminal;
    let elements = [...this.state.Elements];

    // Get proper style to ensure correct constraint/port is used
    let properStyle = this.getProperEdgeCellStyleNameByConnection(edge, sourceVertex, targetVertex, true);
    edge.style = properStyle; // This is necessary for later validation

    // Remove the user-drawn edge from the model
    model.beginUpdate();
    try {
      this.graph.removeCells([edge]);
    }
    finally {
      model.endUpdate();
    }

    // Setup reusable restore function
    let restoreModelAndHandleOptionalError = (err, createdEdge) => {
      model.beginUpdate();
      try {
        if (createdEdge) {
          this.graph.removeCells([createdEdge]);
        }
        let elementConnection = Model.GetElementConnectionById(elements, edge.id);
        let originalSource = model.getCell(elementConnection.SourceProcessElementID);
        let originalTarget = model.getCell(elementConnection.TargetProcessElementID);
        let newEdge = this.graph.insertEdge(parent, elementConnection.ID, elementConnection.Name,
          originalSource, originalTarget);
        let edgeStyle = this.getEdgeCellStyleNameByVerticesAndPortTypes(
          newEdge.source, elementConnection.SourcePortType,
          newEdge.target, elementConnection.TargetPortType);
        const sourceElement = Model.GetElementById(elements, originalSource.id);
        const targetElement = Model.GetElementById(elements, originalTarget.id);
        this.setEdgeStyle(model, newEdge, edgeStyle, sourceElement, targetElement);

      }
      finally {
        model.endUpdate();
      }
      if (err) {
        this.handleApiError(err);
      }
    }

    // VALID CONNECTION - REMOVE ORIGINAL AND CREATE NEW EDGE
    if (this.isEdgeConnectionValid(edge, properStyle)) {
      const label = this.getDefaultEdgeLabelBySourceAndEdgeStyle(edge.source, properStyle);
      const newEdge = this.createNewEdge(model, parent, sourceVertex, targetVertex, properStyle, label);

      // Moving connection - source side
      if (evt.properties.source) {
        let newElement = Model.GetElementById(elements, newVertex.id);
        // Remove connection from originalElement
        this.deleteProcessElementConnection(originalVertex.id, edge.id)
          .then(resp => {
            let originalElement = Model.GetElementById(elements, originalVertex.id);
            originalElement.Connections = originalElement.Connections.filter(e => e.ID !== edge.id);
            this.setState({ Elements: elements });
            // Add connection to newElement
            if (!newElement.Connections) {
              newElement.Connections = [];
            }
            return this.createProcessElementConnection(newVertex, targetVertex, properStyle, label)
              .then(elementConnection => {
                model.beginUpdate();
                try {
                  newEdge.setId(elementConnection.ID);
                  newEdge.notPersisted = false;
                  // Refresh target-vertex style; it may have become complete or incomplete due to changed edge connection
                  let targetElement = Model.GetElementById(this.state.Elements, targetVertex.id);
                  if (targetElement) {
                    this.setVertexStyleByProcessElementCompletion(model, targetVertex, targetElement);
                  }
                }
                finally {
                  model.endUpdate();
                }
              })
              .catch(err => restoreModelAndHandleOptionalError(err, newEdge));
          })
          .catch(err => restoreModelAndHandleOptionalError(err, newEdge));
      }
      // Moving connection - target side
      else {
        // Remove connection to original target
        this.deleteProcessElementConnection(sourceVertex.id, edge.id)
          .then(resp => {
            let sourceElement = Model.GetElementById(elements, sourceVertex.id);
            sourceElement.Connections = sourceElement.Connections.filter(e => e.ID !== edge.id);
            this.setState({ Elements: elements });
            // Add connection to new target
            sourceElement.Connections.push(newVertex.id);
            return this.createProcessElementConnection(sourceVertex, newVertex, properStyle, label)
              .then(elementConnection => {
                model.beginUpdate();
                try {
                  newEdge.setId(elementConnection.ID);
                  newEdge.notPersisted = false;
                  // Refresh source and target-vertex styles; they may have become complete or incomplete due to changed edge connection
                  let originalTargetElement = Model.GetElementById(this.state.Elements, originalVertex.id);
                  if (originalTargetElement) {
                    this.setVertexStyleByProcessElementCompletion(model, originalVertex, originalTargetElement);
                  }
                  let targetElement = Model.GetElementById(this.state.Elements, newVertex.id);
                  if (targetElement) {
                    this.setVertexStyleByProcessElementCompletion(model, newVertex, targetElement);
                  }
                }
                finally {
                  model.endUpdate();
                }
              })
              .catch(err => restoreModelAndHandleOptionalError(err, newEdge));
          })
          .catch(err => restoreModelAndHandleOptionalError(err, newEdge));
      }
    }
    // INVALID CONNECTION
    else {
      restoreModelAndHandleOptionalError();
    }
  }

  getPortTypeByVertexStyleAndPoint(vertexStyle, point) {
      switch (vertexStyle) {
        case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
          if (this.doPointsMatch(point, topPoint)) {
            return mxConstraintNames.INPUT;
          } else if (this.doPointsMatch(point, bottomPoint)) {
            return mxConstraintNames.OUTPUT;
          } else if (this.doPointsMatch(point, pointSynonyms.notificationsPoint)) {
            return mxConstraintNames.NOTIFICATIONS;
          }
          break;
        case mxConstants.SHAPE_ACTION_TASK:
          if (this.doPointsMatch(point, topPoint)) {
            return mxConstraintNames.INPUT;
          } else if (this.doPointsMatch(point, bottomPoint)) {
            return mxConstraintNames.OUTPUT;
          } else if (this.doPointsMatch(point, pointSynonyms.deadlineNotificationsPoint)) {
            return mxConstraintNames.DEADLINE_NOTIFICATIONS;
          } else if (this.doPointsMatch(point, pointSynonyms.notificationsPoint)) {
            return mxConstraintNames.NOTIFICATIONS;
          } 
          break;
        case mxConstants.SHAPE_ACTION_APPROVAL:
          if (this.doPointsMatch(point, topPoint)) {
            return mxConstraintNames.INPUT;
          } else if (this.doPointsMatch(point, pointSynonyms.approvePoint)) {
            return mxConstraintNames.APPROVE;
          } else if (this.doPointsMatch(point, pointSynonyms.declinePoint)) {
            return mxConstraintNames.DECLINE;
          } else if (this.doPointsMatch(point, pointSynonyms.notificationsPoint)) {
            return mxConstraintNames.NOTIFICATIONS;
          }
          break;
        default:
          if (this.doPointsMatch(point, topPoint)) {
            return mxConstraintNames.INPUT;
          } else if (this.doPointsMatch(point, bottomPoint)) {
            return mxConstraintNames.OUTPUT;
          }
          break;
      }
    return null;
  }

  getSourcePortTypeBySourceVertexAndEdgeStyle(sourceVertexStyle, edgeStyle) {
    let exitPoint = this.getExitPointFromCellStyleKey(edgeStyle);
    return this.getPortTypeByVertexStyleAndPoint(sourceVertexStyle, exitPoint);
  }

  getTargetPortTypeByTargetVertexAndEdgeStyle(targetVertexStyle, edgeStyle) {
    let entryPoint = this.getEntryPointFromCellStyleKey(edgeStyle);
    return this.getPortTypeByVertexStyleAndPoint(targetVertexStyle, entryPoint);
  }

  getDefaultEdgeLabelBySourceAndEdgeStyle(sourceVertex, edgeStyle) {
    let sourceVertexStyle = this.getBaseStyleFromVertex(sourceVertex);
    let exitPoint = this.getExitPointFromCellStyleKey(edgeStyle);
    switch (sourceVertexStyle) {
      case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
        if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return "Notify";
        }
        break;
      case mxConstants.SHAPE_ACTION_APPROVAL:
        if (this.doPointsMatch(exitPoint, pointSynonyms.approvePoint)) {
          return "Approve";
        } else if (this.doPointsMatch(exitPoint, pointSynonyms.declinePoint)) {
          return "Decline";
        } else if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return "Notify";
        }
        break;
      case mxConstants.SHAPE_ACTION_TASK:
        if (this.doPointsMatch(exitPoint, pointSynonyms.deadlineNotificationsPoint)) {
          return "Remind";
        } else if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return "Notify";
        } 
        break;
      default:
        break;
    }
    return null;
  }

  getPointName(point) {
    if (this.doPointsMatch(point, topPoint)) {
      return "topPoint";
    } else if (this.doPointsMatch(point, bottomPoint)) {
      return "bottomPoint";
    } else if (this.doPointsMatch(point, leftPoint)) {
      return "leftPoint";
    } else if (this.doPointsMatch(point, rightPoint)) {
      return "rightPoint";
    }
  }

  isEdgeConnectionValid(edge, style) {
    // We use this to invalidate connections that should not occur.
    // This is because there are more cases where connections are valid than invalid.

    const edgeSourceStyle = this.getBaseStyleFromVertex(edge.source);
    const edgeTargetStyle = this.getBaseStyleFromVertex(edge.target);
    const sourcePoint = this.getExitPointFromCellStyleKey(edge.style);
    // const targetPoint = this.getEntryPointFromCellStyleKey(edge.style);

    // Invalidate any connection where target is a trigger
    if (edgeTargetStyle === mxConstants.SHAPE_TRIGGER) {
      return false;
    }

    // Invalidate any connection where source is input-only
    if (edgeSourceStyle === mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY
        || edgeSourceStyle === mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY) {
      return false;
    }

    // Invalidate any source connection specified by target's ProcessElementSubtype
    const elements = [...this.state.Elements];
    const sourceElement = Model.GetElementById(elements, edge.source.id);
    const sourceElementSubtype = GetProcessElementSubtypeByProcessElement(sourceElement);
    const targetElement = Model.GetElementById(elements, edge.target.id);
    const targetElementSubtype = GetProcessElementSubtypeByProcessElement(targetElement);
    // AllowedTargetConnectionsBySubtype takes priority
    if (sourceElementSubtype.AllowedTargetConnectionsBySubtype
      && sourceElementSubtype.AllowedTargetConnectionsBySubtype.length
      && !sourceElementSubtype.AllowedTargetConnectionsBySubtype
      .includes(targetElementSubtype.ProcessElementSubtype)) {
      return false;
    }
    // DisallowedSourceConnectionsBySubtype
    else if (targetElementSubtype.DisallowedSourceConnectionsBySubtype
      && targetElementSubtype.DisallowedSourceConnectionsBySubtype.length
      && targetElementSubtype.DisallowedSourceConnectionsBySubtype
        .includes(sourceElementSubtype.ProcessElementSubtype)) {
        return false;
    }

    // Invalidate when source point is notification and target is not notification-based
    switch (edgeSourceStyle) {
      case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
      case mxConstants.SHAPE_ACTION_APPROVAL:
        if (this.doPointsMatch(sourcePoint, pointSynonyms.notificationsPoint)) {
          // The source point is a notifications point.
          // Allow only notification-based actions.
          if (edgeTargetStyle !== mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY) {
            return false;
          }
        }
        break;
      case mxConstants.SHAPE_ACTION_TASK:
        if (this.doPointsMatch(sourcePoint, pointSynonyms.notificationsPoint)
          || this.doPointsMatch(sourcePoint, pointSynonyms.deadlineNotificationsPoint)) {
          // The source point is a notifications point.
          // Allow only notification-based actions.
          if (edgeTargetStyle !== mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY) {
            return false;
          }
        }
        break;
      default:
        break;
    }

    // Build list of any existing connection points between source and target
    let existingConnections = [];
    if (edge.source.edges) {
      for (let i = 0; i < edge.source.edges.length; i++) {
        // Ignore the new/pending connection
        if (edge.source.edges[i] === edge) {
          continue;
        }
        const otherEdge = edge.source.edges[i];
        // Ignore other connection that's not between the current source and target
        if ((edge.source !== otherEdge.source && edge.source !== otherEdge.target)
          || (edge.target !== otherEdge.source && edge.target !== otherEdge.target)) {
          continue;
        }
        const otherEdgeSourcePoint = this.getExitPointFromCellStyleKey(otherEdge.style);
        const otherEdgeTargetPoint = this.getEntryPointFromCellStyleKey(otherEdge.style);
        // console.log("Existing connection from", 
        //   otherEdge.source.value, this.getPointName(otherEdgeSourcePoint), 
        //   "to", otherEdge.target.value, this.getPointName(otherEdgeTargetPoint));
        // Add to existingConnections
        existingConnections.push({
          source: otherEdge.source,
          target: otherEdge.target,
          sourcePoint: otherEdgeSourcePoint,
          targetPoint: otherEdgeTargetPoint,
        });
      }
    }

    // Existing connection(s) between source and target
    if (existingConnections.length) {
      let allowMultiConnections = false;
      // If there's exactly 1 existing connection
      if (existingConnections.length === 1) {
        // If both source and target are approval actions
        if (edgeSourceStyle === mxConstants.SHAPE_ACTION_APPROVAL
          && edgeTargetStyle === mxConstants.SHAPE_ACTION_APPROVAL) {
          // Two Approval actions can be connected twice as follows...
          // Source Approval Approve -> Target Approval Start
          // and Target Approval Decline -> Source Approval Start
          // Therefore, the sources must be each other/not the same
          if (existingConnections[0].source !== edge.source) {
            // When existing connection source is decline and new connection source is approve
            if (this.doPointsMatch(existingConnections[0].sourcePoint, pointSynonyms.declinePoint)
              && this.doPointsMatch(sourcePoint, pointSynonyms.approvePoint)) {
              allowMultiConnections = true;
            }
            // When existing connection source is approve and new connection source is decline
            else if (this.doPointsMatch(existingConnections[0].sourcePoint, pointSynonyms.approvePoint)
              && this.doPointsMatch(sourcePoint, pointSynonyms.declinePoint)) {
              allowMultiConnections = true;
            } 
          }
        }
        // If source is approval and target is task,
        // and existing connection source is task finish (to approval start)
        // and new connection source is approval decline (to task start)
        if (edgeSourceStyle === mxConstants.SHAPE_ACTION_APPROVAL
          && edgeTargetStyle === mxConstants.SHAPE_ACTION_TASK
          && this.doPointsMatch(existingConnections[0].sourcePoint, bottomPoint)
          && this.doPointsMatch(sourcePoint, pointSynonyms.declinePoint)) {
          allowMultiConnections = true;
        }
        // If source is task and target is approval,
        // and existing connection source is approval decline (to task start),
        // and new connection source is task finish (to approval start)
        else if (edgeSourceStyle === mxConstants.SHAPE_ACTION_TASK
          && edgeTargetStyle === mxConstants.SHAPE_ACTION_APPROVAL
          && this.doPointsMatch(existingConnections[0].sourcePoint, pointSynonyms.declinePoint)
          && this.doPointsMatch(sourcePoint, bottomPoint)) {
          allowMultiConnections = true;
        }
      }
      if (!allowMultiConnections) {
        return false;
      }
    }

    // No existing connection between source and target
    if (!existingConnections.length) {
      // If the source point is an approval decline and the target is an approval,
      // don't allow if another approval has already been chosen as the decline connection.
      // In other words, only one approval can be connected from any decline point.
      if (this.doPointsMatch(sourcePoint, pointSynonyms.declinePoint)
        && edgeTargetStyle === mxConstants.SHAPE_ACTION_APPROVAL) {
        // The new edge is from the decline point
        for (let i = 0; i < edge.source.edges.length; i++) {
          const otherEdge = edge.source.edges[i];
          // Ignore the new/pending connection
          if (otherEdge === edge) {
            continue;
          }
          // if other edge source is the same as this source
          // and other edge exit point is decline
          // and other edge target is an approval
          const otherEdgeExitPoint = this.getExitPointFromCellStyleKey(otherEdge.style);
          if (otherEdge.source === edge.source
            && this.doPointsMatch(otherEdgeExitPoint, pointSynonyms.declinePoint)
            && this.getBaseStyleFromVertex(otherEdge.target) === mxConstants.SHAPE_ACTION_APPROVAL
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }

  doPointsMatch(pointA, pointB) {
    return (pointA.x === pointB.x && pointA.y === pointB.y);
  }

  getProperEdgeCellStyleNameByConnection(edge, sourceVertex, targetVertex, isEdgeMove) {
    let exitPoint = (isEdgeMove)
      ? this.getExitPointFromCellStyleKey(edge.style)
      : this.getExitPointFromRenderedStyle(edge.style);
    let exitStyle = this.getProperEdgeExitStyleByExitPointAndSourceVertex(exitPoint, sourceVertex);
    let entryPoint = (isEdgeMove)
      ? this.getEntryPointFromCellStyleKey(edge.style)
      : this.getEntryPointFromRenderedStyle(edge.style);
    let entryStyle = this.getProperEdgeEntryStyleByEntryPointAndTargetVertex(entryPoint, targetVertex);

    return this.createEdgeCellStyleAndReturnName(exitPoint, exitStyle, entryPoint, entryStyle);
  }

  getBaseStyleFromVertex(vertex) {
    if (!vertex.style) {
      return vertex.style;
    }
    return (vertex.style.endsWith(IncompleteStyleSuffix))
      ? vertex.style.substr(0, vertex.style.length-IncompleteStyleSuffix.length)
      : vertex.style;
  }

  getProperEdgeExitStyleByExitPointAndSourceVertex(exitPoint, sourceVertex) {
    switch (this.getBaseStyleFromVertex(sourceVertex)) {
      case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
        // If the exit point was specifically the notifications point, use it
        if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return edgeStyle_exit_notifications;
        }
        else {
          return edgeStyle_exit_bottom;
        }
      case mxConstants.SHAPE_ACTION_APPROVAL:
        // If the exit point was specifically the decline point, use it
        if (this.doPointsMatch(exitPoint, pointSynonyms.declinePoint)) {
          return edgeStyle_exit_decline;
        }
        // If the exit point was specifically the notifications point, use it
        else if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return edgeStyle_exit_notifications;
        }
        // Otherwise, exit point should be the approve point
        else {
          return edgeStyle_exit_approve;
        }
      case mxConstants.SHAPE_ACTION_TASK:
        // If the exit point was specifically the deadline notifications point, use it
        if (this.doPointsMatch(exitPoint, pointSynonyms.deadlineNotificationsPoint)) {
          return edgeStyle_exit_deadlineNotifications;
        }
        // If the exit point was specifically the notifications point, use it
        else if (this.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)) {
          return edgeStyle_exit_notifications;
        }
        else {
          return edgeStyle_exit_bottom;
        }
      case mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY:
      case mxConstants.SHAPE_ACTION_GENERAL:
      default:
        return edgeStyle_exit_bottom;
    }
  }

  getProperEdgeEntryStyleByEntryPointAndTargetVertex(entryPoint, targetVertex) {
    switch (this.getBaseStyleFromVertex(targetVertex)) {
      case mxConstants.SHAPE_ACTION_APPROVAL:
      case mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY:
      case mxConstants.SHAPE_ACTION_GENERAL:
      case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
      case mxConstants.SHAPE_ACTION_TASK:
      default:
        return edgeStyle_entry_top;
    }
  }

  getInvalidConnectionByPropertiesEdgeStyleNameFromEdgeStyle = edgeStyle => 
    edgeStyle + InvalidConnectionByPropertiesStyleSuffix;

  createEdgeCellStyleAndReturnName(exitPoint, exitStyle, entryPoint, entryStyle) {
    const styleName = "edgeStyle_" + this.getStyleNameFromPoint(exitPoint) + "_" + 
      this.getStyleNameFromPoint(entryPoint);
    const warningStyleName = this.getInvalidConnectionByPropertiesEdgeStyleNameFromEdgeStyle(styleName);
    const styleSheet = this.graph.getStylesheet();
    const existingStyle = styleSheet.getCellStyle(styleName);
    if (!existingStyle || Object.keys(existingStyle).length === 0) {
      styleSheet.putCellStyle(styleName, {...exitStyle, ...entryStyle});
      styleSheet.putCellStyle(warningStyleName, {...exitStyle, ...entryStyle, 
        "strokeColor": `${red[500]}`,
      });
    }
    return styleName;
  }

  getEdgeCellStyleNameByVerticesAndPortTypes(sourceVertex, sourcePortType, targetVertex, targetPortType) {
    let exitPoint = outputConstraint.point;
    switch (sourcePortType) {
      case mxConstraintNames.APPROVE:
        exitPoint = approveConstraint.point;
        break;
      case mxConstraintNames.DECLINE:
        exitPoint = declineConstraint.point;
        break;
      case mxConstraintNames.DEADLINE_NOTIFICATIONS:
        exitPoint = deadlineNotificationsConstraint.point;
        break;
      case mxConstraintNames.NOTIFICATIONS:
        exitPoint = notificationsConstraint.point;
        break;
      case mxConstraintNames.OUTPUT:
      default:
        exitPoint = outputConstraint.point;
        break;
    }
    let exitStyle = this.getProperEdgeExitStyleByExitPointAndSourceVertex(exitPoint, sourceVertex);
    let entryPoint = inputConstraint.point;
    let entryStyle = this.getProperEdgeEntryStyleByEntryPointAndTargetVertex(entryPoint, targetVertex);

    return this.createEdgeCellStyleAndReturnName(exitPoint, exitStyle, entryPoint, entryStyle);
  }

  setEdgeStyle = (model, edge, edgeStyle, sourceElement, targetElement) => {
    const sourceElementSubtype = GetProcessElementSubtypeByProcessElement(sourceElement);
    if (sourceElementSubtype.InvalidConnectionsByPropertiesCheckFunction) {
      const connectionIsValid = 
        sourceElementSubtype.InvalidConnectionsByPropertiesCheckFunction(this, sourceElement, targetElement);
      if (!connectionIsValid) {
        edgeStyle = this.getInvalidConnectionByPropertiesEdgeStyleNameFromEdgeStyle(edgeStyle);
      }
    }
    model.setStyle(edge, edgeStyle);
    this.setAlertTrackingState();
  }

  getExitPointFromRenderedStyle(style) {
    let exitX = parseFloat(this.getStyleValueByKeyFromRenderedStyle(style, mxConstants.STYLE_EXIT_X));
    let exitY = parseFloat(this.getStyleValueByKeyFromRenderedStyle(style, mxConstants.STYLE_EXIT_Y));
    return new mxPoint(exitX, exitY);
  }

  getEntryPointFromRenderedStyle(style) {
    let entryX = parseFloat(this.getStyleValueByKeyFromRenderedStyle(style, mxConstants.STYLE_ENTRY_X));
    let entryY = parseFloat(this.getStyleValueByKeyFromRenderedStyle(style, mxConstants.STYLE_ENTRY_Y));
    return new mxPoint(entryX, entryY);
  }

  getStyleValueByKeyFromRenderedStyle(style, key) {
    let styleArray = style.split(";");
    let styleItemArray = styleArray.filter(s => s.startsWith(key));
    if (styleItemArray.length > 0) {
      return styleItemArray[0].substr(1+key.length);
    }
    return "";
  }

  getExitPointFromCellStyleKey(cellStyleKey) {
    let exitX = this.getStyleValueByKeyFromStylesheet(cellStyleKey, mxConstants.STYLE_EXIT_X);
    let exitY = this.getStyleValueByKeyFromStylesheet(cellStyleKey, mxConstants.STYLE_EXIT_Y);
    return new mxPoint(exitX, exitY);
  }

  getEntryPointFromCellStyleKey(cellStyleKey) {
    let entryX = this.getStyleValueByKeyFromStylesheet(cellStyleKey, mxConstants.STYLE_ENTRY_X);
    let entryY = this.getStyleValueByKeyFromStylesheet(cellStyleKey, mxConstants.STYLE_ENTRY_Y);
    return new mxPoint(entryX, entryY);
  }

  getStyleValueByKeyFromStylesheet(cellStyleKey, key) {
    let style = this.graph.getStylesheet().getCellStyle(cellStyleKey);
    if (style && style[key] !== undefined) {
      return style[key];
    }
    return null;
  }

  getStyleNameFromPoint(point) {
    return `x${point.x}_y${point.y}`;
  }

  isProcessElementJsonDataValid(element, requiredJsonKeys) {
    if (!element.JsonData) {
      return false;
    }
    let data = JSON.parse(element.JsonData);
    for (let i = 0; i < requiredJsonKeys.length; i++) {
      let k = requiredJsonKeys[i];
      if (data[k] === undefined || data[k] === null) {
        return false;
      }
      switch (typeof data[k]) {
        case "string":
          if (data[k] === "") {
            return false;
          }
          break;
        case "object":
          if (data[k].length === 0) {
            return false;
          }
          break;
        default:
          break;
      }
    };
    return true;
  }

  isProcessElementComplete(element, elementSubtype) {
    if (elementSubtype.RequiredJsonKeys && elementSubtype.RequiredJsonKeys.length) {
      if (!this.isProcessElementJsonDataValid(element, elementSubtype.RequiredJsonKeys)) {
        return false;
      }
    }
    if (elementSubtype.CustomRequirementFunction) {
      if (typeof elementSubtype.CustomRequirementFunction === "function") {
        if (!elementSubtype.CustomRequirementFunction(this, element, pointSynonyms)) {
          return false;
        }
      }
    }
    return true;
  }

  setVertexStyleByProcessElementCompletion(model, vertex, element) {
    let elementSubtype = GetProcessElementSubtypeByProcessElement(element);
    if (!this.isProcessElementComplete(element, elementSubtype)) {
      model.setStyle(vertex, elementSubtype.VertexShapeAndStyle+IncompleteStyleSuffix);
    } else {
      model.setStyle(vertex, elementSubtype.VertexShapeAndStyle);
    }
    this.setAlertTrackingState();
  }

  setAlertTrackingState(cells_optional) {
    let stateToUpdate = {};
    
    // Cell alerts
    let cells = cells_optional;
    if (!cells_optional) {
      cells = this.graph.getChildVertices(this.graph.getDefaultParent());
    }
    if (cells && cells.length) {
      stateToUpdate = {...stateToUpdate, 
        TotalIncompleteElements:
          cells
            .filter(c => c.style && c.style.endsWith(IncompleteStyleSuffix))
            .length,
        TotalConnectedTriggers:
          cells
            .filter(c => c.style && c.style.startsWith(mxConstants.SHAPE_TRIGGER))
            .filter(c => c.edges && c.edges.length)
            .length,
      };
    }

    // Edge alerts
    const edges = this.graph.getChildEdges(this.graph.getDefaultParent());
    if (edges && edges.length) {
      stateToUpdate = {...stateToUpdate, 
        TotalInvalidConnectionsByProperties:
          edges
            .filter(e => e.style && e.style.endsWith(InvalidConnectionByPropertiesStyleSuffix))
            .length,
      };
    }

    this.setState(stateToUpdate);
  }

  focusGraphContainer(graph) {
    graph.container.setAttribute('tabindex', '-1');
    graph.container.focus();
  }

  loadGraph() {
    let container = this.divGraph;

    // Checks if the browser is supported
    if (!mxClient.isBrowserSupported()) {
      // Displays an error message if the browser is not supported.
      mxUtils.error("Browser is not supported!", 200, false);
    } else {
      // Disables the built-in context menu
      mxEvent.disableContextMenu(container);

      // Creates the graph inside the given container
      let graph = this.graph;
      let local = this;
      if (!this.graph) {
        this.graph = new mxGraph(container);
        graph = this.graph;
        graph.setPanning(true);
        graph.setTooltips(false);
        graph.setConnectable(true);
        graph.setEnabled(true);
        graph.setEdgeLabelsMovable(false);
        graph.setVertexLabelsMovable(false);
        graph.setGridEnabled(true);
        graph.setAllowDanglingEdges(false);
        graph.setCellsCloneable(false);
        graph.setCellsResizable(false);
        mxGraph.prototype.keepEdgesInBackground = true;
        // graph.setMultigraph(false); // Handling this another way down below to prevent javascript alert messages

        let { theme } = this.props;
        let styleSheet = graph.getStylesheet();

        // Changes the default style for edges "in-place" and assigns
        // an alternate edge style which is applied in mxGraph.flip
        // when the user double clicks on the adjustment control point
        // of the edge. The ElbowConnector edge style switches to TopToBottom
        // if the horizontal style is true.
        let defaultEdgeStyle = styleSheet.getDefaultEdgeStyle();
        
        // Default edge style
        defaultEdgeStyle[mxConstants.STYLE_EDGE] = mxConstants.EDGESTYLE_ORTHOGONAL;
        defaultEdgeStyle[mxConstants.STYLE_ROUNDED] = true;
        defaultEdgeStyle[mxConstants.STYLE_FONTFAMILY] = "Roboto,Arial,Helvetica,sans-serif";
        defaultEdgeStyle[mxConstants.STYLE_FONTSIZE] = "14";
        defaultEdgeStyle[mxConstants.STYLE_FONTCOLOR] = (theme.palette.type === "dark") 
          ? "rgba(255, 255, 255, 0.87)" : "rgba(0, 0, 0, 0.87)";
        defaultEdgeStyle[mxConstants.STYLE_STROKECOLOR] = orange[500];

        // Default Vertex Style
        let defaultVertexStyle = styleSheet.getDefaultVertexStyle();
        defaultVertexStyle[mxConstants.STYLE_FONTFAMILY] = "Roboto,Arial,Helvetica,sans-serif";
        defaultVertexStyle[mxConstants.STYLE_FONTSIZE] = "14";
        defaultVertexStyle[mxConstants.STYLE_FONTCOLOR] = theme.palette.primary.text;
        defaultVertexStyle[mxConstants.STYLE_FILLCOLOR] = "#c4f2f1";
        defaultVertexStyle[mxConstants.STYLE_GRADIENTCOLOR] = "#59dcd9";
        defaultVertexStyle[mxConstants.STYLE_STROKECOLOR] = theme.palette.primary.main;
        // defaultVertexStyle[mxConstants.STYLE_SHADOW] = true;
        
        // Global styles
        mxConstants.DEFAULT_VALID_COLOR = orange[500];
        mxConstants.DEFAULT_INVALID_COLOR = theme.palette.secondary.main;
        mxConstants.VALID_COLOR = orange[500];
        mxConstants.INVALID_COLOR = (theme.palette.type === "dark") ? "#eee" : theme.palette.secondary.main;
        mxConstants.VERTEX_SELECTION_COLOR = orange[500]; // bounding box
        mxConstants.VERTEX_SELECTION_STROKEWIDTH = 2; // bounding box
        mxConstants.CONNECT_HANDLE_FILLCOLOR = theme.palette.primary.main; // edge-to-vertex connection points
        mxConstants.HANDLE_SIZE = 10; // drag handles to alter selected-edge shape
        mxConstants.HANDLE_STROKECOLOR = theme.palette.secondary.main; // drag handles to alter selected-edge shape
        mxConstants.HANDLE_FILLCOLOR = "orange"; // drag handles to alter selected-edge shape
        mxConstants.EDGE_SELECTION_COLOR = "orange"; // selected edge
        // mxConstants.SHADOW_OPACITY = 0.5;
        // mxConstants.CURSOR_CONNECT = "crosshair";

        // Incomplete Vertex Style Additions
        const style_incompleteVertex = {
          [mxConstants.STYLE_FILLCOLOR]:"#faa29b",
          [mxConstants.STYLE_GRADIENTCOLOR]:"#f44336",
          [mxConstants.STYLE_STROKECOLOR]:red[700],
        };

        // Register shape styles
        stylesByShape.forEach(s => {
          let key = Object.keys(s)[0];
          // Register regular style
          styleSheet.putCellStyle(key, s[key]);
          // Register incomplete style
          styleSheet.putCellStyle(key+IncompleteStyleSuffix, {...s[key], ...style_incompleteVertex});
        });

        // Overrides shape of rubber band (modification: added style.backgroundColor)
        mxRubberband.prototype.createShape = function()
        {
          if (this.sharedDiv == null) {
            this.sharedDiv = document.createElement('div');
            this.sharedDiv.className = 'mxRubberband';
            this.sharedDiv.style.backgroundColor = theme.palette.primary.main;
            mxUtils.setOpacity(this.sharedDiv, this.defaultOpacity);
          }

          graph.container.appendChild(this.sharedDiv);
          var result = this.sharedDiv;

          if (mxClient.IS_SVG && (!mxClient.IS_IE || document.documentMode >= 10) && this.fadeOut) {
            this.sharedDiv = null;
          }

          return result;
        };

        // Disables floating connections (only use with no connect image)
        if (graph.connectionHandler.connectImage == null)
        {
          graph.connectionHandler.isConnectableCell = function(cell)
          {
             return false;
          };
          mxEdgeHandler.prototype.isConnectableCell = function(cell)
          {
            return graph.connectionHandler.isConnectableCell(cell);
          };
        }

        // Overrides highlight shape for port/connection points
        mxConstraintHandler.prototype.createHighlightShape = function()
        {
          var hl = new mxEllipse(null, null, "orange", 2);
          hl.opacity = mxConstants.HIGHLIGHT_OPACITY;
          
          return hl;
        };

        // Override validation alert behavior (we don't want to display native alerts)
        mxGraph.prototype.validationAlert = function (message) {
          // mxUtils.alert(message);
          console.log(message);
        };

        // Override edge validation
        mxGraph.prototype.validateEdge = function (edge, source, target) {
          // Invalidate if target is a trigger
          if (target.style === mxConstants.SHAPE_TRIGGER
            || target.style === mxConstants.SHAPE_TRIGGER+IncompleteStyleSuffix) {
            return "cannot connect output to trigger";
          }

          return null;
        }

        // Forces connect preview to use the default edge style
        graph.connectionHandler.createEdgeState = function(me)
        {
          let edge = graph.createEdge(null, null, null, null, null);
          
          return new mxCellState(graph.view, edge, graph.getCellStyle(edge));
        };

        // Listeners
        graph.addListener(mxEvent.CELLS_MOVED, this.handleCellsMoved.bind(this));
        graph.addListener(mxEvent.LABEL_CHANGED, this.handleCellLabelChanged.bind(this));
        graph.connectionHandler.addListener(mxEvent.CONNECT, this.handleCreateEdge.bind(this));
        graph.addListener(mxEvent.CELL_CONNECTED, this.handleEdgeConnectionChanged.bind(this));
        graph.addListener(mxEvent.CELLS_REMOVED, this.handleCellsRemoved.bind(this));
        graph.addListener(mxEvent.CLICK, this.handleCellClicked.bind(this));
        let setOverlayActionButtonsByCellState = this.setOverlayActionButtonsByCellState.bind(this);
        let setConstraintTooltipByMouseOverConstraint = this.setConstraintTooltipByMouseOverConstraint.bind(this);
        graph.addMouseListener({
          hoveredVertexState: null,
          mouseOverConstraint: {},
          mouseDownState: null,
          mouseDown: function(sender, me) {
            if (this.hoveredVertexState) {
              this.vertexMouseOut();
            }
            this.mouseDownState = graph.view.getState(me.getCell());
            // console.log("mouseDown");
          },
          mouseUp: function(sender, me) {
            this.mouseDownState = null;
            // console.log("mouseUp");
            let state = graph.view.getState(me.getCell());
            if (state) {
              if (graph.getModel().isVertex(state.cell)) {
                this.vertexMouseIn(state);
              }
            }
          },
          mouseMove: function(sender, me) {
            if (this.mouseDownState) {
              return;
            }
            let state = graph.view.getState(me.getCell());
            if (state) {
              // Set constraint/port tooltips (if applicable)
              // console.log(me.graphX, me.graphY, state);
              // console.log(state.x, state.y, state.width, state.height);
              let mouseOverConstraint = {};
              // console.log("state.shape.style.shape", state.shape.style.shape);
              allShapes.filter(s => s.name === state.shape.style.shape)
                .forEach((currentShape, idx) => {
                    // console.log("currentShape.shape.prototype.constraints", idx, currentShape.shape.prototype.constraints);
                    currentShape.shape.prototype.constraints.forEach(c => {
                      const moc = getMouseOverConstraint(me, state, c);
                      if (moc.isOverConstraint) {
                        mouseOverConstraint = moc;
                      }
                    });
                });
              if (mouseOverConstraint.shape !== this.mouseOverConstraint.shape
                && mouseOverConstraint.constraint !== this.mouseOverConstraint.constraint) {
                this.mouseOverConstraint = mouseOverConstraint;
                setConstraintTooltipByMouseOverConstraint(mouseOverConstraint);
              }
              if (state !== this.hoveredVertexState) {
                if (graph.getModel().isVertex(state.cell)) {
                  this.vertexMouseIn(state);
                }
              }
            } else {
              this.vertexMouseOut();                
            }
          },
          vertexMouseIn: function(state) {
            this.hoveredVertexState = state;
            setOverlayActionButtonsByCellState(state);
            // console.log("vertexMouseIn", state.cell);
          },
          vertexMouseOut: function() {
            // console.log("vertexMouseOut", this.hoveredVertexState);
            this.hoveredVertexState = null;
            this.mouseOverConstraint = {};
            setOverlayActionButtonsByCellState(null);
            setConstraintTooltipByMouseOverConstraint(null);
          },
        });

        // Workaround for a known issue
        let focusGraphContainer = this.focusGraphContainer;
        mxEvent.addListener(graph.container, 'mousedown', function()
        {
          if (!graph.isEditing())
          {
            focusGraphContainer(graph);
          }
        });
      }
      
      // Gets the default parent for inserting new cells. This is normally the first
      // child of the root (ie. layer 0).
      let parent = graph.getDefaultParent();

      let model = graph.getModel();
      model.beginUpdate();
      try {
        //mxGraph component
        let doc = mxUtils.createXmlDocument();
        let node = doc.createElement("Node");
        node.setAttribute("ComponentID", "[P01]");

        let elements = [...this.state.Elements];

        // Show "adding elements" tutorial when no elements exist
        if (elements.length === 0) {
          this.handleSetDragExampleVisibility(true)();
        }

        // Vertex Pass 1
        let vertexesById = [];
        for (let element of elements) {
          let elementSubtype = GetProcessElementSubtypeByProcessElement(element);
          let vertex = graph.insertVertex(parent, element.ID, element.Name, element.GraphX, element.GraphY, elementSubtype.Width, elementSubtype.Height);
          model.setStyle(vertex, elementSubtype.VertexShapeAndStyle);
          vertexesById[element.ID] = vertex;
        }

        // Edge Pass
        for (let sourceElement of elements) {
          if (sourceElement.Connections) {
            for (let elementConnection of sourceElement.Connections) {
              let targetElements = elements.filter(e => e.ID === elementConnection.TargetProcessElementID);
              if (!targetElements.length) {
                console.log('cannot find vertex'); // needs cleanup logic
                continue;
              }
              let targetElement = targetElements[0];
              if (!vertexesById[sourceElement.ID] || !vertexesById[targetElement.ID]) {
                continue;
              }
              let edge = graph.insertEdge(parent, elementConnection.ID, elementConnection.Name, vertexesById[sourceElement.ID], vertexesById[targetElement.ID]);            
              let edgeStyle = this.getEdgeCellStyleNameByVerticesAndPortTypes(
                vertexesById[sourceElement.ID], elementConnection.SourcePortType,
                vertexesById[targetElement.ID], elementConnection.TargetPortType);
              this.setEdgeStyle(model, edge, edgeStyle, sourceElement, targetElement);
            }
          }
        }

        // Vertex Pass 2
        for (let element of elements) {
          let vertex = vertexesById[element.ID];
          if (!vertex) {
            continue;
          }
          this.setVertexStyleByProcessElementCompletion(model, vertex, element);
        }

        this.setState({Elements: elements});

        // let edge = graph.insertEdge(parent, null, "", vertexes[0], vertexes[1]);
        // graph.setConnectionConstraint(edge, vertexes[0], true, new mxConnectionConstraint(new mxPoint(0.5, 0.75), true));
        // edge = graph.insertEdge(parent, null, "", vertexes[0], vertexes[2]);
        // graph.setConnectionConstraint(edge, vertexes[0], true, new mxConnectionConstraint(new mxPoint(0.5, 0.75), true));

//         let processStart = graph.insertVertex(parent, null, "Invoice\n Image\n Received", 48, 48, 120, 60);
//         model.setStyle(processStart, 'terminal');
// 
//         let saveToDrive = graph.insertVertex(parent, null, "Save to\n Google Drive", 248, 48, 120, 60);
//         model.setStyle(saveToDrive, 'element');
// 
//         graph.insertEdge(parent, null, "", processStart, saveToDrive);
// 
//         let clerkApproval = graph.insertVertex(parent, null, "Clerk Approval", 448, 78, 160, 80);
//         model.setStyle(clerkApproval, 'decision');
// 
//         graph.insertEdge(parent, null, "", saveToDrive, clerkApproval);
// 
//         let managerApproval = graph.insertVertex(parent, null, "Manager Approval", 708, 78, 160, 80);
//         model.setStyle(managerApproval, 'decision');
// 
//         graph.insertEdge(parent, null, "Decline", clerkApproval, managerApproval);
// 
//         let emailVendor = graph.insertVertex(parent, null, "E-mail Vendor\n Declined Invoice", 968, 88, 120, 60);
//         model.setStyle(emailVendor, 'element');
// 
//         graph.insertEdge(parent, null, "Decline", managerApproval, emailVendor);
// 
//         let cfoApproval = graph.insertVertex(parent, null, "CFO Approval", 448, 238, 160, 80);
//         model.setStyle(cfoApproval, 'decision');
// 
//         graph.insertEdge(parent, null, "Approve", clerkApproval, cfoApproval);
// 
//         graph.insertEdge(parent, null, "Decline", cfoApproval, emailVendor);
// 
//         graph.insertEdge(parent, null, "Approve", managerApproval, cfoApproval, 'exitX=0.5;exitY=1;entryX=0.75;entryY=0');
// 
//         let checkCutting = graph.insertVertex(parent, null, "Check-Cutting\n Process", 468, 388, 120, 60);
//         model.setStyle(checkCutting, 'element');
// 
//         graph.insertEdge(parent, null, "Approve", cfoApproval, checkCutting);
// 
//         let mailCheck = graph.insertVertex(parent, null, "Mail Check", 658, 388, 120, 60);
//         model.setStyle(mailCheck, 'element');
// 
//         graph.insertEdge(parent, null, "", checkCutting, mailCheck);
// 
//         let archive = graph.insertVertex(parent, null, "Archive", 808, 488, 120, 60);
//         model.setStyle(archive, 'terminal');
// 
//         graph.insertEdge(parent, null, "", mailCheck, archive);

        //data
      } finally {
        // Updates the display
        model.endUpdate();
      }

      // Enables rubberband (marquee) selection
      // let rubberband = 
      new mxRubberband(graph);
      
      // Enables key handler for basic keystrokes
      let keyHandler = new mxKeyHandler(graph);
      // Delete
      keyHandler.bindKey(46, function(evt)
      {
        if (graph.isEnabled() && graph.getSelectionCells().length)
        {
          local.handleSetDeleteCellsConfirmationVisibility(true);
        }
      });

      // Focus the container to ensure any key bindings will work immediately.
      this.focusGraphContainer(graph);
    }
  }

  handleConfirmProcessName = () => {
    if (!this.state.Process) {
      return;
    }
    this.setState({
      Confirmation: {
        Title: "Change process name",
        RequireTextInput1: true,
        TextInput1Label: "Process Name",
        TextInput1DefaultValue: this.state.Process.Name,
        ConfirmLabel: "GO",
        ConfirmCallback: (textInput1) => this.handleUpdateProcessName(textInput1),
      }
    });
  }

  handleUpdateProcessName(name) {
    if (!name) {
      return;
    }
    let process = {...this.state.Process};
    process.Name = name;
    return this.updateProcess(process);
  }

  handleSetDeleteProcessConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDeleteProcessConfirmation: visible,
      ShowDialogProgressIndicator: false,
    });
  }

  handleSetDeleteCellsConfirmationVisibility = (show, cellsToDelete_optional) => {
    this.setState({
      ShowDeleteCellsConfirmation: show,
      CellsToDelete: cellsToDelete_optional,
      ShowDialogProgressIndicator: false,
    });
  }

  handleDeleteCells = (cellsToDelete_optional) => event => {
    this.setState({ ShowDeleteCellsConfirmation: false });
    // when no explicit cellsToDelete_optional, selected cells are deleted
    this.graph.removeCells(cellsToDelete_optional);
  }

  setOverlayActionButtonsByCellState(state) {
    if (state) {
      let origin = state.origin;
      let points = {
        Configure: new mxPoint(0, 0),
        ElementInfo: new mxPoint(0, 0),
        Delete: new mxPoint(0, 0),
      };
      switch (state.style.shape) {
        case mxConstants.SHAPE_ACTION_APPROVAL:
          points.Configure = new mxPoint(origin.x+28, origin.y+4)
          points.ElementInfo = new mxPoint(origin.x+103, origin.y+4)
          points.Delete = new mxPoint(origin.x+103, origin.y+46)
          break;
        case mxConstants.SHAPE_ACTION_GENERAL_INPUTONLY:
        case mxConstants.SHAPE_ACTION_GENERAL:
        case mxConstants.SHAPE_ACTION_GENERAL_WITHNOTIFICATION:
        case mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY:
        case mxConstants.SHAPE_ACTION_TASK:
          points.Configure = new mxPoint(state.origin.x+15, origin.y-15)
          points.ElementInfo = new mxPoint(state.origin.x+74, origin.y-15)
          points.Delete = new mxPoint(state.origin.x+74, origin.y+46)
          break;
        case mxConstants.SHAPE_TRIGGER:
          points.Configure = new mxPoint(origin.x+15, origin.y-10)
          points.ElementInfo = new mxPoint(origin.x+74, origin.y-10)
          points.Delete = new mxPoint(origin.x+74, origin.y+41)
          break;
        default:
          break;
      }
      const elements = [...this.state.Elements];
      const element = Model.GetElementById(elements, state.cell.id);
      if (element) {
        const elementSubtype = GetProcessElementSubtypeByProcessElement(element);
        this.setState({
          OverlayActionCellName: `${elementSubtype.ProcessElementType}: ${elementSubtype.Name}`,
          OverlayActionButtonsAreVisible: true,
          OverlayActionProcessElementId: state.cell.id,
          OverlayActionButtonPoints: points,
        });
      }
    } else {
      this.setState({OverlayActionButtonsAreVisible: false});
    }
  }

  setConstraintTooltipByMouseOverConstraint = mouseOverConstraint => {
    let ConstraintTooltip = null;
    if (mouseOverConstraint && mouseOverConstraint.isOverConstraint) {
      let X = mouseOverConstraint.x;
      let Y = mouseOverConstraint.y;
      const inputTooltip = { X: X - 22, Y: Y - 44, ToolTip: "Input", };
      const notificationTooltip = { X: X + 17, Y: Y - 24, ToolTip: "Arrival\nNotification\nOverride", };
      // console.log(mouseOverConstraint.shape.style.shape, mouseOverConstraint.constraint.name);
      switch (mouseOverConstraint.shape.style.shape) {
        case mxConstants.SHAPE_ACTION_APPROVAL:
          switch (mouseOverConstraint.constraint.name) {
            case mxConstraintNames.INPUT:
              ConstraintTooltip = inputTooltip;
              break;
            case mxConstraintNames.APPROVE:
              ConstraintTooltip = { X: X - 31, Y: Y + 16, ToolTip: "Approve", };
              break;
            case mxConstraintNames.DECLINE:
              ConstraintTooltip = { X: X - 74, Y: Y - 14, ToolTip: "Decline", };
              break;
            case mxConstraintNames.NOTIFICATIONS:
              ConstraintTooltip = notificationTooltip;
              break;
            default:
              break;
          }
          break;
        default:
          switch (mouseOverConstraint.constraint.name) {
            case mxConstraintNames.INPUT:
              ConstraintTooltip = inputTooltip;
              break;
            case mxConstraintNames.OUTPUT:
              ConstraintTooltip = { X: X - 26, Y: Y + 16, ToolTip: "Output", };
              break;
            case mxConstraintNames.DEADLINE_NOTIFICATIONS:
              ConstraintTooltip = { X: X - 88, Y: Y - 24, ToolTip: "Reminder\nNotification\nOverride", };
              break;
            case mxConstraintNames.NOTIFICATIONS:
              ConstraintTooltip = notificationTooltip;
              break;
            default:
              break;
          }
          break;
      }
    }
    // Set minimums
    if (ConstraintTooltip) {
      if (ConstraintTooltip.X < 0) {
        ConstraintTooltip.X = 0;
      }
      if (ConstraintTooltip.Y < 0) {
        ConstraintTooltip.Y = 0;
      }
    }
    this.setState({ ConstraintTooltip });
  }

  handleOverlayActionButtonClick = action => {
    this.setState({OverlayAction: action});
    switch (action) {
      case "configure":
      // This is handled in render()
      break;
      case "delete":
        let model = this.graph.getModel();
        let overlayCell = model.getCell(this.state.OverlayActionProcessElementId);
        this.handleSetDeleteCellsConfirmationVisibility(true, [overlayCell]);
      break;
      default:
      break;
    }
  }

  handleOverlayActionProcessElementClose = element => {
    const elements = [...this.state.Elements];
    let model = this.graph.getModel();
    let vertex = model.getCell(element.ID);
    if (vertex) {
      model.beginUpdate();
      try {
        this.setVertexStyleByProcessElementCompletion(model, vertex, element);
        // Reset all edges' style to display warnings if any
        if (vertex.edges && vertex.edges.length) {
          vertex.edges.forEach(edge => {
            const properStyle = this.getProperEdgeCellStyleNameByConnection(edge, edge.source, edge.target, true);
            // Dialog closed for element that is the edge source
            if (edge.source.id === vertex.id) {
              const targetElement = Model.GetElementById(elements, edge.target.id);
              this.setEdgeStyle(model, edge, properStyle, element, targetElement);
            }
            // Dialog closed for element that is the edge target
            else if (edge.target.id === vertex.id) {
              const sourceElement = Model.GetElementById(elements, edge.source.id);
              this.setEdgeStyle(model, edge, properStyle, sourceElement, element);
            }
          });
        }
      }
      finally {
        model.endUpdate();
      }
    }
    this.setState({OverlayAction:null});
  }

  handleSetToolboxMenuVisibility = visible => event => {
    this.setState({ ToolboxMenuAnchorEl: (visible) ? event.currentTarget : null });
  }

  handleSetDragExampleVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: visible,
      ShowProgressIndicator: false,
    });
  }

  handleSetConnectionExampleVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowConnectionExampleDialog: visible,
      ShowProgressIndicator: false,
    });
  }

  handleSetShowVideoHelpDialogVisibility = ShowVideoHelpDialog => {
    this.setState({ShowVideoHelpDialog});
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowDialogProgressIndicator: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false
    });
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  componentDidMount() {
    this.loadProcess()
      .then(resp => {
        return this.loadProcessElements()
          .then(resp => {
            this.loadGraph();
          })
        })
      .catch(this.handleApiError);
    if (IsMobile()) {
      this.handleAlert({
        Title: "Productivity",
        BodyText: "Use a desktop browser for the best experience.",
      });
    }
  }

  render() {
    const { 
      ApiError,
      Alert,
      Confirmation,
      Process,
      Elements,
      TotalIncompleteElements,
      TotalInvalidConnectionsByProperties,
      TotalConnectedTriggers,
      OverlayActionProcessElementId,
      OverlayAction,
      OverlayActionButtonsAreVisible,
      OverlayActionButtonPoints,
      ConstraintTooltip,
      CellsToDelete,
      ToolboxMenuAnchorEl,
      ShowDeleteProcessConfirmation,
      ShowDeleteCellsConfirmation,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicator,
      ShowDragExampleDialog,
      ShowConnectionExampleDialog,
      OverlayActionCellName,
      ShowVideoHelpDialog,
  	} = this.state;
    const {
      classes,
      theme,
      connectDropTarget,
      match,
      location,
      history,
    } = this.props;

    let deleteProcessConfirmationDialogDetails = {
      Open:ShowDeleteProcessConfirmation,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:"Delete process?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:this.handleSetDeleteProcessConfirmationVisibility(false),
      CloseCallback:this.handleSetDeleteProcessConfirmationVisibility(false),
      ConfirmCallback:this.handleDeleteProcess,
    };
    let deleteCellsConfirmationDialogDetails = {
      Open:ShowDeleteCellsConfirmation,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:"Delete element(s)?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetDeleteCellsConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteCellsConfirmationVisibility(false),
      ConfirmCallback:this.handleDeleteCells(CellsToDelete),
    };
    let dragExampleDetails = {
      Open:ShowDragExampleDialog,
      Title:"Adding elements",
      BodyContent: (
        <img src="/workflowElementDragExample.gif" alt="Tutorial: Adding elements" />
      ),
      CloseCallback:this.handleSetDragExampleVisibility(false),
    }
    let connectionExampleDetails = {
      Open:ShowConnectionExampleDialog,
      Title:"Connecting elements",
      BodyContent: (
        <img src="/workflowElementConnectExample.gif" alt="Tutorial: Connecting elements" />
      ),
      CloseCallback:this.handleSetConnectionExampleVisibility(false),
    }

    const triggerListItems = ProcessElementSubtypes
      .filter(subtype => subtype.ProcessElementType === "Trigger")
      .map(subtype => (
        <DraggableListItem 
          key={`key_${subtype.ProcessElementType}_${subtype.ProcessElementSubtype}`}
          Data={subtype} 
          Icon={subtype.Icon} 
          Text={subtype.Name}
          fontSize={15}
          onDrop={this.handleCreateCell}
          // onClick={() => this.handleCreateCell({x:310, y:150}, elementSubtype)}
        />
      ));

    const actionListItems = ProcessElementSubtypes
      .filter(subtype => subtype.ProcessElementType === "Action")
      .map(subtype => (
        <DraggableListItem 
          key={`key_${subtype.ProcessElementType}_${subtype.ProcessElementSubtype}`}
          Data={subtype}
          Icon={subtype.Icon} 
          Text={subtype.Name}
          fontSize={15}
          onDrop={this.handleCreateCell}
          // onClick={() => this.handleCreateCell({x:310, y:150}, elementSubtype)}
        />
      ));

    let configureProcessElementDialog = null;
    if (OverlayAction === "configure") {
      let overlayActionProcessElement_InputConnections = null;
      // This grabs all ProcessElementConnections that are connected to the INPUT port
      let elementsWithConnectionsToInput = [...Elements]
        .filter(e => e.Connections && e.Connections
          .filter(c => c.TargetProcessElementID === OverlayActionProcessElementId).length);
      overlayActionProcessElement_InputConnections =
        (elementsWithConnectionsToInput.length)
          ? elementsWithConnectionsToInput[0].Connections
            .filter(c => c.TargetProcessElementID === OverlayActionProcessElementId
              && c.TargetPortType === mxConstraintNames.INPUT)
          : null;
      let overlayActionProcessElement = Model.GetElementById(Elements, OverlayActionProcessElementId);
      if (overlayActionProcessElement) {
        let overlayActionProcessElementSubtype = GetProcessElementSubtypeByProcessElement(overlayActionProcessElement);
        if (overlayActionProcessElementSubtype) {
          configureProcessElementDialog = (
            <ProcessElementDialog 
              open={true}
              history={history}
              location={location}
              organizationId={match.params.organizationID}
              projectId={match.params.projectID}
              processId={Process && Process.ID}
              processElementId={OverlayActionProcessElementId}
              processElementConnections_Input={overlayActionProcessElement_InputConnections}
              dialogWidth={overlayActionProcessElementSubtype.DialogWidth}
              onGetContent={overlayActionProcessElementSubtype.GetContentFunction}
              onClose={this.handleOverlayActionProcessElementClose}
              onUpdate={this.handleUpdateProcessElement}
              onApiError={this.handleApiError}
              onAlert={this.handleAlert}
            />
          );
        }
      }
    }

    const noConnectedTriggerAlertGridItem = (Elements.length > 0 && TotalConnectedTriggers === 0)
      ? (
        <Grid item>
          <Typography variant="body2" className={classes.alert}>
            No connected triggers
          </Typography>
        </Grid>
      ) : null;

    const incompleteElementAlertGridItem = (TotalIncompleteElements > 0)
      ? (
        <Grid item className={classes.alertGridItem}>
          <Typography variant="body2" className={classes.alert}>
            {`${TotalIncompleteElements} incomplete element${(TotalIncompleteElements > 1) ? "s" : ""}`}
          </Typography>
        </Grid>
      ) : null;

    const invalidConnectionAlertGridItem = (TotalInvalidConnectionsByProperties > 0)
      ? (
        <Grid item className={classes.alertGridItem}>
          <Typography variant="body2" className={classes.alert}>
            {`${TotalInvalidConnectionsByProperties} invalid connection${
              (TotalInvalidConnectionsByProperties > 1) ? "s" : ""
            } due to property conflict${(TotalInvalidConnectionsByProperties > 1) ? "s" : ""}`}
          </Typography>
        </Grid>
      ) : null;

    const alertGrid = (
      <Grid container spacing={1}
        direction="column"
        className={classes.alertGrid}
      >
        {noConnectedTriggerAlertGridItem}
        {incompleteElementAlertGridItem}
        {invalidConnectionAlertGridItem}
      </Grid>
    );

    const learnMoreText = "Learn more about this area";
    const learnMoreHelpIcon = (
      <Tooltip title={learnMoreText}>
        <IconButton color="default" aria-label={learnMoreText}
          onClick={() => this.handleSetShowVideoHelpDialogVisibility(true)}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );

    const videoHelpDialog = (ShowVideoHelpDialog)
      ? (
        <VideoHelpDialog
          open={ShowVideoHelpDialog}
          src={GetPublicVideoPath("N1 IP Workflow.mp4")}
          onClose={() => this.handleSetShowVideoHelpDialogVisibility(false)}
        />
      ) : null;

    const content = (
      <div className={classes.contentContainer}>
        <MultiUseDialog Details={deleteProcessConfirmationDialogDetails} />
        <MultiUseDialog Details={deleteCellsConfirmationDialogDetails} />
        <MultiUseDialog Details={dragExampleDetails} />
        <MultiUseDialog Details={connectionExampleDetails} />
        {configureProcessElementDialog}
        {alertGrid}
        {videoHelpDialog}
        
        <div className={classes.toolPane}>
          <div className={classes.toolPaneTopBar}>
            <Grid container spacing={1} className={classes.toolBoxTitleGrid}>
              <Grid item>
                <Typography variant="subtitle2">
                  Toolbox
                </Typography>
              </Grid>
              <Grid item>
                {learnMoreHelpIcon}
              </Grid>
            </Grid>
            <IconButton
              aria-label="Toolbox menu"
              aria-controls="toolbox-menu"
              aria-haspopup="true"
              onClick={this.handleSetToolboxMenuVisibility(true)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="toolbox-menu"
              anchorEl={ToolboxMenuAnchorEl}
              keepMounted
              open={Boolean(ToolboxMenuAnchorEl)}
              onClose={this.handleSetToolboxMenuVisibility(false)}
            >
              <MenuItem onClick={this.handleSetDragExampleVisibility(true)}>Tutorial: Adding elements</MenuItem>
              <MenuItem onClick={this.handleSetConnectionExampleVisibility(true)}>Tutorial: Connecting elements</MenuItem>
              <Divider />
              <MenuItem onClick={this.handleSetDeleteProcessConfirmationVisibility(true)}>Delete process</MenuItem>
            </Menu>
          </div>

          <Grid container direction="column" className={classes.elementGrid}>
            <Grid item>
              <List component="nav"
                subheader={<ListSubheader component="div">Triggers</ListSubheader>}>
                {triggerListItems}
              </List>
            </Grid>

            <Grid item>
              <Divider />
              <List component="nav"
                subheader={<ListSubheader component="div">Actions</ListSubheader>}>
                {actionListItems}
              </List>
            </Grid>

{/*               <Grid item style={{marginTop: 24}}> */}
{/*                 <Button className={classes.button} variant="outlined" fullWidth */}
{/*                   onClick={() => this.handleFastOrganicLayout()}> */}
{/*                   ORGANIC LAYOUT */}
{/*                 </Button> */}
{/*               </Grid> */}
{/*  */}
{/*               <Grid item style={{marginTop: 48}}> */}
{/*                 <Button className={classes.destructiveButton} variant="outlined" fullWidth */}
{/*                   onClick={() => this.handleConfirmClearElements()}> */}
{/*                   CLEAR ELEMENTS */}
{/*                 </Button> */}
{/*               </Grid> */}
{/*  */}

          </Grid>
        </div>

        <div ref={instance => { connectDropTarget(ReactDOM.findDOMNode(instance)); this.divGraph = instance; }}
         id="divGraph"
         style={{
            flexGrow: 1,
            backgroundImage: `url(/mxgraph/images/grid${theme.palette.type === "dark" ? "Dark" : ""}.gif)`,
            backgroundColor: (theme.palette.type === "dark") ? theme.palette.background.default : "white",
            width:"100%",
            height:"100%",
            overflow:"scroll",
            outline:"none",
            }}
        >

          <Tooltip title="Settings" placement="top"
            classes={{
              tooltip:classes.overlayActionTooltip,
            }}
          >
            <IconButton
              size="small"
              style={{
                display: (OverlayActionButtonsAreVisible) ? "" : "none",
                position:"absolute",
                left:OverlayActionButtonPoints.Configure.x,
                top:OverlayActionButtonPoints.Configure.y,
              }}
              onClick={() => this.handleOverlayActionButtonClick("configure")}
            >
              <SettingsIcon style={{color:green[500]}} className={classes.overlayActionIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={OverlayActionCellName} placement="top"
            classes={{
              tooltip:classes.overlayActionTooltip,
            }}
          >
            <IconButton
              size="small"
              style={{
                display: (OverlayActionButtonsAreVisible) ? "" : "none",
                position:"absolute",
                left:OverlayActionButtonPoints.ElementInfo.x,
                top:OverlayActionButtonPoints.ElementInfo.y,
              }}
            >
              <InfoIcon style={{color:lightBlue[500]}} className={classes.overlayActionIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="top"
            classes={{
              tooltip:classes.overlayActionTooltip,
            }}
          >
            <IconButton
              size="small"
              style={{
                display: (OverlayActionButtonsAreVisible) ? "" : "none",
                position:"absolute",
                left:OverlayActionButtonPoints.Delete.x,
                top:OverlayActionButtonPoints.Delete.y,
              }}
              onClick={() => this.handleOverlayActionButtonClick("delete")}
            >
              <DeleteIcon style={{color:red[500]}} className={classes.overlayActionIcon} />
            </IconButton>
          </Tooltip>

          <div
            style={{
              display: (ConstraintTooltip) ? "" : "none",
              position:"absolute",
              left:ConstraintTooltip && ConstraintTooltip.X,
              top:ConstraintTooltip && ConstraintTooltip.Y,
              pointerEvents: "none",
              textAlign: "center",
              backgroundColor: theme.palette.background.default,
              ...tooltipBaseStyle,
            }}
          >
            {
              ConstraintTooltip && ConstraintTooltip.ToolTip
                .split('\n')
                .map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))
            }
          </div>
          
        </div>

      </div>
    );

    let title = (Process) ? Process.Name : "Loading process...";

    return (
      <UiCore title={title}
        onEditTitle={this.handleConfirmProcessName}
        apiError={ApiError}
        alert={Alert}
        confirmation={Confirmation}
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

export default DropTarget(['DraggableListItem'], divTarget, dropCollect)(withStyles(styles, {withTheme: true})(ProcessDesigner));