import {
  GetFormTemplateDesignerPath,
} from '../Util/PathHelper';

export function GetFormUrl(formTemplateId, uniqueId, formShareId) {
	return `${window.location.origin}/form/${formTemplateId}/${uniqueId}${(formShareId) ? "/" + formShareId : ""}`;
}

export const HandleRouteToFormInput = (formTemplateId, uniqueId, formShareId) => {
	window.open(GetFormUrl(formTemplateId, uniqueId, formShareId), "n1_formInput");
}

export const HandleRouteToFormTemplateDesigner = (props, organizationId, projectId, formTemplateId) => {
	props.history.push(GetFormTemplateDesignerPath(organizationId, projectId, formTemplateId));
}