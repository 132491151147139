import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SelectControl from '../Components/SelectControl'
import AsyncSelectControl from '../Components/AsyncSelectControl';
import FieldInput from '../Components/FieldInput';
import ProgressIndicator from '../Components/ProgressIndicator';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

import {
  GetUpdatedFieldObjectForValueChange,
  GetComposedFieldListLabelsAndValues,
  HandleGetFieldListItemsFilterPromise,
  HandleFieldListItemAdd,
  GetDependentFieldsParentValue,
  GetEffectiveSelectionListIsDependent,
} from './Field';
import {
  HandleGetFormTemplateFieldListItemsFilter,
  HandleFormTemplateFieldListItemAdd,
} from './FormTemplateFields';
import DocumentFolders, {
  GetRootDocumentFolderNode,
} from '../Util/DocumentFolders';
import { GetNewTextField } from '../Model/Field';

import debounce from 'es6-promise-debounce';

import { ValidateEmail } from './Regex';

import API, {
  GetFieldsPathForApi,
  GetAssetsPathForApi,
  GetFormTemplatesPathForApi,
  GetProjectFormTemplateFieldsPathForApi,
  GetDocumentPackagePathForApi,
  GetOrganizationItemReleaseProcessElementsPathForApi,
} from './api';
import {
  GetAddressBookItemsPromise,
} from './AddressBookItems';
import ItemSearchDialog from '../Components/ItemSearchDialog';
import {
  HandleGetAssets,
} from '../Util/Assets';
import {
  GetUserValue,
  GetFieldTypeLabel,
} from '../Util/Properties';
import {
  GetTreeNodeComponent,
} from '../Util/Tree';
import {
  GetTagsControl,
  PostTagsFromListValues,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../Util/Tags';
import {
  GetAllFieldsPromise,
  RecurseDependentSelectionListsDown,
} from './Fields';

export const BasicFieldOperators = [
  {
    value: "equals",
    label: "Equals",
  },
];

export const TextNumericDateFieldOperators = [
  {
    value: "notEquals",
    label: "Does not equal",
  },
  {
    value: "hasValue",
    label: "Has any value",
  },
  {
    value: "notHasValue",
    label: "Does not have a value",
  },
];

export const ImageFieldOperators = [
  {
    value: "hasValue",
    label: "Has an image",
  },
  {
    value: "notHasValue",
    label: "Does not have an image",
  },
];

export const NumericDateFieldOperators = [
  {
    value: "greaterThan",
    label: "Greater than",
  },
  {
    value: "lessThan",
    label: "Less than",
  },
  {
    value: "greaterThanEqual",
    label: "Greater than or equal to",
  },
  {
    value: "lessThanEqual",
    label: "Less than or equal to",
  },
  {
    value: "betweenAndIncluding",
    label: "Between and including",
  },
];

export default class ProcessElementDialogHelper {
  constructor(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption, onSetCloseDialogFunc,
  onSetShowFieldPropertiesDialog, onSetManageFieldsDialogCloseFunc) {

    this.location = location;
    this.history = history;
    this.organizationId = organizationId;
    this.projectId = projectId;
    this.processElement = processElement;
    this.onGetLocalState = onGetLocalState;
    this.onSetLocalState = onSetLocalState;
    this.onGetLocalProperty = onGetLocalProperty;
    this.onSetLocalProperty = onSetLocalProperty;
    this.onApiError = onApiError;
    this.onAlert = onAlert;
    this.onChangeHandlers = onChangeHandlers;
    this.onSelectionListValueChange = onSelectionListValueChange;
    this.onSelectionListCreateOption = onSelectionListCreateOption;
    this.onSetCloseDialogFunc = onSetCloseDialogFunc;
    this.completedSetCloseDialogFunc = false;
    this.onGetListValuesAndLabelsPropertyName = onGetListValuesAndLabelsPropertyName;
    this.onSetShowFieldPropertiesDialog = onSetShowFieldPropertiesDialog;
    this.onSetManageFieldsDialogCloseFunc = onSetManageFieldsDialogCloseFunc;

    this.getListValueId_AssetID = isMulti => onGetListValuesAndLabelsPropertyName("AssetID", isMulti);
    this.listValueId_AssetFieldFieldID = onGetListValuesAndLabelsPropertyName("AssetFieldFieldID", true);
    this.listValueId_Tags = onGetListValuesAndLabelsPropertyName("Tags", true);
    this.listValueId_FieldID = onGetListValuesAndLabelsPropertyName("FieldID", true);
    this.getListValueId_FormTemplateID = isMulti => onGetListValuesAndLabelsPropertyName("FormTemplateID", isMulti);
    this.listValueId_FormTemplateFieldID = onGetListValuesAndLabelsPropertyName("FormTemplateFieldID", true);
    this.boolValueId_FilterByDocumentFolder = "FilterByDocumentFolder";
    this.boolValueId_MatchSubfolders = "MatchSubfolders";
    this.boolValueId_SyncProperties = "SyncProperties";
    this.stringValueId_DocumentFolderID = "DocumentFolderID";
    this.stringValueId_DocumentFolderPath = "DocumentFolderPath";
    this.stringValueId_DocumentFolderAncestorIDs = "DocumentFolderAncestorIDs";
    this.anyValueId_ProcessElement = "ProcessElement";
    this.anyValueId_FieldMappings = "FieldMappings";
    this.anyValueId_TemplateDocument = "TemplateDocument";
  }

  GetTriggerContentForDocumentFolders = () => {
    if (!this.processElement) {
      return null;
    }

    const filterByDocumentFolder = this.processElement.Data[this.boolValueId_FilterByDocumentFolder];
    const matchSubfolders = this.processElement.Data.MatchSubfolders;
    const documentFolders = this.onGetLocalProperty("documentFolders");

    if (filterByDocumentFolder && !documentFolders) {
      if (!this.onGetLocalProperty("initiatingDocumentFolders")) {
        this.onSetLocalProperty("initiatingDocumentFolders", true);
        setTimeout(() => {
          this.onSetLocalProperty("documentFolders", new DocumentFolders(
            this.onGetLocalState(),
            this.onSetLocalState,
            this.onApiError, this.organizationId, this.projectId, 
            false, false, "", false,
            null, null, null, null,
            null, null, null, 
            this.handleDocumentFolderSelected,
            this.handleDocumentFolderUpdated,
          ));
          this.initiateDocumentFolders(this.onGetLocalProperty("documentFolders"), this.onGetLocalState);  
        }, 1);
      }
    }

    const documentFolderId = this.processElement.Data[this.stringValueId_DocumentFolderID];
    const propsForLocationPathname = this.getSimulatedPropsForDocumentFolders();

    let documentFoldersTreeGridItem;
    let documentFoldersContent;
    let currentPathGridItem;
    if (filterByDocumentFolder && documentFolders) {
      const localState = this.onGetLocalState();
      const documentFolderTree = GetTreeNodeComponent(
        null, localState, this.onSetLocalState,
        GetRootDocumentFolderNode(documentFolderId === ""),
        () => {},
        documentFolders.GetAndSetDocumentFolderNodesAsPromise(propsForLocationPathname, localState, null, true),
        documentFolders.GetDocumentFolderTreeNodeComponent(propsForLocationPathname, localState, null, true),
        documentFolders.GetContextMenu,
        documentFolders.HandleSetNodeExpandFunction,
        () => {},
        this.handleDocumentFolderSelected,
      );
      documentFoldersTreeGridItem = (
        <Grid item>
          {documentFolderTree}
        </Grid>
      );
      documentFoldersContent = documentFolders.GetContent(null, this.onGetLocalState)
      currentPathGridItem = (
        <Grid item>
          <Typography variant="body1">
            {this.processElement.Data[this.stringValueId_DocumentFolderPath] || "No folder selected"}
          </Typography>
        </Grid>
      );
    }

    const matchSubfoldersContent = (filterByDocumentFolder) ? (
        <FormControlLabel
          style={{marginLeft:8}}
          control={
            <Switch
              color="secondary"
              checked={matchSubfolders}
              onChange={e => this.onChangeHandlers.setBoolProperty(
                this.boolValueId_MatchSubfolders, true, e.target.checked)}
            />
          }
          label="Include subfolders" />
      ) : null;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item style={{marginLeft:12}}>
          <FormControlLabel
            style={{marginLeft:0}}
            control={
              <Switch
                color="secondary"
                checked={filterByDocumentFolder}
                onChange={e => this.onChangeHandlers.setBoolProperty(
                  this.boolValueId_FilterByDocumentFolder, true, e.target.checked)}
              />
            }
            label="Filter by document folder" />
          {matchSubfoldersContent}
        </Grid>
        {currentPathGridItem}
        {documentFoldersTreeGridItem}
        {documentFoldersContent}
      </Grid>
    );
  }

  initiateDocumentFolders = () => {
    if (!this.onGetLocalState("DocumentFolderNodesByNodeId")) {
      setTimeout(() => this.initiateDocumentFolders(), 250);
      return;
    }
    const documentFolderId = this.processElement.Data[this.stringValueId_DocumentFolderID];
    const documentFolders = this.onGetLocalProperty("documentFolders");
    const propsForLocationPathname = this.getSimulatedPropsForDocumentFolders();
    documentFolders.GetAndSetDocumentFolderNodesAsPromise(propsForLocationPathname, this.onGetLocalState(), null, true)
      (GetRootDocumentFolderNode(documentFolderId === ""));
  }

  getSimulatedPropsForDocumentFolders = () => {
    const documentFolderId = this.processElement.Data[this.stringValueId_DocumentFolderID];
    let ids = [];
    const ancestorIds = this.processElement.Data[this.stringValueId_DocumentFolderAncestorIDs];
    if (ancestorIds && ancestorIds.length) {
      ids.push(ancestorIds.reverse());
    }
    if (documentFolderId) {
      ids.push(documentFolderId);
    }
    const pathname = ids.join("/");
    return {
      location: {
        pathname,
      },
      match: {
        params: {
          documentFolderID: pathname,
        }
      }
    };
  }

  handleDocumentFolderUpdated = documentFolder => {
    const { SelectedNode } = this.onGetLocalState();
    if (this.processElement.Data[this.stringValueId_DocumentFolderID] === documentFolder.ID
      || this.processElement.Data[this.stringValueId_DocumentFolderAncestorIDs]
        .filter(id => id === documentFolder.ID).length > 0
    ) {
      this.handleDocumentFolderSelected(SelectedNode);
    }
  }

  handleDocumentFolderSelected = node => {
    this.onChangeHandlers.setStringProperty(this.stringValueId_DocumentFolderID, true, node.DocumentFolderID);
    let ancestorIds = [];
    let folderNames = [];
    const addFolderName = node => {
      folderNames.push((node.DocumentFolderID) ? node.Name : "");
    }
    addFolderName(node);
    while (node.ParentNode) {
      node = node.ParentNode;
      addFolderName(node);
      if (node && node.DocumentFolderID) {
        ancestorIds.push(node.DocumentFolderID);
      }
    }
    this.onChangeHandlers.setAnyProperty(this.stringValueId_DocumentFolderAncestorIDs, true, ancestorIds);
    this.onChangeHandlers.setAnyProperty(this.stringValueId_DocumentFolderPath, true,
      (folderNames.length === 1 && folderNames[0] === "")
        ? node.Name
        : folderNames.reverse().join("/")
    );
  }

  GetTriggerContentForEmailAddressAndTags = (id, label, isMulti, includeAddressBookItems,
    includeProjectMembers, includeFields, includeFormTemplateFields) => {
    if (!this.processElement) {
      return null;
    }

    return (
      <Grid container direction="column" spacing={2} style={{/*Fix for asyncselectcontrols with value*/flexWrap:"noWrap"}}>
        <Grid item>
          {this.GetEmailAddressControl(id, label, isMulti, includeAddressBookItems,
            includeProjectMembers, includeFields, includeFormTemplateFields)}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForTags()}
        </Grid>
      </Grid>
    );
  }

  GetActionContentForFormTemplateAndEmailAddressAndTags = (id, label, isMulti, includeAddressBookItems,
    includeProjectMembers, includeFields, includeFormTemplateFields) => {
    if (!this.processElement) {
      return null;
    }

    return (
      <Grid container direction="column" spacing={2} style={{/*Fix for asyncselectcontrols with value*/flexWrap:"noWrap"}}>
        <Grid item>
          {this.GetContentForFormTemplates(false)}
        </Grid>
        <Grid item>
          {this.GetEmailAddressControl(id, label, isMulti, includeAddressBookItems,
            includeProjectMembers, includeFields, includeFormTemplateFields)}
        </Grid>
        <Grid item>
          {this.GetActionContentForTags()}
        </Grid>
      </Grid>
    );
  }

  GetTriggerContentForFieldsAndTagsAndDocumentFolders = () => {
    if (!this.processElement) {
      return null;
    }

    return (
      <Grid container direction="column" spacing={2} style={{/*Fix for asyncselectcontrols with value*/flexWrap:"noWrap"}}>
        <Grid item>
          {this.GetTriggerContentForFields()}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForTags()}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForDocumentFolders()}
        </Grid>
      </Grid>
    );
  }

  GetTriggerContentForFormTemplatesAndFormTemplateFields = () => {
    if (!this.processElement) {
      return null;
    }

    return (
      <Grid container direction="column" spacing={2} style={{/*Fix for asyncselectcontrols with value*/flexWrap:"noWrap"}}>
        <Grid item>
          {this.GetContentForFormTemplates(true)}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForFormTemplateFields()}
        </Grid>
      </Grid>
    );
  }

  GetTriggerContentForFields = () => {
    if (!this.processElement) {
      return null;
    }

    let gridItems = [
      <Grid key="list_field" item xs={12}>
        <AsyncSelectControl label="Fields" 
          // forceShrinkLabel
          placeholder="Select optional..."
          isMulti
          onGetOptionsFilterPromise={this.handleGetFieldListValuesPromise} 
          listValues={this.processElement[this.listValueId_FieldID]}
          onValueChange={this.handleFieldSelectionListValueChange()}
        />
      </Grid>,
    ];

    const localStateFields = this.onGetLocalState("fields");
    const localStateSecondaryFields = this.onGetLocalState("secondaryFields");

    if (this.processElement[this.listValueId_FieldID]
      && this.processElement[this.listValueId_FieldID].length
      && localStateFields
      && localStateFields.length
      && localStateSecondaryFields
      && localStateSecondaryFields.length) {

      let fields = [];
      this.processElement[this.listValueId_FieldID].forEach(lv => {
        let fieldFinder = localStateFields.filter(f => f.ID === lv.value);
        if (fieldFinder.length) {
          fields.push(fieldFinder[0]);
        }
      });
      let fieldGridItems = fields.map(f => {
        // Special case for checkbox fields - If there is no value, set it to false to ensure field appears complete 
        if (f.Type === "FieldType_Bool" && (f.Value === undefined || f.Value === "")) {
          setTimeout(() => this.handleIndexFieldValueChange(f.ID)({target: { value: "false" }}), 1);
        }

        let f2 = localStateSecondaryFields.filter(sf => sf.ID === f.ID)[0];
        let isHasOrNotHasValueOperator = (f.Operator === "hasValue" || f.Operator === "notHasValue");
        let isBetweenOperator = (f.Type !== "FieldType_Text" && f.Operator === "betweenAndIncluding");
        let operatorOptions = [];
        switch (f.Type) {
          case "FieldType_Text":
            operatorOptions = [...BasicFieldOperators, ...TextNumericDateFieldOperators];
            break;
          case "FieldType_Bool":
            operatorOptions = BasicFieldOperators;
            break;
          case "FieldType_Image":
            operatorOptions = ImageFieldOperators;
            break;
          default:
            operatorOptions = [...BasicFieldOperators, ...TextNumericDateFieldOperators, ...NumericDateFieldOperators];
            break;
        }
        let operatorGridItem = (
          <Grid item key={`list_fieldOperator_${f.ID}`} xs={(isBetweenOperator) ? 12 : 6}>
            <SelectControl label={`${f.Name} Filter Type`}
              id={`list_fieldOperator_control_${f.ID}`}
              hideEmpty
              options={operatorOptions} 
              value={f.Operator}
              onValueChange={this.handleIndexFieldOperatorChange(f.ID)}
            />
          </Grid>
        );
        let secondaryFieldGridItem = (isBetweenOperator)
          ? (
            <Grid item key={`list_fieldSecondary_${f.ID}`} xs={6}>
              <FieldInput Field={f2}
                LabelPrefix="to"
                isSecondary
                useIndeterminateForFalse
                Index={-1}
                onValueChange={this.handleIndexFieldValueChange(f2.ID, true)}
                onGetSelectionListFilterPromise={HandleGetFieldListItemsFilterPromise(this.organizationId, this.projectId, f2.ID, this.onApiError)}
                onSelectionListItemAdd={this.handleIndexFieldListItemAdd(f2, true)}
                onApiError={this.onApiError}
                onAlert={this.onAlert}
                fields={
                  // Fields property is not needed here because it is for non-drop-down lists controls
                  undefined
                }
              />
            </Grid>
          ) : null;

        let primaryFieldGridItem = (!isHasOrNotHasValueOperator)
          ? (
            <Grid item xs={6}>
              <FieldInput Field={f}
                Index={-1}
                useIndeterminateForFalse
                UpdateId={new Date()}
                onValueChange={this.handleIndexFieldValueChange(f.ID)}
                onGetSelectionListFilterPromise={
                  (() => {
                    const parentFieldValue = GetDependentFieldsParentValue(f, fields, f => f.ID);
                    return HandleGetFieldListItemsFilterPromise(this.organizationId, this.projectId, f.ID, this.onApiError, undefined, undefined, undefined, parentFieldValue);
                  })()
                }
                onSelectionListItemAdd={this.handleIndexFieldListItemAdd(f)}
                onApiError={this.onApiError}
                onAlert={this.onAlert}
                fields={localStateFields}
              />
            </Grid>
          ) : null;

        return (
          <Grid item key={`list_fieldPrimary_${f.ID}`}
            xs={12}>
            <Grid container spacing={3}>
              {operatorGridItem}
              {primaryFieldGridItem}
              {secondaryFieldGridItem}
            </Grid>
          </Grid>
        );
      });
      gridItems.push(fieldGridItems);
    }

    return (
      <Grid container spacing={2}>
        {gridItems}
      </Grid>
    );
  }

  GetContentForFormTemplates = isMulti => {
    if (!this.processElement) {
      return null;
    }

    return (
      <AsyncSelectControl label={`Form Template${(isMulti) ? "s" : ""}`} 
        // forceShrinkLabel
        placeholder="Select optional..."
        isMulti={isMulti}
        onGetOptionsFilterPromise={this.handleGetFormTemplateListValuesPromise} 
        listValues={this.processElement[this.getListValueId_FormTemplateID(isMulti)]}
        onValueChange={this.onSelectionListValueChange("FormTemplateID", isMulti)}
      />
    );
  }

  handleSetShowSelectEditorDocumentDialog = ShowSelectEditorDocumentDialog => {
    this.onSetLocalState({ShowSelectEditorDocumentDialog});
  }

  GetActionContentForDocumentTemplateAndTags = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          {this.GetActionContentForDocumentTemplate()}
        </Grid>
        <Grid item>
          {this.GetActionContentForTags()}
        </Grid>
      </Grid>
    );
  }

  GetActionContentForDocumentTemplate = () => {
    if (!this.processElement) {
      return null;
    }

    const handleChangeTemplateDocument = selectedTemplateDocument => {
      this.onChangeHandlers.setAnyProperty(this.anyValueId_TemplateDocument, true, selectedTemplateDocument);
      this.onSetLocalState({selectedTemplateDocument});
    }

    const clearGridItem = (this.processElement.Data[this.anyValueId_TemplateDocument])
      ? (
        <Grid item>
          <Button variant="contained"
            onClick={() => handleChangeTemplateDocument(null)}
          >
            CLEAR
          </Button>
        </Grid>
      )
      : null;

    const selectedTemplateDocument = this.onGetLocalState("selectedTemplateDocument");
    const templateDocumentLoadTimestamp = this.onGetLocalState("templateDocumentLoadTimestamp");
    if (this.processElement.Data[this.anyValueId_TemplateDocument] && !selectedTemplateDocument && !templateDocumentLoadTimestamp) {
      this.onSetLocalState({templateDocumentLoadTimestamp:new Date()});
      API.get(GetDocumentPackagePathForApi(this.organizationId, this.projectId,
        this.processElement.Data[this.anyValueId_TemplateDocument].ID))
        .then(resp => {
          if (resp && resp.data) {
            handleChangeTemplateDocument(resp.data.Document);
          }
        })
        .catch(err => {
          // Do nothing, just ignore. The document has likely been purged.
        });
    }

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {
            `Template: ${(this.processElement.Data[this.anyValueId_TemplateDocument])
              ? (selectedTemplateDocument)
                ? selectedTemplateDocument.Name
                : this.processElement.Data[this.anyValueId_TemplateDocument].Name
              : "None"}`}
          <ItemSearchDialog
            location={this.location}
            history={this.history}
            open={this.onGetLocalState("ShowSelectEditorDocumentDialog") || false}
            itemType="document"
            disableSelect
            hideConfirmButton
            dialogTitle="Choose a document template"
            documentOriginType="Editor"
            confirmButtonText="SELECT"
            onClose={() => this.handleSetShowSelectEditorDocumentDialog(false)}
            onAlert={this.onAlert}
            onApiError={this.onApiError}
            organizationId={this.organizationId}
            projectId={this.projectId}
            isProjectMember={true}
            onItemClick={(item, defaultAction) => {
              handleChangeTemplateDocument(item);
              this.handleSetShowSelectEditorDocumentDialog(false);
            }}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained"
                onClick={() => this.handleSetShowSelectEditorDocumentDialog(true)}
              >
                SELECT
              </Button>
            </Grid>
            {clearGridItem}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  GetTriggerContentForFormTemplateFields = () => {
    if (!this.processElement) {
      return null;
    }

    let gridItems = [
      <Grid key="list_formTemplateField" item xs={12}>
        <AsyncSelectControl label="Form Fields" 
          // forceShrinkLabel
          placeholder="Select optional..."
          isMulti
          onGetOptionsFilterPromise={this.handleGetFormTemplateFieldListValuesPromise} 
          listValues={this.processElement[this.listValueId_FormTemplateFieldID]}
          onValueChange={this.onSelectionListValueChange("FormTemplateFieldID", true)}
        />
      </Grid>,
    ];

    const localStateFormTemplateFields = this.onGetLocalState("formTemplateFields");
    const localStateSecondaryFormTemplateFields = this.onGetLocalState("secondaryFormTemplateFields");

    if (this.processElement[this.listValueId_FormTemplateFieldID]
      && this.processElement[this.listValueId_FormTemplateFieldID].length
      && localStateFormTemplateFields
      && localStateFormTemplateFields.length) {
      let formTemplateFields = [];
      this.processElement[this.listValueId_FormTemplateFieldID].forEach(lv => {
        let fieldFinder = localStateFormTemplateFields.filter(f => f.ID === lv.value);
        if (fieldFinder.length) {
          formTemplateFields.push(fieldFinder[0]);
        }
      });
      let formTemplateFieldGridItems = formTemplateFields.map(f => {
        // Special case for checkbox fields - If there is no value, set it to false to ensure field appears complete
        if (f.Field.Type === "FieldType_Bool" && (f.Field.Value === undefined || f.Field.Value === "")) {
          setTimeout(() => this.handleFormTemplateFieldValueChange(f)({target: { value: "false" }}), 1);
        }

        let f2 = localStateSecondaryFormTemplateFields.filter(sf => sf.ID === f.ID)[0];
        let isHasOrNotHasValueOperator = (f.Operator === "hasValue" || f.Operator === "notHasValue");
        let isBetweenOperator = (f.Field.Type !== "FieldType_Text" && f.Operator === "betweenAndIncluding");
        let operatorOptions = [];
        switch (f.Type) {
          case "RadioGroup":
            f.Field.Type = "FieldType_Radio";
            operatorOptions = [...BasicFieldOperators, ...TextNumericDateFieldOperators];
            break;
          default:
            switch (f.Field.Type) {
              case "FieldType_Text":
                operatorOptions = [...BasicFieldOperators, ...TextNumericDateFieldOperators];
                break;
              case "FieldType_Bool":
                operatorOptions = BasicFieldOperators;
                break;
              case "FieldType_Image":
                operatorOptions = ImageFieldOperators;
                break;
              default:
                operatorOptions = [...BasicFieldOperators, ...TextNumericDateFieldOperators, ...NumericDateFieldOperators];
                break;
            }
            break;
        }
        let operatorGridItem = (
          <Grid item key={`list_formTemplateFieldOperator_${f.ID}`} xs={(isBetweenOperator) ? 12 : 6}>
            <SelectControl label={`${f.Field.Name} Filter Type`}
              id={`list_formTemplateFieldOperator_control_${f.ID}`}
              hideEmpty
              options={operatorOptions} 
              value={f.Operator}
              onValueChange={this.handleFormTemplateFieldOperatorChange(f.ID)}
            />
          </Grid>
        );
        let secondaryFormTemplateFieldGridItem = (isBetweenOperator)
          ? (
            <Grid item key={`list_formTemplateFieldSecondary_${f.ID}`} xs={6}>
              <FieldInput Field={f2.Field}
                LabelPrefix="to"
                isSecondary
                useIndeterminateForFalse
                Index={-1}
                hideHelperText
                onValueChange={this.handleFormTemplateFieldValueChange(f2, true)}
                onGetSelectionListFilterPromise={
                  (f.Type === "Field")
                    ? HandleGetFieldListItemsFilterPromise(f2.OrganizationID, f2.ProjectID, f2.FieldID, this.onApiError)
                    : HandleGetFormTemplateFieldListItemsFilter(f2, this.onApiError)
                }
                onSelectionListItemAdd={this.handleFormTemplateFieldListItemAdd(f2, true)}
                onApiError={this.onApiError}
                onAlert={this.onAlert}
                fields={
                  // Fields property is not needed here because it is for non-drop-down lists controls
                  undefined
                }
              />
            </Grid>
          ) : null;

        const radioGroupSelectionListOptions = (f.Type === "RadioGroup" && f.PossibleValues)
          ? f.PossibleValues.map(v => { return { label: v, value: v }})
          : [];

        let primaryFormTemplateFieldGridItem = (!isHasOrNotHasValueOperator)
          ? (
            <Grid item xs={6}>
              <FieldInput Field={f.Field}
                Index={-1}
                hideHelperText
                useIndeterminateForFalse
                UpdateId={new Date()}
                onValueChange={this.handleFormTemplateFieldValueChange(f)}
                onGetSelectionListFilterPromise={
                  (() => {
                    const parentFieldValue = GetDependentFieldsParentValue(f.Field, formTemplateFields,
                      f => f.FieldID, f => f.Field.Value);
                    return (f.Type === "Field")
                      ? HandleGetFieldListItemsFilterPromise(this.organizationId, this.projectId, f.FieldID, this.onApiError, undefined, undefined, undefined, parentFieldValue)
                      : HandleGetFormTemplateFieldListItemsFilter(f, this.onApiError)
                  })()
                }
                selectionListOptions={radioGroupSelectionListOptions}
                onSelectionListItemAdd={this.handleFormTemplateFieldListItemAdd(f)}
                onApiError={this.onApiError}
                onAlert={this.onAlert}
                fields={localStateFormTemplateFields}
              />
            </Grid>
          ) : null;

        return (
          <Grid item key={`list_formTemplateFieldPrimary_${f.ID}`}
            xs={12}>
            <Grid container spacing={3}>
              {operatorGridItem}
              {primaryFormTemplateFieldGridItem}
              {secondaryFormTemplateFieldGridItem}
            </Grid>
          </Grid>
        );
      });
      gridItems.push(formTemplateFieldGridItems);
    }

    return (
      <Grid container spacing={2}>
        {gridItems}
      </Grid>
    );
  }

  handleGetEmailAddressListValuesPromise = (propertyName, isMulti, includeAddressBookItems,
    includeProjectMembers, includeFields, includeFormTemplateFields, includeCreator) => debounce(filter => {

    return GetAddressBookItemsPromise(this.organizationId, this.projectId, 
        includeAddressBookItems, includeProjectMembers, includeFields, includeFormTemplateFields, filter)
        .then(items => {
          if (!items) {
            return null;
          }
          // Compose list
          const addressBookItems = items.map(abi => {
            if (abi.ProjectMemberID) {
              return ({
                value: `ProjectMemberID:${abi.ProjectMemberID}`,
                plainLabel: `${abi.EmailLower} (member${(abi.Name) ? " " + abi.Name : ""})`,
                label: GetUserValue(abi.EmailLower,
                  (abi.Name)
                    ? `${abi.Name} (member ${abi.EmailLower})`
                    : `${abi.EmailLower} (member)`,
                  "", false, undefined, {}, {}, true,
                ),
              });
            } else if (abi.FieldID) {
              return ({
                value: `FieldID:${abi.FieldID}`,
                label: `${abi.Name} (field)`,
            }); 
            } else if (abi.FormTemplateID && abi.FormTemplateFieldID) {
              return ({
                value: `FormTemplateID:${abi.FormTemplateID} FormTemplateFieldID:${abi.FormTemplateFieldID}`,
                label: `${abi.Name} (form field, ${abi.FormTemplateName})`,
              });
            } else {
              return ({
                value: abi.EmailLower,
                plainLabel: abi.EmailLower + ((abi.Name) ? " (" + abi.Name +")" : ""),
                label: GetUserValue(abi.EmailLower,
                  (abi.Name)
                    ? `${abi.Name} (${abi.EmailLower})`
                    : abi.EmailLower,
                  "", false, undefined, {}, {}, true,
                ),
              });
            }
          });
          // // Update labels with current values (if different)
          // this.setAddressListValues(propertyName, addressBookItems, isMulti);

          if (includeCreator) {
            addressBookItems.unshift({
              value: "Creator",
              label: "Item creator",
            });
          }

          return addressBookItems;
        })
        .catch(this.onApiError);
    }, 250);

  setAddressListValues = (id, addressBookItems, isMulti, optionOrOptionsToMatch) => {
    if (!addressBookItems) {
      return;
    }
    let listValueId = [this.onGetListValuesAndLabelsPropertyName(id, isMulti)];
    if (!optionOrOptionsToMatch) {
      optionOrOptionsToMatch = this.processElement[listValueId];
    }
    if (optionOrOptionsToMatch) {
      if (isMulti) {
        optionOrOptionsToMatch.forEach(a => {
          let matching = addressBookItems.filter(abi => abi.value === a.value);
          if (matching.length) {
            if (a.label !== matching[0].label) {
              a.label = matching[0].label;
            }
          }
        });
      } else {
        let matching = addressBookItems.filter(abi => abi.value === optionOrOptionsToMatch.value);
        if (matching.length) {
          if (optionOrOptionsToMatch.label !== matching[0].label) {
            optionOrOptionsToMatch.label = matching[0].label;
          }
        }
      }
    }
    this.processElement[listValueId] = optionOrOptionsToMatch;
  }

  handleEmailListCreateOption = (id, isMulti) => option => {
    if (!ValidateEmail(option)) {
      this.onApiError("E-mail address is not valid.");
      return;
    }
    this.onSelectionListCreateOption(id, isMulti)(option);
  }

  handleGetEmailAddressControlListValues = (id, isMulti) => {
    let listValues = this.processElement[this.onGetListValuesAndLabelsPropertyName(id, isMulti)];
    return listValues;
  }

  GetEmailAddressControl = (id, label, isMulti, includeAddressBookItems,
    includeProjectMembers, includeFields, includeFormTemplateFields, includeCreator) => {

    return (
      <AsyncSelectControl label={label}
        // forceShrinkLabel
        floatingOptions
        onGetOptionsFilterPromise={this.handleGetEmailAddressListValuesPromise(id, isMulti, includeAddressBookItems,
          includeProjectMembers, includeFields, includeFormTemplateFields, includeCreator)} 
        listValues={this.handleGetEmailAddressControlListValues(id, isMulti)}
        onValueChange={this.onSelectionListValueChange(id, isMulti)}
        onCreateOption={this.handleEmailListCreateOption(id, isMulti)}
        autoReloadOnValueChange
        isMulti={isMulti}
      />
    );
  }

  GetActionContentForEmailAddressControlAndPreferredItemType = (id, label, isMulti, includeAddressBookItems,
    includeProjectMembers, includeFields, includeFormTemplateFields, includeCreator) => {
    
    const preferredProcessItemTypeOptions = [
      {label:"Document",value:"Document"},
      {label:"Task",value:"Task"},
      {label:"Asset",value:"AssetItem"},
    ];

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {this.GetEmailAddressControl(id, label, isMulti, includeAddressBookItems,
            includeProjectMembers, includeFields, includeFormTemplateFields, includeCreator)}
        </Grid>
        <Grid item>
          <AsyncSelectControl label="Preferred item type"
            placeholder="Select optional..."
            onGetOptionsFilterPromise={filter => Promise.resolve(preferredProcessItemTypeOptions.filter(lv => lv.label.startsWith(filter)))} 
            listValues={this.processElement[this.onGetListValuesAndLabelsPropertyName("PreferredProcessItemType")]}
            onValueChange={this.onSelectionListValueChange("PreferredProcessItemType")}
          />
        </Grid>
      </Grid>
    );
  }

  setDefaultFieldOperator = (fieldOrFormTemplateField, fieldType) => {
    if (fieldType === "FieldType_Image") {
      fieldOrFormTemplateField.Operator = ImageFieldOperators[0].value;
    } else {
      fieldOrFormTemplateField.Operator = BasicFieldOperators[0].value;
    }
  }

  setInitialFieldValue = (valueData, field) => {
    if (valueData) {
      if (field.DisplaySelectionList) {
        if (field.AllowMultipleValues) {
          field.Values = valueData;
        } else {
          field.Value = valueData;
        }
        field.ListValues = GetComposedFieldListLabelsAndValues(field);
      } else {
        field.Value = valueData;
      }
    }

    // If this is a dependent selection list, cause the control to be reloaded, regardless of
    // whether it had its value set, so that its selection list is repopulated from the server
    // with its parent field's value now available to it
    if (GetEffectiveSelectionListIsDependent(field)) {
      field.UpdateId = new Date();
    }
  }

  setInitialIndexFieldValue = (field, prefix) => {
    let valueData = this.processElement.Data[`${(prefix) ? prefix : ""}${field.ID}`];
    this.setInitialFieldValue(valueData, field);
  }

  setInitialFormTemplateFieldValue = (formTemplateFieldId, field, prefix) => {
    let valueData = this.processElement.Data[`${(prefix) ? prefix : ""}${formTemplateFieldId}`];
    this.setInitialFieldValue(valueData, field);
  }

  GetFields = filter => {
    let extraParams = {
      filter,
    };
    if (this.processElement[this.listValueId_FieldID]) {
      extraParams.includeFieldIds_json = JSON.stringify(
        this.processElement[this.listValueId_FieldID].map(f => f.value)
      );
    }
    return GetAllFieldsPromise(this.organizationId, this.projectId, extraParams)
      .then(resp => {
        if (!resp.data) {
          return null;
        }
        let fields = resp.data.Fields;
        let secondaryFields = JSON.parse(JSON.stringify(fields));
        fields.forEach(f => {
          this.setInitialIndexFieldValue(f, "fieldPrimary_");
          this.setInitialIndexFieldValue(secondaryFields.filter(f2 => f2.ID === f.ID)[0], "fieldSecondary_");
          let operatorData = this.processElement.Data[`fieldOperator_${f.ID}`];
          if (operatorData) {
            f.Operator = operatorData;
          } else {
            this.setDefaultFieldOperator(f, f.Type);
          }
        });
        this.onSetLocalState({
          fields,
          secondaryFields,
        });
        return {fields,secondaryFields};
      });
  }

  setInitialListValueLabels = (isMulti, listValueId, allListValues) => {
    if (this.processElement[listValueId]) {
      const setLabel = selectedListValue => {
        const item = allListValues.find(lv => lv.value === selectedListValue.value);
        if (item) {
          if (selectedListValue.label !== item.label) {
            selectedListValue.label = item.label;
          }
        }
      }
      if (isMulti) {
        this.processElement[listValueId].forEach(selectedListValue => setLabel(selectedListValue));  
      } else {
        setLabel(this.processElement[listValueId]);
      }
    }
  }

  handleGetFieldListValuesPromise = debounce(filter => {
    return this.GetFields(filter)
      .then(fieldPackage => {
        if (!fieldPackage.fields) {
          return null;
        }
        let listValues = fieldPackage.fields.map(f => {
          return this.getListValueForField(f);
        });
        // Update labels with current values (if different)
        this.setInitialListValueLabels(true, this.listValueId_FieldID, listValues);
        return listValues;
      })
      .catch(err => this.onApiError(err));
  }, 250);

  handleGetFormTemplateListValuesPromise = debounce((filter, isMulti) => {
    let params = {
      filter,
    };
    if (this.processElement[this.getListValueId_FormTemplateID(isMulti)]) {
      params.includeFormTemplateIds_json = JSON.stringify(
        this.processElement[this.getListValueId_FormTemplateID(isMulti)].map(ft => ft.value)
      );
    }
    return API.get(GetFormTemplatesPathForApi(this.organizationId, this.projectId), { params })
      .then(resp => {
        if (!resp.data) {
          return null;
        }
        let listValues = resp.data.FormTemplates.map(ft => { return ({ value: ft.ID, label: ft.Name }) });
        // Update labels with current values (if different)
        this.setInitialListValueLabels(true, this.getListValueId_FormTemplateID(isMulti), listValues);
        return listValues;
      })
      .catch(err => this.onApiError(err));
  }, 250);

  GetFormTemplateFields = filter => {
    let params = {
      filter,
    };
    if (this.processElement[this.listValueId_FormTemplateFieldID]) {
      params.includeFormTemplateFieldUniqueIds_json = JSON.stringify(
        this.processElement[this.listValueId_FormTemplateFieldID].map(ft => ft.UniqueID)
      );
    }
    return API.get(GetProjectFormTemplateFieldsPathForApi(this.organizationId, this.projectId), { params })
      .then(resp => {
        if (!resp.data) {
          return null;
        }
        let fields = resp.data.FormTemplateFields;
        let secondaryFields = JSON.parse(JSON.stringify(fields));
        fields.forEach(f => {
          this.setInitialFormTemplateFieldValue(f.ID, f.Field, "formTemplateFieldPrimary_");
          this.setInitialFormTemplateFieldValue(f.ID, secondaryFields.filter(f2 => f2.ID === f.ID)[0].Field, "formTemplateFieldSecondary_");
          let operatorData = this.processElement.Data[`formTemplateFieldOperator_${f.ID}`];
          if (operatorData) {
            f.Operator = operatorData;
          } else {
            this.setDefaultFieldOperator(f, f.Field.Type);
          }
        });
        this.onSetLocalState({formTemplateFields:fields,secondaryFormTemplateFields:secondaryFields});
        return {formTemplateFields:fields,secondaryFormTemplateFields:secondaryFields};
      })
      .catch(err => this.onApiError(err));
  }

  handleGetFormTemplateFieldListValuesPromise = debounce(filter => {
    return this.GetFormTemplateFields(filter)
      .then(fieldPackage => {
        if (!fieldPackage.formTemplateFields) {
          return null;
        }
        let listValues = fieldPackage.formTemplateFields.map(ftf => { 
          return ({ 
            value: ftf.ID,
            label: `${ftf.Field.Name} (${ftf.FormTemplateName})`,
            UniqueID: ftf.UniqueID,
          });
        });
        // Update labels with current values (if different)
        this.setInitialListValueLabels(true, this.listValueId_FormTemplateFieldID, listValues);
        return listValues;
      })
      .catch(err => this.onApiError(err));
  }, 250);

  handleIndexFieldListItemAdd = (field, isSecondary) => newValue => {
    HandleFieldListItemAdd(this.organizationId, this.projectId, field.ID, field,
      fieldId => (event, selectedOptions) => this.handleIndexFieldValueChange(fieldId, isSecondary)(event, selectedOptions),
      this.onApiError)
      (newValue)
      ;
  }

  handleFormTemplateFieldListItemAdd = (formTemplateField, isSecondary) => newValue => {
    HandleFormTemplateFieldListItemAdd(formTemplateField, this.onApiError,
      unusedFieldId => (event, selectedOptions) => this.handleFormTemplateFieldValueChange(formTemplateField, isSecondary)(event, selectedOptions)
    )
      (newValue)
      ;
  }

  handleFieldSelectionListValueChange = () => selectedOptions => {
    this.onSelectionListValueChange("FieldID", true)(selectedOptions);
  };

  handleIndexFieldValueChange = (fieldId, isSecondary) => (event, selectedOptions) => {
    let localStateFieldsCopy = [...this.onGetLocalState("fields")];
    let localStateSecondaryFieldsCopy = [...this.onGetLocalState("secondaryFields")];
    let fields = (isSecondary) ? localStateSecondaryFieldsCopy : localStateFieldsCopy;
    let field = GetUpdatedFieldObjectForValueChange(
      fields.filter(f => f.ID === fieldId)[0],
      event, selectedOptions);
    let prefix = (isSecondary) ? "fieldSecondary_" : "fieldPrimary_";
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(field, fields, false, prefix);

    field.ListValues = GetComposedFieldListLabelsAndValues(field);
    this.onChangeHandlers.setStringProperty(
      `${prefix}${fieldId}`,
      true, 
      (field.DisplaySelectionList && field.AllowMultipleValues) ? field.Values : field.Value,
      field.Mask > "");
  }

  handleFormTemplateFieldValueChange = (formTemplateField, isSecondary) => (event, selectedOptions) => {
    let localStateFormTemplateFieldsCopy = [...this.onGetLocalState("formTemplateFields")];
    let localStateSecondaryFormTemplateFieldsCopy = [...this.onGetLocalState("secondaryFormTemplateFields")];
    let formTemplateFields = (isSecondary) ? localStateSecondaryFormTemplateFieldsCopy : localStateFormTemplateFieldsCopy;
    formTemplateField.Field = GetUpdatedFieldObjectForValueChange(
      formTemplateFields.filter(ftf => ftf.ID === formTemplateField.ID)[0].Field,
      event, selectedOptions);
    let prefix = (isSecondary) ? "formTemplateFieldSecondary_" : "formTemplateFieldPrimary_";
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(formTemplateField, formTemplateFields, true, prefix);
    
    formTemplateField.Field.ListValues = GetComposedFieldListLabelsAndValues(formTemplateField.Field);
    this.onChangeHandlers.setStringProperty(
      `${prefix}${formTemplateField.ID}`,
      true, 
      (formTemplateField.Field.DisplaySelectionList && formTemplateField.Field.AllowMultipleValues) 
        ? formTemplateField.Field.Values : formTemplateField.Field.Value,
      formTemplateField.Field.Mask > ""
    );
  }

  handleIndexFieldOperatorChange = fieldId => operatorValue => {
    this.onChangeHandlers.setStringProperty(`fieldOperator_${fieldId}`, true, operatorValue);
    this.onGetLocalState("fields").filter(f => f.ID === fieldId)[0].Operator = operatorValue;
  }

  handleFormTemplateFieldOperatorChange = formTemplateFieldId => operatorValue => {
    this.onChangeHandlers.setStringProperty(`formTemplateFieldOperator_${formTemplateFieldId}`, true, operatorValue);
    this.onGetLocalState("formTemplateFields").filter(ftf => ftf.ID === formTemplateFieldId)[0].Operator = operatorValue;
  }

  clearAndReloadDependentFieldValues = (field, fields, isForForms, prefix) => {
    if (!document || !field) {
      return;
    }

    const getEffectiveField = f => isForForms ? f.Field : f;

    RecurseDependentSelectionListsDown(field, fields,
      f => {
        return (f.FieldID !== undefined) ? f.FieldID : f.ID;
      },
      f => getEffectiveField(f).ParentFieldID,
      f => {
        const fLocal = getEffectiveField(f);

        if (!GetEffectiveSelectionListIsDependent(fLocal)) {
          return;
        }
        // Clear the field's value
        GetUpdatedFieldObjectForValueChange(fLocal, undefined, []);
        fLocal.ListValues = GetComposedFieldListLabelsAndValues(fLocal);
        this.onChangeHandlers.setStringProperty(`${prefix}${f.ID}`, true, fLocal.Values); 
        
        // Cause the selection list to be reloaded
        fLocal.UpdateId = new Date();
      },
    );
  }

  GetTriggerContentForAssetsAndFieldsAndTags = () => {
    if (!this.processElement) {
      return null;
    }

    return (
      <Grid container direction="column" spacing={2} style={{/*Fix for asyncselectcontrols with value*/flexWrap:"noWrap"}}>
        <Grid item>
          {this.GetTriggerContentForAssets()}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForFields()}
        </Grid>
        <Grid item>
          {this.GetTriggerContentForTags()}
        </Grid>
      </Grid>
    );
  }

  GetAssetsAsAsyncSelectControl = (isMulti, isOptional) => {
    return (
      <AsyncSelectControl label={(isMulti) ? "Assets" : "Asset"} 
        placeholder={(isOptional) ? "Select optional..." : undefined}
        isMulti={isMulti}
        onGetOptionsFilterPromise={this.handleGetAssetListValuesPromise(isMulti)} 
        listValues={this.processElement[this.getListValueId_AssetID(isMulti)]}
        onValueChange={this.onSelectionListValueChange("AssetID", isMulti)}
      />
    );
  }

  GetTriggerContentForAssets = () => {
    if (!this.processElement) {
      return null;
    }

    return this.GetAssetsAsAsyncSelectControl(true, true);
  }

  handleGetAssetListValuesPromise = isMulti => debounce(filter => {
    return this.GetAssets(isMulti, filter)
      .then(assets => {
        if (!assets) {
          return null;
        }
        let listValues = assets.map(a => {
          return ({ value: a.ID, label: a.Name })
        });
        // Update labels with current values (if different)
        this.setInitialListValueLabels(false, this.getListValueId_AssetID(isMulti), listValues);
        return listValues;
      })
      .catch(err => this.onApiError(err));
  }, 250);

  GetAssets = (isMulti, filter) => {
    let otherParams = {};
    const id = this.getListValueId_AssetID(isMulti);
    if (this.processElement[id]) {
      if (isMulti) {
        otherParams.includeAssetIds_json = JSON.stringify(
          this.processElement[id].map(a => a.value)
        );
      } else {
        otherParams.includeAssetIds_json = JSON.stringify(
          [this.processElement[id].value]
        );
      }
    }

    return HandleGetAssets(this.organizationId, this.projectId, this.onApiError, filter, otherParams)
      .then(assets => {
        this.onSetLocalState({assets});
        return assets;
      });
  }

  GetTriggerContentForTags = () => {
    if (!this.processElement) {
      return null;
    }

    if (this.onSetCloseDialogFunc && !this.completedSetCloseDialogFunc) {
      this.onSetCloseDialogFunc(this.handlePostAllTagsToServer(this.processElement[this.listValueId_Tags]));
      this.completedSetCloseDialogFunc = true;
    }

    return GetTagsControl(this.organizationId, this.projectId, null, 
      false, false, null, true,
      this.processElement[this.listValueId_Tags] || [],
      TagListValues => this.onSetLocalState({TagListValues}),
      this.handleTriggerTagsSelectionListValueChange,
      false, -1, null, true
    );
  }

  handleTriggerTagsSelectionListValueChange = selectedOptions => {
    this.onSelectionListValueChange("Tags", true)(selectedOptions);
  }

  // This should be called when ProcessElementDialog is closed.
  // It will send all selected tags to the server, which will
  // ensure any new items are persisted.
  handlePostAllTagsToServer = tagListValues => {
    if (tagListValues && tagListValues.length) {
      PostTagsFromListValues(
        this.organizationId,
        this.projectId,
        tagListValues,
        this.onApiError,
      );
    }
  }

  GetActionContentForTags = () => {
    if (!this.processElement) {
      return null;
    }

    const tagListValues = GetTagListValuesFromTagsObject({
      Tags: this.processElement.Data["Tags"],
      AssetItemTags: this.processElement.Data["AssetItemTags"],
    });

    if (this.onSetCloseDialogFunc && !this.completedSetCloseDialogFunc) {
      this.onSetCloseDialogFunc(this.handlePostAllTagsToServer(tagListValues));
      this.completedSetCloseDialogFunc = true;
    }

    return GetTagsControl(this.organizationId, this.projectId, null, 
      false, false, null, true,
      tagListValues,
      TagListValues => this.onSetLocalState({TagListValues}),
      this.handleActionTagsSelectionListValueChange,
      false, -1, null, true
    );
  }

  handleActionTagsSelectionListValueChange = selectedOptions => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(selectedOptions);
    this.onChangeHandlers.setAnyProperty("Tags", true, Tags);
    this.onChangeHandlers.setAnyProperty("AssetItemTags", true, AssetItemTags);
  }

  GetActionContentForNamingItems = (includeSharing, showSampleAsFolderPath, showSampleAsDocumentName) => {
    const defaultNamingItemType = "Text";
    const defaultNamingItemTypeOption = {
      value: defaultNamingItemType,
      label: "Text",
    };
    const namingTypeOptions = [
      defaultNamingItemTypeOption,
      {
        value: "Field",
        label: "Field value",
      },
      {
        value: "AssetItemTag",
        label: "Asset tag",
      },
      {
        value: "Tags",
        label: "All text tags in alphabetical order",
      }
    ];
    const valueLengthTypeOptions = [
      {
        value: "Full",
        label: "Full length",
      },
      {
        value: "Partial",
        label: "Partial length",
      },
    ];

    const newNamingItem = {
      Type: defaultNamingItemType,
      TextValue: "",
      FieldID: "",
      FieldLabelOrName: "",
      FieldType: "",
      ValueLengthType: "Full",
      ValueLength: 5,
      DatePart: "",
      AssetID: "",
      AssetName: "",
    };

    let namingItems = this.processElement.Data["NamingItems"]
      || [
        newNamingItem,
      ];

    const fieldsLoaded = this.onGetLocalProperty("fieldsLoaded");
    if (!fieldsLoaded) {
      this.onSetLocalProperty("fieldsLoaded", true);
      API.get(GetFieldsPathForApi(this.organizationId, this.projectId))
        .then(resp => {
          if (resp && resp.data) {
            this.onSetLocalState({Fields:resp.data.Fields});
          }
        })
        .catch(this.onApiError);
    }
    const fieldOptions = (this.onGetLocalState("Fields"))
      ? this.onGetLocalState("Fields").map(field => ({value: field.ID, label: field.LabelOrName}))
      : [];

    const assetsLoaded = this.onGetLocalProperty("assetsLoaded");
    if (!assetsLoaded) {
      this.onSetLocalProperty("assetsLoaded", true);
      API.get(GetAssetsPathForApi(this.organizationId, this.projectId))
        .then(resp => {
          if (resp && resp.data) {
            this.onSetLocalState({Assets:resp.data.Assets});
          }
        })
        .catch(this.onApiError);
    }
    const assetOptions = (this.onGetLocalState("Assets"))
      ? this.onGetLocalState("Assets").map(asset => ({value: asset.ID, label: asset.Name}))
      : [];

    const handleAddNamingItem = () => {
      namingItems.push(newNamingItem);
      this.onChangeHandlers.setAnyProperty("NamingItems", true, namingItems);
    }

    const handleRemoveNamingItem = index => {
      namingItems.splice(index, 1)
      this.onChangeHandlers.setAnyProperty("NamingItems", true, namingItems);
    }

    const handleNamingItemPropertyChange = (propertyName, itemIndex, value) => {
      namingItems[itemIndex][propertyName] = value;
      this.onChangeHandlers.setAnyProperty("NamingItems", true, namingItems);
    }

    let sampleFolderPath = "";
    let sampleDocumentName = "";
    const namingItemGridItems = namingItems.map((namingItem, index) => {
      // Build samples
      switch (namingItem.Type) {
      case "Text":
        if (namingItem.TextValue) {
          sampleFolderPath += "/" + namingItem.TextValue;
          sampleDocumentName += namingItem.TextValue;
        }
        break;
      case "Field":
        let sampleFieldValue = "";
        if (namingItem.FieldID) {
          sampleFieldValue += namingItem.FieldLabelOrName;
          if (namingItem.ValueLengthType === "Partial") {
            switch (namingItem.FieldType) {
              case "FieldType_Text":
                if (namingItem.ValueLength) {
                  sampleFieldValue += ` (${namingItem.ValueLength} character${(namingItem.ValueLength > 1) ? "s" : ""})`;
                }
                break;
              case "FieldType_Date":
                if (namingItem.DatePart) {
                  sampleFieldValue += ` (${namingItem.DatePart})`;
                }
                break;
              default:
                break;
            }
          }
        }
        sampleFolderPath += "/" + sampleFieldValue;
        sampleDocumentName += " " + sampleFieldValue;
        break;
      case "AssetItemTag":
        let sampleAssetName = "";
        if (namingItem.AssetID) {
          sampleAssetName = `${(namingItem.AssetName) ? namingItem.AssetName + " name" : namingItem.AssetID}`;
        }
        sampleFolderPath += "/" + sampleAssetName;
        sampleDocumentName += " " + sampleAssetName;
        break;
      case "Tags":
        sampleFolderPath += "/TagABC/TagXYZ";
        sampleDocumentName += " TagABC TagXYZ";
        break;
      default:
        break;
      }

      const fieldTypeAllowsPartialLength = fieldType => 
        (fieldType === "FieldType_Text" || fieldType === "FieldType_Date"); 

      const valueLengthTypeGridItem = fieldTypeAllowsPartialLength(namingItem.FieldType)
        ? (
          <Grid item xs={namingItem.ValueLengthType === "Full" ? 5 : 3}>
            <SelectControl
              id={`selectValueLength_${index}`}
              label="Length"
              options={valueLengthTypeOptions}
              value={namingItem.ValueLengthType}
              hideEmpty
              onValueChange={value => handleNamingItemPropertyChange("ValueLengthType", index, value)} />
          </Grid>
        ) : null;

      const textFieldLengthOptions = [];
      for (let i = 1; i <= 25; i++) {
        textFieldLengthOptions.push({value: i, label: i});
      }
      const textFieldLengthGridItem = (namingItem.ValueLengthType === "Partial" && namingItem.FieldType === "FieldType_Text")
        ? (
          <Grid item xs={2}>
            <SelectControl
              id={`selectTextFieldLength_${index}`}
              label="Characters"
              options={textFieldLengthOptions}
              value={namingItem.ValueLength}
              hideEmpty
              onValueChange={value => handleNamingItemPropertyChange("ValueLength", index, value)} />
          </Grid>
        ) : null;

      const dateFieldPartialOptions = [
        { value: "YYYY", label: "Year, YYYY" },
        { value: "MM", label: "Month, MM" },
        { value: "DD", label: "Day, DD" },
      ];
      const dateFieldPartialGridItem = (namingItem.ValueLengthType === "Partial" && namingItem.FieldType === "FieldType_Date")
        ? (
          <Grid item xs={2}>
            <SelectControl
              id={`selectDatePart_${index}`}
              label="Date part"
              options={dateFieldPartialOptions}
              value={namingItem.DatePart}
              hideEmpty
              onValueChange={value => handleNamingItemPropertyChange("DatePart", index, value)} />
          </Grid>
        ) : null;

      let targetGridItems;
      switch(namingItem.Type) {
        case "Field":
          if (this.onGetLocalState("Fields")) {
            targetGridItems = (
              <React.Fragment>
                <Grid item xs={(namingItem.FieldID && fieldTypeAllowsPartialLength(namingItem.FieldType)) ? 4 : 9}>
                  <SelectControl
                    id={`selectField_${index}`}
                    label="Field"
                    options={fieldOptions}
                    value={namingItem.FieldID}
                    hideEmpty
                    onValueChange={value => {
                      handleNamingItemPropertyChange("FieldID", index, value);
                      const field = this.onGetLocalState("Fields").find(f => f.ID === value);
                      if (field) {
                        handleNamingItemPropertyChange("FieldType", index, field.Type);
                        handleNamingItemPropertyChange("FieldLabelOrName", index, field.LabelOrName);
                      }
                    }} />
                </Grid>
                {valueLengthTypeGridItem}
                {textFieldLengthGridItem}
                {dateFieldPartialGridItem}
              </React.Fragment>
            );
          }
          break;
        case "AssetItemTag":
          targetGridItems = (
            <Grid item xs={9}>
              <SelectControl
                id={`selectAsset_${index}`}
                label="Asset"
                options={assetOptions}
                value={namingItem.AssetID}
                hideEmpty
                onValueChange={value => {
                  handleNamingItemPropertyChange("AssetID", index, value);
                  const asset = this.onGetLocalState("Assets").find(a => a.ID === value);
                  if (asset) {
                    handleNamingItemPropertyChange("AssetName", index, asset.Name);
                  }
                }} />
            </Grid>
          );
          break;
        case "Tags":
          break;
        case defaultNamingItemType:
        default:
          targetGridItems = (
            <Grid item xs={9} key="gridItemTextValue">
              <TextField
                label="Text"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true, }}
                placeholder="Text value"
                inputProps={{maxLength:50}}
                onChange={e => handleNamingItemPropertyChange("TextValue", index, e.target.value)}
                value={namingItem.TextValue || ""}
              />
            </Grid>
          );
          break;
      }

      const removeButton = (index > 0) ? (
        <div style={{marginRight:8}}>
          <IconButton aria-label="Remove item"
            onClick={() => handleRemoveNamingItem(index)}>
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null;

      return (
        <React.Fragment key={`gridRow_${index}`}>
          <Grid item xs={(namingItem.Type === "Tags") ? 12 : 3} style={{display:"flex"}}>
            {removeButton}
            <div style={{flexGrow:1}}>
              <SelectControl
                id={`selectFolderType_${index}`}
                label="Type"
                hideEmpty
                options={namingTypeOptions} 
                value={namingItem.Type}
                onValueChange={value => handleNamingItemPropertyChange("Type", index, value)}
              />
            </div>
          </Grid>
          {targetGridItems}
        </React.Fragment>
      );
    });

    sampleDocumentName += ".extension";

    const sampleFolderPathGridItem = (showSampleAsFolderPath && sampleFolderPath)
      ? (
        <Grid item xs={12} style={{marginTop:8,marginBottom:8,fontWeight:600}}>
          {sampleFolderPath}
        </Grid>
      ) : null;

    if (!this.onGetLocalState("Fields") && !this.onGetLocalState("Assets")) {
      return null;
    }

    const sampleDocumentNameGridItem = (showSampleAsDocumentName && sampleDocumentName)
      ? (
        <Grid item xs={12} style={{marginTop:8,marginBottom:8,fontWeight:600}}>
          {sampleDocumentName}
        </Grid>
      ) : null;

    if (!this.onGetLocalState("Fields") && !this.onGetLocalState("Assets")) {
      return null;
    }

    const shareGridItem = (includeSharing)
      ? (
        <Grid item xs={12}>
          {
            this.GetEmailAddressControl(
              "AssignmentUserEmails",
              "Share with people (optional)",
              true,
              true,
              true,
              true,
              false,
            )
          }
        </Grid>
      ) : null;

    return (
      <Grid container spacing={2} alignItems="center">
        {shareGridItem}
        {sampleFolderPathGridItem}
        {sampleDocumentNameGridItem}
        {namingItemGridItems}
        <Grid item xs={12}>
          <IconButton aria-label="Add item"
            onClick={() => handleAddNamingItem()}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  GetActionContentForItemRelease = () => {
    const itemTypeOptions = [
      {label:"Document",value:"Document"},
      {label:"Asset",value:"AssetItem"},
    ];

    const syncProperties = this.processElement.Data[this.boolValueId_SyncProperties];
    const itemTypeIsAssetItem = 
      this.processElement[this.onGetListValuesAndLabelsPropertyName("ItemType")]
      && this.processElement[this.onGetListValuesAndLabelsPropertyName("ItemType")].value === "AssetItem";
    const assetGridItem = (itemTypeIsAssetItem)
      ? (
        <Grid item>
          {this.GetAssetsAsAsyncSelectControl(false, false)}
        </Grid>
      ) : null;

    const syncPropertiesGridItem = (
      <Grid item style={{marginLeft:12}}>
        <Grid container spacing={1} style={{alignItems:"center"}}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={syncProperties}
                  onChange={e => this.onChangeHandlers.setBoolProperty(
                    this.boolValueId_SyncProperties, true, e.target.checked)}
                />
              }
              label="Allow properties sync" />
          </Grid>
          <Grid item>
            <Tooltip title="Allow properties of received items to be updated when the source is updated. Received items will be read only.">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );

    const selectedListValues = this.processElement[this.listValueId_FieldID];
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <AsyncSelectControl label="Item type"
            onGetOptionsFilterPromise={filter => Promise.resolve(itemTypeOptions.filter(lv => lv.label.startsWith(filter)))} 
            listValues={this.processElement[this.onGetListValuesAndLabelsPropertyName("ItemType")]}
            onValueChange={this.onSelectionListValueChange("ItemType")}
          />
        </Grid>
        {assetGridItem}
        <Grid item>
          <AsyncSelectControl label="Fields allowed to transfer" 
            isMulti
            placeholder="Select optional..."
            onGetOptionsFilterPromise={this.handleGetFieldListValuesPromise} 
            listValues={selectedListValues}
            onValueChange={this.handleFieldSelectionListValueChange()}
            helperText={(!selectedListValues?.length) ? "All fields will be transferred since none are specified." : null}
          />
        </Grid>
        {syncPropertiesGridItem}
      </Grid>
    );
  }

  GetItemReleaseProcessElements = filter => {
    let params = {
      filter,
    };

    if (this.processElement.Data[this.anyValueId_ProcessElement]) {
      const pe = this.processElement.Data[this.anyValueId_ProcessElement];
      params.includeProcessElements_json = JSON.stringify([{
        ProjectID: pe.ProjectID,
        ProcessID: pe.ProcessID,
        ID: pe.ID,
      }]);
    }
    return API.get(GetOrganizationItemReleaseProcessElementsPathForApi(this.organizationId), { params })
      .then(resp => {
        return resp.data.ProcessElements;
      });
  }

  getProcessElementListValue = processElement => ({
    processElement,
    value: processElement.ID,
    label: `${processElement.Name}${
      (processElement.ProjectName) ? "; Project: " + processElement.ProjectName : ""}${
      (processElement.ProcessName) ? "; Process: " + processElement.ProcessName : ""}`,
  });

  handleGetItemReleaseProcessElementListValuesPromise = debounce(filter => {
    return this.GetItemReleaseProcessElements(filter)
      .then(ReleaseProcessElements => {
        if (!ReleaseProcessElements) {
          return null;
        }
        let listValues = ReleaseProcessElements.map(pe => this.getProcessElementListValue(pe));
        // Update labels with current values (if different)
        if (this.processElement.Data[this.anyValueId_ProcessElement]) {
          const item = listValues.find(lv => lv.value === this.processElement.Data[this.anyValueId_ProcessElement].ID);
          if (item && item.processElement) {
            if (this.getProcessElementListValue(this.processElement.Data[this.anyValueId_ProcessElement]).label !== item.label) {
              this.handleProcessElementValueChange(
                this.getProcessElementListValue(item.processElement)
              );
            }
          }
        }
        this.onSetLocalState({
          ReleaseProcessElements,
          ReleaseProcessElementsLoaded:true,
        });
        return listValues;
      })
      .catch(err => this.onApiError(err));
  }, 250);

  handleProcessElementValueChange = listValue => {
    this.onChangeHandlers.setAnyProperty(this.anyValueId_ProcessElement, true, (listValue) ? listValue.processElement : null);
  }

  handleGetFieldListValuesPromiseForFieldMapping = (labelOrNameFilter, typeFilter) => {
    const AllFieldsForMapping = this.onGetLocalState("AllFieldsForMapping") || [];
    return Promise.resolve(
      AllFieldsForMapping
        .filter(f => (typeFilter) ? f.Type === typeFilter : f)
        .filter(f => (labelOrNameFilter) ? f.LabelOrName.startsWith(labelOrNameFilter) : f)
        .map(f => this.getListValueForField(f))
    );
  }

  handleGetAndSetFieldsForFieldMapping = () => {
    return this.GetFields()
      .then(fieldPackage => {
        if (!fieldPackage.fields) {
          return null;
        }
        this.onSetLocalState({
          AllFieldsForMapping: fieldPackage.fields,
          AllFieldsForMappingAreLoaded: true,
        });
      })
      .catch(err => this.onApiError(err));
  }

  handleFieldMappingValueChange = sourceFieldListValue => listValue => {
    let FieldMappings = this.processElement.Data[this.anyValueId_FieldMappings] || [];
    let fieldMapping = FieldMappings.find(fm => fm.SourceFieldID === sourceFieldListValue.value);
    if (listValue) {
      if (fieldMapping) {
        fieldMapping.DestinationFieldID = listValue?.value;
        fieldMapping.DestinationFieldLabel = listValue?.label;
      } else {
        FieldMappings.push({
          SourceFieldID: sourceFieldListValue.value,
          SourceFieldLabel: sourceFieldListValue.label,
          DestinationFieldID: listValue?.value,
          DestinationFieldLabel: listValue?.label,
        });
      }
    } else {
      if (fieldMapping) {
        FieldMappings.splice(FieldMappings.indexOf(fieldMapping), 1);
      }
    }
    
    // Ensure all mappings are relevant
    let validFieldMappings = [];
    const releaseProcessElement = this.getReleaseProcessElement();
    if (releaseProcessElement) {
      const release = JSON.parse(releaseProcessElement.JsonData);
      FieldMappings.forEach(fm => {
        if (release.MultiSelectValuesAndLabels_FieldID
          && release.MultiSelectValuesAndLabels_FieldID.find(lv => lv.value === fm.SourceFieldID)) {
          validFieldMappings.push(fm);
        }
      });
    } else {
      validFieldMappings = [...FieldMappings];
    }
    
    this.onChangeHandlers.setAnyProperty(this.anyValueId_FieldMappings, true, validFieldMappings);
  }

  getListValueForField = field => {
    return {
      value: field.ID,
      label: field.Name + ((field.Label) ? ` (${field.Label})` : ""),
      field,
    };
  }

  getAllFieldsForItemReleaseProject = projectId => {
    return GetAllFieldsPromise(this.organizationId, projectId)
      .then(resp => {
        if (!resp.data) {
          return [];
        }
        const itemReleaseFields = resp.data.Fields;
        this.onSetLocalState({
          itemReleaseFields,
        });
        return itemReleaseFields;
      })
      .catch(this.onApiError);
  }

  handleAddFieldToCurrentProject = sourceListFieldValue => Name => {
    if (!Name) {
      return;
    }
    this.onSetLocalState({ShowProgressIndicatorImmediately: true});
    const newField = GetNewTextField(Name);
    API.post(GetFieldsPathForApi(this.organizationId, this.projectId),
      [newField])
      .then(resp => {
        const returnedNewField = resp.data[0];
        const AllFieldsForMapping = this.onGetLocalState("AllFieldsForMapping");
        AllFieldsForMapping.splice(0,0,returnedNewField);
        this.onSetLocalState({AllFieldsForMapping});
        this.handleFieldMappingValueChange(sourceListFieldValue)(this.getListValueForField(returnedNewField));
        this.onSetShowFieldPropertiesDialog(true, returnedNewField, true);
      })
      .catch(this.onApiError)
      .finally(() => {
        this.onSetLocalState({ShowProgressIndicatorImmediately: false});
      });
  }

  getFieldMappingGridItems = releaseProcessElement => {
    if (!this.onGetLocalState("ReleaseProcessElementsLoaded")
      || !this.onGetLocalState("AllFieldsForMappingAreLoaded")
      || !releaseProcessElement
      || !releaseProcessElement.JsonData) {
      return [];
    }

    const release = JSON.parse(releaseProcessElement.JsonData);
    const itemReleaseFields = this.onGetLocalState("itemReleaseFields");
    const allFieldsForMapping = this.onGetLocalState("AllFieldsForMapping");

    const getFieldMappingGridItem = (sourceFieldListValue, fieldMapping_optional) => {
      const fieldForMapping = (fieldMapping_optional)
        ? allFieldsForMapping.find(f => f.ID === fieldMapping_optional.DestinationFieldID)
        : null;
      const selectedListValue = (fieldForMapping)
        ? { 
          label:fieldForMapping.LabelOrName,
          value:fieldForMapping.ID,
        }
        : null;

      const fieldLabel = (sourceFieldListValue.field)
        ? `${GetFieldTypeLabel(sourceFieldListValue.field.Type)} field for "${sourceFieldListValue.label}"`
        : `Field for "${sourceFieldListValue.label}"`;

      return (
        <Grid item key={`dest_${sourceFieldListValue.value}`} xs={6}>
          <AsyncSelectControl label={fieldLabel}
            onGetOptionsFilterPromise={filter => 
              this.handleGetFieldListValuesPromiseForFieldMapping(filter, sourceFieldListValue.field?.Type)
            } 
            listValues={selectedListValue}
            onValueChange={this.handleFieldMappingValueChange(sourceFieldListValue)}
            onCreateOption={this.handleAddFieldToCurrentProject(sourceFieldListValue)}
            updateId={this.onGetLocalState("fieldMappingUpdateId")}
          />
        </Grid>
      );
    }

    let mappingGridItems = [];
    const fieldMappings = this.processElement.Data[this.anyValueId_FieldMappings] || [];
    if (release.MultiSelectValuesAndLabels_FieldID && release.MultiSelectValuesAndLabels_FieldID.length) {
      release.MultiSelectValuesAndLabels_FieldID.forEach(sourceFieldListValue => {
        const fieldMapping = fieldMappings.find(fm => fm.SourceFieldID === sourceFieldListValue.value);
        mappingGridItems.push(getFieldMappingGridItem(sourceFieldListValue, fieldMapping));
      });
    } else {
      if (!this.onGetLocalProperty("itemReleaseFieldsLoaded")) {
        this.onSetLocalProperty("itemReleaseFieldsLoaded", true);
        this.getAllFieldsForItemReleaseProject(releaseProcessElement.ProjectID);
      } else if (itemReleaseFields && itemReleaseFields.length) {
        itemReleaseFields.forEach(f => {
          const fieldMapping = fieldMappings.find(fm => fm.SourceFieldID === f.ID);
          mappingGridItems.push(getFieldMappingGridItem(this.getListValueForField(f), fieldMapping));
        });        
      }
    }
    return mappingGridItems;
  }

  getReleaseProcessElement = () => {
    let releaseProcessElement;
    const dataReleaseProcessElement = this.processElement.Data[this.anyValueId_ProcessElement];
    const releaseProcessElements = this.onGetLocalState("ReleaseProcessElements");
    if (releaseProcessElements && dataReleaseProcessElement) {
      releaseProcessElement = releaseProcessElements.find(rpe => rpe.ID === dataReleaseProcessElement.ID);
    }
    return releaseProcessElement;
  }

  GetTriggerContentForItemAccept = () => {
    this.onSetManageFieldsDialogCloseFunc(
      () => this.handleGetAndSetFieldsForFieldMapping()
        .then(() => {
          this.onSetLocalState({fieldMappingUpdateId:new Date()});
        })
    );

    // Load fields once
    if (!this.onGetLocalProperty("fieldsLoadedForMapping")) {
      this.onSetLocalProperty("fieldsLoadedForMapping", true);
      this.handleGetAndSetFieldsForFieldMapping();
    }

    const releaseProcessElement = this.getReleaseProcessElement()
    const fieldMappingGridItems = this.getFieldMappingGridItems(releaseProcessElement);
    let fieldMappingGrid;
    if (fieldMappingGridItems.length) {
      fieldMappingGrid = (
        <Grid container spacing={2}>
          {fieldMappingGridItems}
        </Grid>
      );
    }

    const release = (releaseProcessElement && releaseProcessElement.JsonData)
      ? JSON.parse(releaseProcessElement.JsonData)
      : null;
    const syncProperties = this.processElement.Data[this.boolValueId_SyncProperties];
    const syncPropertiesGridItem = (release && release[this.boolValueId_SyncProperties])
      ? (
        <Grid item style={{marginLeft:12}}>
          <Grid container spacing={1} style={{alignItems:"center"}}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={syncProperties}
                    onChange={e => this.onChangeHandlers.setBoolProperty(
                      this.boolValueId_SyncProperties, true, e.target.checked)}
                  />
                }
                label="Sync properties" />
            </Grid>
            <Grid item>
              <Tooltip title="Item properties will be updated when the source is updated. Items will be read only.">
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : null;

    const ShowProgressIndicatorImmediately = this.onGetLocalState("ShowProgressIndicatorImmediately");
    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator constrained showImmediately />
      ) : null;

    return (
      <Grid container direction="column" spacing={2}>
        {progressIndicator}
        <Grid item>
          <AsyncSelectControl label="Item-release element"
            onGetOptionsFilterPromise={this.handleGetItemReleaseProcessElementListValuesPromise} 
            listValues={
              (this.processElement.Data[this.anyValueId_ProcessElement])
                ? this.getProcessElementListValue(this.processElement.Data[this.anyValueId_ProcessElement])
                : undefined
            }
            onValueChange={this.handleProcessElementValueChange}
          />
        </Grid>
        {syncPropertiesGridItem}
        <Grid item>
          {fieldMappingGrid}
        </Grid>
      </Grid>
    );
  }
}