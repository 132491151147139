import React, { Component } from 'react';

import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import Card from './Card';

import FormDesignerIcon from '@material-ui/icons/Edit';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  HandleRouteToFormTemplateDesigner,
} from '../Util/FormTemplates';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';

import { IsMobile } from '../Util/MobileDetector';

class FormShareCard extends Component {
  render() {
    const {
      FormShare,
      onCardAction,
      style,
      height,
      minHeight,
      noSelect,
      onSelect,
      selected,
      isSearchResults,
      showFormTemplateDesignerAction,
    } = this.props; 

    let metadata = [
      ["Shared with", GetUserValue(FormShare.AssignmentUserEmail, FormShare.AssignmentUserName)],
      ["Project", FormShare.ProjectName],
      ["Created On", GetDateValue(FormShare.CreatedOn)],
      ["Tags", GetTagsPreviewFromTagsObject(FormShare)],
    ];

    const hoverActionItems = [];
    if (showFormTemplateDesignerAction && !IsMobile()) {
      hoverActionItems.push(
        <IconButton
          key="Form_RouteToDesigner"
          aria-label="Designer"
          title="Designer"
          onClick={() => HandleRouteToFormTemplateDesigner(this.props, FormShare.OrganizationID,
            FormShare.ProjectID, FormShare.FormTemplateID)}
          >
          <FormDesignerIcon />
        </IconButton>
      );
    }

    return (
      <Card
        style={style}
        height={height}
        minHeight={minHeight}
        onCardAction={onCardAction}
        name={FormShare.Name || FormShare.FormTemplateName}
        metadata={metadata}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        isSearchResults={isSearchResults}
        contentItem={FormShare}
        hoverActionItems={hoverActionItems}
      />
    );
  }
}

FormShareCard.propTypes = {
  FormShare: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  showFormInputAction: PropTypes.bool,
  showFormTemplateDesignerAction: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default FormShareCard;