import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '../Components/Card';
import IconButton from '@material-ui/core/IconButton';
import SubscribedIcon from '@material-ui/icons/Bookmark';

import ActionType from '../Model/ActionType';

import {
  GetFormattedDescriptionPromise,
  GetDueOnStyle,
} from '../Util/Tasks';
// import { IsMobile } from '../Util/MobileDetector';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import {
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
  GetTaskSubscription,
} from '../Util/Task';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';

import orange from '@material-ui/core/colors/orange';

const styles = theme => ({
  TaskDescription: {
    ...GetRichTextEditorStyles(theme),
  },
});

class TaskCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      FormattedDescriptionIsLoaded: false,
      TaskSubscription: null,
    };

    this.FormattedDescription = null;
  }

  loadDescription = () => {
    const {
      Task,
      theme,
      classes,
    } = this.props;
    GetFormattedDescriptionPromise(Task, theme, classes)
      .then(formattedDescription => {
        if (formattedDescription) {
          this.FormattedDescription = formattedDescription;
          this.setState({FormattedDescriptionIsLoaded: true});
        }
      })
      .catch(err => {});
  }

  loadTaskSubscription = () => {
    GetTaskSubscription(this.props.organizationId, this.props.projectId, this.props.Task.ID)
      .then(TaskSubscription => this.setState({TaskSubscription}));
  }

  handleSubscribeAction = (actionType, id) => {
    if (!this.props.onAction) {
      return;
    }
    this.props.onAction(actionType, id, true)
      .then(taskSubscriptions => {
        if (taskSubscriptions && taskSubscriptions.length) {
          this.setState({TaskSubscription: taskSubscriptions[0]});
        } else {
          this.loadTaskSubscription();
        }
      });
  }

  handlePostActionData = data => {
    // Updated subscription
    if (data.TaskSubscriptions) {
      let subscriptionFinder = data.TaskSubscriptions.filter(ds => ds.TaskID === this.props.Task.ID);
      if (subscriptionFinder.length) {
        this.setState({TaskSubscription: subscriptionFinder[0]});
      }
    }
  }

  componentDidMount() {
    // This has been disabled to tighten overall performance and prevent too much network overhead
    // this.loadDescription();

    if (this.props.allowSubscriptions) {
      this.loadTaskSubscription();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postActionData
      && this.props.postActionData !== prevProps.postActionData) {
      this.handlePostActionData(this.props.postActionData);
    }

    if (prevProps.Task
      && this.props.Task
      && prevProps.Task.RefreshID !== this.props.Task.RefreshID
      && this.props.allowSubscriptions) {
      this.loadTaskSubscription();
    }
  }

  render() {
    const {
      FormattedDescriptionIsLoaded,
      TaskSubscription,
    } = this.state;
    const {
      Task,
      primaryDocumentLinkComponent,
      style,
      onAction,
      noSelect,
      onSelect,
      selected,
      onCardAction,
      height,
      isSearchResults,
      disableCardActions,
    } = this.props;

    let hoverActionItems = [];
    if (TaskSubscription) {
      hoverActionItems.push(
        (TaskSubscription.Subscribed)
          ? (
            <IconButton
              key={ActionType.TaskSubscription_Delete.Id}
              aria-label={ActionType.TaskSubscription_Delete.Label}
              title={ActionType.TaskSubscription_Delete.Label}
              onClick={() => this.handleSubscribeAction(ActionType.TaskSubscription_Delete, Task.ID)}
              >
              <SubscribedIcon style={{color:orange[500]}} />
            </IconButton> 
          )
          : (
            <IconButton
              key={ActionType.TaskSubscription_Create.Id}
              aria-label={ActionType.TaskSubscription_Create.Label}
              title={ActionType.TaskSubscription_Create.Label}
              onClick={() => this.handleSubscribeAction(ActionType.TaskSubscription_Create, Task.ID)}
              >
              {ActionType.TaskSubscription_Create.Icon}
            </IconButton> 
          )
      );
    }

    if (onAction && !Task.Result) {
      hoverActionItems.push(
        <IconButton
          key={ActionType.Task_Complete.Id}
          aria-label={ActionType.Task_Complete.Label}
          title={ActionType.Task_Complete.Label}
          onClick={() => onAction(ActionType.Task_Complete, Task.ID)}
        >
          {ActionType.Task_Complete.Icon}
        </IconButton>
      );
    }

    let metadata = [];

    if (Task.Result === "Complete") {
      metadata.push(["", "Completed Task"]);
    }
    if (Task.Result === "Deny") {
      metadata.push(["", "Deleted Task"]);
    }

    metadata.push(
      ["Project", Task.ProjectName],
      ["Assigned to", GetUserValue(Task.AssignmentUserEmail, Task.AssignmentUserName, "sm")],
    );

    if (Task.ProcessName) {
      metadata.push(["Process", Task.ProcessName]);
    }

    if (Task.ProcessElementName) {
      metadata.push(["Process Action", Task.ProcessElementName]);
    }

    metadata.push(
      ["Created On", GetDateValue(Task.CreatedOn)],
      ["Created By", GetUserValue(Task.CreatedByUserEmail, Task.CreatedByUserName, "sm")],
      ["Priority", GetTaskPriorityLabel(Task.Priority)],
      ["Milestone", Task.TaskMilestoneName],
      ["Task State", Task.TaskStateName],
      ["Tags", GetTagsPreviewFromTagsObject(Task)],
      ["Due On", GetDateValue(Task.DueOn), (!Task.Result) ? GetDueOnStyle(Task.DueOn) : undefined],
      ["Duration", GetTaskDurationAsString(Task.DurationMultiplier || Task.TaskDurationMultiplier, Task.DurationInterval || Task.TaskDurationInterval)],
    );

    if (Task.Result === "Complete") {
      metadata.push(
        ["Completed On", GetDateValue(Task.CompletedOn)],
        ["Completed By", GetUserValue(Task.CompletedByUserEmail, Task.CompletedByUserName, "sm")],
      );
    }

    if (primaryDocumentLinkComponent) {
      metadata.push(["Primary Document", primaryDocumentLinkComponent]);
    }

    return (
      <Card
        style={style}
        hoverActionItems={(!disableCardActions) ? hoverActionItems : undefined}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={height || 350}
        translucentActionBar
        name={Task.Name}
        metadata={metadata}
        isSigned={Task.PrimaryDocument && Task.PrimaryDocument.IsSigned}
        fileName={Task.PrimaryDocument && Task.PrimaryDocument.Name}
        thumbnailUrl={Task.PrimaryDocument && Task.PrimaryDocument.ThumbnailUrl}
        description={(FormattedDescriptionIsLoaded) ? this.FormattedDescription : undefined}
        isSearchResults={isSearchResults}
        contentItem={Task}
      />
    );
  }
}

TaskCard.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  primaryDocumentLinkComponent: PropTypes.object,
  Task: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  disableCardActions: PropTypes.bool,
  allowSubscriptions: PropTypes.bool,
  postActionData: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(TaskCard);