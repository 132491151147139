import { GetFieldForFilterAndSort } from '../Util/Field';

const TaskSubscriptionCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Document Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[ItemCreatedOn].string",
		"Document Created On",
		"FieldType_Date",
	),
];

export default TaskSubscriptionCollectionFields;