import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import ProjectMemberCard from '../Admin/Components/ProjectMemberCard';
import ProjectMemberCollectionFields from '../Model/ProjectMemberCollectionFields';
import ProjectMemberDialog from '../Admin/Components/ProjectMemberDialog';
import ProjectMemberCreationDialog from '../Admin/Components/ProjectMemberCreationDialog';

import {
  GetProjectMembersPathForApi,
  GetProjectMemberPathForApi,
} from '../Util/api';
import {
  HandleRouteToProjectMember,
} from '../Util/ProjectMembers';
import {
  GetProjectMembersPath,
} from '../Util/PathHelper';

import API from '../Util/api';

import {
  GetBoolValue,
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

export class ProjectMembers_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, 
    projectId, context, onItemTitleChange) {
    
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleItemTitleChange = onItemTitleChange;

    this.PageTitle = "Project Members";
    this.ContentUri = GetProjectMembersPathForApi(organizationId, projectId);
    this.CollectionName = "ProjectMembers";
    this.ItemsName = "ProjectMembers";
    this.ItemName = `Project Member`;
    this.DefaultViewType = "List";
    this.AllowSelect = true;
    this.ProjectMembersForDialog = [];
    this.CanSelectItem = item => 
      item.UserEmail !== context.UserPreferences.UserEmail
      && !item.OrganizationMemberIsAdmin;

    this.context = context;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(ProjectMemberCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'UserName', sortId: 'Meta_text_kw256lc[UserName].keyword', numeric: false, label: 'Name' },
    { id: 'UserEmail', sortId: 'Meta_text_kw256lc[UserEmail].keyword', numeric: false, label: 'Email' },
    { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Added' },
    { id: 'Admin', numeric: false, label: "Project Manager" },
    { id: 'Stakeholder', numeric: false, label: "Stakeholder" },
    { id: 'OrgAdmin', numeric: false, label: 'Organization Admin' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <ProjectMemberCard
                ProjectMember={i}
                onCardAction={() => HandleRouteToProjectMember(this.props, this.ProjectID, i.ID)}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const labelId = `label_${i.UserEmail}`;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={() => HandleRouteToProjectMember(this.props, this.ProjectID, i.ID)}
              onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                  }}
                  color="secondary"
                  onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                  checked={selectedIDs.indexOf(i.ID) > -1}
                  inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                {GetUserValue(i.UserEmail, i.UserName)}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.UserEmail}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.IsAdmin)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.IsStakeholder)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.OrganizationMemberIsAdmin)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleItemsChanged = items => {
    this.ProjectMembersForDialog = items || [];
    this.handleDisplayProjectMemberDialogIfApplicable(null, items);
  }

  handleDisplayProjectMemberDialogIfApplicable = (projectMemberId, projectMembers) => {
    if (!projectMemberId) {
      projectMemberId = this.props.match.params.collectionItemID;
    }
    if (projectMemberId) {
      const displayMemberDialog = (projectMember, extraState) => {
        this.setState({
          ProjectMemberForProjectMemberDialog: projectMember,
          ProjectMemberDialogIsOpen: true,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(projectMember.UserEmail);
        }
      }
      if (!projectMembers) {
        projectMembers = [...this.ProjectMembersForDialog];
      }
      let memberFinder = projectMembers.filter(tm => tm.ID === projectMemberId);
      if (!memberFinder.length) {
        API.get(GetProjectMemberPathForApi(this.OrganizationID, this.ProjectID, projectMemberId))
          .then(resp => {
            displayMemberDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleProjectMemberDialogClosed();
          });
      } else {
        displayMemberDialog(memberFinder[0]);
      }
    }
  }

  handleProjectMemberDialogClosed = () => {
    const stateToUpdate = {
      ProjectMemberDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
    
    const newPath = GetProjectMembersPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  HandleGetDialogContent = state => {
    if (state.ProjectMemberDialogIsOpen) {
      return (
        <ProjectMemberDialog
          open={state.ProjectMemberDialogIsOpen || false}
          ProjectMember={state.ProjectMemberForProjectMemberDialog}
          onProjectMemberRevised={this.HandleItemRevised}
          onApiError={this.handleApiError}
          onClose={this.handleProjectMemberDialogClosed}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
        />
      );
    }
    else if (state.AddProjectMemberDialogIsOpen) {
      return (
        <ProjectMemberCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          // projectName={projectName}
          open={state.AddProjectMemberDialogIsOpen || false}
          onCreated={this.handleProjectMembersCreated}
          onClose={() => this.handleAddProjectMemberDialogClosed()}
          onApiError={this.handleApiError}
        />
      );
    }
  }

  handleAddProjectMemberDialogClosed = () => {
    this.setState({
      AddProjectMemberDialogIsOpen: false,
    })
  }

  handleProjectMembersCreated = projectMembers => {
    this.handleAddProjectMemberDialogClosed();
    this.context.Reset(); // for ensuring SUBSCRIBE NOW appears if necessary
    if (!projectMembers || !projectMembers.length) {
      return;
    }
    this.setState({
      ForcePrependItems: projectMembers,
    });
  }

  HandleCreateNew = () => {
    this.setState({
      AddProjectMemberDialogIsOpen: true,
    });
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleDisplayProjectMemberDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleProjectMemberDialogClosed();
      return false;
    }

    return true;
  }
}