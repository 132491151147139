import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetBoolValue,
  GetDateValue,
  GetUserValue,
} from '../../Util/Properties';

import red from '@material-ui/core/colors/red';

class ProjectMemberCard extends Component {
  render() {
    const {
      ProjectMember,
      style,
      height,
      onCardAction,
      onSelect,
      selected,
      isSearchResults,
    } = this.props; 
   
    let metadata = [];

    if (ProjectMember.UserName && ProjectMember.UserEmail) {
      metadata.push(["Email", ProjectMember.UserEmail]);
    }

    metadata.push(["Added", GetDateValue(ProjectMember.CreatedOn)]);

    if (ProjectMember.OrganizationMemberIsAdmin) {
      metadata.push(["", "Organization Admin"]);
    }

    if (ProjectMember.IsAdmin) {
      metadata.push(["", "Project Manager"]);
    }

    if (ProjectMember.IsStakeholder) {
      metadata.push(["", "Stakeholder"]);
    }

    if (ProjectMember.Disabled) {
      metadata.push(["Disabled", GetBoolValue(true), { color: red[500], }]);
    }

    return (
      <Card
        style={style}
        height={height}
        onCardAction={onCardAction}
        onSelect={onSelect}
        selected={selected}
        minHeight={132}
        name={GetUserValue(ProjectMember.UserEmail, ProjectMember.UserName, "lg")}
        metadata={metadata}
        isSearchResults={isSearchResults}
        contentItem={ProjectMember}
      />
    );
  }
}

ProjectMemberCard.propTypes = {
  ProjectMember: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default ProjectMemberCard;