import React from 'react';

import ApproveIcon from '@material-ui/icons/ThumbUp';
import DeclineIcon from '@material-ui/icons/ThumbDown';
import SubscribeIcon from '@material-ui/icons/Bookmark';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import DenyIcon from '@material-ui/icons/RemoveCircle';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import RecycleBinIcon from '@material-ui/icons/Delete';
import RestoreFromRecycleBinIcon from '@material-ui/icons/RestoreFromTrash';
import AssignTaskIcon from '@material-ui/icons/AssignmentInd';
import MarkTaskCompleteIcon from '@material-ui/icons/Done';
import RestoreTaskIcon from '@material-ui/icons/AssignmentReturn';
import NotificationMarkReadIcon from '@material-ui/icons/Markunread';
import NotificationMarkUnreadIcon from '@material-ui/icons/Drafts';
import NotificationClearIcon from '@material-ui/icons/Clear';
import ArchiveIcon from '@material-ui/icons/Archive';
import RestoreIcon from '@material-ui/icons/Unarchive';
import ShareIcon from '@material-ui/icons/Share';
import { FolderMoveIcon } from '../Util/Icons';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';


const ActionType = {
	Approval_Approve: {
		Id: "Approval_Approve",
		Label: "Approve",
		Icon: (<ApproveIcon style={{color:green[500]}} />),
	},
	Approval_Decline: {
		Id: "Approval_Decline",
		Label: "Decline",
		Icon: (<DeclineIcon style={{color:red[500]}} />),
	},
	Approval_Deny: {
		Id: "Approval_Deny",
		Label: "Deny",
		Icon: (<DenyIcon />),
	},
	DocumentSubscription_Create: {
		Id: "DocumentSubscription_Create",
		Label: "Follow",
		Icon: (<SubscribeIcon />),
	},
	DocumentSubscription_Delete: {
		Id: "DocumentSubscription_Delete",
		Label: "Unfollow",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	TaskSubscription_Create: {
		Id: "TaskSubscription_Create",
		Label: "Follow",
		Icon: (<SubscribeIcon />),
	},
	TaskSubscription_Delete: {
		Id: "TaskSubscription_Delete",
		Label: "Unfollow",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	Field_Delete: {
		Id: "Field_Delete",
		Label: "Delete",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	Asset_Archive: {
		Id: "Asset_Archive",
		Label: "Archive",
		Icon: (<ArchiveIcon />),
	},
	Asset_Restore: {
		Id: "Asset_Restore",
		Label: "Restore",
		Icon: (<RestoreIcon />),
	},
	AssetItem_Archive: {
		Id: "AssetItem_Archive",
		Label: "Archive",
		Icon: (<ArchiveIcon />),
	},
	AssetItem_Restore: {
		Id: "AssetItem_Restore",
		Label: "Restore",
		Icon: (<RestoreIcon />),
	},
	TaskMilestone_Delete: {
		Id: "TaskMilestone_Delete",
		Label: "Delete",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	TaskState_Delete: {
		Id: "TaskState_Delete",
		Label: "Delete",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	Download: {
		Id: "Download",
		Label: "Download",
		Icon: (<DownloadIcon />),
	},
	SendToRecycleBin: {
		Id: "SendToRecycleBin",
		Label: "Send to Recycle Bin",
		Icon: (<RecycleBinIcon />),	
	},
	RestoreFromRecycleBin: {
		Id: "RestoreFromRecycleBin",
		Label: "Restore",
		Icon: (<RestoreFromRecycleBinIcon />),	
	},
	SetDocumentFolder: {
		Id: "SetDocumentFolder",
		Label: "Move to",
		Icon: (<FolderMoveIcon />),	
	},
	ShareForm: {
		Id: "ShareForm",
		Label: "Share",
		Icon: (<ShareIcon />),	
	},
	Task_Assign: {
		Id: "Task_Assign",
		Label: "Assign",
		Icon: (<AssignTaskIcon />),
	},
	Task_Complete: {
		Id: "Task_Complete",
		Label: "Mark Complete",
		Icon: (<MarkTaskCompleteIcon style={{color:green[500]}} />),
	},
	Task_Restore: {
		Id: "Task_Restore",
		Label: "Restore",
		Icon: (<RestoreTaskIcon />),
	},
	Task_Deny: {
		Id: "Task_Deny",
		Label: "Remove",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	TaskDocument_Remove: {
		Id: "TaskDocument_Remove",
		Label: "Remove from task",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	AssetItemDocument_Remove: {
		Id: "AssetItemDocument_Remove",
		Label: "Remove asset tag",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	AssetItemTask_Remove: {
		Id: "AssetItemTask_Remove",
		Label: "Remove asset tag",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	AssetItemAssetItem_Remove: {
		Id: "AssetItemAssetItem_Remove",
		Label: "Remove asset tag",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
	Notification_MarkRead: {
		Id: "Notification_MarkRead",
		Label: "Mark Read",
		Icon: (<NotificationMarkReadIcon />),
	},
	Notification_MarkUnread: {
		Id: "Notification_MarkUnread",
		Label: "Mark Unread",
		Icon: (<NotificationMarkUnreadIcon />),
	},
	Notification_Clear: {
		Id: "Notification_Clear",
		Label: "Clear",
		Icon: (<NotificationClearIcon />),
	},
	Tag_Delete: {
		Id: "Tag_Delete",
		Label: "Delete",
		Icon: (<RemoveIcon style={{color:red[500]}} />),
	},
};

export default ActionType;