import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import FilterIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import RemoveIcon from '@material-ui/icons/RemoveCircle';

import ActionType from '../Model/ActionType';
import OrganizationActionType from '../Model/OrganizationActionType';
import ProjectActionType from '../Model/ProjectActionType';
import FormTemplateActionType from '../Model/FormTemplateActionType';
import UserApiKeyActionType from '../Model/UserApiKeyActionType';
import UiCore from '../Components/UiCore';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import CollapsibleLeftPane from '../Components/CollapsibleLeftPane';
import DashboardWidget from '../Model/DashboardWidget';
import CaptureCore from '../Components/CaptureCore';
import FilterSortDrawer from '../Components/FilterSortDrawer';
import SetDocumentFolderDialog from '../Components/SetDocumentFolderDialog';
import FormShareDialog from '../Components/FormShareDialog';
import ActionDrawer from '../Components/ActionDrawer';
import TitleComponent from '../Components/TitleComponent';

import { IsMobile } from '../Util/MobileDetector';

import red from '@material-ui/core/colors/red';

import API, {
  GetOrganizationMembersPathForApi,
  GetProjectMembersPathForApi,
  GetDocumentContentPackagesPathForApi,
  GetDocumentContentPackagesPublicPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectDocumentSignatureSessionRecipientDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemDocumentContentPackagesPathForApi,
  GetUserApiKeysPathForApi,
  GetFormTemplatesPathForApi,
  GetTagsPathForApi,
} from '../Util/api';

import ItemListBase from '../Components/ItemListBase';
import KanbanBase from '../Components/KanbanBase';
import {
  GetIdSubPrefix, 
  GetCurrentViewType,
  GetNextViewType,
  HandleCycleViewType,
  HandleSetViewTypeAnchorEl,
  GetViewTypeMenuForDesktopTasks,
} from '../Util/ViewType';
import { NumberWithSeparators } from '../Util/NumberFormatting';
import { 
  GetCurrentSort,
  SaveSort,
} from '../Util/Sort';

import { GlobalContext } from '../Context/Global.context';
import { 
  GetExecuteMultipleDownloadsPromise,
  SendDocumentsToRecycleBin,
  RestoreDocumentsFromRecycleBin,
  SetDocumentFolderForDocuments,
  CreateDocumentSubscriptions,
  DeleteDocumentSubscriptions,
} from '../Util/Document';
import {
  ShareFormTemplates,
} from '../Util/FormTemplate';
import {
  // SortApprovals,
  ApproveApprovals,
  DeclineApprovals,
  DenyApprovals,
} from '../Util/Approvals';
import {
  MarkUserNotificationsRead,
  MarkUserNotificationsUnread,
  ClearUserNotifications,
} from '../Util/UserNotifications';
import { 
  DeleteFields,
} from '../Util/Fields';
import { 
  HandleUpdateAssets,
} from '../Util/Assets';
import { 
  HandleUpdateAssetItems,
} from '../Util/AssetItems';
import {
  DeleteTaskMilestones,
} from '../Util/TaskMilestones';
import {
  DeleteTaskStates,
} from '../Util/TaskStates';
import {
  RemoveTaskDocuments,
} from '../Util/TaskDocuments';
import {
  RemoveAssetItemDocuments,
} from '../Util/AssetItemDocuments';
import {
  RemoveAssetItemTasks,
} from '../Util/AssetItemTasks';
import {
  RemoveAssetItemAssetItems,
} from '../Util/AssetItemAssetItems';
import {
  GetSensitiveFields,
} from '../Util/Documents';
import {
  GetSavedFilterState,
  GetFilterFieldsAsMetaFieldFilters,
  CompareCollectionField,
} from '../Util/Filters';
import {
  GetContentTypeLabel,
  CleanSearchTerms,
} from '../Util/SearchResults';
import {
  CompleteTasks,
  AssignTasks,
  RestoreTasks,
  DenyTasks,
  CreateTaskSubscriptions,
  DeleteTaskSubscriptions,
} from '../Util/Tasks';
import {
  GetTaskAssignmentDialogDetails,
} from '../Util/Task'

import debounce from 'es6-promise-debounce';

const styles = theme => ({
  content: {
    height: "100%",
    width: "100%",
    display:"flex",
  },
  contentRight: {
    flexGrow:1,
    overflowX: "auto",
    height:"100%",
  },
  contentRightContent: {
    display:"flex",
    height:"100%",
    overflowY: "auto",
    overflowX:"hidden",
  },
  itemListContainer: {
    width:"100%",
    overflow:"auto",
  },
  infoGrid: {
    marginRight:theme.spacing(1),
    "& div": {
      whiteSpace: "nowrap",
    },
  },
  toolHeader: {
    borderBottom:"1px solid",
    borderBottomColor:theme.palette.divider,
    backgroundColor:theme.palette.background.toolBar,
    display:"flex",
    alignItems: "center",
    justifyContent:"flex-end",
    // paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(2),
    paddingLeft:2,
    transition:"padding 200ms",
    [theme.breakpoints.down('xs')]: {
      // paddingLeft:theme.spacing(2),
      paddingRight:theme.spacing(1),
    },
  },
  toolHeaderTitle: {
    paddingLeft: theme.spacing(3),
  },
  toolHeaderLeft: {
    flexGrow:1,
    whiteSpace:"nowrap",
    display:"flex",
    alignItems:"center",
    zIndex:3,
  },
  toolHeaderRight: {
    display:"flex",
    alignItems:"center",
  },
  breadcrumbNoSelect: {
    fontFamily: "inherit",
    fontSize: "inherit",
    margin: theme.spacing(1),
    lineHeight: 1.75,
    padding: "6px 8px",
    userSelect: "none",
    // textTransform: "uppercase",
  },
  breadcrumbButton: {
    fontFamily: "inherit",
    fontSize: "inherit",
    margin: theme.spacing(1),
    minWidth: "inherit",
    letterSpacing: "inherit",
    textTransform: "inherit",
  },
  breadcrumbArrowForward: {
    fill: "#888",
    fontSize:14,
  },
  cardGridItem: {
    width: "100%",
  },
  emptyContainer: {
    display:"flex",
    justifyContent: "center",
    marginTop:150,
  },
  tableCell_FirstCell: {
    paddingLeft:15,
    paddingRight:20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft:7,
    },
  },
  fab: {
    position: "fixed",
    zIndex: 1,
    right: theme.spacing(3),
    bottom: theme.spacing(2),
  },
  filterButtonContainer: {
    position:"relative",
  },
  activeFilterIndicator: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:12,
    right:12,
    zIndex:1,
  },
});

class ItemCollectionBase extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
  
    const {
      collectionName,
      onConnectRefreshItemsFunction,
      onConnectOnActionFunction,
      onCollectionNameSet,
      loadItemsImmediately,
      isPublicApi,
      initialViewTypeForPublicApi,
    } = this.props;

    this.state = {
      Items: null,
      ItemsCollectionName: null,
      SelectedItems: [],
      Cursor: "",
      ShowGetMoreButton: false,
      
      CollectionFields: [],
      SortType: null,
      SortDescending: false,
      SortMenuAnchor: null,
      ViewType: (isPublicApi) ? initialViewTypeForPublicApi || "" : "",
      ShowAddItemDialog: false,
      MetaFieldFilters: [],
      FullTextFilter: "",
      UserFiltersAreActive: false,
      GetMoreButtonLeftMargin: null,
      
      LeftPaneIsVisible: false,
      ForceHideLeftPane: false,
      ForceShowLeftPane: false,
      PostActionData: {},
      SensitiveFields: null,
      ShowCreateDashboardWidgetDialog: false,
      ShowTaskAssignmentDialog: false,
      ShowFilterAndSortDrawer: this.props.showFilterAndSortDrawerOnLoad || false,
      ForceResetFilterAndSortDrawer: false,
      SelectedTaskAssignmentOption: {},
      SelectedProject: {value:"AllProjects",label:"All Projects"},
      ActionConfirmationDialogDetails: { Open: false },
      ShowDocumentFolderDialog: false,
      ShowFormShareDialog: false,
      SelectedFormTemplates: [],
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    }

    this.ChangeViewRef = React.createRef();

    // These are connected after initial document loading to prevent double loading in areas like power search instant results
    if (onConnectRefreshItemsFunction) {
      onConnectRefreshItemsFunction(this.handleRefresh);
    }
    if (onConnectOnActionFunction) {
      onConnectOnActionFunction(this.handleAction);
    }
    if (onCollectionNameSet && collectionName) {
      onCollectionNameSet(collectionName);
    }

    this.ReloadItemsCheckIntervalID = null;
    this.AutoRefreshIntervalID = null;
    this.AutoRefreshIntervalMS = 15000;
    this.LastQueryDate = null;

    this.LastQueryUri = "";
    this.LastQueryParams = {};

    this.KanbanColumnRefreshFunc = null;

    this.WindowIsFocused = true;

    if (loadItemsImmediately) {
      this.handleLoadItems(true, true, undefined, undefined, props.fullTextFilter);
    }
  }

  handleSaveCurrentStateToHistory(sortType, sortDescending) {
    // If returnContentOnly requested, do NOT replace history
    if (this.props.returnContentOnly) {
      return;
    }
    // let stateToSave = {
    //   SortType: (sortType !== null) ? sortType : this.state.SortType,
    //   SortDescending: (sortDescending !== null) ? sortDescending : this.state.SortDescending,
    // };
    // this.props.history.replace(this.props.location.pathname, { ...this.props.location.state, ...stateToSave });
  }

  selectedItemsChanged = selectedItems => {
    if (this.props.onSelectedItemsChanged) {
      this.props.onSelectedItemsChanged(selectedItems);
    }
  }

  itemsChanged = items => {
    if (this.props.onItemsChanged
      && this.props.collectionName === this.state.ItemsCollectionName) {
      this.props.onItemsChanged(items);
    }
  }

  getCollectionFields = () => {
    return this.props.onGetCollectionFieldsPromise()
      .then(CollectionFields => {
        if (CollectionFields) {
          CollectionFields = CollectionFields.sort(CompareCollectionField);
          this.setState({CollectionFields});
          return CollectionFields;
        }
        return [];
      });
  }

  handleLoadItems = async (isCollectionChange, reset, sortType, sortDescending, userFullTextFilter, userMetaFieldFilters) => {
    // Use this opportunity to check for new notifications
    if (this.context
      && this.context.UserPreferences 
      && this.context.UserPreferences.ActiveOrganizationID) {
      this.context.GetUserHasUnreadNotification(this.context.UserPreferences.ActiveOrganizationID);
    }

    // this.handleSaveCurrentStateToHistory(sortType, sortDescending);
    let {
      contentUri,
      contentUriParams,
      contentUriMetaFieldFilters,
      collectionName,
      itemsName,
      defaultViewType,
      includeItemIds,
      initialSortTypeForPublicApi,
    } = this.props;
    let {
      SortType,
      CollectionFields,
      SortDescending,
      FullTextFilter,
      MetaFieldFilters,
    } = this.state;

    if (!(contentUri && collectionName)) {
      return;
    }

    if (reset) {
      this.stopAutoRefresh();
      if (isCollectionChange) {
        CollectionFields = await this.getCollectionFields();
      }
      const filterState = (!this.props.skipFilterSavedState)
        ? GetSavedFilterState(this.props, CollectionFields)
        : {};
      if (typeof userFullTextFilter === "string") {
        FullTextFilter = userFullTextFilter;
      } else if (filterState.FullTextFilter) {
        FullTextFilter = filterState.FullTextFilter;
      } // otherwise, leave FullTextFilter untouched
      // Combine user-based and collection-based MetaFieldFilters.
      MetaFieldFilters = [];
      if (!Array.isArray(userMetaFieldFilters) && filterState.Fields) {
        userMetaFieldFilters = MetaFieldFilters.concat(GetFilterFieldsAsMetaFieldFilters(
          filterState.Fields, filterState.SecondaryFields));
      }
      MetaFieldFilters = MetaFieldFilters.concat(userMetaFieldFilters);
      if (Array.isArray(contentUriMetaFieldFilters)) {
        MetaFieldFilters = MetaFieldFilters.concat(contentUriMetaFieldFilters);
      }
      this.setState({
        FullTextFilter,
        MetaFieldFilters,
        UserFiltersAreActive: this.getUserFiltersAreActive(FullTextFilter, userMetaFieldFilters || []),
      });
    }

    contentUriParams = contentUriParams || {};
    
    if (reset) {
      if (isCollectionChange) {
        if (this.props.isPublicApi) {
          if (initialSortTypeForPublicApi) {
            SortType = this.getValidSortType(initialSortTypeForPublicApi, CollectionFields);
          }
        } else {
          GetCurrentViewType(collectionName, 
            GetIdSubPrefix(collectionName, IsMobile(), this.props.isWorkspace),
            ViewType => this.setState({ViewType}), defaultViewType, this.handleApiError)
            .then(viewType => {
              this.handleViewTypeChanged(viewType);
            });

          await GetCurrentSort(collectionName)
            .then(initialSort => {
              SortType = (initialSort) ? this.getValidSortType(initialSort.SortType, CollectionFields) : null;
              SortDescending = (initialSort) ? initialSort.SortDescending : false;
              this.setState({ SortType, SortDescending });
            })
            .catch(this.handleApiError);
        }
      }
    }

    let params = {
      ...contentUriParams,
      cursor: (!reset) ? this.state.Cursor : "",
      timezoneOffsetMinutes: (new Date()).getTimezoneOffset(),
    };

    // Ensure outgoing SortType is valid against CollectionFields
    if (sortType === null) {
      SortType = null;
    } else {
      SortType = this.getValidSortType(sortType || SortType, CollectionFields);
    }
    if (!this.props.noSorting) {
      params.sortType = SortType;
      params.sortDescending = (typeof sortDescending === "boolean") ? sortDescending : SortDescending;
    }
    params.fullTextFilter = FullTextFilter;
    params.metaFieldFilters_json = JSON.stringify(MetaFieldFilters);

    if (includeItemIds) {
      params.includeItemIds_json = JSON.stringify(includeItemIds);
    }

    this.setState({ ShowProgressIndicatorImmediately: true });

    // Save contentUri and params for auto-refresh
    this.LastQueryUri = contentUri;
    this.LastQueryParams = params;
    
    this.LastQueryDate = new Date();
    API.get(contentUri, { params })
      .then(resp => {
        let contentList = resp.data;

        if (!contentList[itemsName]) {
          console.log("ItemsName is incorrect", contentList);
        }

        let Items = [];
        const RefreshID = new Date();
        if (reset) {
          Items = contentList[itemsName].map(newItem => {
            return { ...newItem, RefreshID, };
          });
        } else {
          Items = [...this.state.Items];
          contentList[itemsName].forEach(newItem => {
            const existingItemFinder = Items.filter(item => item.ID === newItem.ID);
            if (existingItemFinder.length) {
              Items.splice(Items.indexOf(existingItemFinder[0]), 1);
            }
            Items.push({...newItem, RefreshID});
          });
        }
        
        let stateToUpdate = {
          Items,
          ItemsCollectionName: collectionName,
          ShowGetMoreButton: contentList[itemsName].length >= contentList.PageSize,
          Cursor: contentList.Cursor,
          ShowProgressIndicatorImmediately: false,
        };
        if (reset) {
          stateToUpdate.SelectedItems = [];
          this.selectedItemsChanged([]);
        }
        this.setState(stateToUpdate);
        this.itemsChanged(Items);

        if (reset) {
          this.doAutoRefresh("handleLoadItems");
        }
      })
      .catch(this.handleApiError);
  }

  handleRefresh = forceIsCollectionChange => {
    this.handleLoadItems(forceIsCollectionChange || false, true, undefined, undefined, this.props.fullTextFilter);
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
    this.handleRefreshKanbanColumns();
    if (this.props.skipFilterSavedState) {
      this.setState({ForceResetFilterAndSortDrawer:true});
      setTimeout(() => this.setState({ForceResetFilterAndSortDrawer:false}), 1);
    }
  }

  handleRefreshKanbanColumns = () => {
    if (this.KanbanColumnRefreshFunc) {
      this.KanbanColumnRefreshFunc();
    }
  }

  doAutoRefresh = source => {
    // console.log("doAutoRefresh", source);

    // AutoRefresh only for listed cases below
    switch (this.props.collectionName) {
      case "Tasks":
      case "CompletedTasks":
      case "DeniedTasks":
        break;
      default:
        return;
    }

    const isItemDragging = () => this.state.Items && [...this.state.Items].filter(i => i.IsDragging).length > 0;
    if (this.WindowIsFocused
      && !isItemDragging()
      && this.AutoRefreshIntervalID
      && this.LastQueryUri
      && this.LastQueryDate
    ) {
      const createdOnOrModifiedOnNewerThan = new Date(this.LastQueryDate.getTime() - this.AutoRefreshIntervalMS);
      const params = {
        ...this.LastQueryParams,
        createdOnOrModifiedOnNewerThan,
      }
      switch (this.state.ItemsCollectionName) {
        case "Tasks":
          params.activeOnly = false;
          if (this.state.ViewType.startsWith("Kanban")) {
            this.handleRefreshKanbanColumns();
          }
          break;
        case "CompletedTasks":
        case "DeniedTasks":
          params.activeOnly = false;
          params.completedOnly = false;
          params.deniedOnly = false;
          break;
        default:
          break;
      }
      this.LastQueryDate = new Date();
      API.get(this.LastQueryUri, { params })
      .then(resp => {
        // Abort if an item started dragging while we were calling server
        if (isItemDragging()) {
          // console.log("aborting");
          return;
        }
        let contentList = resp.data;
        if (contentList) {
          const changedItems = contentList[this.props.itemsName];
          // console.log("doAutoRefresh results", changedItems);
          if (changedItems && changedItems.length) {

            let Items = [...this.state.Items];
            switch (this.state.ItemsCollectionName) {
              case "Tasks":
                // Remove completed/denied items
                const hiddenItems = changedItems.filter(i => i.Result !== "");
                if (hiddenItems.length) {
                  hiddenItems.forEach(hi => {
                    Items = Items.filter(i => i.ID !== hi.ID);
                  });
                }
                // Add new items & Update existing items
                changedItems.forEach(ci => {
                  // Ignore completed/denied items
                  const hiddenItemFinder = hiddenItems.filter(hi => hi.ID === ci.ID);
                  if (hiddenItemFinder.length) {
                    return;
                  }
                  const existingItemFinder = Items.filter(i => i.ID === ci.ID);
                  if (existingItemFinder.length) {
                    Items.splice(Items.indexOf(existingItemFinder[0]), 1);
                  }
                  Items = [ci, ...Items];
                });
                break;
              case "CompletedTasks":
              case "DeniedTasks":
                // Remove active items
                const activeItems = changedItems.filter(i => i.Result === "");
                if (activeItems.length) {
                  activeItems.forEach(ai => {
                    Items = Items.filter(i => i.ID !== ai.ID);
                  });
                }
                // Add new items & Update existing items
                changedItems.forEach(ci => {
                  // Ignore active items
                  const activeItemFinder = activeItems.filter(ai => ai.ID === ci.ID);
                  if (activeItemFinder.length) {
                    return;
                  }
                  const existingItemFinder = Items.filter(i => i.ID === ci.ID);
                  if (existingItemFinder.length) {
                    Items.splice(Items.indexOf(existingItemFinder[0]), 1);
                  }
                  Items = [ci, ...Items];
                });
                break;
              default:
                break;
            }
            this.setState({Items});
          }
        }
      })
      .catch(err => {
        // Do nothing - there will be another cycle
      });
    }
    this.AutoRefreshIntervalID = setTimeout(() => this.doAutoRefresh("self"), this.AutoRefreshIntervalMS);
  }

  stopAutoRefresh = () => {
    if (this.AutoRefreshIntervalID) {
      clearTimeout(this.AutoRefreshIntervalID);
      this.AutoRefreshIntervalID = null;
    }
  }

  handleResetAutoRefresh = () => {
    this.stopAutoRefresh();
    this.doAutoRefresh("handleResetAutoRefresh");
  }

  handleSortMenuClose() {
    this.setState({ SortMenuAnchor: null });
  };

  handleSortChange = (SortType, SortDescending) => {
    if (SortType === undefined) {
      SortType = this.state.SortType;
    }
    if (typeof SortDescending !== "boolean") {
      SortDescending = this.state.SortDescending;
    }
    this.handleSortMenuClose();
    this.setState({SortType, SortDescending});
    this.handleLoadItems(false, true, SortType, SortDescending);
    if (!this.props.isPublicApi) {
      SaveSort(this.props.collectionName, null, SortType, SortDescending);
    }
  }

  handleFlipSortDirection = () => {
    this.handleSortMenuClose();
    let sortDescending = !this.state.SortDescending;
    this.handleSortChange(this.state.SortType, sortDescending);
  }

  handleSortChangeFromTableHeader = sortType => {
    // Flip sort direction if same sortType selected
    if (this.state.SortType && this.state.SortType.indexOf(sortType) > -1) {
      this.handleFlipSortDirection();
    } else {
      this.handleSortChange(this.getValidSortType(sortType), false);
    }
  }

  getValidSortType = (sortType, collectionFields) => {
    if (!collectionFields || !collectionFields.length) {
      collectionFields = (this.state && this.state.CollectionFields)
        ? [...this.state.CollectionFields]
        : [];
    }
    let sortTypeFinder = collectionFields.filter(st => st.ID.indexOf(sortType) > -1);    
    if (sortTypeFinder.length) {
      return sortTypeFinder[0].ID;
    }
    return null;
  }

  getUserFiltersAreActive = (fullTextFilter, metaFieldFilters) => {
    return fullTextFilter
      || metaFieldFilters.filter(f => f.FieldValue).length
      || metaFieldFilters.filter(f => f.FieldValues && f.FieldValues.length).length;
  }

  handleFiltersChanged = debounce((FullTextFilter, MetaFieldFilters) => {
    this.setState({
      FullTextFilter,
      MetaFieldFilters,
      UserFiltersAreActive: this.getUserFiltersAreActive(FullTextFilter, MetaFieldFilters),
    });
    this.handleLoadItems(false, true, undefined, undefined, FullTextFilter, MetaFieldFilters);
  }, 250);

  handleSetAddItemDialogVisibility(show) {
    this.setState({ 
      ShowAddItemDialog: show,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleAddItem = name => {
    if (!name && !this.props.addItemDialogIsConfirmation) {
      // Closes the dialog
      this.handleSetAddItemDialogVisibility(false);
      return;
    }
    if (!this.props.onAddItemPromise) {
      return;
    }
    this.setState({ShowDialogProgressIndicatorImmediately:true});
    this.props.onAddItemPromise(name)
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowDialogProgressIndicatorImmediately:false});
        this.handleSetAddItemDialogVisibility(false);
      });
  }

  handleForcePrependItems = items => {
    let newItems = [];
    items.forEach(i => {
      const existingItemFinder = this.state.Items.filter(ei => ei.ID === i.ID);
      if (!existingItemFinder.length) {
        newItems.push(i);
      }
    });
    if (newItems.length) {
      const Items = [...newItems, ...this.state.Items];
      this.setState({ Items });
      this.itemsChanged(Items)
    }
  }

  handleSelect = id => {
    let SelectedItems = [...this.state.SelectedItems];
    const selectedIndex = SelectedItems.map(i => i.ID).indexOf(id);
    if (selectedIndex === -1) {
      const itemFinder = [...this.state.Items].filter(i => i.ID === id);
      if (itemFinder.length) {
        SelectedItems.push(itemFinder[0]);
      }
    } else if (selectedIndex > -1) {
      SelectedItems.splice(selectedIndex, 1);
    }
    this.setState({ SelectedItems });
    this.selectedItemsChanged(SelectedItems);
  }

  handleSelectAll = e => {
    let SelectedItems = [...this.state.SelectedItems];
    let items = [...this.state.Items];
    if (!e.target.checked) {
      SelectedItems = [];
    } else {
      if (this.props.canSelectItem) {
        items = items.filter(i => this.props.canSelectItem(i));
      }
      SelectedItems = items;
    }
    this.setState({ SelectedItems });
    this.selectedItemsChanged(SelectedItems);
  }

  handleAction = (actionType, id_optional, silentProgress, params, selectedItems) => {
    if (id_optional) {
      selectedItems = [...this.state.Items].filter(i => i.ID === id_optional)
    }
    if (!selectedItems || !selectedItems.length) {
      selectedItems = [...this.state.SelectedItems];
    }
    if (selectedItems.length === 0) {
      return Promise.resolve();
    }
    const {
      organizationId,
      projectId,
    } = this.props;
    if (!silentProgress) {
      this.setState({ShowProgressIndicatorImmediately: true});
    }
    
    let isProjectMember = false;
    let isProjectAdmin = false;
    let documentsAccessedByAssignment = false;
    if (this.context && this.context.ProjectMembershipPackages) {
      const projectMemberPkgFinder = this.context.ProjectMembershipPackages.filter(p => 
        p.Project.ID === projectId
          || (
            selectedItems.length === 1 // This won't work when multiple search results can be selected for a multi-download action
            && selectedItems[0].ProjectID
            && p.Project.ID === selectedItems[0].ProjectID
          )
        );
      isProjectMember = projectMemberPkgFinder.length > 0;
      isProjectAdmin = isProjectMember && projectMemberPkgFinder[0].IsAdmin;
      documentsAccessedByAssignment = isProjectMember
        && (projectMemberPkgFinder[0].Project.Access.MemberAccess.DocumentFolders === "Assigned")
        && !isProjectAdmin;
    }

    let actionFinalizer = (PostActionData, refreshItems) => {
      let stateToUpdate = { PostActionData };
      if (!silentProgress && !refreshItems) {
        stateToUpdate.ShowProgressIndicatorImmediately = false;
      }
      this.setState(stateToUpdate);
      if (refreshItems) {
        this.handleRefresh();
      }
      if (this.props.onActionCompleted) {
        this.props.onActionCompleted(actionType);
      }
    }
    switch (actionType) {
      case ActionType.Approval_Approve:
        ApproveApprovals(selectedItems, isProjectMember)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
      case ActionType.Approval_Decline:
        DeclineApprovals(selectedItems, isProjectMember)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Approval_Deny:
        DenyApprovals(selectedItems)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Task_Assign:
        if (!this.state.SelectedTaskAssignmentOption || !this.state.SelectedTaskAssignmentOption.value) {
          this.setState({
            ShowTaskAssignmentDialog: true,
            ShowProgressIndicatorImmediately: false,
          });
        } else {
          AssignTasks(selectedItems, this.state.SelectedTaskAssignmentOption.value,
            this.state.SelectedTaskAssignmentOption.assignmentUserName)
          .then(() => {
            this.setState({
              ShowTaskAssignmentDialog: false,
              SelectedTaskAssignmentOption: null,
            });
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
        }
      break;
    case ActionType.Task_Complete:
        CompleteTasks(selectedItems, isProjectMember)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Task_Restore:
        RestoreTasks(selectedItems)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Task_Deny:
        DenyTasks(selectedItems)
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.DocumentSubscription_Create:
        return CreateDocumentSubscriptions(this.getBasicDocumentsFromItems(selectedItems))
          .then(DocumentSubscriptions => {
            actionFinalizer({ DocumentSubscriptions });
            return DocumentSubscriptions;
          })
          .catch(this.handleApiError);
    case ActionType.DocumentSubscription_Delete:
        return DeleteDocumentSubscriptions(this.getBasicDocumentsFromItems(selectedItems))
          .then(DocumentSubscriptions => {
            actionFinalizer({ DocumentSubscriptions }, this.props.collectionName === "FollowedDocuments");
            return DocumentSubscriptions;
          })
          .catch(this.handleApiError);
    case ActionType.TaskSubscription_Create:
        return CreateTaskSubscriptions(this.getBasicTasksFromItems(selectedItems))
          .then(TaskSubscriptions => {
            actionFinalizer({ TaskSubscriptions });
            return TaskSubscriptions;
          })
          .catch(this.handleApiError);
    case ActionType.TaskSubscription_Delete:
        return DeleteTaskSubscriptions(this.getBasicTasksFromItems(selectedItems))
          .then(TaskSubscriptions => {
            actionFinalizer({ TaskSubscriptions }, this.props.collectionName === "FollowedTasks");
            return TaskSubscriptions;
          })
          .catch(this.handleApiError);
    case ActionType.Field_Delete:
        this.showActionConfirmation(
          `Delete field${(selectedItems.length > 1) ? "s" : ""}?`,
          "This action cannot be undone.",
          {BodyClassName:"warning"},
          () => {
            DeleteFields(organizationId, projectId, selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.Asset_Archive:
        const archivedAssets = selectedItems.map(i => {
          i.Archived = true;
          return i;
        });
        HandleUpdateAssets(organizationId, projectId, archivedAssets, this.handleApiError)
          .then(resp => {
            actionFinalizer(undefined, true);
          });
      break;
    case ActionType.Asset_Restore:
        const restoredAssets = selectedItems.map(i => {
          i.Archived = false;
          return i;
        });
        HandleUpdateAssets(organizationId, projectId, restoredAssets, this.handleApiError)
          .then(resp => {
            actionFinalizer(undefined, true);
          });
      break;
    case ActionType.AssetItem_Archive:
        const archivedAssetItems = selectedItems.map(i => {
          i.Archived = true;
          return i;
        });
        if (archivedAssetItems.length) {
          HandleUpdateAssetItems(organizationId, projectId, archivedAssetItems[0].AssetID,
            archivedAssetItems, this.handleApiError)
            .then(resp => {
              actionFinalizer(undefined, true);
            });
        }
      break;
    case ActionType.AssetItem_Restore:
        const restoredAssetItems = selectedItems.map(i => {
          i.Archived = false;
          return i;
        });
        if (restoredAssetItems.length) {
          HandleUpdateAssetItems(organizationId, projectId, restoredAssetItems[0].AssetID,
            restoredAssetItems, this.handleApiError)
            .then(resp => {
              actionFinalizer(undefined, true);
            });
        }
      break;
    case ActionType.TaskMilestone_Delete:
        this.showActionConfirmation(
          `Delete task milestone${(selectedItems.length > 1) ? "s" : ""}?`,
          "This action cannot be undone.",
          {BodyClassName:"warning"},
          () => {
            DeleteTaskMilestones(organizationId, projectId, selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.TaskState_Delete:
        this.showActionConfirmation(
          `Delete task state${(selectedItems.length > 1) ? "s" : ""}?`,
          "This action cannot be undone.",
          {BodyClassName:"warning"},
          () => {
            DeleteTaskStates(organizationId, projectId, selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.TaskDocument_Remove:
        this.showActionConfirmation(
          `Remove document${(selectedItems.length > 1) ? "s" : ""} from task?`,
          "",
          {},
          () => {
            RemoveTaskDocuments(organizationId, projectId, selectedItems[0].TaskID, selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.AssetItemDocument_Remove:
        this.showActionConfirmation(
          `Remove ${this.props.assetItemForAssetItemContext.Name
            } (${this.props.assetItemForAssetItemContext.AssetName
            }) tag from selected document${(selectedItems.length > 1) ? "s" : ""}?`,
          "",
          {},
          () => {
            RemoveAssetItemDocuments(organizationId, projectId, 
              this.props.assetItemForAssetItemContext.AssetID,
              this.props.assetItemForAssetItemContext.ID,
              selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.AssetItemTask_Remove:
        this.showActionConfirmation(
          `Remove ${this.props.assetItemForAssetItemContext.Name
            } (${this.props.assetItemForAssetItemContext.AssetName
            }) tag from selected task${(selectedItems.length > 1) ? "s" : ""}?`,
          "",
          {},
          () => {
            RemoveAssetItemTasks(organizationId, projectId, 
              this.props.assetItemForAssetItemContext.AssetID,
              this.props.assetItemForAssetItemContext.ID,
              selectedItems.map(i => i.ID))
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.AssetItemAssetItem_Remove:
        this.showActionConfirmation(
          `Remove ${this.props.assetItemForAssetItemContext.Name
            } (${this.props.assetItemForAssetItemContext.AssetName
            }) tag from selected asset${(selectedItems.length > 1) ? "s" : ""}?`,
          "",
          {},
          () => {
            RemoveAssetItemAssetItems(organizationId, projectId, 
              this.props.assetItemForAssetItemContext.AssetID,
              this.props.assetItemForAssetItemContext.ID,
              selectedItems)
              .then(resp => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    case ActionType.Download:
        let uris = [];
        if (this.props.isPublicApi) {
          this.getBasicDocumentsFromItems(selectedItems)
            .forEach(d => {
              if (d.Origin !== "Editor") {
                uris.push(
                  GetDocumentContentPackagesPublicPathForApi(organizationId, projectId, d.ID)
                );
              }
            });
        } else if (!isProjectMember || documentsAccessedByAssignment) {
          switch (this.state.ItemsCollectionName) {
            case "Tasks":
            case "CompletedTasks":
            case "DeniedTasks":
              selectedItems.forEach(i => {
                if (i.PrimaryDocument.Document && i.PrimaryDocument.Document.Origin !== "Editor") {
                  uris.push(GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi(
                    i.OrganizationID, i.ProjectID, i.ID, i.PrimaryDocument.DocumentID));
                }
              });
              break;
            case "TaskDocuments":
              selectedItems.forEach(i => {
                if (i && i.Origin !== "Editor") {
                  if (params && params.loadByApprovalAssetItemTaskAssignment && params.approvalId
                    && params.assetId && params.assetItemId) {
                    uris.push(GetUserOrganizationProjectApprovalAssetItemTaskDocumentContentPackagesPathForApi(
                        i.OrganizationID, i.ProjectID, params.approvalId, params.assetId, 
                        params.assetItemId, i.TaskID, i.DocumentID));
                  } else if (params && params.loadByApprovalTaskAssignment && params.approvalId) {
                    uris.push(GetUserOrganizationProjectApprovalTaskDocumentContentPackagesPathForApi(
                        i.OrganizationID, i.ProjectID, params.approvalId, i.TaskID, i.DocumentID));
                  } else {
                    uris.push(GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi(
                      i.OrganizationID, i.ProjectID, i.TaskID, i.DocumentID));
                  }
                }
              });
              break;
            case "AssetItemDocuments":
              selectedItems.forEach(i => {
                if (i && i.Origin !== "Editor") {
                  if (params && params.loadByApprovalAssignment && params.approvalId) {
                    uris.push(GetUserOrganizationProjectApprovalAssetItemDocumentContentPackagesPathForApi(
                      i.OrganizationID, i.ProjectID, params.approvalId, i.TaskID, i.DocumentID));
                  }
                }
              });
              break;
            case "NeedSignatureDocuments":
              selectedItems.forEach(i => {
                if (i.DocumentOrigin !== "Editor") {
                  uris.push(GetUserOrganizationProjectDocumentSignatureSessionRecipientDocumentContentPackagesPathForApi(
                    i.OrganizationID, i.ProjectID, i.ID, i.DocumentSignatureSessionID, i.DocumentID));
                }
              });
              break;
            case "Documents":
            case "SearchResults":
              this.getBasicDocumentsFromItems(selectedItems)
                .forEach(d => {
                  if (d.Origin !== "Editor") {
                    uris.push(GetUserOrganizationProjectDocumentFolderDocumentContentPackagesPathForApi(
                      d.OrganizationID, d.ProjectID, d.DocumentFolderID, d.ID));
                  }
                });
              break;
            default:
              break;
          }
        } else {
          this.getBasicDocumentsFromItems(selectedItems)
            .forEach(d => {
              if (d.Origin !== "Editor") {
                uris.push(GetDocumentContentPackagesPathForApi(d.OrganizationID, d.ProjectID, d.ID));
              }
            });
        }
        GetExecuteMultipleDownloadsPromise(uris)
          .then(() => {
            actionFinalizer();
          })
          .catch(this.handleApiError);
      break;
    case ActionType.SendToRecycleBin:
        SendDocumentsToRecycleBin(this.getBasicDocumentsFromItems(selectedItems))
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.RestoreFromRecycleBin:
        RestoreDocumentsFromRecycleBin(this.getBasicDocumentsFromItems(selectedItems))
          .then(() => {
            actionFinalizer(undefined, true);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.SetDocumentFolder:
        if (!params || typeof params.documentFolderId !== "string") {
          this.handleSetShowDocumentFolderDialog(true, false);
        } else {
          this.handleSetShowDocumentFolderDialog(false);
          SetDocumentFolderForDocuments(this.getBasicDocumentsFromItems(selectedItems), params.documentFolderId)
            .then(resp => {
              actionFinalizer(undefined, true);
            })
            .catch(this.handleApiError);
        }
      break;
    case ActionType.ShareForm:
        if (!params || !params.recipientEmails || !params.recipientEmails.length) {
          this.handleSetShowFormShareDialog(true, false, selectedItems);
        } else {
          this.handleSetShowFormShareDialog(false);
          ShareFormTemplates(
            selectedItems,
            params.recipientEmails,
            params.tags,
            params.assetItemTags,
          )
            .then(resp => {
              actionFinalizer(undefined, true);
            })
            .catch(this.handleApiError);
        }
      break;
    case OrganizationActionType.OrganizationMember_Delete:
        if (organizationId) {
          this.showActionConfirmation(
            `Delete member${(selectedItems.length > 1) ? "s" : ""}?`,
            "This action cannot be undone.",
            {BodyClassName:"warning"},
            () => {
              API.delete(GetOrganizationMembersPathForApi(organizationId),
                { data: { IDs: selectedItems.map(om => om.ID) } })
                .then(() => {
                  this.context.Reset();
                  actionFinalizer(undefined, true);
                })
                .catch(this.handleApiError);
            },
            () => actionFinalizer(undefined, false),
          );
        }
      break;
    case ProjectActionType.ProjectMember_Delete:
        if (organizationId && projectId) {
          this.showActionConfirmation(
            `Delete member${(selectedItems.length > 1) ? "s" : ""}?`,
            "This action cannot be undone.",
            {BodyClassName:"warning"},
            () => {
              API.delete(GetProjectMembersPathForApi(organizationId, projectId),
                { data: { IDs: selectedItems.map(pm => pm.ID) } })
                .then(() => {
                  this.context.Reset();
                  actionFinalizer(undefined, true);
                })
                .catch(this.handleApiError);
            },
            () => actionFinalizer(undefined, false),
          );
        }
      break;
    case FormTemplateActionType.FormTemplate_Delete:
        if (organizationId && projectId) {
          this.showActionConfirmation(
            `Delete form template${(selectedItems.length > 1) ? "s" : ""}?`,
            "This action cannot be undone.",
            {BodyClassName:"warning"},
            () => {
              API.delete(GetFormTemplatesPathForApi(organizationId, projectId),
                { data: { IDs: selectedItems.map(ft => ft.ID) } })
                .then(() => {
                  actionFinalizer(undefined, true);
                })
                .catch(this.handleApiError);
            },
            () => actionFinalizer(undefined, false),
          );
        }
      break;
    case ActionType.Tag_Delete:
        if (organizationId && projectId) {
          this.showActionConfirmation(
            `Delete tag${(selectedItems.length > 1) ? "s" : ""}?`,
            "This action cannot be undone. Tagged items will remain unchanged.",
            {BodyClassName:"warning"},
            () => {
              API.delete(GetTagsPathForApi(organizationId, projectId),
                { data: { IDs: selectedItems.map(t => t.ID) } })
                .then(() => {
                  actionFinalizer(undefined, true);
                })
                .catch(this.handleApiError);
            },
            () => actionFinalizer(undefined, false),
          );
        }
      break;
    case ActionType.Notification_MarkRead:
        MarkUserNotificationsRead(this.context, selectedItems, organizationId)
          .then(resp => {
            if (resp.data && resp.data.length) {
              let Items = [...this.state.Items];
              resp.data.forEach(n => {
                const itemFinder = Items.filter(i => i.ID === n.ID);
                if (itemFinder.length) {
                  itemFinder[0].IsRead = n.IsRead;
                }
              });
              this.setState({Items});
              this.itemsChanged(Items);
            }
            actionFinalizer(undefined, false);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Notification_MarkUnread:
        MarkUserNotificationsUnread(this.context, selectedItems, organizationId)
          .then(resp => {
            if (resp.data && resp.data.length) {
              let Items = [...this.state.Items];
              resp.data.forEach(n => {
                const itemFinder = Items.filter(i => i.ID === n.ID);
                if (itemFinder.length) {
                  itemFinder[0].IsRead = n.IsRead;
                }
              });
              this.setState({Items});
              this.itemsChanged(Items);
            }
            actionFinalizer(undefined, false);
          })
          .catch(this.handleApiError);
      break;
    case ActionType.Notification_Clear:
        ClearUserNotifications(this.context, selectedItems, organizationId)
          .then(() => {
            let Items = [];
            if (this.state.Items && this.state.Items.length) {
              this.state.Items.forEach(n => {
                const itemFinder = selectedItems.filter(i => i.ID === n.ID);
                if (!itemFinder.length) {
                  Items.push(n);
                }
              });
            }
            this.setState({ Items, SelectedItems: [], });
            this.itemsChanged(Items);
            this.selectedItemsChanged([]);
            actionFinalizer(undefined, !Items.length);
          })
          .catch(this.handleApiError);
      break;
    case UserApiKeyActionType.UserApiKey_Delete:
        this.showActionConfirmation(
          `Delete key${(selectedItems.length > 1) ? "s" : ""}?`,
          "This action cannot be undone. API calls referencing deleted keys will stop working.",
          {BodyClassName:"warning"},
          () => {
            API.delete(GetUserApiKeysPathForApi(),
              { data: { IDs: selectedItems.map(pm => pm.ID) } })
              .then(() => {
                actionFinalizer(undefined, true);
              })
              .catch(this.handleApiError);
          },
          () => actionFinalizer(undefined, false),
        );
      break;
    default:
        actionFinalizer(undefined, false);
      break;
    }

    return Promise.resolve();
  }

  showActionConfirmation = (Title, BodyText, extraProperties, confirmAction, cancelAction) => {
    this.setState({
      ActionConfirmationDialogDetails: {
        Open:true,
        Title,
        IsConfirmation:true,
        BodyText,
        CancelCallback:() => this.hideActionConfirmation(cancelAction),
        CloseCallback:() => this.hideActionConfirmation(cancelAction),
        // ConfirmLabel,
        ConfirmCallback:() => this.hideActionConfirmation(confirmAction),
        ...extraProperties,
      },
    });
  }

  hideActionConfirmation = action => {
    this.setState({
      ActionConfirmationDialogDetails: { ...this.state.ActionConfirmationDialogDetails, Open: false, },
    });
    if (action) {
      action();
    }
  }

  getBasicDocumentsFromItems = items => {
    // A basic document is one that has { OrganizationID, ProjectID, DocumentFolderID, ID }
    let basicDocuments = [];
    items.forEach(item => {
      if (!item.ContentType || item.ContentType === "Document") {
        let doc = {
          OrganizationID: item.OrganizationID,
          ProjectID: item.ProjectID,
          DocumentFolderID: item.DocumentFolderID,
          Origin: item.DocumentOrigin || item.Origin,
        };
        if (item.DocumentID) {
          doc.ID = item.DocumentID;
        } else {
          doc.ID = item.ID;
        }
        basicDocuments.push(doc);
      }
    });
    return basicDocuments;
  }

  getBasicTasksFromItems = items => {
    // A basic task is one that has { OrganizationID, ProjectID, ID }
    let basicTasks = [];
    items.forEach(item => {
      if (!item.ContentType || item.ContentType === "Task") {
        const task = {
          OrganizationID: item.OrganizationID,
          ProjectID: item.ProjectID,
          ID: item.TaskID || item.ID,
        };
        basicTasks.push(task);
      }
    });
    return basicTasks;
  }

  handleUpdateRevisedItem = debounce(revisedItem => {
    let Items = [...this.state.Items];
    if (Items && Items.length && revisedItem.ID) {
      let itemFinder = Items.filter(i => i.ID === revisedItem.ID);
      if (itemFinder.length) {
        Items.splice(Items.indexOf(itemFinder[0]), 1, revisedItem);
        this.setState({Items});
        this.itemsChanged(Items);
      }
    }
  }, 250);

  handleFabClick = e => {
    if (this.props.onFabClick) {
      this.props.onFabClick(e);
    } else if (this.props.onAddItemPromise) {
      this.handleSetAddItemDialogVisibility(true);  
    }
  }

  checkReloadItemsFlag = () => {
    if (this.context && this.context.GetReloadItemsFlag()) {
      this.context.ClearReloadItemsFlag();
      this.handleRefresh();
    }
    this.ReloadItemsCheckIntervalID = setTimeout(this.checkReloadItemsFlag, 1000);
  }

  handleSetShowDocumentFolderDialog = (visible, isCancel)=> {
    let stateToUpdate = {
      ShowDocumentFolderDialog: visible,
    };
    if (isCancel) {
      stateToUpdate.ShowProgressIndicatorImmediately = false;
    }
    this.setState(stateToUpdate);
  }

  handleSetShowFormShareDialog = (visible, isCancel, SelectedFormTemplates)=> {
    let stateToUpdate = {
      ShowFormShareDialog: visible,
      SelectedFormTemplates,
    };
    if (isCancel) {
      stateToUpdate.ShowProgressIndicatorImmediately = false;
    }
    this.setState(stateToUpdate);
  }

  handleCreateDashboardWidget = name => {
    this.setState({ShowDialogProgressIndicatorImmediately:true});

    let dashboardWidget = {...DashboardWidget};
    dashboardWidget.Name = name;
    dashboardWidget.Type = "Folder";
    dashboardWidget.JsonData = JSON.stringify(this.props.selectedNode);
    API.post("/dashboardWidgets", [dashboardWidget])
    .then(resp => {
      this.setState({
        Alert: {
          Title: "Widget created",
          BodyText: "A dashboard widget has been created for this folder."
        },
      });
      this.handleSetCreateDashboardWidgetDialogVisibility(false);
    })
    .catch(this.handleApiError);
  }

  handleSetCreateDashboardWidgetDialogVisibility(show) {
    this.setState({ ShowCreateDashboardWidgetDialog: show });
    this.setState({ ShowDialogProgressIndicatorImmediately: false });
    return true;
  }

  handleSetShowFilterAndSortDrawerVisibility = visible => {
    this.setState({
      ShowFilterAndSortDrawer: visible,
    });
  }

  handleLeftPaneVisibilityChange = LeftPaneIsVisible => {
    this.setState({LeftPaneIsVisible});
  }

  handleTaskAssignmentDialogClosed = () => {
    this.setState({
      ShowTaskAssignmentDialog: false,
      SelectedTaskAssignmentOption: null,
    });
  }

  handleTaskAssignmentDialogConfirmed = () => {
    this.setState({
      ShowTaskAssignmentDialog: false,
    });
    this.handleAction(ActionType.Task_Assign);
  }

  handleItemListContainerScroll = e => {
    this.setState({
      GetMoreButtonLeftMargin: e.target.scrollLeft,
    });
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
    this.setState({
      ApiError: err, 
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleAlert = Alert => {
    this.setState({Alert});
    if (this.props.onAlert) {
      this.props.onAlert(Alert);
    }
  }

  handleGetAllItems = () => {
    return [...this.state.Items];
  }

  handleSetAllItems = Items => {
    this.setState({Items});
  }

  handleProjectsNavClicked = () => {
    if (IsMobile()) {
      return;
    }
    this.setState({ForceShowLeftPane:true});
    setTimeout(() => {
      this.setState({ForceShowLeftPane:false});
    }, 1);
  }

  handleViewTypeChanged = viewType => {
    if (this.props.onViewTypeChanged) {
      this.props.onViewTypeChanged(viewType);
    }
    // This has been disabled for now as it appears to be a bad user experience
    // if (viewType && viewType.startsWith("Kanban")) {
    //   this.setState({ForceHideLeftPane:true});
    //   setTimeout(() => {
    //     this.setState({ForceHideLeftPane:false});
    //   }, 1);
    // }
  }

  handleSetKanbanColumnRefreshFunc = f => {
    this.KanbanColumnRefreshFunc = f;
  }

  handleWindowFocused = focused => {
    this.WindowIsFocused = true;
  }

  handleWindowBlured = () => {
    this.WindowIsFocused = false;
  }

  componentDidMount() {
    if (!this.props.returnContentOnly) {
      // To ensure a document will always be opened in a new tab, we must keep this component's window name unique
      window.name = "n1_itemCollection";
    }

    window.addEventListener('blur', this.handleWindowBlured);
    window.addEventListener('focus', this.handleWindowFocused);

    // if (this.props.collectionName === "FolderSearchResults") {
    //   let node = GetNodeFromPath(this.props);
    //   if (node) {
    //     this.handleNodeSelect(node);
    //   }
    // }

    if (!this.props.loadItemsImmediately && this.props.onGetCollectionFieldsPromise) {
      this.getCollectionFields();
    }

    if (this.props.onSetUpdateRevisedItemFunction) {
      this.props.onSetUpdateRevisedItemFunction(this.handleUpdateRevisedItem);
    }
    if (this.props.onSetGetAllItemsFunction) {
      this.props.onSetGetAllItemsFunction(this.handleGetAllItems);
    }
    if (this.props.onSetSetAllItemsFunction) {
      this.props.onSetSetAllItemsFunction(this.handleSetAllItems);
    }
    if (this.props.onSetResetAutoRefreshFunc) {
      this.props.onSetResetAutoRefreshFunc(this.handleResetAutoRefresh);
    }
    
    this.checkReloadItemsFlag();
  }

  componentWillUnmount() {
    clearTimeout(this.ReloadItemsCheckIntervalID);
    clearTimeout(this.AutoRefreshIntervalID);
    window.removeEventListener('blur', this.handleWindowBlured);
    window.removeEventListener('focus', this.handleWindowFocused);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.contentUri !== prevProps.contentUri && !this.props.skipResetOnContentUriChange)
      || this.props.collectionName !== prevProps.collectionName
      || JSON.stringify(this.props.contentUriParams) !== JSON.stringify(prevProps.contentUriParams)
      || JSON.stringify(this.props.contentUriMetaFieldFilters) !== JSON.stringify(prevProps.contentUriMetaFieldFilters)
      || this.props.passThroughComponent !== prevProps.passThroughComponent
      || this.props.fullTextFilter !== prevProps.fullTextFilter
    ) {
      if (!this.props.preventReloadOnUpdate) {
        this.setState({
          CollectionFields: [],
          Items: null,
          SelectedItems: [],
        });
        this.itemsChanged(null);
        this.selectedItemsChanged([]);
        this.handleLoadItems(true, true, undefined, undefined, this.props.fullTextFilter);
      }
    }

    if (this.props.hideSensitiveFields && !this.state.SensitiveFields) {
      GetSensitiveFields(this.props.organizationId, this.props.projectId, 
        state => this.setState(state), this.handleApiError);
    }

    if (this.props.addItemDialogConfirm === true && prevProps.addItemDialogConfirm === false) {
      this.handleAddItem();
    }

    if (this.state.Items && this.props.forcePrependItems && this.props.forcePrependItems !== prevProps.forcePrependItems) {
      this.handleForcePrependItems(this.props.forcePrependItems);
    }

    if (prevProps.forceRefresh !== this.props.forceRefresh && this.props.forceRefresh === true) {
      this.handleRefresh();
    }
    if (prevProps.forceRefreshKanbanColumns !== this.props.forceRefreshKanbanColumns
      && this.props.forceRefreshKanbanColumns) {
      this.handleRefreshKanbanColumns();
    }

    if (this.props.onSetUpdateRevisedItemFunction
      && prevProps.onSetUpdateRevisedItemFunction !== this.props.onSetUpdateRevisedItemFunction) {
      this.props.onSetUpdateRevisedItemFunction(this.handleUpdateRevisedItem);
    }
    if (this.props.onSetGetAllItemsFunction
      && prevProps.onSetGetAllItemsFunction !== this.props.onSetGetAllItemsFunction) {
      this.props.onSetGetAllItemsFunction(this.handleGetAllItems);
    }
    if (this.props.onSetSetAllItemsFunction
      && prevProps.onSetSetAllItemsFunction !== this.props.onSetSetAllItemsFunction) {
      this.props.onSetSetAllItemsFunction(this.handleSetAllItems);
    }

    if (prevProps.alert !== this.props.alert) {
      this.setState({Alert: this.props.alert});
    }
    if (prevProps.apiError !== this.props.apiError) {
      this.handleApiError(this.props.apiError);
    }
  }

  render() {
    const {
      Alert,
      ApiError,
      Items,
      ItemsCollectionName,
      SelectedItems,
      ViewType,
      FullTextFilter,
      MetaFieldFilters,
      ShowGetMoreButton,
      GetMoreButtonLeftMargin,
      CollectionFields,
      SortType,
      SortDescending,
      PostActionData,
      SensitiveFields,
      UserFiltersAreActive,
      LeftPaneIsVisible,
      ForceHideLeftPane,
      ForceShowLeftPane,
      // SortMenuAnchor,
      ShowCreateDashboardWidgetDialog,
      ShowAddItemDialog,
      ShowTaskAssignmentDialog,
      SelectedTaskAssignmentOption,
      ActionConfirmationDialogDetails,
      // SelectedProject,
      ShowFilterAndSortDrawer,
      ForceResetFilterAndSortDrawer,
      ShowDocumentFolderDialog,
      ShowFormShareDialog,
      SelectedFormTemplates,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicator,
      ShowDialogProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      isWorkspace,
      itemName,
      itemsName,
      passThroughComponent,
      collectionName,
      additionalCardStyle,
      disableCardActions,
      hideSubscriptionExpirationAlert,
      pageTitle,
      pageTitleFunc,
      titleComponentTitle,
      onGetHeadCells,
      onGetCardGridItems,
      onGetCardGridItemsForKanban,
      onGetTableRows,
      addItemDialogTitle,
      addItemDialogNamePlaceholder,
      addItemDialogBodyContent,
      addItemDialogIsConfirmation,
      addItemDialogConfirmLabel,
      onAddItemPromise,
      onFabClick,
      allowFabOnDesktop,
      fabTop,
      fabLeft,
      fabSize,
      fabPosition,
      onGetFabMenu,
      fabMenuCoords,
      forceMiniFab,
      captureReservationParams,
      onSetBeginFileUploadFunc,
      returnContentOnly,
      toolHeaderTitle,
      customNoResultsMessage,
      startupMessage,
      allowSelect,
      dialogContent,
      miscContent,
      totalItems,
      selectedNode,
      groupItemsByContentType,
      leftPaneContent,
      leftPaneStyle,
      leftPaneInnerStyle,
      toolHeaderStyle,
      toolHeaderControlSize,
      hideToolHeader,
      toolHeaderLeftContent,
      additionalToolHeaderRightContent,
      secondaryNavTabs,
      hideSensitiveFields,
      hideFilters,
      hideFilterSortDrawer,
      skipFilterSavedState,
      hideSearchAllFilter,
      fullTextFilter,
      disableActionDrawer,
      itemListContainerStyle,
      itemListStyle,
      history,
      location,
      organizationId,
      projectId,
      isPublicApi,
      onItemClick,
      allowKanban,
      taskIdForTaskAssignmentContext,
      approvalIdForApprovalAssignmentContext,
      assetItemForAssetItemContext,
      singleLineTableCells,
      showOrgAsTitleOnDesktop,
      preContent,
      showProgressIndicator,
      showProgressIndicatorImmediately,
    } = this.props;

    const getItems = (contentType, assetId) => {
     return (contentType)
        ? (assetId)
          ? Items.filter(i => i.ContentType === contentType && i.AssetID === assetId)
          : Items.filter(i => i.ContentType === contentType)
        : Items;
    }
    const getHeadCells = (contentType, assetId) => {
      return onGetHeadCells(getItems(contentType, assetId), SensitiveFields);
    };

    const searchTerms = CleanSearchTerms(fullTextFilter);

    const getTableRows = (contentType, assetId) => {
      return onGetTableRows(getHeadCells(contentType, assetId), getItems(contentType, assetId), SensitiveFields,
        classes, theme, this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
        PostActionData, SortType, SortDescending, onItemClick, searchTerms);
    }

    const getCardGridItems = (contentType, assetId) => {
      if (ViewType && ViewType.startsWith("Kanban") && onGetCardGridItemsForKanban) {
        return onGetCardGridItemsForKanban(getItems(contentType, assetId), SensitiveFields, classes, theme,
          this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
          PostActionData, SortType, SortDescending, false, additionalCardStyle || {},
          ViewType, onItemClick, disableCardActions);
      }

      return onGetCardGridItems(getItems(contentType, assetId), SensitiveFields, classes, theme,
        this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
        PostActionData, SortType, SortDescending, false, additionalCardStyle || {},
        ViewType, onItemClick, disableCardActions, searchTerms);
    }

    let contentTypeGroups = [];
    let headCells = [];
    let tableRows = [];
    let cardGridItems = [];

    if (collectionName === ItemsCollectionName) {
      if (groupItemsByContentType) {
          // Create Groups
          if (Items) {
              Items.forEach(i => {
              if (!contentTypeGroups.find(g => g.ContentType === i.ContentType)) {
                contentTypeGroups.push({
                  ContentType: i.ContentType,
                  ContentTypeLabel: GetContentTypeLabel(i.ContentType, null, i.AssetName,
                    i.AssetPluralName, true, true),
                  AssetID: i.AssetID,
                });
              }
            });
          }

          // Sort
          contentTypeGroups.sort((a, b) => {
            if (a.ContentTypeLabel < b.ContentTypeLabel) {
              return -1;
            }
            return 1;
          });

          // Prioritize Document, Task
          const taskContentType = contentTypeGroups.find(g => g.ContentType === "Task");
          if (taskContentType) {
            contentTypeGroups = contentTypeGroups.filter(g => g.ContentType !== "Task");
            contentTypeGroups.unshift(taskContentType);
          }
          const documentContentType = contentTypeGroups.find(g => g.ContentType === "Document");
          if (documentContentType) {
            contentTypeGroups = contentTypeGroups.filter(g => g.ContentType !== "Document");
            contentTypeGroups.unshift(documentContentType);
          }

          // Get content for each group
          contentTypeGroups.forEach(g => {
            g.HeadCells = getHeadCells(g.ContentType, g.AssetID);
            g.TableRows = getTableRows(g.ContentType, g.AssetID);
            g.CardGridItems = getCardGridItems(g.ContentType, g.AssetID);
          })
      } else {
        headCells = getHeadCells();
        tableRows = getTableRows();
        cardGridItems = getCardGridItems();
      }
    }
    
    const numSelected = SelectedItems.length;
    const indeterminate = numSelected > 0 && Items && numSelected < Items.length;
    const allSelected = numSelected > 0 && Items && numSelected === Items.length;

    const projectMembershipPackages = (this.context)
      ? this.context.ProjectMembershipPackages
      : [];
    const projectMemberPkgFinder = projectMembershipPackages && projectMembershipPackages
        .filter(p => p.Project.ID === projectId);
    const isProjectMember = projectMemberPkgFinder.length > 0;
    const isProjectAdmin = isProjectMember && projectMemberPkgFinder[0].IsAdmin;
    const documentsAccessedByAssignment = isProjectMember
      && (projectMemberPkgFinder[0].Project.Access.MemberAccess.DocumentFolders === "Assigned")
      && !isProjectAdmin;
    
    let itemContent;
    // Wait for SensitiveFields if required
    const canShowContent = (
      (!hideSensitiveFields || SensitiveFields)
    );

    if (canShowContent) {
      let itemDisplay;
      if (ViewType && ViewType.startsWith("Kanban") && cardGridItems) {
        itemDisplay = (
          <KanbanBase
            organizationId={organizationId}
            projectId={projectId}
            projectAccess={(projectMemberPkgFinder.length) ? projectMemberPkgFinder[0].Project.Access : undefined}
            isProjectAdmin={isProjectAdmin}
            viewType={ViewType}
            fullTextFilter={FullTextFilter}
            metaFieldFilters={MetaFieldFilters}
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
            cardGridItems={cardGridItems}
            showGetMoreButton={ShowGetMoreButton}
            onGetMoreItems={this.handleLoadItems}
            // getMoreButtonLeftMargin={GetMoreButtonLeftMargin}
            indeterminate={indeterminate}
            allSelected={allSelected}
            onSelectAll={(allowSelect) ? this.handleSelectAll : undefined}
            onSetRefreshColumnsFunc={this.handleSetKanbanColumnRefreshFunc}
            onForcePrependItems={this.handleForcePrependItems}
          />
        );
      } else if (groupItemsByContentType && contentTypeGroups.length) {
        contentTypeGroups.forEach(g => {
          g.Content = (
            <ItemListBase
              cardGridItems={g.CardGridItems}
              headCells={g.HeadCells}
              tableBodyContent={g.TableRows}
              // showGetMoreButton={ShowGetMoreButton}
              // onGetMoreItems={this.handleLoadItems}
              // getMoreButtonLeftMargin={GetMoreButtonLeftMargin}
              sortType={SortType}
              // singleLineTableCells={singleLineTableCells}
              collectionFields={CollectionFields}
              // sortDescending={SortDescending}
              // onSortChangeFromTableHeader={this.handleSortChangeFromTableHeader}
              // viewType={ViewType}
              viewType="List"
              // leftPaneIsVisible={LeftPaneIsVisible}
              style={itemListStyle}
            />
          );
        });
        const groupGridItems = contentTypeGroups.map(g => (
          <Grid item key={`g_${g.ContentType}`}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h5">
                  {g.ContentTypeLabel}
                </Typography>
              </Grid>
              <Grid item>
                {g.Content}
              </Grid>                
            </Grid>
          </Grid>
        ));
        let preContentGridItem;
        if (preContent) {
          preContentGridItem = (
            <Grid item style={{paddingBottom:theme.spacing(1)}}>
              {preContent}
            </Grid>
          );
        }
        itemDisplay = (
          <Grid container direction="column" spacing={6} style={{
            padding:theme.spacing(5),
          }}>
            {preContentGridItem}
            {groupGridItems}
          }
          </Grid>
        );
      } else if (cardGridItems && cardGridItems.length) {
        itemDisplay = (
          <ItemListBase
            cardGridItems={cardGridItems}
            headCells={headCells}
            tableBodyContent={tableRows}
            showGetMoreButton={ShowGetMoreButton}
            onGetMoreItems={this.handleLoadItems}
            getMoreButtonLeftMargin={GetMoreButtonLeftMargin}
            sortType={SortType}
            singleLineTableCells={singleLineTableCells}
            collectionFields={CollectionFields}
            sortDescending={SortDescending}
            onSortChangeFromTableHeader={this.handleSortChangeFromTableHeader}
            viewType={ViewType}
            indeterminate={indeterminate}
            allSelected={allSelected}
            onSelectAll={(allowSelect) ? this.handleSelectAll : undefined}
            leftPaneIsVisible={LeftPaneIsVisible}
            style={itemListStyle}
          />
        );
      }
      let noResultsMessageDiv;
      if (!(ViewType && ViewType.startsWith("Kanban"))
        && Items !== null
        && !contentTypeGroups.length
        && (!cardGridItems || !cardGridItems.length)
      ) {
        noResultsMessageDiv = (
          <div className={classes.emptyContainer}>{customNoResultsMessage || "No results"}</div>
        );
      }
      const startupMessageDiv = (startupMessage && Items === null) ? (
        <div className={classes.emptyContainer}>{startupMessage}</div>
      ) : null;
      itemContent = (
        <React.Fragment>
          {itemDisplay}
          {startupMessageDiv}
          {noResultsMessageDiv}
          {passThroughComponent}
        </React.Fragment>
      );
    }

    let infoGridItems = [];
    if (totalItems && !IsMobile() && !UserFiltersAreActive) {
      infoGridItems.push(
        <Grid item key="grid_totalItems">
          <Typography>{NumberWithSeparators(totalItems)} total</Typography>
        </Grid>
      );
    }
    if (SelectedItems.length > 0) {
      infoGridItems.push(
        <Grid item key="grid_selectedCount">
          <Typography>{NumberWithSeparators(SelectedItems.length)} selected</Typography>
        </Grid>
      );
    }
    const infoGrid = (allowSelect && infoGridItems.length)
      ? (
        <Grid container spacing={2} wrap="nowrap" className={classes.infoGrid}>
          {infoGridItems}
        </Grid>
      )
      : null;

    const selectAllComponent = (
      allowSelect
      && (ViewType === "Card" || ViewType.startsWith("Kanban"))
      && Items
      && Items.length
    )
      ? (
        <Tooltip title="Select">
          <Checkbox
            size={toolHeaderControlSize || undefined}
            color="secondary"
            indeterminate={indeterminate}
            checked={indeterminate || allSelected}
            onChange={this.handleSelectAll}
          />
        </Tooltip>
      ) : null;

    let switchViewComponent;
    // For mobile and everything on desktop except where Kanban is allowed,
    // show a simple switcher between Card and List
    if (IsMobile() || !allowKanban) {
      let nextViewTitle = "";
      let nextViewIcon = null;
      switch (GetNextViewType(ViewType)) {
        case "Card":
          nextViewTitle = "Card view";
          nextViewIcon = <ViewModuleIcon />;
          break;
        case "List":
        default:
          nextViewTitle = "List view";
          nextViewIcon = <ViewListIcon />;
          break;
      }
      switchViewComponent = (
        <div>
          <Tooltip title={nextViewTitle}>
            <IconButton
              onClick={() => HandleCycleViewType(state => this.setState(state), this.handleApiError,
                collectionName, GetIdSubPrefix(collectionName, IsMobile(), isWorkspace), ViewType,
                isPublicApi,
              )}
              color="inherit"
            >
              {nextViewIcon}
            </IconButton>
          </Tooltip>
        </div>
      );
    }
    // For desktop where Kanban is allowed, show menu switcher for Card, List, Kanban
    if (!IsMobile() && allowKanban) {
      switchViewComponent = (
        <div ref={instance => this.ChangeViewRef = instance}>
          <Tooltip title="Change view">
            <IconButton
              size={(toolHeaderControlSize || undefined)}
              color="inherit"
              onClick={() => HandleSetViewTypeAnchorEl(state => this.setState(state), this.ChangeViewRef)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>
          {GetViewTypeMenuForDesktopTasks(
            id => this.state[id], 
            state => this.setState(state), 
            this.handleApiError,
            this.handleViewTypeChanged,
          )}
        </div>
      );
    }

    const toolHeaderTitleContent = (toolHeaderTitle)
      ? (
        <Typography className={classes.toolHeaderTitle}>
          {toolHeaderTitle}
        </Typography>
      )
      : null;

    const activeFilterIndicator = (UserFiltersAreActive && !hideFilters)
      ? (
        <div 
          className={classes.activeFilterIndicator}
          style={{
            top:(IsMobile() && toolHeaderControlSize === "small") ? 4 : 12,
            right:(IsMobile() && toolHeaderControlSize === "small") ? 4 : 12,
          }}
        />
      ) : (<div />);
    const filterSortIcon = (hideFilters)
      ? (<SortIcon />)
      : (<FilterIcon />);
    const filterButtonComponent = (!hideFilterSortDrawer)
      ? (
        <div className={classes.filterButtonContainer}>
          {activeFilterIndicator}
          <Tooltip title={(hideFilters) ? "Sort" : "Filter & Sort"}>
            <IconButton
              size={(toolHeaderControlSize || undefined)}
              color="inherit"
              onClick={() => this.handleSetShowFilterAndSortDrawerVisibility(true)}
            >
              {filterSortIcon}
            </IconButton>
          </Tooltip>
        </div>
      ) : null;

    const refreshComponent = (Items)
      ? (
        <Tooltip title="Refresh">
          <IconButton
            size={(toolHeaderControlSize || undefined)}
            color="inherit"
            onClick={this.handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    const toolHeaderRightComponents = (!passThroughComponent)
      ? (
        <div style={{
          display:"flex",
          alignItems:"center",
          marginLeft:theme.spacing(2),
        }}>
          {additionalToolHeaderRightContent}
          {infoGrid}
          {selectAllComponent}
          {filterButtonComponent}
          {switchViewComponent}
          {refreshComponent}
        </div>
      ) : null;

    const addDashboardWidgetButton = (collectionName === "FolderSearchResults" && selectedNode)
      ? (
          <Tooltip
            title="Create dashboard widget">
            <IconButton aria-label="Create dashboard widget"
              onClick={() => this.handleSetCreateDashboardWidgetDialogVisibility(true)}>
              <DashboardIcon fontSize="small"
                className={classes.breadcrumbDashboardIcon} />
            </IconButton>
          </Tooltip>
        ) : null;

    const addFabButton = (
        (!SelectedItems || !SelectedItems.length)
        && (onAddItemPromise || onFabClick) 
        && (IsMobile() || allowFabOnDesktop)
      )
      ? (
        <Fab 
          color="secondary"
          aria-label="Add" 
          className={classes.fab}
          size={(fabSize) ? fabSize : undefined}
          style={{
            top:fabTop,
            left:fabLeft,
            position:fabPosition,
          }}
          onClick={this.handleFabClick}
        >
          <AddIcon />
        </Fab>
      )
      : null;

    const miniFabButton = (
        (selectedNode || forceMiniFab)
        && (onAddItemPromise || onFabClick) 
        && !IsMobile()
      )
      ? (
        <Tooltip title={`Add${(itemName) ? " " + itemName.toLowerCase() : ""}`}>
          <IconButton color="inherit" aria-label="Add"
            style={{marginLeft:theme.spacing(1)}}
            onClick={this.handleFabClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )
      : null;

    let breadcrumbNodeComponents = [];
    if (selectedNode) {
      let node = selectedNode;
      const getClickFunction = selectedNode => {
        return (this.props.onBreadcrumbSelect)
          ? () => this.props.onBreadcrumbSelect(selectedNode)
          : () => {};
      };
      while (node) {
        if (breadcrumbNodeComponents.length > 0) {
          breadcrumbNodeComponents.splice(0, 0,
            <ArrowForwardIcon
              key={`arrow_${node.UniqueId}`}
              className={classes.breadcrumbArrowForward} />
          );  
        }
        if (node.IsRoot || node.NoSelect) {
          breadcrumbNodeComponents.splice(0, 0,
            <Typography variant="body1"
              key={node.UniqueId}
              className={classes.breadcrumbNoSelect}
              // style={{textTransform:(node.ItemIndex === 0) ? "inherit" : null}}
            >
              {node.Name}
            </Typography>
          );
        } else {
          breadcrumbNodeComponents.splice(0, 0,
            <Button
              key={node.UniqueId}
              className={classes.breadcrumbButton}
              onClick={getClickFunction(node)}
            >
              <span style={{color:node.HexColor}}>
                {node.Name}
              </span>
            </Button>
          );
        }
        node = node.ParentNode;
      }
    }
    const toolHeaderHeight = 48;
    const toolHeader = (!hideToolHeader) ? (
      <div className={classes.toolHeader} 
        style={{
          height:toolHeaderHeight,
          // paddingLeft:(!LeftPaneIsVisible) ? 10 : undefined,
          ...toolHeaderStyle,
        }}
      >
        <div className={classes.toolHeaderLeft}>
          {toolHeaderLeftContent}
          {toolHeaderTitleContent}
          {breadcrumbNodeComponents}
          {addDashboardWidgetButton}
          {miniFabButton}
        </div>
        <div className={classes.toolHeaderRight}>
          {toolHeaderRightComponents}
        </div>
      </div>
    ) : null;

    const addItemDialogDetails = (onAddItemPromise)
      ? {
        Open:ShowAddItemDialog,
        ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
        Title:addItemDialogTitle,
        IsConfirmation:addItemDialogIsConfirmation,
        RequireTextInput1:(!addItemDialogIsConfirmation),
        TextInput1Label:(!addItemDialogIsConfirmation) ? "Name" : undefined,
        TextInput1PlaceHolder:(!addItemDialogIsConfirmation) ? addItemDialogNamePlaceholder : undefined,
        BodyContent:(addItemDialogBodyContent) ? addItemDialogBodyContent : undefined,
        CancelCallback:() => this.handleSetAddItemDialogVisibility(false),
        CloseCallback:() => this.handleSetAddItemDialogVisibility(false),
        ConfirmLabel:addItemDialogConfirmLabel || "ADD",
        ConfirmCallback:this.handleAddItem,
      }
      : {
        Open: false,
      };

    const createDashboardWidgetDetails = {
      Open:ShowCreateDashboardWidgetDialog,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      Title:"Create dashboard widget",
      RequireTextInput1:true,
      TextInput1Label:"Name",
      TextInput1DefaultValue:(selectedNode) ? selectedNode.Name : null,
      CancelCallback:() => this.handleSetCreateDashboardWidgetDialogVisibility(false),
      CloseCallback:() => this.handleSetCreateDashboardWidgetDialogVisibility(false),
      ConfirmLabel:"CREATE",
      ConfirmCallback: this.handleCreateDashboardWidget,
    };

    const taskAssignmentDialog = (ShowTaskAssignmentDialog)
      ? (
        <MultiUseDialog Details={
          GetTaskAssignmentDialogDetails(
            organizationId,
            projectId,
            this.handleTaskAssignmentDialogConfirmed,
            this.handleTaskAssignmentDialogClosed,
            this.handleApiError,
            SelectedTaskAssignmentOption,
            SelectedTaskAssignmentOption => this.setState({SelectedTaskAssignmentOption}),
          )
        } />
      )
      : null;

    let collapsibleLeftPane;
    if (leftPaneContent) {
      collapsibleLeftPane = (
        <CollapsibleLeftPane 
          paneInnerStyle={{
            padding:theme.spacing(2),
            paddingTop:theme.spacing(3),
            ...leftPaneInnerStyle,
          }}
          paneStyle={leftPaneStyle}
          onVisibilityChange={this.handleLeftPaneVisibilityChange}
          forceHide={ForceHideLeftPane}
          forceShow={ForceShowLeftPane}
        >
          {leftPaneContent}
        </CollapsibleLeftPane>
      );
    }

    const internalProgressIndicator = (returnContentOnly
      && (ShowProgressIndicator || showProgressIndicator 
        || ShowProgressIndicatorImmediately || showProgressIndicatorImmediately))
      ? (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately || showProgressIndicatorImmediately} />
      ) : null;

    const userPreferences = this.context && this.context.UserPreferences;
    const filterSortDrawer = (!hideFilterSortDrawer && CollectionFields.length)
      ? (
        <FilterSortDrawer
          organizationId={organizationId}
          isOrganizationMember={userPreferences && userPreferences.ActiveOrganizationMember}
          isProjectMember={isProjectMember}
          projectId={projectId}
          isWorkspace={isWorkspace}
          history={this.props.history}
          location={this.props.location}
          collectionFields={CollectionFields}
          sortType={SortType}
          sortDescending={SortDescending}
          hideFilters={hideFilters}
          initialFullText={fullTextFilter}
          hideSearchAll={hideSearchAllFilter}
          skipSavedState={skipFilterSavedState}
          forceReset={ForceResetFilterAndSortDrawer}
          open={ShowFilterAndSortDrawer}
          onSetVisibility={this.handleSetShowFilterAndSortDrawerVisibility}
          onSortChange={this.handleSortChange}
          onFlipSortDirection={this.handleFlipSortDirection}
          onFiltersChanged={this.handleFiltersChanged}
          onApiError={this.handleApiError}
        />
      ) : null;

    const setDocumentFolderDialog = (ShowDocumentFolderDialog)
      ? (
        <SetDocumentFolderDialog
          open={ShowDocumentFolderDialog}
          organizationId={organizationId}
          projectId={projectId}
          documentIds={this.getBasicDocumentsFromItems(SelectedItems).map(d => d.ID)}
          onApiError={this.handleApiError}
          onAction={documentFolderId => this.handleAction(ActionType.SetDocumentFolder, null, false, { documentFolderId })}
          onClose={() => this.handleSetShowDocumentFolderDialog(false, true)}
        />
      ): null;

    const formShareDialog = (ShowFormShareDialog)
      ? (
        <FormShareDialog
          open={ShowFormShareDialog}
          organizationId={organizationId}
          projectId={projectId}
          formTemplates={SelectedFormTemplates}
          onApiError={this.handleApiError}
          onAction={
            (recipientEmails, tags, assetItemTags) =>
              this.handleAction(ActionType.ShareForm, null, false,
              { recipientEmails, tags, assetItemTags, },
              SelectedFormTemplates,
            )
          }
          onClose={() => this.handleSetShowFormShareDialog(false, true)}
        />
      ) : null;

    const onAction = (...props) => this.handleAction(props[0], props[1] || null, props[2] || false);

    const allowActionDrawer = SelectedItems.length > 0;
    let actionListItems = [];
    const restoreTaskAction = (
      <ListItem key="action_task_restore" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Task_Restore)}>
        <ListItemIcon>
          {ActionType.Task_Restore.Icon}
        </ListItemIcon>
        <ListItemText primary={ActionType.Task_Restore.Label} />
      </ListItem>
    )
    const formShareAction = (
      <ListItem key="actions_formShare" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.ShareForm)}>
        <ListItemIcon>
          {ActionType.ShareForm.Icon}
        </ListItemIcon>
        <ListItemText primary={ActionType.ShareForm.Label} />
      </ListItem>
    );
    const taskFollowActions = [
      <Divider key="divider_followedTasks" />,
      <ListItem key="action_followedTask_create" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskSubscription_Create)}>
        <ListItemIcon>
          {ActionType.TaskSubscription_Create.Icon}
        </ListItemIcon>
        <ListItemText primary={ActionType.TaskSubscription_Create.Label} />
      </ListItem>,
      <ListItem key="action_followedTask_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskSubscription_Delete)}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText primary={ActionType.TaskSubscription_Delete.Label} />
      </ListItem>,
    ];

    let collectionNameForActions = collectionName;
    switch (itemsName) {
      case "AssetItems":
        if (collectionName === "AssetItemAssetItems") {
          break;
        }
        collectionNameForActions = (collectionName.startsWith("Archived"))
            ? `Archived${itemsName}`
            : itemsName;
        break;
      default:
        break;
    }
    switch (collectionNameForActions) {
      case "OrganizationMembers":
        actionListItems.push(
          <ListItem key="action_organizationMember_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(OrganizationActionType.OrganizationMember_Delete)}>
            <ListItemIcon>
              {OrganizationActionType.OrganizationMember_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={OrganizationActionType.OrganizationMember_Delete.Label} />
          </ListItem>
        );
        break;
      case "OrganizationProjects":
        actionListItems.push(
          <ListItem key="action_organizationProject_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(OrganizationActionType.OrganizationProject_Delete)}>
            <ListItemIcon>
              {OrganizationActionType.OrganizationProject_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={OrganizationActionType.OrganizationProject_Delete.Label} />
          </ListItem>
        );
        break;
      case "ProjectMembers":
        actionListItems.push(
          <ListItem key="action_projectMember_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ProjectActionType.ProjectMember_Delete)}>
            <ListItemIcon>
              {ProjectActionType.ProjectMember_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ProjectActionType.ProjectMember_Delete.Label} />
          </ListItem>
        );
        break;
      case "Forms":
        actionListItems.push(formShareAction);
        break;
      case "FormTemplates":
        actionListItems.push(
          formShareAction,
          <Divider key="divider_formTemplates" />,
          <ListItem key="action_formTemplate_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(FormTemplateActionType.FormTemplate_Delete)}>
            <ListItemIcon>
              {FormTemplateActionType.FormTemplate_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={FormTemplateActionType.FormTemplate_Delete.Label} />
          </ListItem>
        );
        break;
      case "Tags":
        actionListItems.push(
          <ListItem key="action_tag_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Tag_Delete)}>
            <ListItemIcon>
              {ActionType.Tag_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Tag_Delete.Label} />
          </ListItem>
        );
        break;
      case "UserApiKeys":
        actionListItems.push(
          <ListItem key="action_userApiKey_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(UserApiKeyActionType.UserApiKey_Delete)}>
            <ListItemIcon>
              {UserApiKeyActionType.UserApiKey_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={UserApiKeyActionType.UserApiKey_Delete.Label} />
          </ListItem>
        );
        break;
      case "Notifications":
        actionListItems.push(
          /*<ListItem key="action_notification_markread" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Notification_MarkRead)}>
          <ListItemIcon>
            {ActionType.Notification_MarkRead.Icon}
          </ListItemIcon>
          <ListItemText primary={ActionType.Notification_MarkRead.Label} />
        </ListItem>,
        <ListItem key="action_notification_markunread" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Notification_MarkUnread)}>
          <ListItemIcon>
            {ActionType.Notification_MarkUnread.Icon}
          </ListItemIcon>
          <ListItemText primary={ActionType.Notification_MarkUnread.Label} />
        </ListItem>,
        */  
          <ListItem key="action_notification_clear" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Notification_Clear)}>
            <ListItemIcon>
              {ActionType.Notification_Clear.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Notification_Clear.Label} />
          </ListItem>,
        );
        actionListItems.push(
          <Divider key="divider_notifications" />
        );
        break;
      case "Approvals":
        actionListItems.push(
          <ListItem key="action_approval_approve" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Approval_Approve)}>
            <ListItemIcon>
              {ActionType.Approval_Approve.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Approval_Approve.Label} />
          </ListItem>,
          <ListItem key="action_approval_decline" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Approval_Decline)}>
            <ListItemIcon>
              {ActionType.Approval_Decline.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Approval_Decline.Label} />
          </ListItem>
        );
        if (isProjectAdmin) {
          actionListItems.push(
            <ListItem key="action_approval_deny" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Approval_Deny)}>
              <ListItemIcon>
                {ActionType.Approval_Deny.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.Approval_Deny.Label} />
            </ListItem>
          );
        }
        actionListItems.push(
          <Divider key="divider_approvals" />
        );
        break;
      case "Tasks":
        actionListItems.push(
          <ListItem key="action_task_complete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Task_Complete)}>
            <ListItemIcon>
              {ActionType.Task_Complete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Task_Complete.Label} />
          </ListItem>
        );
        if (isProjectMember) {
          actionListItems.push(
            <ListItem key="action_task_assign" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Task_Assign)}>
              <ListItemIcon>
                {ActionType.Task_Assign.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.Task_Assign.Label} />
            </ListItem>,
            <ListItem key="action_task_deny" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Task_Deny)}>
              <ListItemIcon>
                {ActionType.Task_Deny.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.Task_Deny.Label} />
            </ListItem>,
          );
          actionListItems.push(taskFollowActions);
        }
        break;
      case "FollowedTasks":
        actionListItems.push(
          <ListItem key="action_followedTask_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskSubscription_Delete)}>
            <ListItemIcon>
              {ActionType.TaskSubscription_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.TaskSubscription_Delete.Label} />
          </ListItem>,
        );
        break;
      case "CompletedTasks":
      case "DeniedTasks":
        actionListItems.push(
          restoreTaskAction,
          taskFollowActions,
        );
        break;
      case "TaskDocuments":
        actionListItems.push(
          <ListItem key="action_tasks_documents_remove" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskDocument_Remove)}>
            <ListItemIcon>
              {ActionType.TaskDocument_Remove.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.TaskDocument_Remove.Label} />
          </ListItem>,
        );
        break;
      case "AssetItemDocuments":
        if (isProjectMember) {
          actionListItems.push(
            <ListItem key="action_assetItems_documents_remove" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.AssetItemDocument_Remove)}>
              <ListItemIcon>
                {ActionType.AssetItemDocument_Remove.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.AssetItemDocument_Remove.Label} />
            </ListItem>,
          );
        }
        break;
      case "AssetItemTasks":
        if (isProjectMember) {
          actionListItems.push(
            <ListItem key="action_assetItems_tasks_remove" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.AssetItemTask_Remove)}>
              <ListItemIcon>
                {ActionType.AssetItemTask_Remove.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.AssetItemTask_Remove.Label} />
            </ListItem>,
          );
        }
        break;
      case "AssetItemAssetItems":
        if (isProjectMember) {
          actionListItems.push(
            <ListItem key="action_assetItems_assetItems_remove" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.AssetItemAssetItem_Remove)}>
              <ListItemIcon>
                {ActionType.AssetItemAssetItem_Remove.Icon}
              </ListItemIcon>
              <ListItemText primary={ActionType.AssetItemAssetItem_Remove.Label} />
            </ListItem>,
          );
        }
        break;
      case "Fields":
        actionListItems.push(
          <ListItem key="action_allareas_field_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Field_Delete)}>
            <ListItemIcon>
              {ActionType.Field_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Field_Delete.Label} />
          </ListItem>,
        );
        break;
      case "Assets":
        actionListItems.push(
          <ListItem key="action_assets_archive" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Asset_Archive)}>
            <ListItemIcon>
              {ActionType.Asset_Archive.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Asset_Archive.Label} />
          </ListItem>,
        );
        break;
      case "ArchivedAssets":
        actionListItems.push(
          <ListItem key="action_assets_restore" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Asset_Restore)}>
            <ListItemIcon>
              {ActionType.Asset_Restore.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Asset_Restore.Label} />
          </ListItem>,
        );
        break;
      case "AssetItems":
        actionListItems.push(
          <ListItem key="action_assetItems_archive" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.AssetItem_Archive)}>
            <ListItemIcon>
              {ActionType.AssetItem_Archive.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.AssetItem_Archive.Label} />
          </ListItem>,
        );
        break;
      case "ArchivedAssetItems":
        actionListItems.push(
          <ListItem key="action_assetItems_restore" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.AssetItem_Restore)}>
            <ListItemIcon>
              {ActionType.AssetItem_Restore.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.AssetItem_Restore.Label} />
          </ListItem>,
        );
        break;
      case "TaskMilestones":
        actionListItems.push(
          <ListItem key="action_allareas_taskMilestone_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskMilestone_Delete)}>
            <ListItemIcon>
              {ActionType.TaskMilestone_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.TaskMilestone_Delete.Label} />
          </ListItem>,
        );
        break;
      case "TaskStates":
        actionListItems.push(
          <ListItem key="action_allareas_taskState_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.TaskState_Delete)}>
            <ListItemIcon>
              {ActionType.TaskState_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.TaskState_Delete.Label} />
          </ListItem>,
        );
        break;
      case "RecycleBinDocuments":
      case "FollowedDocuments":
      case "Documents":
      case "AllNeedSignatureDocuments":
      case "NeedSignatureDocuments":
      case "SearchResults":
      case "FolderSearchResults":
        const isRecycleBin = (collectionName === "RecycleBinDocuments");
        let action_sendToOrRemoveFromRecycleBin = null;
        if (isProjectMember) {
          action_sendToOrRemoveFromRecycleBin = (isRecycleBin) ?
            (
              <ListItem key="action_recyclebin_restore" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.RestoreFromRecycleBin)}>
                <ListItemIcon>
                  {ActionType.RestoreFromRecycleBin.Icon}
                </ListItemIcon>
                <ListItemText primary={ActionType.RestoreFromRecycleBin.Label} />
              </ListItem>
            )
          : (
              <ListItem key="action_recyclebin_sendTo" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.SendToRecycleBin)}>
                <ListItemIcon>
                  {ActionType.SendToRecycleBin.Icon}
                </ListItemIcon>
                <ListItemText primary={ActionType.SendToRecycleBin.Label} />
              </ListItem>
            );
        }

        const actions_setDocumentFolder = (
          <ListItem key="action_setDocumentFolder" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.SetDocumentFolder)}>
            <ListItemIcon>
              {ActionType.SetDocumentFolder.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.SetDocumentFolder.Label} />
          </ListItem>
        );

        let actions_followedDocuments_allareas = [];
        if (isProjectMember) {
          const isSubscriptions = (collectionName === "FollowedDocuments");
          if (!isSubscriptions) {
            actions_followedDocuments_allareas.push(
              <ListItem key="action_followedDocument_create" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.DocumentSubscription_Create)}>
                <ListItemIcon>
                  {ActionType.DocumentSubscription_Create.Icon}
                </ListItemIcon>
                <ListItemText primary={ActionType.DocumentSubscription_Create.Label} />
              </ListItem>,
              <ListItem key="action_allareas_followedDocument_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.DocumentSubscription_Delete)}>
                <ListItemIcon>
                  <RemoveIcon />
                </ListItemIcon>
                <ListItemText primary={ActionType.DocumentSubscription_Delete.Label} />
              </ListItem>,
              <Divider key="divider_followedDocuments" />
            );
          }
          let actions_followedDocuments = [];
          if (isSubscriptions) {
            actions_followedDocuments.push(
              <ListItem key="action_followedDocument_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.DocumentSubscription_Delete)}>
                <ListItemIcon>
                  {ActionType.DocumentSubscription_Delete.Icon}
                </ListItemIcon>
                <ListItemText primary={ActionType.DocumentSubscription_Delete.Label} />
              </ListItem>,
              <Divider key="divider_followedDocuments" />
            );
          }
          actionListItems.push(
            actions_followedDocuments,
          );
        }
        actionListItems.push(
          <ListItem key="action_download" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(ActionType.Download)}>
            <ListItemIcon>
              {ActionType.Download.Icon}
            </ListItemIcon>
            <ListItemText primary={ActionType.Download.Label} />
          </ListItem>,
          <Divider key="divider_general" />
        )
        if (isProjectMember && !documentsAccessedByAssignment) {
          if (!isRecycleBin) {
            actionListItems.push(
              actions_setDocumentFolder,
            );
          }
          actionListItems.push(
            actions_followedDocuments_allareas,
            action_sendToOrRemoveFromRecycleBin,
          );
        }
        break;
      default:
        break;
    }

    const actionDrawer = (!disableActionDrawer)
      ? (
        <ActionDrawer
          actionListItems={actionListItems}
          allowDrawer={allowActionDrawer}
        />
      ) : null;

    const titleComponent = (titleComponentTitle)
      ? (
        <TitleComponent title={titleComponentTitle} />
      ) : null;

    const content = (
      <div className={classes.content}>
        {filterSortDrawer}
        <MultiUseDialog Details={ActionConfirmationDialogDetails} />
        <MultiUseDialog Details={addItemDialogDetails} />
        <MultiUseDialog Details={createDashboardWidgetDetails} />
        {taskAssignmentDialog}
        {setDocumentFolderDialog}
        {formShareDialog}
        {dialogContent}
        {miscContent}
        {titleComponent}
        {addFabButton}
        {(onGetFabMenu) ? onGetFabMenu(fabMenuCoords) : null}
        {collapsibleLeftPane}
        <div className={classes.contentRight}>
          {internalProgressIndicator}
          {toolHeader}
          <div className={classes.contentRightContent}
            style={{
              height:(!hideToolHeader) ? `calc(100% - ${toolHeaderHeight}px)` : undefined,
            }}
          >
            <div className={classes.itemListContainer}
              style={itemListContainerStyle}
              onScroll={this.handleItemListContainerScroll}
            >
              {(collectionName === "FolderSearchResults"
                || collectionName === "Documents"
                || collectionName === "TaskDocuments"
                || collectionName === "AssetItemDocuments")
                ? (
                  <CaptureCore
                    organizationId={organizationId}
                    projectId={projectId}
                    taskIdForTaskAssignmentContext={taskIdForTaskAssignmentContext}
                    approvalIdForApprovalAssignmentContext={approvalIdForApprovalAssignmentContext}
                    assetItemForAssetItemContext={assetItemForAssetItemContext}
                    history={history}
                    location={location}
                    node={selectedNode}
                    forceHideInstructionContent
                    reservationParams={captureReservationParams}
                    onSetBeginFileUploadFunc={onSetBeginFileUploadFunc}
                    gatherFieldValuesBeforeUpload
                    onClose={() => this.handleRefresh()}
                    onApiError={this.handleApiError}
                    onAlert={this.handleAlert}>
                    {itemContent}
                  </CaptureCore>
                )
                : itemContent
              }
            </div>
            {actionDrawer}
          </div>
        </div>
      </div>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title={(pageTitleFunc) ? pageTitleFunc(Items) : pageTitle}
        alert={Alert}
        apiError={ApiError}
        hideSubscriptionExpirationAlert={hideSubscriptionExpirationAlert}
        showProgressIndicator={ShowProgressIndicator || showProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately || showProgressIndicatorImmediately}
        constrainedProgressIndicator
        content={content}
        secondaryNavTabs={secondaryNavTabs}
        showOrgAsTitleOnDesktop={showOrgAsTitleOnDesktop}
        onProjectsNavClicked={this.handleProjectsNavClicked}
      />
    );
  }
}

ItemCollectionBase.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleFunc: PropTypes.func,
  titleComponentTitle: PropTypes.string,

  isWorkspace: PropTypes.bool,
  passThroughComponent: PropTypes.object,

  contentUri: PropTypes.string.isRequired,
  contentUriParams: PropTypes.object,
  contentUriMetaFieldFilters: PropTypes.array,
  metaFieldFilters: PropTypes.array,
  collectionName: PropTypes.string.isRequired,
  itemsName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  defaultViewType: PropTypes.string.isRequired,

  onGetCollectionFieldsPromise: PropTypes.func.isRequired,
  onGetHeadCells: PropTypes.func.isRequired,
  onGetCardGridItems: PropTypes.func.isRequired,
  onGetCardGridItemsForKanban: PropTypes.func,
  onGetTableRows: PropTypes.func.isRequired,
  singleLineTableCells: PropTypes.bool,

  onItemsChanged: PropTypes.func,
  onRefresh: PropTypes.func,
  onConnectRefreshItemsFunction: PropTypes.func,
  onConnectOnActionFunction: PropTypes.func,
  onSelectedItemsChanged: PropTypes.func,
  onCollectionNameSet: PropTypes.func,

  selectedNode: PropTypes.object,  
  onBreadcrumbSelect: PropTypes.func,
  totalItems: PropTypes.number,
  allowSelect: PropTypes.bool,
  canSelectItem: PropTypes.func,
  disableActionDrawer: PropTypes.bool,
  addItemDialogTitle: PropTypes.string,
  addItemDialogNamePlaceholder: PropTypes.string,
  addItemDialogBodyContent: PropTypes.object,
  addItemDialogIsConfirmation: PropTypes.bool,
  addItemDialogConfirm: PropTypes.bool,
  addItemDialogConfirmLabel: PropTypes.string,
  onAddItemPromise: PropTypes.func,
  onFabClick: PropTypes.func,
  allowFabOnDesktop: PropTypes.bool,
  fabTop: PropTypes.number,
  fabLeft: PropTypes.number,
  fabSize: PropTypes.string,
  fabPosition: PropTypes.string,
  onGetFabMenu: PropTypes.func,
  fabMenuCoords: PropTypes.object,
  forceMiniFab: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  forceRefreshKanbanColumns: PropTypes.bool,
  onSetResetAutoRefreshFunc: PropTypes.func,
  additionalCardStyle: PropTypes.object,
  leftPaneContent: PropTypes.object,
  leftPaneStyle: PropTypes.object,
  leftPaneInnerStyle: PropTypes.object,
  toolHeaderStyle: PropTypes.object,
  toolHeaderControlSize: PropTypes.string,
  secondaryNavTabs: PropTypes.object,
  toolHeaderLeftContent: PropTypes.object,
  additionalToolHeaderRightContent: PropTypes.object,
  hideSensitiveFields: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hideFilterSortDrawer: PropTypes.bool,
  hideSearchAllFilter: PropTypes.bool,
  hideToolHeader: PropTypes.bool,
  showFilterAndSortDrawerOnLoad: PropTypes.bool,
  skipFilterSavedState: PropTypes.bool,
  fullTextFilter: PropTypes.string,
  returnContentOnly: PropTypes.bool,
  loadItemsImmediately: PropTypes.bool,
  preventReloadOnUpdate: PropTypes.bool,
  customNoResultsMessage: PropTypes.string,
  startupMessage: PropTypes.string,
  skipResetOnContentUriChange: PropTypes.bool,
  showProgressIndicator: PropTypes.bool,
  showProgressIndicatorImmediately: PropTypes.bool,
  showOrgAsTitleOnDesktop: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  isPublicApi: PropTypes.bool,
  initialSortTypeForPublicApi: PropTypes.string,
  initialViewTypeForPublicApi: PropTypes.string,
  taskIdForTaskAssignmentContext: PropTypes.string,
  approvalIdForApprovalAssignmentContext: PropTypes.string,
  assetItemForAssetItemContext: PropTypes.object,
  dialogContent: PropTypes.object,
  miscContent: PropTypes.object,
  allowKanban: PropTypes.bool,
  captureReservationParams: PropTypes.object,
  onSetBeginFileUploadFunc: PropTypes.func,
  itemListContainerStyle: PropTypes.object,
  itemListStyle: PropTypes.object,
  onSetUpdateRevisedItemFunction: PropTypes.func,
  onSetGetAllItemsFunction: PropTypes.func,
  onSetSetAllItemsFunction: PropTypes.func,
  onViewTypeChanged: PropTypes.func,
  apiError: PropTypes.object,
  alert: PropTypes.object,
  onAlert: PropTypes.func,
  onItemClick: PropTypes.func,
  disableCardActions: PropTypes.bool,
  onActionCompleted: PropTypes.func,
  groupItemsByContentType: PropTypes.bool,
  noSorting: PropTypes.bool,
  preContent: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(ItemCollectionBase);