// This must be set as a cookie since it is used across multiple browser windows/tabs
  export const GetReloadItemsFlag = props => {
    return props.cookies.get("reloadItems");
  }

  export const SetReloadItemsFlag = props => {
    props.cookies.set("reloadItems", true, { path: "/" });
  }
  
  export const ClearReloadItemsFlag = props => {
    const removeFlagFunc = () => props.cookies.remove("reloadItems", { path: "/" });
    // We execute via timeout to assist all tabs in seeing the call to reload
    setTimeout(removeFlagFunc, 1000);
  }