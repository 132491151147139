import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import TextField from '@material-ui/core/TextField';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import LinearProgress from '@material-ui/core/LinearProgress';
import MoveUpIcon from '@material-ui/icons/ArrowDropUp';
import MoveDownIcon from '@material-ui/icons/ArrowDropDown';
import MoveLeftIcon from '@material-ui/icons/ArrowLeft';
import MoveRightIcon from '@material-ui/icons/ArrowRight';
import NavigateFirstIcon from '@material-ui/icons/FirstPage';
import NavigateLastIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import NavigateToPageIcon from '@material-ui/icons/Description';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/HelpOutline';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import SelectControl from '../../Components/SelectControl';
import NumericTextField from '../../Components/NumericTextField';
import ProgressIndicator from '../../Components/ProgressIndicator';
import AsyncSelectControl from '../../Components/AsyncSelectControl';
import MultiUseDialog from '../../Components/MultiUseDialog';
import DraggableListItem from '../../Components/DraggableListItem';
import DraggableFormTemplateField from './DraggableFormTemplateField';
import ConfigureFieldsDialog from '../../Components/ConfigureFieldsDialog';
import FormTemplateFieldDragLayer from './FormTemplateFieldDragLayer';
import FieldPropertyGrid from '../../Components/FieldPropertyGrid';
import FieldPropertiesDialog from '../../Components/FieldPropertiesDialog';
import FormShareDialog from '../../Components/FormShareDialog';
import CaptureCore from '../../Components/CaptureCore';
import { SortForTabOrder } from '../../Util/FormTemplateFields';
import API, {
  GetFieldsPathForApi,
  GetFormTemplatePageImagesPathForApi,
  GetFormTemplateFieldsPathForApi,
  GetFormTemplateFieldPathForApi,
  GetFormTemplateFieldImagesPathForApi,
  GetFormTemplatesPublicFieldListItemsPathForApi,
  GetPublicVideoPath,
  UploadFiles,
} from '../../Util/api';
import {
  DefaultFormTemplatePageSize,
  FormTemplatePageSizes,
} from '../../Model/FormTemplatePageSizes';
import {
  FormTemplateFieldTypes,
  GetExtendedFormTemplateFields,
} from '../../Model/FormTemplateFieldTypes';
import { GetNewTextField } from '../../Model/Field';
import {
  HandleRouteToFormInput,
  GetFormUrl,
} from '../../Util/FormTemplates';
import {
  GetMinFieldWidth,
} from '../../Util/FormTemplateFields';
import {
  ShareFormTemplates,
} from '../../Util/FormTemplate';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../../Util/Tags';
import { AddMaskType } from '../../Util/Field';
import { RailTabWidth } from '../../Components/UiCore';
import VideoHelpDialog from '../../Components/VideoHelpDialog';
import { BrowserImageMimeTypes } from '../../Util/Image';
import debounce from 'es6-promise-debounce';
import { GetAllFieldsPromise } from '../../Util/Fields';
import { UniqueIdDebouncer } from '../../Util/Debounce';

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const canvasTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    switch (monitor.getItemType()) {
      case "DraggableListItem":
        const draggableListItem = monitor.getItem();
        draggableListItem.onDrop(draggableListItem.Data,
          monitor.getClientOffset(), monitor.getInitialSourceClientOffset(), true);
        break;
      case "DraggableFormTemplateField":
        const draggableFormTemplateField = monitor.getItem();
        draggableFormTemplateField.onDrop(draggableFormTemplateField.FormTemplateField, 
          monitor.getClientOffset(), monitor.getInitialSourceClientOffset(), false);
        break;
      default:
        break;
    }

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // Obtain the dragged item
    switch (monitor.getItemType()) {
      case "DraggableListItem":
        break;
      case "DraggableFormTemplateField":
        // const draggableFormTemplateField = monitor.getItem();
        // component.handleMoveFormTemplateField(draggableFormTemplateField.FormTemplateField, 
        //   monitor.getClientOffset(), monitor.getInitialSourceClientOffset());
        break;
      default:
        break;
    }
  },
}

/**
 * Specifies which props to inject into your component.
 */
function canvasDropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectCanvasDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const toolPaneTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    switch (monitor.getItemType()) {
      case "DraggableFormTemplateField":
        const draggableFormTemplateField = monitor.getItem();
        draggableFormTemplateField.onDrop2(draggableFormTemplateField.FormTemplateField);
        break;
      default:
        break;
    }

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // const draggableFormTemplateField = monitor.getItem();
    // component.handleMoveFormTemplateField(draggableFormTemplateField.Data, monitor.getClientOffset());
  },
}

/**
 * Specifies which props to inject into your component.
 */
function toolPaneDropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectToolPaneDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

// NOTE: _pageWidth should be updated on FormInput.js
// if _toolPaneWidth or _propertyPaneWidth or scaleContainer.width is changed
const _scaleContainerWidth = 1490; // Targeting 1000 pixel canvas width after 15 pixel scrollbar
const _toolPaneWidth = 175;
const _propertyPaneWidth = 300;
const _navBarHeight = 48;
const _navBarPadding = 8;
// const _fieldMaxHeight = 36;
const _getTotalNavBarHeight = () => {
  return _navBarHeight + (2 * _navBarPadding)/*top+bottom padding*/ + 1/*bottom border*/;
}
const _initialFieldWidth = 0.30;

const styles = theme => ({
  outerContainer: {
    height:"100%",
    overflow:"hidden",
    position:"relative",// For ProgressIndicator.constrained
  },
  scaleContainer: {
    height:"100%",
    transformOrigin:"top left",
    overflow:"hidden",
    width:_scaleContainerWidth,
  },
  contentContainer: {
    display:"flex",
    height:"100%",
    overflow:"hidden",
  },
  canvasContainer: {
    height:`calc(100% - ${_getTotalNavBarHeight()}px)`,
    overflowY:"scroll",
    position:"relative",
  },
  canvas: {
    position:"relative",
    width:"100%",
    userSelect: "none",
    backgroundColor:theme.palette.type === "dark" ? theme.palette.background.default : "white",
    backgroundImage: `linear-gradient(${(theme.palette.type === "dark") ? "#000" : "#ddd"} 1px, transparent 1px), linear-gradient(90deg, ${(theme.palette.type === "dark") ? "#000" : "#ddd"} 1px, transparent 1px)`,
    backgroundPosition:"0.5px 0.5px, 0.5px 0.5px",
  },
  pageBackgroundImage: {
    position:"absolute",
    left:0,
    top:0,
    width:"100%",
    height:"100%",
    opacity:0.5,
  },
  imageContainer: {
    width:"100%",
    position:"relative",
  },
  formNavBar: {
    userSelect: "none",
    position:"sticky",
    top:0,
    left:0,
    // This wasn't needed after UiCore updates from Dark Mode work
    // height:_navBarHeight,
    display:"flex",
    alignItems:"center",
    backgroundColor:theme.palette.background.pane,
    zIndex:2,
    padding: _navBarPadding,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  formNavBarFlexEnd: {
    display:"flex",
    flexGrow: 1,
    justifyContent:"flex-end",
    alignItems:"center",
    marginRight:theme.spacing(2),
  },
  toolPane: {
    height:"100%",
    // Must use minWidth and maxWidth instead of width
    minWidth:_toolPaneWidth,
    maxWidth:_toolPaneWidth,
    backgroundColor:theme.palette.background.default,
    userSelect:"none",
  },
  toolPaneTopBar: {
    position:"sticky",
    top:0,
    left:0,
    // This wasn't needed after UiCore updates from Dark Mode work
    // height:_navBarHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: _navBarPadding,
    paddingBottom: _navBarPadding,
    zIndex:2,
    display:"flex",
    alignItems:"center",
  },
  toolPaneList: {
    padding: theme.spacing(1),
  },
  toolBoxTitle: {
    flexGrow: 1,
    paddingLeft:11,
  },
  fieldGrid: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflowY:"auto",
  },
  fieldHeader: {
    backgroundColor:theme.palette.background.default,
  },
  propertyPane: {
    height:"100%",
    // Must use minWidth and maxWidth instead of width
    minWidth:_propertyPaneWidth,
    maxWidth:_propertyPaneWidth,
    backgroundColor:theme.palette.background.default,
    overflowY: "auto",
    userSelect: "none",
  },
  popoverPaper: {
    padding: theme.spacing(2),
  },
  imageFileDropZone: {
    width:"100%",
  },
  uploadProgress: {
  },
  rectSelectContainer: {
    position:"absolute",
    left:0,
    top:0,
    width:"100%",
    height:"100%",
    backgroundColor:"transparent",
    zIndex:1000,
  },
  rectSelect: {
    position:"absolute",
    border:"1px dashed #888",
  },
});

// const _autoPlaceFieldHeight = 40;

const _snapIncrement = 0.01;

class FormTemplateDesignerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      FormTemplateFields: [],
      Fields: [],
      FieldsLoaded: false,
      FieldIsDragging: false,
      PageIndex: 0,
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: false,
      ShowClearAllFieldsConfirmation: false,
      ShowDeleteFieldsConfirmation: false,
      ShowDeleteFormTemplateConfirmation: false,
      ShowConfigureFieldsDialog: false,
      SelectedFormTemplateFieldIds: [],
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      DragHandleOffset: {
        x:0,
        y:0,
      },
      NavigateToPage_PageNumber: "",
      ShowNavigateToPageDialog: false,
      FormUrlCopiedPopoverOpen: false,
      ImageUploadProgressPercent: 0,
      RectSelectStartPosition: null,
      RectSelect: null,
      ShowFieldPropertiesDialog: false,
      FieldToCustomize: {},
      TagListValues: GetTagListValuesFromTagsObject(props.FormTemplate),
      ShowVideoHelpDialog: false,
      ShowFormShareDialog: false,
    }

    this.Scale = 1.0;
    this.ScaleContainerRef = React.createRef();
    this.OuterContainerRef = React.createRef();
    this.FieldGridRef = React.createRef();
    this.CanvasRef = React.createRef();
    this.CanvasContainerRef = React.createRef();
    this.PublicCheckboxGridRef = React.createRef();
    this.RectSelectRef = React.createRef();
    this.RectSelectContainerRef = React.createRef();
    this.PropertyPaneFieldGridRef = React.createRef();
    this.PreDragMousePosition = {x:0,y:0};
    // this.DefaultDropLocations = [/* { pageIndex, active, ID, x, y } */];
    this.ResizeStart = {
      x:0,
      y:0,
      width:0,
    };
    this.ResizingFormTemplateFieldID = null;
    this.ImageUploadCancelToken = null;
    this.FormTemplateFieldUpdateTimestamp = {};

    this.SelectionListItemsUploadedPopoverVisibilityFunction = null;

    this.FieldUniqueIdDebouncer = new UniqueIdDebouncer(250);
  }

  updateFormTemplate = (formTemplate_optional, skipServerUpdate) => {
    if (this.props.onUpdateFormTemplate) {
      this.props.onUpdateFormTemplate(this.state.FormTemplateFields.length, formTemplate_optional,
        false, skipServerUpdate);
    }
  }

  handleUpdateFormTemplatePageCount = pageCount => {
    this.props.onFormTemplateMultiPropertyChange([{name:"PageCount", value:pageCount}]);
    if (this.state.PageIndex > pageCount-1) {
      this.handleGoToPage(pageCount-1);
    }
  }

  getAspect = (width, height) => {
    return height/width;
  }

  handleUpdateFormTemplatePageWidth = pageIndex => e => {
    const pageWidth = parseFloat(this.getValidPageDimensionString(e.target.value));
    const formTemplatePage = (pageIndex >= 0)
      ? this.getFormTemplatePage()
      : null;
    const aspect = this.getAspect(
      pageWidth,
      (pageIndex < 0)
        ? this.props.FormTemplate.PageHeight
        : formTemplatePage.PageHeight,
    );
    const values = [
      {name:"PageWidth", value:pageWidth},
      {name:"Aspect", value:aspect},
    ];
    if (pageIndex < 0) {
      this.props.onFormTemplateMultiPropertyChange(values);  
    } else {
      this.props.onFormTemplatePageMultiPropertyChange(pageIndex, values);
    }

    this.updateComponentSizes(aspect);
  }

  handleUpdateFormTemplatePageHeight = pageIndex => e => {
    const pageHeight = parseFloat(this.getValidPageDimensionString(e.target.value));
    const formTemplatePage = (pageIndex >= 0)
      ? this.getFormTemplatePage()
      : null;
    const aspect = this.getAspect(
      (pageIndex < 0)
        ? this.props.FormTemplate.PageWidth
        : formTemplatePage.PageWidth,
      pageHeight,
    );
    const values = [
      {name:"PageHeight", value:pageHeight},
      {name:"Aspect", value:aspect},
    ];
    if (pageIndex < 0) {
      this.props.onFormTemplateMultiPropertyChange(values);  
    } else {
      this.props.onFormTemplatePageMultiPropertyChange(pageIndex, values);
    }

    this.updateComponentSizes(aspect);
  }

  handleUpdateFormTemplatePageUseCustomSize = pageIndex => e => {
    this.handleUpdateFormTemplatePageBoolPropertyFromCheckbox(pageIndex, "UseCustomSize")(e);
    const currentFormTemplatePage = this.getFormTemplatePage();
    if (currentFormTemplatePage.PageSize) {
      this.updateComponentSizesByDocumentOrCurrentPage(currentFormTemplatePage);
    } else {
      this.handleUpdateCurrentPageSize(this.getCurrentFormTemplatePageSize());
    }
  }

  handleUpdateFormTemplatePageBoolPropertyFromCheckbox = (pageIndex, propertyName) => e => {
    this.props.onFormTemplatePageMultiPropertyChange(pageIndex, [
      {name:propertyName, value:e.target.checked},
    ]);
  }

  handleFormTemplatePageImageComplete = (reservation, file, resp) => {
    if (resp && resp.data && resp.data.length) {
      const formTemplatePage = resp.data[0];
      const formTemplate = {...this.props.FormTemplate};
      const formTemplatePages = formTemplate.Pages.filter(p => p.PageIndex !== formTemplatePage.PageIndex);
      formTemplatePages.push(formTemplatePage);
      formTemplate.Pages = formTemplatePages;
      this.updateFormTemplate(formTemplate, true);
    }
  }

  updateComponentSizesByDocumentOrCurrentPage = currentFormTemplatePage => {
    if (currentFormTemplatePage.UseCustomSize) {
      const pageWidth = currentFormTemplatePage.PageWidth || DefaultFormTemplatePageSize.PageWidth;
      const pageHeight = currentFormTemplatePage.PageHeight || DefaultFormTemplatePageSize.PageHeight;
      this.updateComponentSizes(this.getAspect(pageWidth, pageHeight));
    } else {
      const formTemplate = this.props.FormTemplate;
      this.updateComponentSizes(this.getAspect(formTemplate.PageWidth, formTemplate.PageHeight));      
    }
  }

  getValidPageDimensionString = s => {
    const min = 1.0;
    const max = 22;
    let m = parseFloat(s);
    if (Number.isNaN(m)) {
      m = min;
    } else if (m > max) {
      m = parseFloat(s.substring(0, 1));
    }
    m = Math.min(Math.max(min, m), max);
    m = m.toString();
    if (!m.includes(".")) {
      m += ".0";
    }
    return m;
  }

  handleUpdateFormTemplatePageSize = pageSize => {
    const size = FormTemplatePageSizes.find(s => s.PageSize === pageSize);
    if (size) {
      this.props.onFormTemplateMultiPropertyChange([
        {name:"PageSize",value:size.PageSize},
        {name:"PageWidth",value:size.PageWidth},
        {name:"PageHeight",value:size.PageHeight},
        {name:"Aspect",value:size.Aspect},
      ]);
      this.updateComponentSizes(size.Aspect);
    }
  }

  handleUpdateCurrentPageSize = pageSize => {
    const size = FormTemplatePageSizes.find(s => s.PageSize === pageSize);
    if (size) {
      this.props.onFormTemplatePageMultiPropertyChange(this.state.PageIndex, [
        {name:"PageSize",value:size.PageSize},
        {name:"PageWidth",value:size.PageWidth},
        {name:"PageHeight",value:size.PageHeight},
        {name:"Aspect",value:size.Aspect},
      ]);
      this.updateComponentSizes(size.Aspect);
    }
  }

  getPageSizeOptions = () => {
    return FormTemplatePageSizes
      .map(s => {
        return {
          label:s.Name,
          value:s.PageSize,
        };
      });
  }

  getCurrentFormTemplatePageSize = () => {
    return this.getFormTemplatePage().PageSize || DefaultFormTemplatePageSize.PageSize;
  }

  handleUpdateRenderType = renderType => {
    this.props.onFormTemplateMultiPropertyChange([{name:"RenderType", value:renderType}]);
  }

  handleUpdateFormTemplateIsPublic = e => {
    this.props.onFormTemplateMultiPropertyChange([{name:"IsPublic", value:e.target.checked}]);
  }

  handleUpdateFormTemplateSnapToGrid = e => {
    this.props.onFormTemplateMultiPropertyChange([{name:"DisableSnapToGrid", value:!e.target.checked}]);
  }

  handleSetFormUrlCopiedPopoverVisibility = visible => {
    this.setState({ FormUrlCopiedPopoverOpen: visible });
    if (visible) {
      setTimeout(() => this.handleSetFormUrlCopiedPopoverVisibility(false), 1500);
    }
  }

  loadFields = clearSelectedFields => {
    if (clearSelectedFields) {
      this.setState({
        SelectedFormTemplateFieldIds: [],
      });
    }
    return this.handleGetFields()
      .then(() => {
        return this.getFormTemplateFields();
      });
  }

  handleGetFields = () => {
    this.setState({ShowProgressIndicatorImmediately: true});

    return GetAllFieldsPromise(this.props.organizationId, this.props.projectId)
      .then(resp => {
        const Fields = resp.data.Fields;
        this.setState({
          Fields,
          FieldsLoaded: true,
          // ShowProgressIndicatorImmediately: false,
        });
        return Fields;
      })
      .catch(this.handleApiError);
  }

  getFormTemplateFields = formTemplate => {
    if (!formTemplate) {
      formTemplate = this.props.FormTemplate;
    }
    this.setState({ShowProgressIndicatorImmediately: true});

    return API.get(GetFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID))
      .then(resp => {
        let fields = SortForTabOrder(GetExtendedFormTemplateFields(resp.data));
        // Add local MaskType property
        fields.forEach(f => {
          if (f.Field.Mask) {
            AddMaskType(f.Field);
          }
          // This will cause selection lists to reload
          if (f.Field.DisplaySelectionList) {
            f.Field.UpdateId = new Date();
          }
        });
        this.setState({
          FormTemplateFields: fields,
          ShowProgressIndicatorImmediately: false,
        });
        return fields;
      })
      .catch(this.handleApiError);
  }

  handleCreateFormTemplateField = field => {
    field.PageIndex = this.state.PageIndex;
    // Defaults
    switch (field.Type) {
      case "Upload":
      field.Field.Required = true;
        break;
      default:
        break;
    }
    return this.createFormTemplateFields([field], false);
  }

  createFormTemplateFields = (newFields, clearExisting) => {
    this.setState({
      ShowProgressIndicator: true,
      SelectedFormTemplateFieldIds: [],
    });
    return API.post(GetFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID), newFields, { params: { clearExisting }})
      .then(resp => {
        let returnedFields = resp.data;
        let existingFields = [...this.state.FormTemplateFields];
        let nonNewFields = existingFields.filter(ef => !ef.IsNew);
        let allFields = [...nonNewFields, ...GetExtendedFormTemplateFields(returnedFields)];
        // TODO
        // this.updateDefaultDropLocationId(existingFieldFinder[0].ID, returnedField.ID);
        this.setState({
          FormTemplateFields: SortForTabOrder(allFields),
          ShowProgressIndicator: false,
          // Select first new field
          SelectedFormTemplateFieldIds: [returnedFields[0].ID],
        });
        this.updateFormTemplate();
        return allFields;
      })
      .catch(this.handleApiError); 
  }

  handleUpdateFormTemplateField = (formTemplateField, timestamp) => this.FieldUniqueIdDebouncer.debounceByUniqueId(
    formTemplateField.ID,
    () => this.handleUpdateFormTemplateFieldNoDebounce(formTemplateField, timestamp)
  );

  handleUpdateFormTemplateFieldNoDebounce = (formTemplateField, timestamp) => {
    return API.put(GetFormTemplateFieldPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID, formTemplateField.ID), formTemplateField)
      .then(resp => { return {field: resp.data, timestamp}})
      .catch(this.handleApiError); 
  };

  handleUpdateFormTemplateFields = debounce((formTemplateFields, timestamp) => {
    return API.put(GetFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID), formTemplateFields)
      .then(resp => { return {fields: resp.data, timestamp}})
      .catch(this.handleApiError); 
  }, 250);

  handleDeleteFormTemplateFields = (FieldsToDelete, DeleteAll) => {
    let originalFields = [...this.state.FormTemplateFields];
    let originalSelectedFieldIds = [...this.state.SelectedFormTemplateFieldIds];
    let remainingFields = (FieldsToDelete) 
      ? originalFields.filter(f => FieldsToDelete.map(field => field.ID).indexOf(f.ID) < 0)
      : (DeleteAll) ? [] : originalFields;
    let remainingSelectedFieldIds = (FieldsToDelete)
      ? originalSelectedFieldIds.filter(sId => FieldsToDelete.map(field => field.ID).indexOf(sId) < 0)
      : (DeleteAll) ? [] : originalSelectedFieldIds;
    this.setState({ 
      FormTemplateFields: remainingFields,
      SelectedFormTemplateFieldIds: remainingSelectedFieldIds,
    });
    return API.delete(GetFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID),
        {
          data: {
            DeleteAll,
            IDs: (FieldsToDelete) ? FieldsToDelete.map(f => f.ID) : [],
          }
        }
      )
      .then(() => {
        this.updateFormTemplate();
      })
      .catch(err => {
        this.setState({
          FormTemplateFields: originalFields,
          SelectedFormTemplateFieldIds: originalSelectedFieldIds,
        });
        this.handleApiError(err);
      });  
  }

  handleDeleteAllFormTemplateFields = () => {
    this.setState({ ShowClearAllFieldsConfirmation: false });
    return this.handleDeleteFormTemplateFields(null, true);
  }

  deleteFormTemplateField = field => {
    let originalFields = [...this.state.FormTemplateFields];
    let originalSelectedFieldIds = [...this.state.SelectedFormTemplateFieldIds];
    this.setState({
      FormTemplateFields: originalFields
        .filter(f => f.ID !== field.ID),
      SelectedFormTemplateFieldIds: originalSelectedFieldIds
        .filter(sId => sId !== field.ID),
    });
    return API.delete(GetFormTemplateFieldPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID, field.ID))
      .then(() => {
        this.updateFormTemplate();
      })
      .catch(err => {
        this.setState({
          FormTemplateFields: SortForTabOrder(this.state.FormTemplateFields.concat(field)),
          SelectedFormTemplateFieldIds: this.state.SelectedFormTemplateFieldIds.concat(field.ID),
        });
        this.handleApiError(err);
      });  
  }

  handleUpdateFormTemplateFieldProperty = (fieldId, propertyName, value, isEmbeddedFieldValue) => {
    // this.setState({ ShowProgressIndicatorImmediately: true });
    let fields = [...this.state.FormTemplateFields];
    let field = fields.find(f => f.ID === fieldId);
    if (field) {
      let updatedField = field;
      const needsReset = (propertyName === "FieldID" || propertyName === "Type");
      if (needsReset) {
        const formTemplateFieldType = FormTemplateFieldTypes.find(t => t.Type === value);
        if (formTemplateFieldType) {
          const { 
            ID,
            PageIndex,
            X,
            Y,
            ControlSize,
            Field,
            FormTemplateSourceFieldID,
            FormTemplateSourceFullName,
            FormTemplateSourcePartialName,
            FormTemplateSourceObjectName,
            JavaScript,
            Width,
          } = field;
          updatedField = {
            ...formTemplateFieldType,
            IsNew: true,
            ID,
            PageIndex,
            X,
            Y,
            ControlSize,
            FormTemplateSourceFieldID,
            FormTemplateSourceFullName,
            FormTemplateSourcePartialName,
            FormTemplateSourceObjectName,
            JavaScript,
            Width: (propertyName === "FieldID") ? _initialFieldWidth : Width,
          };
          if (updatedField.Field) {
            updatedField.Field.HideLabel = Field.HideLabel;
            updatedField.Field.ReadOnly = Field.ReadOnly;
          }
          fields = [...this.state.FormTemplateFields.filter(f => f.ID !== fieldId)];
          fields = SortForTabOrder(fields.concat(updatedField));
        }
      }

      if (isEmbeddedFieldValue) {
        updatedField.Field[propertyName] = value;
        let newFields = [...this.state.FormTemplateFields.filter(f => f.ID !== fieldId)];
        this.setState({
          FormTemplateFields: SortForTabOrder(GetExtendedFormTemplateFields(newFields.concat(updatedField))),
        });
        this.FormTemplateFieldUpdateTimestamp[updatedField.ID] = new Date();
        return this.handleUpdateFormTemplateField(updatedField, this.FormTemplateFieldUpdateTimestamp[updatedField.ID])
          .then(returnPackage => {
            if (returnPackage.timestamp === this.FormTemplateFieldUpdateTimestamp[updatedField.ID]) {
              let newFields = [...this.state.FormTemplateFields.filter(f => f.ID !== fieldId)];
              this.setState({
                FormTemplateFields: SortForTabOrder(GetExtendedFormTemplateFields(newFields.concat(returnPackage.field))),
                // ShowProgressIndicatorImmediately: false,
              });
            }
          });
      } else {
        updatedField[propertyName] = value;
        let newFields = SortForTabOrder(GetExtendedFormTemplateFields(fields));
        this.setState({ FormTemplateFields: newFields });
        return this.handleUpdateFormTemplateField(updatedField)
          .then(returnPackage => {
            if (needsReset) {
              newFields = newFields.filter(f => f.ID !== returnPackage.field.ID);
              this.setState({
                FormTemplateFields: SortForTabOrder(GetExtendedFormTemplateFields(newFields.concat(returnPackage.field))),
                // ShowProgressIndicatorImmediately: false,
              });
            }
          });
      }
    }
  }

  getRelativePosition = (/*widthPercent, */clientOffset, initialClientOffset_optional, 
    allowNegative, ignoreCanvasScroll, offsetTopLeftCanvasSiblings) => {

    let yScrollOffset = 0;
    if (ignoreCanvasScroll && this.CanvasContainerRef.scrollTop > 0) {
      const yScrollC = _snapIncrement * this.CanvasRef.clientHeight;
      yScrollOffset = this.CanvasContainerRef.scrollTop % yScrollC;
    }

    const scaleY = this.OuterContainerRef.clientHeight / this.ScaleContainerRef.clientHeight;

    const canvasContainerRect = this.CanvasContainerRef.getBoundingClientRect();
    let x = clientOffset.x, y = clientOffset.y;
    if (initialClientOffset_optional) {
      let mouseDownOffset = {
        x:this.PreDragMousePosition.x - initialClientOffset_optional.x,
        y:this.PreDragMousePosition.y - initialClientOffset_optional.y,
      };
      let scrollLeft = (ignoreCanvasScroll)
        ? 0 : this.CanvasContainerRef.scrollLeft * this.Scale;
      let scrollTop = (ignoreCanvasScroll)
        ? yScrollOffset
        : this.CanvasContainerRef.scrollTop * scaleY;//this.Scale;
      x += scrollLeft - canvasContainerRect.x - mouseDownOffset.x;
      y += scrollTop - canvasContainerRect.y - mouseDownOffset.y;
    }

    x = x / this.Scale;
    y = y / scaleY;//this.Scale;
    
    // Min limits
    if (!allowNegative) {
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
    }

    // // Max limits - Also check "handleMoveSelectedFields for X Y max movement"
    // const canvasRect = this.CanvasRef.getBoundingClientRect();
    // let adjustedWidth = (canvasRect.width*(widthPercent*100))/100;
    // if (x > canvasRect.width /*- adjustedWidth*/ - 2) {
    //   x = canvasRect.width /*- adjustedWidth*/ - 2;
    // }
    // if (y > canvasRect.height - _fieldMaxHeight - 3) {
    //   y = canvasRect.height - _fieldMaxHeight - 3;
    // }

    let xP = x/this.CanvasRef.clientWidth;
    let yP = y/this.CanvasRef.clientHeight;

    if (!this.props.FormTemplate.DisableSnapToGrid) {
      xP = Math.round(xP / _snapIncrement) * _snapIncrement;
      xP = Number(Math.round(xP+'e3')+'e-3');
      x = xP * this.CanvasRef.clientWidth;
      yP = Math.round(yP / _snapIncrement) * _snapIncrement;
      yP = Number(Math.round(yP+'e3')+'e-3');
      y = yP * this.CanvasRef.clientHeight;
      // If the canvas scroll is to be ignored, we need to account for the amount of actual
      // scroll in play and offset the snap position by that amount.
      // This is primarily for the drag layer.
      if (ignoreCanvasScroll && this.CanvasContainerRef.scrollTop > 0) {
        y -= yScrollOffset;
      }
    }

    if (offsetTopLeftCanvasSiblings) {
      x += _toolPaneWidth;
      y += _getTotalNavBarHeight();
    }

    return { 
      x: x,
      y: y,
      xPercent: xP,
      yPercent: yP,
    };
  }

  getNewFormTemplateFieldFromType = fieldType => {
    let f = {...fieldType};
    f.ID = (new Date()).getTime().toString();
    return f;
  }

  // handleMoveFormTemplateField = (formTemplateField, clientOffset, initialClientOffset) => {
  //   let pos = this.getRelativePosition(/*f.WidthPercent, */clientOffset, initialClientOffset);
  //   let fields = [...this.state.FormTemplateFields];
  //   let field = fields.find(f => f.ID === formTemplateField.ID);
  //   if (field) {
  //     field.X = pos.xPercent;
  //     field.Y = pos.yPercent;
  //     this.setState({FormTemplateFields:SortForTabOrder(fields)});
  //   }
  // }

  handleDropFormTemplateFieldOnCanvas = (formTemplateFieldOrType, clientOffset, initialClientOffset, isNew) => {
    if (!this.CanvasRef.getBoundingClientRect) {
      return;
    }

    let fields = this.state.FormTemplateFields;
    let f = {};
    if (isNew) {
      f = this.getNewFormTemplateFieldFromType(formTemplateFieldOrType);
    } else {
      f = fields.filter(sf => sf.ID === formTemplateFieldOrType.ID)[0];
      // this.setDefaultDropLocationInactive(f.ID);
    }
    if (f) {
      let pos = this.getRelativePosition(/*f.WidthPercent, */clientOffset, initialClientOffset);
      if (pos.xPercent < 1 - _snapIncrement) {
        f.X = pos.xPercent;
      }
      if (pos.yPercent < 1 - _snapIncrement) {
        f.Y = pos.yPercent;
      }
      if (isNew) {
        // f.PageIndex = this.state.DocumentContentPackage.PageIndex;
        fields = fields.concat(f);
        this.setState({ FormTemplateFields: SortForTabOrder(fields) });
        this.handleCreateFormTemplateField(f);
      } else {
        this.handleUpdateFormTemplateField(f);
      }
    }
  }

  handleDropFormTemplateFieldOnToolPane = formTemplateField => {
    this.deleteFormTemplateField(formTemplateField);
  }

  handleFormTemplateFieldMouseDown = (e, id) => {
    this.PreDragMousePosition = {x:e.clientX, y:e.clientY};
    e.stopPropagation();
    let SelectedFormTemplateFieldIds = [...this.state.SelectedFormTemplateFieldIds];
    let isMulti = e.ctrlKey;
    let field = this.state.FormTemplateFields.find(f => f.ID === id);
    if (field) {
      if (isMulti) {
        if (!SelectedFormTemplateFieldIds.filter(i => i === field.ID).length) {
          SelectedFormTemplateFieldIds.push(field.ID);
        }
      } else {
        SelectedFormTemplateFieldIds = [field.ID];
      }
      this.setState({SelectedFormTemplateFieldIds});
    }
  }

  handleFormTemplateFieldDefaultValueChange = (formTemplateFieldId, value, values) => {
    let fields = [...this.state.FormTemplateFields];
    let formTemplateField = fields.find(f => f.ID === formTemplateFieldId);
    if (formTemplateField) {
      switch (formTemplateField.Type) {
        case "CheckBox":
          formTemplateField.DefaultValue = value.toString();
          break;
        case "RadioGroup":
          if (formTemplateField.DefaultValue === value) {
            // Deselect
            formTemplateField.DefaultValue = "";
          } else {
            // Select
            formTemplateField.DefaultValue = value;
            // Deselect any other radio button having the same FormTemplateField.Field.Name
            // This is for handling PDF forms, where radio groups are multiple controls.
            for (let i = 0; i < fields.length; i++) {
              let ftf = fields[i];
              if (ftf.ID !== formTemplateFieldId
                && ftf.Field.Name === formTemplateField.Field.Name
                && ftf.Type === "RadioGroup") {
                if (ftf.DefaultValue) {
                  ftf.DefaultValue = "";
                  this.handleUpdateFormTemplateFieldNoDebounce(ftf);
                }
              }
            }
          }
          break;
        default:
          formTemplateField.DefaultValue = value;
          formTemplateField.DefaultValues = values;
          // DefaultValues must exist as a JSON array
          if (Array.isArray(formTemplateField.DefaultValues)) {
            formTemplateField.DefaultValues = JSON.stringify(formTemplateField.DefaultValues);
          }
          break;
      }
      this.setState({FormTemplateFields: fields});
      this.handleUpdateFormTemplateField(formTemplateField);
    }
  }

  // setDefaultDropLocationInactive = id => {
  //   let location = this.DefaultDropLocations.find(l => l.ID === id);
  //   if (location) {
  //     location.active = false;
  //   }
  // }

  // updateDefaultDropLocationId = (oldId, newId) => {
  //   let locations = this.DefaultDropLocations;
  //   let location = locations.find(l => l.ID === oldId);
  //   if (location) {
  //     location.ID = newId;
  //   }
  // }

  // Needs work - Team opted to shelve this
  // handleCreateNewFormTemplateFieldAtDefaultLocation = formTemplateFieldType => {
  //   let f = this.getNewFormTemplateFieldFromType(formTemplateFieldType);

  //   // Get default drop location
  //   let maxDefaultPositions = 10;
  //   let defaultPos = this.props.theme.spacing(3);
  //   let increment = _autoPlaceFieldHeight + this.props.theme.spacing(2);
  //   // let pageIndex = this.state.DocumentContentPackage.PageIndex;
  //   let pageIndex = 0;
  //   let locations = [...this.DefaultDropLocations.filter(l => l.pageIndex === pageIndex)];
  //   let location = {};
  //   if (!locations.length) {
  //     location = { pageIndex, active: true, ID: f.ID, x: defaultPos, y: defaultPos };
  //     this.DefaultDropLocations = this.DefaultDropLocations.concat(location);
  //   } else {
  //     let ddl = locations.filter(l => !l.active);
  //     if (ddl.length) {
  //       location = { pageIndex, active: true, ID: f.ID, x: ddl[0].x, y: ddl[0].y };
  //       this.DefaultDropLocations.splice(this.DefaultDropLocations.indexOf(ddl[0]), 1, location);
  //     } else {
  //       location = locations[locations.length-1];
  //       if (locations.length < maxDefaultPositions) {
  //         // We're only incrementing the y value for now
  //         let x = location.x;
  //         let y = location.y + increment;
  //         location = { pageIndex, active: true, ID: f.ID, x, y };
  //         this.DefaultDropLocations = this.DefaultDropLocations.concat(location);
  //       }
  //     }
  //   }
  //   let clientOffset = { x: location.x, y: location.y };
  //   this.handleDropFormTemplateFieldOnCanvas(f, clientOffset, undefined, true);
  // }

  handleFieldSelectionListFilter = filter => {
    const fields = this.state.Fields
      .filter(f => 
        // Image type not handled yet
        f.Type !== "FieldType_Image"
        && f.Name.toLowerCase().startsWith(filter.toLowerCase()))
      .map(f => {
        return {
          label:f.Name,
          value:f.ID,
        };
      })
    return Promise.resolve(fields);
  }

  handleFieldChange = option => {
    if (this.state.SelectedFormTemplateFieldIds.length === 1) {
      let field = this.state.FormTemplateFields.find(f => f.ID
        === this.state.SelectedFormTemplateFieldIds[0]);
      if (field) {
        this.setState({ShowProgressIndicatorImmediately: true});
        const fieldId = (option) ? option.value : "";
        this.handleUpdateFormTemplateFieldProperty(field.ID, "FieldID", fieldId)
          .then(() => {
            this.setState({ShowProgressIndicatorImmediately: false});
          })
      }
    }
  }

  handleAddFieldToCurrentProjectAndSelectedFormTemplateField = Name => {
    if (!Name) {
      return;
    }
    const currentFormTemplateFieldId = this.state.SelectedFormTemplateFieldIds[0];
    this.setState({ShowProgressIndicatorImmediately: true});
    const newField = GetNewTextField(Name);
    API.post(GetFieldsPathForApi(this.props.organizationId, this.props.projectId),
      [newField])
      .then(resp => {
        const returnedNewField = resp.data[0];
        this.handleUpdateFormTemplateFieldProperty(currentFormTemplateFieldId, "FieldID", returnedNewField.ID)
          .then(() => {
            this.setState({ShowProgressIndicatorImmediately: false});
            this.handleSetShowFieldPropertiesDialog(true, returnedNewField);
          });
      })
      .catch(this.handleApiError);
  }

  handleSetShowFieldPropertiesDialog = (visible, field) => {
    this.setState({
      ShowFieldPropertiesDialog: visible,
      FieldToCustomize: field || {},
    });
    if (!visible) {
      this.loadFields(false);
    }
  }

  handleSetToolboxMenuVisibility = visible => event => {
    this.setState({ ToolboxMenuAnchorEl: (visible) ? event.currentTarget : null });
  }

  handleSetClearAllFieldsConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowClearAllFieldsConfirmation: visible,
    });
  }

  handleSetDeleteFieldsConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDeleteFieldsConfirmation: visible,
    });
  }

  handleSetDeleteFormTemplateConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDeleteFormTemplateConfirmation: visible,
    });
  }

  handleSetShowConfigureFieldsDialogVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowConfigureFieldsDialog: visible,
    });
    if (!visible) {
      this.loadFields(true);
    }
  }

  handleWindowKeyDown = e => {
    if (this.state.SelectedFormTemplateFieldIds.length) {
      const fieldPropertyIsFocused = this.PropertyPaneFieldGridRef
        && document.activeElement.parentElement
        && this.PropertyPaneFieldGridRef.contains(document.activeElement.parentElement);
      if (!fieldPropertyIsFocused) {
        switch (e.key) {
          case "ArrowUp":
            e.preventDefault();
            this.handleMoveSelectedFieldsUp();
            break;
          case "ArrowDown":
            e.preventDefault();
            this.handleMoveSelectedFieldsDown();
            break;
          case "ArrowLeft":
            e.preventDefault();
            this.handleMoveSelectedFieldsLeft();
            break;
          case "ArrowRight":
            e.preventDefault();
            this.handleMoveSelectedFieldsRight();
            break;
          case "Delete":
            e.preventDefault();
            this.handleSetDeleteFieldsConfirmationVisibility(true)();
            break;
          default:
            break;
        }
      }
    }
  }

  getFormTemplatePage = PageIndex => {
    if (PageIndex === undefined || PageIndex === null) {
      PageIndex = this.state.PageIndex;
    }
    let currentFormTemplatePage = {
      PageIndex,
      PageWidth: null,
      PageHeight: null,
      Aspect: null,
    };
    if (this.props.FormTemplate.Pages) {
      const page = this.props.FormTemplate.Pages
        .find(p => p.PageIndex === PageIndex);
      if (page) {
        currentFormTemplatePage = page;
      }
    }
    return currentFormTemplatePage;
  }

  handleWindowResize = () => {
    this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage());
  }

  updateComponentSizes = pageAspect => {
    if (!this.OuterContainerRef
      || !this.FieldGridRef
      || !this.ScaleContainerRef
      || !this.CanvasRef
      || !this.props.FormTemplate) {
      return;
    }
    this.Scale = this.OuterContainerRef.clientWidth / this.ScaleContainerRef.clientWidth;
    // ScaleContainer transformf
    this.ScaleContainerRef.style.transform = `scale(${this.Scale})`;
    // ScaleContainer height
    this.ScaleContainerRef.style.height = `${this.OuterContainerRef.clientHeight / this.Scale}px`;
    // FieldGrid height
    this.FieldGridRef.style.height = `${(this.OuterContainerRef.clientHeight / this.Scale) - _getTotalNavBarHeight()}px`;
    // Canvas height
    if (!pageAspect) {
      pageAspect = this.props.FormTemplate.Aspect;
    }
    this.CanvasRef.style.height = `${pageAspect * this.CanvasRef.clientWidth}px`;
  }

  handleHorizDragHandleMouseDown = formTemplateFieldId => e => {
    e.stopPropagation();
    e.preventDefault();
    let field = this.state.FormTemplateFields.find(f => f.ID === formTemplateFieldId);
    if (field) {
      this.ResizeStart = {
        width: Math.max(GetMinFieldWidth(field), field.Width),
        x: e.clientX / this.CanvasRef.clientWidth,
      };
      this.ResizingFormTemplateFieldID = formTemplateFieldId;
    }
  }

  handleCanvasMouseDown = e => {
    const x = e.clientX - RailTabWidth;
    const y = e.clientY;
    this.PreDragMousePosition = {x,y};
    let stateToUpdate = {
      RectSelectStartPosition: {
        x, 
        y,
        shiftKey: e.shiftKey,
      },
    };
    if (!e.shiftKey) {
      stateToUpdate.SelectedFormTemplateFieldIds = [];
    }
    this.setState(stateToUpdate);
  }

  handleCanvasMouseMove = e => {
    // Field resize
    if (this.ResizingFormTemplateFieldID) {
      let xOffset = ((e.clientX / this.CanvasRef.clientWidth) - this.ResizeStart.x)/this.Scale;
      let stateToUpdate = {
        DragHandleOffset: {
          x: xOffset,
        },
      };
      let fields = [...this.state.FormTemplateFields];
      let field = fields.find(f => f.ID === this.ResizingFormTemplateFieldID);
      if (field) {
        const minFieldWidth = GetMinFieldWidth(field);
        let width = (this.props.FormTemplate.DisableSnapToGrid)
          ? this.ResizeStart.width + xOffset
          : Math.round((this.ResizeStart.width + xOffset) / _snapIncrement) * _snapIncrement;
        if (width < minFieldWidth) {
          width = minFieldWidth;
        }
        field.Width = width;
        stateToUpdate.FormTemplateFields = fields;
      }
      this.setState(stateToUpdate);
    }
  }

  handleRectSelectContainerMouseMove = e => {
    let s = {...this.state.RectSelectStartPosition};
    const x = e.clientX - RailTabWidth;
    if (s) {
      const toolPaneWidth = _toolPaneWidth * this.Scale;
      const navBarHeight = _getTotalNavBarHeight();
      const scrollOffset = this.CanvasContainerRef.scrollTop * this.Scale;
      let left = (
        (x > s.x) ? (s.x - toolPaneWidth) : (x - toolPaneWidth)
      ) / this.Scale;
      let top = (
        (
          (e.clientY > s.y)
            ? s.y - (navBarHeight * this.Scale) + scrollOffset
            : e.clientY - (navBarHeight * this.Scale) + scrollOffset
        ) - navBarHeight
      ) / this.Scale;
      let width = Math.abs(x - s.x) / this.Scale;
      let height = Math.abs(e.clientY - s.y) / this.Scale;
      this.setState({
        RectSelect: {
          left,
          top,
          width,
          height,
        }
      });
    }
  }

  handleProcessRectSelect = e => {
    if (this.state.RectSelectStartPosition) {
      let rss = {...this.state.RectSelectStartPosition};
      let rs = {...this.state.RectSelect};
      let SelectedFormTemplateFieldIds = (rss.shiftKey)
        ? [...this.state.SelectedFormTemplateFieldIds]
        : [];
      this.state.FormTemplateFields.forEach(f => {
        let rsLeftP = rs.left / this.CanvasContainerRef.clientWidth;
        let rsRightP = (rs.left + rs.width) / this.CanvasContainerRef.clientWidth; 
        let rsTopP = rs.top / this.CanvasRef.clientHeight;
        let rsBottomP = (rs.top + rs.height) / this.CanvasRef.clientHeight;
        if (f.PageIndex === this.state.PageIndex
          && rsLeftP < f.X && f.X < rsRightP
          && rsTopP < f.Y && f.Y < rsBottomP) {
          SelectedFormTemplateFieldIds.push(f.ID);
        }
      });
      this.setState({
        SelectedFormTemplateFieldIds,
        RectSelectStartPosition: null,
        RectSelect: null,
      });
    }
  }

  handleCanvasMouseUp = e => {
    // Field resize
    if (this.ResizingFormTemplateFieldID) {
      let fields = [...this.state.FormTemplateFields];
      let field = fields.find(f => f.ID === this.ResizingFormTemplateFieldID);
      if (field && field.Width !== this.ResizeStart.width) {
        this.handleUpdateFormTemplateFieldProperty(this.ResizingFormTemplateFieldID, "Width", field.Width);
      }
      this.ResizingFormTemplateFieldID = null;
    }
  }

handleGoToPage = PageIndex => {
    this.handleSetShowNavigateToPageDialogVisibility(false);
    if (PageIndex === undefined || PageIndex === null) {
      PageIndex = this.state.NavigateToPage_PageNumber-1;
    }
    let pageCount = this.props.FormTemplate.PageCount;
    if (pageCount && PageIndex > -1 && PageIndex < pageCount) {
      this.setState({
        PageIndex,
        SelectedFormTemplateFieldIds: [],
      });
      this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage(PageIndex));
      this.CanvasContainerRef.scrollTop = 0;
    }
  }

  handleSetShowNavigateToPageDialogVisibility = visible => {
    this.setState({ NavigateToPage_PageNumber: "", ShowNavigateToPageDialog: visible });
  }

  handleNavigateToPage_PageNumberValueChanged = e => {
    let value = "";
    if (e && e.target) {
      value = e.target.value;
    }
    this.setState({ NavigateToPage_PageNumber: value });      
  }

  handleImageUploadProgress = (fileName, totalSize, completedSize, completedAtServer) => {
    let percentComplete = 100 * ((1 + completedSize) / totalSize);
    if (percentComplete >= 100.0) {
      this.setState({ImageUploadProgressPercent: 0});
    } else {
      this.setState({ImageUploadProgressPercent: percentComplete});
    }
  }

  handleImageFileDrop = (formTemplateFieldId, files) => {
    // Reset some items
    this.ImageUploadCancelToken = axios.CancelToken.source();

    let reservationUri = GetFormTemplateFieldImagesPathForApi(this.props.organizationId, this.props.projectId,
      this.props.FormTemplate.ID, formTemplateFieldId);

    let onCompletedFileHandler = async (file, formTemplateFieldImageUpload) => {
      formTemplateFieldImageUpload.ContentType = file.type;

      return await API.put(reservationUri, formTemplateFieldImageUpload,
        {
          params: {
            uniqueId: formTemplateFieldImageUpload.UniqueId,
          }
        })
        .then(resp => {
          let fields = [...this.state.FormTemplateFields].filter(f => f.ID !== formTemplateFieldId)
          fields.push(resp.data);
          this.setState({FormTemplateFields: SortForTabOrder(fields)});
          return { resp };
        })
        .catch(err => { return { err }; });
    }

    UploadFiles([files[0]], reservationUri, {}, this.ImageUploadCancelToken, this.handleImageUploadProgress, 
      onCompletedFileHandler, this.handleAlert, this.handleApiError);
  }

  handleChangeFieldProperty = formTemplateFieldId => updateFunc => {
    let fields = [...this.state.FormTemplateFields];
    let field = fields.find(f => f.ID === formTemplateFieldId);
    if (field) {
      updateFunc(field.Field);
      this.setState({FormTemplateFields: fields});
      this.handleUpdateFormTemplateField(field);
    }
  }

  handleUploadSelectionListItems = formTemplateFieldId => event => {
    this.setState({ShowProgressIndicatorImmediately: true});
    let files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.file = file;
      reader.onload = (function (file, formTemplateFieldId, scope) {
        return function (e) {
          let binaryStr = reader.result;
          return API.post(GetFormTemplatesPublicFieldListItemsPathForApi(scope.props.FormTemplate.ID, 
            formTemplateFieldId), binaryStr,
            {
              params: { 
                organizationId: scope.props.FormTemplate.OrganizationID,
                projectId: scope.props.FormTemplate.ProjectID,
                type: "file", 
              },
              // onUploadProgress: e => scope.handleUploadProgress(file.name, e),
            }
          )
            .then(resp => {
              const field = [...scope.state.FormTemplateFields].find(f => f.ID === formTemplateFieldId);
              if (field) {
                // By setting state without field and then with it again causes a reload of the field
                // so that the items will appear immediately
                const fieldsWithoutField = [...scope.state.FormTemplateFields].filter(f => f.ID !== formTemplateFieldId);
                scope.setState({
                  FormTemplateFields: fieldsWithoutField,
                });

                let fields = [...fieldsWithoutField];
                fields.push(field);
                let stateToUpdate = {
                  ShowProgressIndicatorImmediately: false,
                  FormTemplateFields: SortForTabOrder(fields),
                };
                scope.setState(stateToUpdate);
                if (scope.SelectionListItemsUploadedPopoverVisibilityFunction) {
                  scope.SelectionListItemsUploadedPopoverVisibilityFunction(true);
                  setTimeout(() => scope.SelectionListItemsUploadedPopoverVisibilityFunction(false), 1500);
                }
              }
            })
            .catch(err => { console.log(err);scope.handleApiError(err);});
        };
      })(file, formTemplateFieldId, this);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleDownloadSelectionListItems = formTemplateFieldId => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.get(GetFormTemplatesPublicFieldListItemsPathForApi(this.props.FormTemplate.ID, 
            formTemplateFieldId) + "?getAllAsFlatFile=true",
      {
        params: { 
          organizationId: this.props.FormTemplate.OrganizationID,
          projectId: this.props.FormTemplate.ProjectID,
          type: "file",
        },
        // onUploadProgress: e => scope.handleUploadProgress(file.name, e),
      }
    )
      .then(resp => {
        const link = document.createElement('a');
        link.href = "data:application/octet-stream," + encodeURI(resp.data);
        link.download = "Selection List Items.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ShowProgressIndicatorImmediately: false});
      })
      .catch(this.handleApiError);
  }

  handleSetDragExampleVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: visible,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  handleDeleteSelectedFields = () => {
    this.setState({ ShowDeleteFieldsConfirmation: false });
    this.handleDeleteFormTemplateFields(
      this.state.FormTemplateFields
        .filter(f => this.state.SelectedFormTemplateFieldIds.indexOf(f.ID) > -1)
    );
  }

  handleMoveSelectedFields = moveFunc => {
    let fieldsCopy = [...this.state.FormTemplateFields];
    let canMoveAll = true;
    let fields = [];
    let updatedFields = [];
    for (let i = 0; i < fieldsCopy.length; i++) {
      let field = {...fieldsCopy[i]};
      let selectedId = this.state.SelectedFormTemplateFieldIds.find(id => field.ID === id);
      if (selectedId) {
        if (moveFunc) {
          let updatedField = moveFunc(field);
          if (!updatedField) {
            canMoveAll = false;
            break;
          } else {
            fields.push(updatedField);
            updatedFields.push(updatedField);
          }
        }
      } else {
        fields.push(field);
      }
    }
    if (canMoveAll && updatedFields.length) {
      this.setState({ FormTemplateFields: fields });
      this.handleUpdateFormTemplateFields(updatedFields);
    }
  }

  getPixelIncrementX = () => {
    return 1 / this.CanvasRef.clientWidth;
  }

  getPixelIncrementY = () => {
    return 1 / this.CanvasRef.clientHeight;
  }

  handleMoveSelectedFieldsUp = () => {
    this.handleMoveSelectedFields(field => {
      if (field.Y > 0) {
        field.Y -= (this.props.FormTemplate.DisableSnapToGrid)
          ? this.getPixelIncrementY() : _snapIncrement;
        return field;
      }
    });
  }

  handleMoveSelectedFieldsDown = () => {
    this.handleMoveSelectedFields(field => {
      if (field.Y < 1 - _snapIncrement) {
        field.Y += (this.props.FormTemplate.DisableSnapToGrid)
          ? this.getPixelIncrementY() : _snapIncrement;
        return field;
      }
    });
  }

  handleMoveSelectedFieldsLeft = () => {
    this.handleMoveSelectedFields(field => {
      if (field.X > 0) {
        field.X -= (this.props.FormTemplate.DisableSnapToGrid)
          ? this.getPixelIncrementX() : _snapIncrement;
        return field;
      }
    });
  }

  handleMoveSelectedFieldsRight = () => {
    this.handleMoveSelectedFields(field => {
      if (field.X < 1 - _snapIncrement) {
        field.X += (this.props.FormTemplate.DisableSnapToGrid)
          ? this.getPixelIncrementX() : _snapIncrement;
        return field;
      }
    });
  }

  handleTagListValuesChanged = tagListValues => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    this.props.onFormTemplateMultiPropertyChange([
      {name:"Tags", value:Tags},
      {name:"AssetItemTags", value:AssetItemTags},
    ]);
  }

  handleShowFormShareDialog = ShowFormShareDialog => {
    this.setState({ShowFormShareDialog});
  }
  
  handleFormShare = (recipientEmails, tags, assetItemTags) => {
    this.handleShowFormShareDialog(false);
    ShareFormTemplates(
      [this.props.FormTemplate],
      recipientEmails,
      tags,
      assetItemTags,
    )
      .catch(this.handleApiError);
  }

  handleSetShowVideoHelpDialogVisibility = ShowVideoHelpDialog => {
    this.setState({ShowVideoHelpDialog});
  }

  handleShowProgressIndicatorImmediately = show => {
    this.setState({ShowProgressIndicatorImmediately: show});
  }

  handleAlert = details => {
    if (this.props.onAlert) {
      this.props.onAlert(details);
    }
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
    this.setState({
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('mouseup', this.handleProcessRectSelect);
    window.addEventListener('blur', this.handleProcessRectSelect);
    window.addEventListener('keydown', this.handleWindowKeyDown);
    this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage());
    // Run it again to account for scrollbar width
    this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage());
    this.loadFields(true)
      .then(fields => {
        if (!fields.length) {
          this.handleSetDragExampleVisibility(true)(null);
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('mouseup', this.handleProcessRectSelect);
    window.removeEventListener('blur', this.handleProcessRectSelect);
    window.removeEventListener('keydown', this.handleWindowKeyDown);
  }
  
  render() {
    const { 
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      FormTemplateFields,
      FieldIsDragging,
      PageIndex,
      ToolboxMenuAnchorEl,
      ShowDragExampleDialog,
      ShowClearAllFieldsConfirmation,
      ShowDeleteFieldsConfirmation,
      ShowDeleteFormTemplateConfirmation,
      ShowConfigureFieldsDialog,
      SelectedFormTemplateFieldIds,
      ShowNavigateToPageDialog,
      NavigateToPage_PageNumber,
      FormUrlCopiedPopoverOpen,
      ImageUploadProgressPercent,
      RectSelectStartPosition,
      RectSelect,
      ShowFieldPropertiesDialog,
      FieldToCustomize,
      ShowVideoHelpDialog,
      Fields,
      FieldsLoaded,
      TagListValues,
      ShowFormShareDialog,
    } = this.state;
    const {
      organizationId,
      projectId,
      FormTemplate,
      connectCanvasDropTarget,
      connectToolPaneDropTarget,
      history,
      location,
      classes,
      theme,
    } = this.props;

    let clearAllFieldsConfirmationDialogDetails = {
      Open:ShowClearAllFieldsConfirmation,
      IsConfirmation:true,
      Title:"Clear all fields?",
      BodyText:"All fields on all pages will be removed. This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:this.handleSetClearAllFieldsConfirmationVisibility(false),
      CloseCallback:this.handleSetClearAllFieldsConfirmationVisibility(false),
      ConfirmCallback:this.handleDeleteAllFormTemplateFields,
    };

    let deleteFieldsConfirmationDialogDetails = {
      Open:ShowDeleteFieldsConfirmation,
      IsConfirmation:true,
      Title:"Delete selected field(s)?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:this.handleSetDeleteFieldsConfirmationVisibility(false),
      CloseCallback:this.handleSetDeleteFieldsConfirmationVisibility(false),
      ConfirmCallback:this.handleDeleteSelectedFields,
    };

    let deleteFormTemplateConfirmationDialogDetails = {
      Open:ShowDeleteFormTemplateConfirmation,
      IsConfirmation:true,
      Title:"Delete template?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:this.handleSetDeleteFormTemplateConfirmationVisibility(false),
      CloseCallback:this.handleSetDeleteFormTemplateConfirmationVisibility(false),
      ConfirmCallback:this.props.onDeleteFormTemplate,
    };

    // let selectedFieldInfo = null;
    // if (SelectedFormTemplateFieldIds.length) {
    //   selectedFieldInfo = (
    //     <div className={classes.formNavBarFlexEnd}>
    //       {`${SelectedFormTemplateFieldIds.length} selected field${SelectedFormTemplateFieldIds.length > 1 ? "s" : ""}`}
    //     </div>
    //   );
    // }

    let navigateToPageDialogDetails = {
      Open:ShowNavigateToPageDialog,
      Title:`Go to page (1-${(FormTemplate.PageCount)})`,
      DialogWidth: "xs",
      BodyContent: (
        <NumericTextField
          id="navigateToPage_pageNumber"
          label="Page number"
          autoFocus
          value={NavigateToPage_PageNumber}
          onValueChange={this.handleNavigateToPage_PageNumberValueChanged}
          hideClearButton
          onEnterKey={this.handleGoToPage}
        />
      ),
      CloseCallback:() => this.handleSetShowNavigateToPageDialogVisibility(false),
    };

    let dragExampleDetails = {
      Open:ShowDragExampleDialog,
      Title:"Adding fields",
      BodyContent: (
        <img src="/formTemplateFieldDragExample.gif" alt="Tutorial: Adding fields" />
      ),
      CloseCallback:this.handleSetDragExampleVisibility(false),
    }

    let goToPageButton = (FormTemplate.PageCount > 2)
      ? (
        <Tooltip title="Go to page">
          <IconButton
            disabled={FormTemplate.PageCount <= 2}
            onClick={() => this.handleSetShowNavigateToPageDialogVisibility(true)}>
            <NavigateToPageIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    let currentPageNumber = (FormTemplate.PageCount)
    ? (
        <Typography variant="body2"
          style={{marginLeft:theme.spacing(3)}}>
          {`Viewing page ${1 + PageIndex} of ${FormTemplate.PageCount}`}
        </Typography>
    ) : null;

    const learnMoreText = "Learn more about this area";
    const learnMoreHelpIcon = (
      <Tooltip title={learnMoreText}>
        <IconButton color="default" aria-label={learnMoreText}
          style={{marginLeft:theme.spacing(3)}}
          onClick={() => this.handleSetShowVideoHelpDialogVisibility(true)}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );
    const videoHelpDialog = (ShowVideoHelpDialog)
      ? (
        <VideoHelpDialog
          open={ShowVideoHelpDialog}
          src={GetPublicVideoPath("N1 IP Form Builder.mp4")}
          onClose={() => this.handleSetShowVideoHelpDialogVisibility(false)}
        />
      ) : null;

    let formNavBar = (
      <div className={classes.formNavBar}>
        <Tooltip title="First page">
          <span>
          <IconButton
            disabled={FormTemplate.PageCount < 3 || PageIndex === 0}
            onClick={() => this.handleGoToPage(0)}>
            <NavigateFirstIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Previous page">
          <span>
          <IconButton
            disabled={!FormTemplate.PageCount || PageIndex === 0}
            onClick={() => this.handleGoToPage(PageIndex - 1)}>
            <NavigatePrevIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Next page">
          <span>
          <IconButton
            disabled={!FormTemplate.PageCount || PageIndex === FormTemplate.PageCount - 1}
            onClick={() => this.handleGoToPage(PageIndex + 1)}>
            <NavigateNextIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Last page">
          <span>
          <IconButton
            disabled={FormTemplate.PageCount < 3 || PageIndex === FormTemplate.PageCount - 1}
            onClick={() => this.handleGoToPage(FormTemplate.PageCount-1)}>
            <NavigateLastIcon />
          </IconButton>
          </span>
        </Tooltip>
        {goToPageButton}
        {currentPageNumber}
        {learnMoreHelpIcon}
        <div className={classes.formNavBarFlexEnd}>
          <Button onClick={() => HandleRouteToFormInput(FormTemplate.ID, FormTemplate.UniqueID)}>
            PREVIEW
          </Button>
          <Button onClick={() => this.handleShowFormShareDialog(true)} style={{marginLeft:theme.spacing(2)}}>
            SHARE
          </Button>
        </div>
        {/*selectedFieldInfo*/}
      </div>
    );

    let getDraggableListItem = formTemplateFieldType => (
      <DraggableListItem 
        key={formTemplateFieldType.Type}
        Data={{...formTemplateFieldType, Width: _initialFieldWidth, IsNew: true, }} 
        Text={formTemplateFieldType.TypeLabel}
        hideDragPreview
        onDrop={this.handleDropFormTemplateFieldOnCanvas}
        // onClick={() => this.handleCreateNewFormTemplateFieldAtDefaultLocation(formTemplateFieldType)}
      />
    );

    let formTemplateFieldListItems_Static = FormTemplateFieldTypes
      .filter(t => t.Category === "Static" && !t.HideFromUI)
      .map(t => getDraggableListItem(t));
    let formTemplateFieldListItems_Input = FormTemplateFieldTypes
      .filter(t => t.Category === "Input" && !t.HideFromUI)
      .map(t => getDraggableListItem(t));

    let formTemplateFields = FormTemplateFields
      .filter(f => f.PageIndex === PageIndex)
      .map(f => {
      return (
        <React.Fragment key={`f_${f.ID}`}>
          <DraggableFormTemplateField
            organizationId={organizationId}
            projectId={projectId}
            key={f.ID}
            tabIndex={FormTemplateFields.indexOf(f)}
            totalFields={FormTemplateFields.length}
            FormTemplate={FormTemplate}
            FormTemplateField={f}
            FormTemplateFields={FormTemplateFields}
            minWidth={GetMinFieldWidth(f)}
            selected={SelectedFormTemplateFieldIds.filter(id => id === f.ID).length}
            hideDragPreview
            onMouseDown={this.handleFormTemplateFieldMouseDown}
            onBeginDrag={() => this.setState({FieldIsDragging:true})}
            onEndDrag={() => this.setState({FieldIsDragging:false})}
            onDrop={this.handleDropFormTemplateFieldOnCanvas}
            onDrop2={this.handleDropFormTemplateFieldOnToolPane}
            onDefaultValueChange={this.handleFormTemplateFieldDefaultValueChange}
            onHorizDragHandleMouseDown={this.handleHorizDragHandleMouseDown}
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
            fields={Fields}
          />
        </React.Fragment>
      );
    });

    let rectSelect = (RectSelect)
      ? (
        <div
          ref={instance => this.RectSelectRef = instance}
          className={classes.rectSelect}
          style={{
            left:RectSelect.left,
            top:RectSelect.top,
            width:RectSelect.width,
            height:RectSelect.height,
          }}
        >
        </div>
      ) : null;
    let rectSelectContainer = (RectSelectStartPosition)
      ? (
        <div
          ref={instance => this.RectSelectContainerRef = instance}
          className={classes.rectSelectContainer}
          onMouseMove={this.handleRectSelectContainerMouseMove}
        >
          {rectSelect}
        </div>
      ) : null

    const currentFormTemplatePage = this.getFormTemplatePage();
    const currentPageBackgroundImage = (currentFormTemplatePage
      && currentFormTemplatePage.UseImage && currentFormTemplatePage.ImageSignedUrl)
      ? (<img src={currentFormTemplatePage.ImageSignedUrl} className={classes.pageBackgroundImage} alt="" />) : null;
    const formCanvas = (
      <div
        ref={instance => {
          connectCanvasDropTarget(ReactDOM.findDOMNode(instance));
          this.CanvasRef = instance;
        }}
        className={classes.canvas}
        style={{
          backgroundImage:(FormTemplate.DisableSnapToGrid) ? "none" : undefined,
          backgroundSize:`${(this.CanvasRef.scrollWidth/100).toFixed(2)}px ${((this.CanvasRef.scrollHeight/100)).toFixed(2)}px`,
        }}
        onMouseDown={this.handleCanvasMouseDown}
        onMouseMove={this.handleCanvasMouseMove}
        onMouseUp={this.handleCanvasMouseUp}
      >
        {currentPageBackgroundImage}
        {rectSelectContainer}
        {formTemplateFields}
        {/*image from document was here */}
      </div>
    );

    let clearAllFieldsMenuItem = (FormTemplateFields.length)
      ? (
        <MenuItem onClick={this.handleSetClearAllFieldsConfirmationVisibility(true)}>Clear all fields</MenuItem>
      ) : null;

    let toolPaneTopBar = (
      <div className={classes.toolPaneTopBar}>
        <Typography variant="subtitle2" className={classes.toolBoxTitle}>
          Toolbox
        </Typography>
        <IconButton
          aria-label="Toolbox menu"
          aria-controls="toolbox-menu"
          aria-haspopup="true"
          onClick={this.handleSetToolboxMenuVisibility(true)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="toolbox-menu"
          anchorEl={ToolboxMenuAnchorEl}
          keepMounted
          open={Boolean(ToolboxMenuAnchorEl)}
          onClose={this.handleSetToolboxMenuVisibility(false)}
        >
          <MenuItem onClick={this.handleSetDragExampleVisibility(true)}>Tutorial: Adding fields</MenuItem>
          <Divider />
          {clearAllFieldsMenuItem}
          <MenuItem onClick={this.handleSetDeleteFormTemplateConfirmationVisibility(true)}>Delete template</MenuItem>
          <Divider />
          <MenuItem onClick={this.handleSetShowConfigureFieldsDialogVisibility(true)}>Manage project fields</MenuItem>
        </Menu>
      </div>
    );

    let toolPaneContent = (FieldIsDragging)
      ? (
        <div 
          style={{
            width:"100%",
            height:"100%",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}
          ref={instance => connectToolPaneDropTarget(ReactDOM.findDOMNode(instance))}>
          <DeleteIcon fontSize="large" />
        </div>
      )
      : (
        <div>
          {toolPaneTopBar}
          <Divider />
          <Grid container direction="column"
            ref={instance => this.FieldGridRef = instance}
            className={classes.fieldGrid}>
            <Grid item>
              <List component="nav">
                {formTemplateFieldListItems_Static}
              </List>
              <Divider />
              <List component="nav">
                {formTemplateFieldListItems_Input}
              </List>
            </Grid>
          </Grid>
        </div>
      );

    const configureFieldsDialog = (ShowConfigureFieldsDialog)
      ? (
        <ConfigureFieldsDialog
          organizationId={organizationId}
          projectId={projectId}
          open={ShowConfigureFieldsDialog}
          onApiError={this.handleApiError}
          closeCallback={this.handleSetShowConfigureFieldsDialogVisibility(false)} />
      ) : null;

    const fieldPropertiesDialog = (ShowFieldPropertiesDialog)
      ? (
        <FieldPropertiesDialog
          organizationId={organizationId}
          projectId={projectId}
          open={ShowFieldPropertiesDialog}
          onApiError={this.handleApiError}
          closeCallback={() => this.handleSetShowFieldPropertiesDialog(false)}
          field={FieldToCustomize}
        />
      ) : null;

    const formShareDialog = (ShowFormShareDialog)
      ? (
        <FormShareDialog
          open={ShowFormShareDialog}
          organizationId={organizationId}
          projectId={projectId}
          formTemplates={[FormTemplate]}
          onApiError={this.handleApiError}
          onAction={this.handleFormShare}
          onClose={() => this.handleShowFormShareDialog(false)}
        />
      ) : null;

    let isPublicCheckbox = (
      <Checkbox
        color="secondary"
        ref={instance => this.PublicCheckboxGridRef = instance}
        checked={FormTemplate.IsPublic}
        onChange={this.handleUpdateFormTemplateIsPublic} />
    );
    if (!FormTemplate.IsPublic) {
      isPublicCheckbox = (
        <CopyToClipboard
          text={GetFormUrl(FormTemplate.ID, FormTemplate.UniqueID)}
          onCopy={() => this.handleSetFormUrlCopiedPopoverVisibility(true)}
        >
          {isPublicCheckbox}
        </CopyToClipboard>
      );
    }

    const documentPageSizeGridItem = (!currentFormTemplatePage.UseCustomSize) ? (
      <Grid item xs={12} key="docPageSize">
        <SelectControl
          id="selectDocPageSize"
          label="Document page size"
          // forceShrinkLabel
          hideEmpty
          options={this.getPageSizeOptions()} 
          value={FormTemplate.PageSize || DefaultFormTemplatePageSize.PageSize}
          onValueChange={value => this.handleUpdateFormTemplatePageSize(value)}
        />
      </Grid>
    ) : null;
    const customDocumentPageSizeGridItems = (!currentFormTemplatePage.UseCustomSize
      && FormTemplate.PageSize === "Custom")
      ? [
        <Grid item xs={6} key="docPageWidth">
          <NumericTextField
            id="formTemplatePageWidth"
            label="Width (inches)"
            decimalPlaces={2}
            value={this.getValidPageDimensionString(FormTemplate.PageWidth.toString())}
            onValueChange={this.handleUpdateFormTemplatePageWidth(-1)}
            hideClearButton
            InputLabelProps={{ shrink: true, }}
          />
        </Grid>,
        <Grid item xs={6} key="docPageHeight">
          <NumericTextField
            id="formTemplatePageHeight"
            label="Height"
            decimalPlaces={2}
            value={this.getValidPageDimensionString(FormTemplate.PageHeight.toString())}
            onValueChange={this.handleUpdateFormTemplatePageHeight(-1)}
            hideClearButton
          />
        </Grid>,
      ] : null;

    const getPageDimensionMaxMin = value => Math.min(Math.max(1,parseInt(value)),99);
    const propertyPaneFormTemplateSettingsGrid = (!SelectedFormTemplateFieldIds.length)
      ? (
      <Grid container spacing={2}
        style={{
          paddingTop:theme.spacing(1),
          alignItems:"center",
        }}
      >
        <Grid item xs={4} key="pageCount">
          <TextField
            // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
            variant="outlined"
            autoComplete="off"
            label="Pages"
            type="number"
            // disabled
            value={getPageDimensionMaxMin(FormTemplate.PageCount).toString()}
            // size="small"
            onChange={e => this.handleUpdateFormTemplatePageCount(
              getPageDimensionMaxMin(e.target.value))}
            // id={fieldID}
            fullWidth
            InputLabelProps={{ shrink: true, }}
          />
        </Grid>
        <Grid item xs={8}>
          <div style={{
              visibility:(!FormTemplate.FormTemplateSourceID) ? "hidden" : undefined,
            }}
          >
            <Grid container spacing={1} style={{alignItems:"center"}}>
              <Grid item xs={10}>
                <SelectControl
                  id="selectRenderType"
                  label="Render type"
                  // forceShrinkLabel
                  hideEmpty
                  options={
                    [
                      {label: "Fill source", value: "FillSource",},
                      {label: "Render new", value: "RenderNew",},
                    ]
                  }
                  value={FormTemplate.RenderType}
                  onValueChange={this.handleUpdateRenderType}
                />
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Fill a copy of the source PDF form associated with this template or render a new, flattened PDF. Submitted signatures will not appear in filled PDFs.">
                  <InfoIcon />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={5}
          ref={instance => this.PublicCheckboxGridRef = instance}>
          <Tooltip title="A public form can be accessed by anyone having the URL.">
            <FormControlLabel
              control={isPublicCheckbox}
              label="Public"
            />
          </Tooltip>
          <Popover
            open={FormUrlCopiedPopoverOpen}
            anchorEl={(this.PublicCheckboxGridRef && !this.PublicCheckboxGridRef.hasOwnProperty('current')) ? this.PublicCheckboxGridRef : undefined}
            onClose={() => this.handleSetFormUrlCopiedPopoverVisibility(false)}
            anchorOrigin={{
              vertical:'bottom',
              horizontal:'center',
            }}
            transformOrigin={{
              vertical:'top',
              horizontal:'center',
            }}
            classes={{
              paper: classes.popoverPaper,
            }}
          >
            Form URL copied to clipboard
          </Popover>
        </Grid>
        <Grid item xs={7} style={{paddingTop:0,paddingBottom:0}}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary" 
                checked={!FormTemplate.DisableSnapToGrid}
                onChange={this.handleUpdateFormTemplateSnapToGrid} />
            }
            label={<span style={{fontSize:15}}>Snap to grid</span>}
          />
        </Grid>
        <Grid item xs={12}>
          {GetTagsControl(this.props.organizationId, this.props.projectId, null, 
            false, false, null, true,
            TagListValues,
            TagListValues => this.setState({TagListValues}),
            this.handleTagListValuesChanged,
            false, -1, "Document tags", true
          )}
        </Grid>
        {documentPageSizeGridItem}
        {customDocumentPageSizeGridItems}
      </Grid>
    ) : null;

    const currentPageSizeGridItem = (currentFormTemplatePage.UseCustomSize) ? (
      <Grid item xs={12} key="docPageSize">
        <SelectControl
          id="selectCurrentPageSize"
          label="Page size"
          // forceShrinkLabel
          hideEmpty
          options={this.getPageSizeOptions()} 
          value={this.getCurrentFormTemplatePageSize()}
          onValueChange={value => this.handleUpdateCurrentPageSize(value)}
        />
      </Grid>
    ) : null;
    const customCurrentPageSizeGridItems = (currentFormTemplatePage.UseCustomSize
      && currentFormTemplatePage.PageSize === "Custom")
      ? [
        <Grid item xs={6} key="pageWidth">
          <NumericTextField
            id="formTemplatePageWidth"
            label="Width (inches)"
            decimalPlaces={2}
            value={this.getValidPageDimensionString(currentFormTemplatePage.PageWidth.toString())}
            onValueChange={this.handleUpdateFormTemplatePageWidth(PageIndex)}
            hideClearButton
            InputLabelProps={{ shrink: true, }}
          />
        </Grid>,
        <Grid item xs={6} key="pageHeight">
          <NumericTextField
            id="formTemplatePageHeight"
            label="Height"
            decimalPlaces={2}
            value={this.getValidPageDimensionString(currentFormTemplatePage.PageHeight.toString())}
            onValueChange={this.handleUpdateFormTemplatePageHeight(PageIndex)}
            hideClearButton
          />
        </Grid>,
      ] : null;

    const getUploadPageImageButton = clickFunc => {
      return (
        <Button variant="contained" fullWidth
          onClick={e => (clickFunc) ? clickFunc(e) : undefined}
        >
          SELECT IMAGE
        </Button>
      );
    }
    const pageImageUploadGridItem = (currentFormTemplatePage.UseImage) ? (
      <Grid item xs={12} key="pageImageUpload">
        <CaptureCore
          history={history}
          location={location}
          reservationUri={GetFormTemplatePageImagesPathForApi(organizationId, projectId, FormTemplate.ID, PageIndex)}
          fullWidth
          singleFile
          skipCompleteAlert
          onGetChildren={getUploadPageImageButton}
          onComplete={this.handleFormTemplatePageImageComplete}
          includeResponseInOnComplete
          acceptTypes={BrowserImageMimeTypes}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      </Grid>
    ) : null;

    const propertyPaneFormTemplatePageSettingsGrid = (!SelectedFormTemplateFieldIds.length)
      ? (
      <Grid container spacing={2}
        style={{
          paddingTop:theme.spacing(1),
          alignItems:"center",
        }}
      >
        <Grid item xs={12} key="divider">
          <Divider />
        </Grid>
        <Grid item xs={12} key="customPageSize" style={{paddingTop:0,paddingBottom:0}}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary" 
                checked={currentFormTemplatePage.UseCustomSize || false}
                onChange={this.handleUpdateFormTemplatePageUseCustomSize(PageIndex)} />
            }
            label="Use custom page size"
          />
        </Grid>
        {currentPageSizeGridItem}
        {customCurrentPageSizeGridItems}
        <Grid item xs={12} key="usePageImage" style={{paddingTop:0,paddingBottom:0}}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary" 
                checked={currentFormTemplatePage.UseImage || false}
                onChange={this.handleUpdateFormTemplatePageBoolPropertyFromCheckbox(PageIndex, "UseImage")} />
            }
            label="Use background image"
          />
        </Grid>
        {pageImageUploadGridItem}
      </Grid>
    ) : null;

    let propertyPaneGridFields = [];

    // Single Select
    if (SelectedFormTemplateFieldIds.length === 1) {
      let fieldTypeCategory = "";
      const formTemplateField = FormTemplateFields.find(f => f.ID === SelectedFormTemplateFieldIds[0]);
      if (formTemplateField) {
        const formTemplateFieldType = FormTemplateFieldTypes.find(t => t.Type === formTemplateField.Type);
        if (formTemplateFieldType) {
          fieldTypeCategory = formTemplateFieldType.Category;
        }
        let formTemplateFieldTypeOptions = []; 
          FormTemplateFieldTypes.forEach(t => {
            if (!t.HideFromUI) {
              let categoryLabel = "";
              switch (t.Category) {
                case "Input":
                  categoryLabel = "Input";
                break;
                case "Static":
                default:
                  categoryLabel = "Presentation";
                break;
              }
              formTemplateFieldTypeOptions.push({
                label:`${categoryLabel}: ${t.TypeLabel}`,
                value:t.Type,
              });
            }
          });

        const selectedFormTemplateFieldTypeOption = 
          formTemplateFieldTypeOptions.find(o => o.value === formTemplateField.Type);
        const selectedFormTemplateFieldType = (selectedFormTemplateFieldTypeOption)
          ? formTemplateField.Type : undefined;
        propertyPaneGridFields.push(
          <Grid item key="fieldType">
            <SelectControl
              id="selectFieldType"
              label="Field type"
              // forceShrinkLabel
              hideEmpty
              options={formTemplateFieldTypeOptions} 
              value={selectedFormTemplateFieldType}
              onValueChange={value => this.handleUpdateFormTemplateFieldProperty(formTemplateField.ID, "Type", value)}
            />
          </Grid>
        );
        let showControlSize = false;
        switch (fieldTypeCategory) {
          case "Input":
            switch (formTemplateField.Type) {
            case "Field":
            case "ListBox":
            case "TextBox":
            case "CheckBox":
            case "RadioGroup":
            case "Signature":
              showControlSize = true;
              break;
            default:
              break;
            }
            break;
          default:
            break;
        }
        if (showControlSize) {
          const formTemplateControlSizeOptions = [
            { label: "Medium", value: "Medium" },
            { label: "Small", value: "Small" },
            { label: "Micro", value: "Micro" },
          ];
          propertyPaneGridFields.push(
            <Grid item key="controlSize">
              <SelectControl
                id="selectControlSize"
                label="Control size"
                // forceShrinkLabel
                emptyLabel="Normal"
                options={formTemplateControlSizeOptions} 
                value={formTemplateField.ControlSize}
                onValueChange={value => this.handleUpdateFormTemplateFieldProperty(formTemplateField.ID, "ControlSize", value)}
              />
            </Grid>
          );
        }

        switch (fieldTypeCategory) {
          case "Static":
            switch (formTemplateField.Type) {
              case "Image":
                propertyPaneGridFields.push(
                  <Grid item key="imageUpload">
                    <Dropzone 
                      onDrop={files => this.handleImageFileDrop(formTemplateField.ID, files)}
                      noClick
                      noKeyboard
                      multiple={false}
                      accept={BrowserImageMimeTypes}
                    >
                      {({getRootProps, getInputProps, open}) => (
                          <div 
                            {...getRootProps()}
                            className={classes.imageFileDropZone}
                          >
                            <input {...getInputProps()} />
                            <Button
                              variant="contained"
                              onClick={open}
                              fullWidth
                            >
                              SELECT IMAGE
                            </Button>
                          </div>
                      )}
                    </Dropzone>
                  </Grid>
                );
                if (ImageUploadProgressPercent > 0) {
                  propertyPaneGridFields.push(
                    <Grid item key="imageUploadProgress">
                      <LinearProgress
                        className={classes.uploadProgress}
                        color="secondary"
                        variant="determinate"
                        value={ImageUploadProgressPercent} />
                    </Grid>
                  );
                }
              break;
              default:
              break;
            }
          break;
          case "Input":
            switch (formTemplateField.Type) {
              case "Field":
                propertyPaneGridFields.push(
                  <Grid item key="field">
                    <AsyncSelectControl
                      id="selectField"
                      label="Field"
                      // forceShrinkLabel
                      fullWidth
                      floatingOptions
                      // autoFocus
                      key={formTemplateField.ID}
                      autoReloadOnValueChange
                      onGetOptionsFilterPromise={this.handleFieldSelectionListFilter} 
                      value={(formTemplateField.FieldID) 
                        ? {value:formTemplateField.FieldID,label:formTemplateField.Field.Name}
                        : undefined
                      }
                      onValueChange={this.handleFieldChange}
                      onCreateOption={this.handleAddFieldToCurrentProjectAndSelectedFormTemplateField}
                    />
                  </Grid>,
                  <Grid item key="manageFields">
                    <Button onClick={this.handleSetShowConfigureFieldsDialogVisibility(true)}>
                      MANAGE FIELDS
                    </Button>
                  </Grid>
                );
                break;
              case "RadioGroup":
              case "Signature":
              case "Upload":
                if (formTemplateField.Type !== "Signature"
                  && formTemplateField.Type !== "Upload") {
                  propertyPaneGridFields.push(
                    <Grid item key="fieldName">
                      <TextField
                        // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                        variant="outlined"
                        autoComplete="off"
                        label="Field name (optional)"
                        // disabled
                        value={formTemplateField.Field.Name}
                        // size="small"
                        onChange={e => this.handleUpdateFormTemplateFieldProperty(formTemplateField.ID, "Name", e.target.value, true)}
                        // id={fieldID}
                        fullWidth
                        InputLabelProps={{ shrink: true, }}
                      />
                    </Grid>
                  );
                }
                let fieldLabelPlaceholder = formTemplateField.Name || formTemplateFieldType.TypeLabel;
                propertyPaneGridFields.push(
                  <Grid item key="fieldLabel">
                    <TextField
                      // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                      variant="outlined"
                      autoComplete="off"
                      label={`Label${(formTemplateField.Field.Name && formTemplateField.Field.Name.length) ? " (optional)" : ""}`}
                      // disabled
                      value={formTemplateField.Field.Label}
                      placeholder={fieldLabelPlaceholder}
                      // size="small"
                      onChange={e => this.handleUpdateFormTemplateFieldProperty(formTemplateField.ID, "Label", e.target.value, true)}
                      // id={fieldID}
                      fullWidth
                      InputLabelProps={{ shrink: true, }}
                    />
                  </Grid>
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }

        let showRequiredCheckbox = false;
        const getFontSizeMaxMin = fontSize => {
          const minFontSize = 1;
          const defaultFontSize = 16;
          const maxfontSize = 48;
          if (!fontSize) {
            fontSize = defaultFontSize;
          } else {
            if (typeof fontSize === "string") {
              fontSize = parseInt(fontSize);
            }
            if (typeof fontSize !== "number") {
              fontSize = defaultFontSize;
            }
          }
          return Math.min(Math.max(minFontSize,fontSize), maxfontSize);
        }
        switch (formTemplateField.Category) {
          case "Static":
            switch (formTemplateField.Type) {
              case "Text":
                propertyPaneGridFields.push(
                   <Grid item key="textFontSize">
                    <TextField
                      // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                      variant="outlined"
                      autoComplete="off"
                      label="Font size"
                      type="number"
                      // disabled
                      value={getFontSizeMaxMin(formTemplateField.FontSize).toString()}
                      // size="small"
                      onChange={e => this.handleUpdateFormTemplateFieldProperty(
                        formTemplateField.ID, "FontSize", getFontSizeMaxMin(e.target.value))}
                      // id={fieldID}
                      fullWidth
                      InputLabelProps={{ shrink: true, }}
                    />
                  </Grid>,
                  <Grid item key="textFontBold" style={{paddingTop:0,paddingBottom:0}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary" 
                          checked={formTemplateField.FontBold}
                          onChange={e => this.handleUpdateFormTemplateFieldProperty(
                            formTemplateField.ID, "FontBold", e.target.checked)} />
                      }
                      label="Bold"
                    />
                  </Grid>,
                  <Grid item key="textFontItalic" style={{paddingTop:0,paddingBottom:0}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary" 
                          checked={formTemplateField.FontItalic}
                          onChange={e => this.handleUpdateFormTemplateFieldProperty(
                            formTemplateField.ID, "FontItalic", e.target.checked)} />
                      }
                      label="Italic"
                    />
                  </Grid>,
                  <Grid item key="textFontUnderline" style={{paddingTop:0,paddingBottom:0}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary" 
                          checked={formTemplateField.FontUnderline}
                          onChange={e => this.handleUpdateFormTemplateFieldProperty(
                            formTemplateField.ID, "FontUnderline", e.target.checked)} />
                      }
                      label="Underline"
                    />
                  </Grid>,
                );
                break;
              default:
                break;
            }
            break;
          case "Input":
            switch (formTemplateField.Type) {
              case "Field":
              break;
              case "RadioGroup":
                showRequiredCheckbox = true;
                propertyPaneGridFields.push(
                  <Grid item key="radioGroupHideLabel">
                    <FormControlLabel
                      control={<Switch checked={formTemplateField.Field.HideLabel}
                        color="secondary"
                        onChange={e =>
                          this.handleChangeFieldProperty(formTemplateField.ID)
                          (f => f.HideLabel = e.target.checked)
                        } />}
                      label="Hide label" />
                  </Grid>,
                  <Grid item key="radioGroupItems">
                    <TextField
                      // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                      variant="outlined"
                      autoComplete="off"
                      label="Radio values (one per line)"
                      multiline
                      rows={10}
                      // disabled
                      value={(formTemplateField.PossibleValues && formTemplateField.PossibleValues.join("\n")) || ""}
                      // size="small"
                      onChange={e => this.handleUpdateFormTemplateFieldProperty(
                        formTemplateField.ID, "PossibleValues", e.target.value.split(/\r?\n/))}
                      // id={fieldID}
                      fullWidth
                      InputLabelProps={{ shrink: true, }}
                    />
                  </Grid>,
                  <Grid item key="displayInColumn">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary" 
                          checked={formTemplateField.UseColumnLayout}
                          onChange={e => this.handleUpdateFormTemplateFieldProperty(
                            formTemplateField.ID, "UseColumnLayout", e.target.checked)} />
                      }
                      label="Display in column"
                    />
                  </Grid>
                );
                break;
              case "Signature":
                break;
              case "Upload":
                showRequiredCheckbox = true;
                break;
              default:
                propertyPaneGridFields.push(
                  <Grid item key="fieldPropertyGrid">
                    <FieldPropertyGrid
                      organizationId={organizationId}
                      projectId={projectId}
                      Field={formTemplateField.Field}
                      FieldID={formTemplateField.ID}
                      Fields={FormTemplateFields}
                      allowCreationDate
                      spacing={2}
                      disallowFieldTypeChange={formTemplateField.Type !== "TextBox"}
                      hideListItemsComponent={
                        formTemplateField.Type === "ListBox"
                        || formTemplateField.Type === "TextBox"
                      }
                      hideDocumentTags
                      onChangeFieldProperty={this.handleChangeFieldProperty(formTemplateField.ID)}
                      onDownloadSelectionListItems={() => this.handleDownloadSelectionListItems(formTemplateField.ID)}
                      onUploadSelectionListItems={this.handleUploadSelectionListItems(formTemplateField.ID)}
                      onSetSelectionListItemsUploadedPopoverVisibilityFunction={
                        f => this.SelectionListItemsUploadedPopoverVisibilityFunction = f
                      }
                      onShowProgressIndicatorImmediately={this.handleShowProgressIndicatorImmediately}
                      onApiError={this.handleApiError}
                      formTemplateFieldType={formTemplateField.Type}
                    />
                  </Grid>
                );
                break;
            }
            break;
          default:
            break;
        }
        
        if (showRequiredCheckbox) {
          propertyPaneGridFields.push(
            <Grid item key="width">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary" 
                    checked={formTemplateField.Field.Required}
                    onChange={e => this.handleUpdateFormTemplateFieldProperty(
                      formTemplateField.ID, "Required", e.target.checked, true)} />
                }
                label="Required"
              />
            </Grid>
          );
        }
      }

      // JavaScript for PDF Forms
      if (FormTemplate.FormTemplateSourceID) {
        propertyPaneGridFields.push(
          <Grid item key="js">
            <TextField
              // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
              variant="outlined"
              autoComplete="off"
              label="JavaScript"
              multiline
              rows={10}
              // disabled
              value={formTemplateField.JavaScript}
              // size="small"
              onChange={e => this.handleUpdateFormTemplateFieldProperty(
                formTemplateField.ID, "JavaScript", e.target.value)}
              // id={fieldID}
              fullWidth
              // InputLabelProps={{ shrink: true, }}
            />
          </Grid>
        );
      }
    }

    // Single and Multi Select
    if (SelectedFormTemplateFieldIds.length) {
      if (SelectedFormTemplateFieldIds.length > 1) {
        propertyPaneGridFields.push(
          <Grid item key="numSelected" style={{textAlign:"center"}}>
            {`${SelectedFormTemplateFieldIds.length} selected`}
          </Grid>
        );
      }
      propertyPaneGridFields.push(
        <Grid item key="moveFields">
          <Grid container spacing={0}>
            <Grid item key="moveUp" xs={12} style={{textAlign:"center"}}>
              <Tooltip title="Move up">
                <IconButton
                  onClick={this.handleMoveSelectedFieldsUp}>
                  <MoveUpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item key="moveLeft" xs={5} style={{textAlign:"right"}}>
              <Tooltip title="Move left">
                <IconButton
                  onClick={this.handleMoveSelectedFieldsLeft}>
                  <MoveLeftIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item key="moveSpace" xs={2}>
            </Grid>
            <Grid item key="moveRight" xs={5}>
              <Tooltip title="Move right">
                <IconButton
                  onClick={this.handleMoveSelectedFieldsRight}>
                  <MoveRightIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item key="moveDown" xs={12} style={{textAlign:"center"}}>
              <Tooltip title="Move down">
                <IconButton
                  onClick={this.handleMoveSelectedFieldsDown}>
                  <MoveDownIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    return (
      <div
        ref={instance => this.OuterContainerRef = instance}
        className={classes.outerContainer}
      >
        <MultiUseDialog Details={clearAllFieldsConfirmationDialogDetails} />
        <MultiUseDialog Details={deleteFieldsConfirmationDialogDetails} />
        <MultiUseDialog Details={deleteFormTemplateConfirmationDialogDetails} />
        <MultiUseDialog Details={navigateToPageDialogDetails} />
        <MultiUseDialog Details={dragExampleDetails} />

        {configureFieldsDialog}
        {fieldPropertiesDialog}
        {formShareDialog}
        {videoHelpDialog}
        {progressIndicator}

        <div 
          ref={instance => this.ScaleContainerRef = instance}
          className={classes.scaleContainer}
        >

          <div className={classes.contentContainer}>
            <div className={classes.toolPane}
              onMouseDown={e => this.PreDragMousePosition = {x:e.clientX, y:e.clientY}}>
              {toolPaneContent}
            </div>

            <div className={classes.imageContainer}>
              {formNavBar}
              <div 
                ref={instance => this.CanvasContainerRef = instance}
                className={classes.canvasContainer}
              >
                {
                  FieldsLoaded &&
                    <FormTemplateFieldDragLayer
                      organizationId={organizationId}
                      projectId={projectId}
                      getRelativePosition={this.getRelativePosition}
                      fields={Fields}
                    />
                }
                {formCanvas}
              </div>
            </div>

            <div className={classes.propertyPane}>
              {/*<Typography variant="subtitle2">
                Field Properties
              </Typography>*/}
              <div style={{margin:theme.spacing(3)}}>
                {propertyPaneFormTemplateSettingsGrid}
                {propertyPaneFormTemplatePageSettingsGrid}
              </div>

              <div style={{margin:theme.spacing(3)}}>
                <Grid container direction="column" spacing={2}
                  style={{paddingTop:theme.spacing(1),}}
                  ref={instance => this.PropertyPaneFieldGridRef = instance}>
                  {propertyPaneGridFields}
                </Grid>
              </div>
            </div>  

          </div>

        </div>
      </div>
    );
  }
}

FormTemplateDesignerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  onFormTemplateMultiPropertyChange: PropTypes.func.isRequired,
  onFormTemplatePageMultiPropertyChange: PropTypes.func.isRequired,
}

export default DropTarget(['DraggableListItem','DraggableFormTemplateField'], canvasTarget, canvasDropCollect)(DropTarget('DraggableFormTemplateField', toolPaneTarget, toolPaneDropCollect)(withStyles(styles, {withTheme: true})(FormTemplateDesignerComponent)));
