import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import NotificationCard from '../Components/NotificationCard';
import NotificationCollectionFields from '../Model/NotificationCollectionFields';

import ActionType from '../Model/ActionType';
import { GetTextWithUrlsAsAnchors } from '../Util/Regex';
import {
  GetDateValue,
  // GetBoolValue,
} from '../Util/Properties';
import {
  GetUserOrganizationNotificationsPathForApi,
} from '../Util/api';
import {
  OpenDocumentDetailTab,
} from '../Util/Documents';
import {
  HandleRouteToDocumentFolder,
} from '../Util/DocumentFolders';
import {
  HandleRouteToAssetItem,
} from '../Util/AssetItems';
import {
  HandleRouteToTask,
} from '../Util/Tasks';
import {
  HandleRouteToApprovalDocument,
  HandleRouteToApproval,
} from '../Util/Approvals';
import {
  HandleRouteToFormInput,
} from '../Util/FormTemplates';
import {
  GetProjectCommentsPath,
} from '../Util/PathHelper';

export class Notifications_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, organizationId) {
    super(props, onSetState, onApiError, true, false, organizationId);

    this.PageTitle = "Notifications";
    this.ContentUri = GetUserOrganizationNotificationsPathForApi(organizationId);
    this.CollectionName = "Notifications";
    this.ItemsName = "UserNotifications";
    this.ItemName = "Notification";
    this.DefaultViewType = "List";
    this.AllowSelect = true;

    this.context = context;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(NotificationCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'Subject', numeric: false, label: 'Subject' },
    { id: 'ProjectName', numeric: false, label: 'Project' },
    { id: 'CreatedOn', numeric: false, label: 'Created On' },
    // { id: 'Read', numeric: false, label: 'Read' },
    { id: 'BodyHtml', numeric: false, label: 'Message' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <NotificationCard
                UserNotification={i}
                history={this.props.history}
                onCardAction={() => HandleRouteToItemByItemType(this.props, this.context, onAction, items, i)}
                onSelect={() => onSelect(i.ID)}
                onAction={onAction}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const labelId = `label_${i.ID}`;
        return (
          <ItemTableRow key={`k_${i.ID}`}
            onSelect={() => onSelect(i.ID)}
            selected={selected}
            onItemClick={() => HandleRouteToItemByItemType(this.props, this.context, onAction, items, i)}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                color="secondary"
                onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
                inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
              />
            </TableCell>
            <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none"
              style={{fontWeight: (i.IsRead) ? 300 : 500}}
            >
              {i.Subject}
            </TableCell>
            <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
            {/*<TableCell className={classes.tableCell}>{GetBoolValue(i.IsRead)}</TableCell>*/}
            <TableCell className={classes.tableCell}>{GetTextWithUrlsAsAnchors(i.BodyText, theme, "Go", 1000)}</TableCell>
          </ItemTableRow>
        );
      })
      : [];
  }
}

export const HandleRouteToItemByItemType = (props, context, onAction, items, item) => {
  const projectMemberPkgFinder = context.ProjectMembershipPackages.filter(p => p.Project.ID === item.ProjectID);
  const isProjectMember = projectMemberPkgFinder.length > 0;
  const isProjectAdmin = isProjectMember && projectMemberPkgFinder[0].ProjectMember.IsAdmin;

    // Clear the notification
    onAction(ActionType.Notification_Clear, item.ID, true);

    switch (item.ItemType) {
    case "DocumentComment":
    case "DocumentEvent":
    case "DocumentSignatureSessionCompletion":
      OpenDocumentDetailTab(item.OrganizationID, item.ProjectID, item.ItemID);
      break;
    case "DocumentSignatureSessionRecipient":
      const recipient = {
        OrganizationID: item.OrganizationID,
        ProjectID: item.ProjectID,
        DocumentSignatureSessionID: item.AdditionalIDs.DocumentSignatureSessionID,
        ID: item.ItemID,
        UniqueID: item.AdditionalIDs.UniqueID,
      };
      OpenDocumentDetailTab(item.OrganizationID, item.ProjectID, recipient, true);
      break;
    case "DocumentFolder":
      const documentFolder  = { 
        OrganizationID: item.OrganizationID,
        ProjectID: item.ProjectID,
        ID: item.ItemID,
        AncestorIDs: item.AdditionalIDs.AncestorIDs,
      };
      HandleRouteToDocumentFolder(props, documentFolder, isProjectMember);
      break;
    case "ProjectComment":
      props.history.push(GetProjectCommentsPath(item.ProjectID), { ...props.location.state });
      break;
    case "TaskCompletion":
      HandleRouteToTask(props, item.ProjectID, item.ItemID, isProjectMember, false,
        true, false);
        break;
    case "Task":
    case "TaskComment":
    case "TaskReminder":
    case "TaskEvent":
      const forCompletedTasks = item.AdditionalProps.Result && item.AdditionalProps.Result === "Complete";
      const forDeniedTasks = item.AdditionalProps.Result && item.AdditionalProps.Result === "Deny";
      HandleRouteToTask(props, item.ProjectID, item.ItemID, isProjectMember, false,
        forCompletedTasks, forDeniedTasks);
      break;
    case "Approval":
      switch (item.AdditionalIDs.ApprovalItemType) {
        case "Document":
          HandleRouteToApprovalDocument(item.OrganizationID, item.ProjectID, item.ItemID, 
            item.AdditionalIDs.DocumentID, true);
          break;
        case "Task":
        case "AssetItem":
          HandleRouteToApproval(props, item.ProjectID, item.ItemID, 
            !isProjectMember
              || (!isProjectAdmin && projectMemberPkgFinder[0].Project.Access.MemberAccess.Approvals === "Assigned")
          );
          break;
        default:
          console.log("No handler for this ItemType", item.ItemType);
      }
      break;
    case "AssetItem":
    case "AssetItemComment":
      if (isProjectMember) {
        HandleRouteToAssetItem(props, item.ProjectID, item.AdditionalIDs.AssetID, item.ItemID,
          (item.AdditionalProps) ? item.AdditionalProps.Archived : false);
      }
      break;
    case "WorkflowMessage":
      // Nothing to do
      break;
    case "FormShare":
      HandleRouteToFormInput(item.ItemID, item.AdditionalIDs.UniqueID, item.AdditionalIDs.FormShareID);
      break;
    default:
      console.log("There is no case for this ContentType", item.ContentType);
      break;
  }
}