import pako from 'pako';
import { GetSearchQuery } from '../Util/Search';

import API from '../Util/api';

import {
  DocumentFoldersPathElement,
} from '../Util/DocumentFolders';

import { IsMobile } from '../Util/MobileDetector';

export const NotificationsNodeType = "notifications";
export const ProjectCommentsNodeType = "projectComments";
export const TasksNodeType = "tasks";
export const CompletedTasksNodeType = "completedTasks";
export const DeletedTasksNodeType = "deletedTasks";
export const FieldsNodeType = "fields";
export const AssetsNodeType = "assets";
export const AssetItemsNodeType = "assetItems";
export const ArchivedAssetsNodeType = "archivedAssets";
export const ArchivedAssetItemsNodeType = "archivedAssetItems";
export const FormTemplatesNodeType = "formTemplates";
export const ProcessesNodeType = "processes";
export const TaskMilestonesNodeType = "taskMilestones";
export const TaskStatesNodeType = "taskStates";
export const DocumentFoldersNodeType = "documentFolders";
export const DocumentsNodeType = "documents";
export const WorkspaceProjectNodeType = "workspaceProject";
export const ProjectMembersNodeType = "projectMembers";
export const TagsNodeType = "tags";
export const SettingsNodeType = "settings";
export const ProfileNodeType = "profile";
export const ApprovalsNodeType = "approvals";
export const NeedSignatureNodeType = "signatures";
export const FormsNodeType = "forms";
export const FormShareNodeType = "sharedForms";

export const SettingsPathElement = "/settings";
export const ProfilePathElement = "/profile";
export const TasksPathElement = "/tasks";
export const AssetsPathElement = "/assets";
export const SharedFormsPathElement = "/sharedForms";
export const AssetItemsPathElementPrefix = "assetItems_";
export const ArchivedAssetItemsPathElement = "/management/archivedAssetItems";
export const ArchivedAssetsPathElement = "/management/archivedAssets";
export const CompletedTasksPathElement = "/management/completedTasks";
export const DeletedTasksPathElement = "/management/deletedTasks";
export const FormsPathElement = "/forms";
export const GetFollowedTasksPathElement = useManagementPrefix =>
  `${(useManagementPrefix) ? "/management" : ""}/followedTasks`;

export const DocumentsNodeDefaultName = "Documents";
export const DocumentsNodeDefaultNameForRestrictiveAccess = "Document Folders";

export function GetNodeFromPath(props) {
  let node = null;
  if (props.location && props.location.pathname) {
    let data = props.location.pathname.substr(1+props.match.path.length);
    if (data) {
      try {
        node = JSON.parse(pako.inflate(atob(data), { to: 'string' }));
      } catch(err) { console.log(err); }
    }
  }
  return node;
}

// export function GetInboxNode() {
//   const id = "inbox";
//   return {
//     UniqueId: id,
//     NodeType: id,
//     CustomIconID: id,
//     Name: "Inbox",
//     PathElement: "/inbox",
//   };
// }

export function GetFormsNode() {
  const id = FormsNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Forms",
    PathElement: FormsPathElement,
  };
}

export function GetSharedFormsNode(useManagementPrefix) {
  const id = FormShareNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Shared Forms",
    PathElement: `${(useManagementPrefix) ? "/management" : ""}${SharedFormsPathElement}`,
  };
}

export function GetFormTemplatesNode() {
  const id = FormTemplatesNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "Forms" : "Form Templates",
    PathElement: "/formTemplates",
  };
}

export function GetFieldsNode() {
  const id = FieldsNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Fields",
    PathElement: "/fields",
  };
}

export function GetAssetsNode() {
  const id = AssetsNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Assets",
    PathElement: AssetsPathElement,
  };
}

export function GetArchivedAssetsNode() {
  const id = ArchivedAssetsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Archived",
      PathElement: ArchivedAssetsPathElement,
  };
}

export function GetAssetItemsNode(Asset) {
  return {
      UniqueId: AssetItemsNodeType + "_" + Asset.ID,
      NodeType: AssetItemsNodeType,
      CustomIconID: AssetItemsNodeType,
      Name: Asset.PluralName,
      PathElement: "/" + AssetItemsPathElementPrefix + Asset.ID,
      Asset,
  };
}

export function GetArchivedAssetItemsNode() {
  const id = ArchivedAssetItemsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Archived",
      PathElement: ArchivedAssetItemsPathElement,
  };
}

export function GetProcessesNode() {
  const id = ProcessesNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "Workflow" : "Workflow Processes",
    PathElement: "/processes",
  };
}

export function GetTaskMilestonesNode() {
  const id = TaskMilestonesNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "Milestones" : "Task Milestones",
    PathElement: "/taskMilestones",
  };
}

export function GetTaskStatesNode() {
  const id = TaskStatesNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "States" : "Task States",
    PathElement: "/taskStates",
  };
}

export function GetFileCaptureNode() {
  const id = "fileCapture";
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "Capture" : "File Capture",
    PathElement: "/fileCapture",
  };
}

export function GetDeviceCaptureNode() {
  const id = "deviceCapture";
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Document Scanning",
    PathElement: "/deviceCapture",
  };
}

export function GetDocumentsNode() {
  const id = DocumentsNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: DocumentsNodeDefaultName,
    IsDocumentFolderRoot: true,
    PathElement: "/documents",
  };
}

export function GetDocumentFoldersNode() {
  const id = DocumentFoldersNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: (IsMobile()) ? "Folders" : "Document Folders",
    PathElement: DocumentFoldersPathElement,
  };
}

export function GetNeedSignatureNode() {
  const id = NeedSignatureNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Signatures",
    PathElement: "/signatures",
  };
}

export function GetRecycleBinNode() {
  const id = "recycleBin";
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Document Recycle Bin",
    PathElement: "/recycleBin",
  };
}

export function GetProjectSettingsNode() {
  const id = SettingsNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Settings",
    PathElement: SettingsPathElement,
  };
}

export function GetProjectProfileNode() {
  const id = ProfileNodeType;
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Project Profile",
    PathElement: ProfilePathElement,
  };
}

export function GetFollowedDocumentsNode() {
  const id = "followedDocuments";
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Followed Documents",
    PathElement: "/followedDocuments",
  };
}

export function GetFollowedTasksNode(useManagementPrefix) {
  const id = "followedTasks";
  return {
    UniqueId: id,
    NodeType: id,
    CustomIconID: id,
    Name: "Followed Tasks",
    PathElement: GetFollowedTasksPathElement(useManagementPrefix),
  };
}

export function GetApprovalsNode() {
  const id = ApprovalsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Approvals",
      PathElement: "/approvals",
  };
}

export function GetAllApprovalsNode() {
  const id = "allApprovals";
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "approvals",
      Name: "All Approvals",
      PathElement: "/allApprovals",
  };
}

export function GetNotificationsNode() {
  const id = NotificationsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Notifications",
      PathElement: "/notifications",
  };
}

export function GetTasksNode(NameSuffix) {
  const id = TasksNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Tasks",
      NameSuffix,
      PathElement: TasksPathElement,
  };
}

export function GetCompletedTasksNode() {
  const id = CompletedTasksNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Completed Tasks",
      PathElement: CompletedTasksPathElement,
  };
}

export function GetDeletedTasksNode() {
  const id = DeletedTasksNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Deleted Tasks",
      PathElement: DeletedTasksPathElement,
  };
}

export function GetProjectCommentsNode() {
  const id = ProjectCommentsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Chat",
      PathElement: "/comments",
  };
}

export function GetProjectMembersNode() {
  const id = ProjectMembersNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "members",
      Name: "Project Members",
      PathElement: "/members",
  };
}

export function GetTagsNode() {
  const id = TagsNodeType;
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "tags",
      Name: "Tags",
      PathElement: "/tags",
  };
}

export function GetOrganizationProfileNode() {
  const id = "organizationProfile";
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "profile",
      Name: "Profile",
      PathElement: "/profile",
  };
}

export function GetOrganizationMembersNode() {
  const id = "organizationMembers";
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "members",
      Name: "Members",
      PathElement: "/members",
  };
}

export function GetOrganizationProjectsNode() {
  const id = "organizationProjects";
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: "projects",
      Name: "Projects",
      PathElement: "/projects",
  };
}

export function GetOrganizationSubscriptionNode() {
  const id = "organizationSubscription";
  return {
      UniqueId: id,
      NodeType: id,
      CustomIconID: id,
      Name: "Subscription",
      PathElement: "/subscription",
  };
}

export function GetNodeJsonAsParameter(nodeJson) {
  let parameter = "/" + btoa(pako.deflate(nodeJson, { to: 'string' }));
  return parameter;
}

export function RouteToFolderDocuments(node, props, useHistoryReplace) {
  let searchFields = [];
  let friendlyName = "";
  while (node) {
    if (node.SearchField) {
      searchFields.push(node.SearchField);
    }
    if (friendlyName !== "") {
      friendlyName += " < ";
    }
    friendlyName += node.Name;
    node = node.ParentNode;
  }
  let path = "/folderDocuments/" 
    + btoa(
      JSON.stringify(
        GetSearchQuery(null, searchFields, null, friendlyName)
      )
    );
  if (useHistoryReplace) {
    props.history.replace(path);  
  } else {
    props.history.push(path);
  }
}

export const GetFolderHierarchies = (onSetState_optional, onApiError) => {
  return API.get("/folderHierarchies", { params: { getAll: true } })
    .then(resp => {
      if (onSetState_optional) {
        onSetState_optional({ FolderHierarchies: resp.data.FolderHierarchies });
      }
      return resp.data.FolderHierarchies;
    })
    .catch(onApiError);
}

export const GetUserOrganizationAssignmentTypeFromNodeType = nodeId => {
  switch (nodeId) {
    case ApprovalsNodeType:
      return "Approval";
    case TasksNodeType:
      return "Task";
    case NeedSignatureNodeType:
      return "DocumentSignatureSessionRecipient";
    case DocumentFoldersNodeType:
      return "DocumentFolder";
    case FormShareNodeType:
      return "FormShare";
    default:
      return ""
  }
}