import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Comments from './Comments';
import TaskDialogGrid from './TaskDialogGrid';
import TaskChecklist from './TaskChecklist';
import {
  GetTagsControl,
  GetTagsAndAssetItemTagsFromTagListValues,
  GetTagListValuesFromTagsObject,
} from '../Util/Tags';
import DeviceCaptureDialog from './DeviceCaptureDialog';
import {
  OpenDocumentDetailTab,
} from '../Util/Documents';
import {
  HandlePreCaptureFieldGathering,
  GetPreCaptureFieldGatheringContent,
} from '../Util/PreCaptureFieldGathering';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ItemCollectionBase from './ItemCollectionBase';
import ProgressIndicator from './ProgressIndicator';
import ItemSearchDialog from './ItemSearchDialog';

import { TaskDocuments_Collection } from '../Model/TaskDocuments';
import { ValidateEmail } from '../Util/Regex';
import { GlobalContext } from '../Context/Global.context';
import {
  GetTaskWithAssignmentOption,
} from '../Util/Tasks';

import API ,{
  GetDocumentsPathForApi,
  GetTasksPathForApi,
  GetTaskPathForApi,
  GetUserOrganizationProjectTaskPathForApi,
  GetTaskCommentsPathForApi,
  GetUserOrganizationProjectTaskCommentsPathForApi,
  GetUserOrganizationProjectApprovalTaskCommentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskCommentsPathForApi,
} from '../Util/api';
import {
  GetSavedFilterState,
  SaveFilterStateToHistory,
} from '../Util/Filters';
import debounce from 'es6-promise-debounce';
import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';
import { NumberWithSeparators } from '../Util/NumberFormatting';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  GetTaskLabelByResult,
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
  HandleSubTaskAdded,
  HandleSubTaskAddedAfter,
  HandleSubTaskChanged,
  HandleStartMoveSubTask,
  HandleMoveSubTask,
  HandleAbortMoveSubTask,
  HandleEndMoveSubTask,
  GetTaskSubscription,
  GetTaskSubscriptionIconButton,
  GetStakeholdersControl,
  HandleStakeholdersValueChange,
} from '../Util/Task';
import {
  AddTaskDocuments,
} from '../Util/TaskDocuments';
import MultiUseDialog from '../Components/MultiUseDialog';
import { Beforeunload } from 'react-beforeunload';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "90%" : undefined,
    // width:(!IsMobile()) ? "90%" : undefined,
  },
  dialogTitle: {
    paddingBottom:0,
  },
  dialogContent: {
    overflow:"hidden",
  },
  dialogActions: {
  },
  gridContainer: {
    height:"100%",
  },
  contentContainer: {
    height:"100%",
    display:"flex",
    flexDirection: "column",
  },
  paper: {
    overflowX:"hidden",
    overflowY:"auto",
    flexGrow:1,
  },
  tabs: {
  },
  tab: {
  },
  documentContainer: {
    display:"flex",
  },
  thumbnailImage: {
    width:250,
    // borderTopRightRadius:theme.spacing(1),
    border:"1px solid",
    borderColor:theme.palette.divider,
  },
  documentDetailGrid: {
    marginLeft:theme.spacing(3),
    flexGrow:1,
  },
  metadataName: {
    fontWeight:500,
  },
  buttonWithColor: {
    color: "#ffffff",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  propertyName: {
    fontWeight:600,
  },
  mobileTaskListContainer: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

class TaskDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.InitialState = {
      SelectedTab: "task",
      ShowCloseNewTaskConfirmationDialog: false,
      ShowCloseWhilePersistingConfirmationDialog: false,
      DocumentThumbnailUrlsByDocumentId: [],
      ShowDeviceCaptureDialog: false,
      FabMenu_MouseY: null,
      FabMenu_MouseX: null,
      FieldIDsAndValuesForCapture: null,
      TagsForCapture: null,
      AssetItemTagsForCapture: null,
    };

    this.state = {
      ...this.InitialState,
      open: props.open,
      Task: this.props.Task,
      TagListValues: GetTagListValuesFromTagsObject(this.props.Task),
      TaskSubscription: null,
      StakeholderProjectMembers: [],
      PreMoveSubTasksJson: "",
      SensitiveFields: [],
      ServerUpdateActive: false,
      ShowProgressIndicatorImmediately: false,
      ForceTaskDocumentsRefresh: false,
      TaskDocumentsCollectionUpdateID: null,
      ShowAddExistingDocumentsDialog: false,
    }
    
    // **** We should probably do away with Sensitive Fields and simply stop putting them in Document.PreviewMetadata
    // GetSensitiveFields(this.props.organizationId, this.props.projectId, 
    //   state => this.setState(state), this.handleApiError);

    this.BeginFileUploadFunc = null;
    this.TaskDocumentsCollection = null;
    this.CallerFilterState = {};
    this.PostFieldGatheringCaptureFunc = null;
    this.AdditionalBodyTextForFieldGatheringDialog = null;
  }

  loadTaskSubscription = () => {
    GetTaskSubscription(this.props.organizationId, this.props.projectId, this.props.Task.ID)
      .then(TaskSubscription => this.setState({TaskSubscription}));
  }

  hasPrimaryDocument = () => {
    return this.props.Task
      && this.props.Task.PrimaryDocument
      && this.props.Task.PrimaryDocument.DocumentID;
  }

  hasPrimaryOrSecondaryDocuments = () => {
    return this.hasPrimaryDocument() || this.hasSecondaryDocuments();
  }

  handleSetShowCloseNewTaskConfirmationDialog = ShowCloseNewTaskConfirmationDialog => {
    this.setState({ShowCloseNewTaskConfirmationDialog});
  }

  handleSetShowCloseWhilePersistingConfirmationDialog = ShowCloseWhilePersistingConfirmationDialog => {
    this.setState({ShowCloseWhilePersistingConfirmationDialog});
  }

  getTaskIsInactive = () => {
    return (
      !this.props.isCreateNew
      && this.state.Task
      && this.state.Task.Result !== ""
    );
  }

  handleClose = confirmed => {
    // Ensure the user really wants to close a new task if a name or description exists
    if (this.props.isCreateNew
      && !confirmed
      && (this.state.Task && (this.state.Task.Name || this.state.Task.Description)) 
    ) {
      this.handleSetShowCloseNewTaskConfirmationDialog(true);
      return;
    }
    // This doesn't seem necessary because the PUT calls will finish out after a dialog is closed
    // // Try to prevent premature closing while server is being updated
    // if (!this.props.isCreateNew
    //   && this.state.ServerUpdateActive
    //   && !confirmed) {
    //   this.handleSetShowCloseWhilePersistingConfirmationDialog(true);
    //   return;
    // }

    this.setState({
      open: false,
    });
    this.restoreCallerFilterState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreateNew = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    let task = {
      ...this.state.Task,
      ...this.props.additionalTaskPropertiesForCreation,
    };
    if (this.props.primaryDocumentIdForCreateNew) {
      task.PrimaryDocument = { DocumentID: this.props.primaryDocumentIdForCreateNew };
    }
    if (this.props.assetItemForNewTask && this.props.assetItemForNewTask.AssetID
      && this.props.assetItemForNewTask.ID) {
      if (!task.AssetItemTags) {
        task.AssetItemTags = [];
      }
      task.AssetItemTags = [
        ...task.AssetItemTags,
        {
          AssetID: this.props.assetItemForNewTask.AssetID,
          AssetName: this.props.assetItemForNewTask.AssetName,
          AssetItemID: this.props.assetItemForNewTask.ID,
          AssetItemName: this.props.assetItemForNewTask.Name,
        },
      ];
    }
    API.post(GetTasksPathForApi(this.props.organizationId, this.props.projectId), [task])
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately:false,
          Task: resp.data[0],
        });
        if (this.props.onCreated) {
          this.props.onCreated(resp.data[0]);
        }
        this.handleClose(true);
        // this.context.SetReloadItemsFlag();
      })
      .catch(this.handleApiError);
  }

  handleStringChange = (propertyName, disallowEmpty, secondaryName, secondaryValue) => eventOrValue => {
    let Task = {...this.state.Task};
    let value = (eventOrValue)
      ? (eventOrValue.target)
        ? eventOrValue.target.value
        : (typeof eventOrValue === "string")
          ? eventOrValue
          : ""
      : "";
    if (!value && disallowEmpty) {
      return;
    }
    Task[propertyName] = value;
    if (secondaryName) {
      Task[secondaryName] = secondaryValue;
    }
    this.updateTask(Task);
  }

  handleNumberChange = (propertyName, disallowEmpty) => eventOrValue => {
    let Task = {...this.state.Task};
    let value = (eventOrValue !== undefined && eventOrValue !== null)
      ? (eventOrValue.target)
        ? eventOrValue.target.value
        : (typeof eventOrValue === "number")
          ? eventOrValue
          : null
      : null;
    if (!value && disallowEmpty) {
      return;
    }
    Task[propertyName] = value;
    this.updateTask(Task);
  }

  handleDurationChange = (multiplier, interval) => {
    let Task = {...this.state.Task};
    Task["DurationMultiplier"] = (typeof multiplier === "number")
      ? multiplier
      : parseFloat(multiplier);
    Task["DurationInterval"] = interval;
    this.updateTask(Task);
  }

  handleRichTextChange = (propertyName, richTextAsJsonString, htmlPropertyName, html, plainTextPropertyName, plainText) => {
    let Task={...this.state.Task};
    Task[propertyName] = richTextAsJsonString;
    if (htmlPropertyName) {
      Task[htmlPropertyName] = html;
    }
    if (plainTextPropertyName) {
      Task[plainTextPropertyName] = plainText;
    }
    this.updateTask(Task);
  }

  handleDateTimeChange = (propertyName, dateTime) => {
    let Task = {...this.state.Task};
    Task.DueOn = (dateTime) ? dateTime : null;
    this.updateTask(Task);
  }

  handleAssignmentValueChange = selectedOption => {
    const originalTaskForRestoreOnError = {...this.state.Task};
    const updatedTask = GetTaskWithAssignmentOption(selectedOption, {...this.state.Task});
    return this.updateTask(updatedTask, originalTaskForRestoreOnError);
  }

  handleAssignmentCreateOption = (value, onAssignmentValueChange) => {
    if (!value) {
      return;
    }
    if (!ValidateEmail(value)) {
      this.handleApiError("E-mail address is not valid.");
      return;
    }
    value = value.trim();
    const listValue = {value:value,label:value};
    onAssignmentValueChange(listValue);
  }

  updateTask = (Task, originalTaskForRestoreOnError) => {
    this.setState({Task});
    if (!this.props.isCreateNew) {
      return this.handleUpdateServerTask(Task)
        .then(() => {
          if (this.props.onTaskRevised && Task) {
            this.props.onTaskRevised({...Task});
          }
        })
        .catch(err => {
          if (originalTaskForRestoreOnError) {
            this.setState({Task:originalTaskForRestoreOnError});
          }
          return Promise.reject(err);
        });
    }
    return Promise.resolve(null);
  }

  handleUpdateServerTask = debounce((task) => {
    if (!task || !this.props.Task || this.props.Task.Result || this.props.isCreateNew) {
      return;
    }

    // this.setState({ShowProgressIndicatorImmediately:true});

    const uri = (this.props.isWorkspace)
      ? GetUserOrganizationProjectTaskPathForApi(task.OrganizationID, task.ProjectID, task.ID)
      : (task.ID)
        ? GetTaskPathForApi(this.props.organizationId, this.props.projectId, task.ID)
        : GetTasksPathForApi(this.props.organizationId, this.props.projectId);

    this.setState({ServerUpdateActive: true});

    return API.put(uri, task)
      .catch(err => {
        this.handleApiError(err);
        return Promise.reject(err);
      })
      .finally(() => {
        this.setState({ServerUpdateActive: false});
      });
  }, 250);

  handleTabChange = (e, newValue) => {
    this.setState({SelectedTab: newValue});
  }

  handleSubTaskFunction = f => (...props) => {
    let Task = {...this.state.Task};
    f(
      Task.SubTasks,

      (id) => {
        return this.state[id];
      },
      
      (subTasks, finalizeSubTasksState, otherState) => {
        if (subTasks) {
          let Task = {...this.state.Task};
          Task.SubTasks = subTasks;
          if (finalizeSubTasksState) {
            this.updateTask(Task);
          } else {
            this.setState({Task});
          }
        }
        if (otherState) {
          this.setState(otherState);
        }
      },

    )
    (...props);
  }

  handleSubTaskAdded = (...props) => {
    this.handleSubTaskFunction(HandleSubTaskAdded)(...props);
  }

  handleSubTaskAddedAfter = (...props) => {
    this.handleSubTaskFunction(HandleSubTaskAddedAfter)(...props);
  }

  handleSubTaskChanged = (...props) => {
    this.handleSubTaskFunction(HandleSubTaskChanged)(...props);
  }

  handleStartMoveSubTask = (...props) => {
    this.handleSubTaskFunction(HandleStartMoveSubTask)(...props);
  }

  handleMoveSubTask = (...props) => {
    this.handleSubTaskFunction(HandleMoveSubTask)(...props);
  }

  handleAbortMoveSubTask = (...props) => {
    this.handleSubTaskFunction(HandleAbortMoveSubTask)(...props);
  }

  handleEndMoveSubTask = (...props) => {
    this.handleSubTaskFunction(HandleEndMoveSubTask)(...props);
  }

  handleTagListValuesChanged = tagListValues => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    const task = {...this.state.Task};
    task.Tags = Tags;
    task.AssetItemTags = AssetItemTags;
    this.updateTask(task);
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  setNewTaskProperties = () => {
    // console.log(this.props.additionalTaskPropertiesForCreation);
    this.setState({ Task: {
      Priority: 0,
      DescriptionVersion: 2,
      StagingID: (new Date()).toISOString(),
      AssignmentUserEmail: this.context.UserPreferences.UserEmail,
      AssignmentUserName: this.context.UserPreferences.UserName,
      ...this.props.additionalTaskPropertiesForCreation,
    }});
  }

  handleSetShowDeviceCaptureDialog = (ShowDeviceCaptureDialog, extraState) => {
    this.setState({
      ...extraState,
      ShowDeviceCaptureDialog,
    });
    if (!ShowDeviceCaptureDialog) {
      this.forceTaskDocumentsRefresh();
    }
  }

  forceTaskDocumentsRefresh = () => {
    this.setState({ForceTaskDocumentsRefresh:true});
    setTimeout(() => this.setState({ForceTaskDocumentsRefresh:false}), 1);
  }

  handleSetShowAddExistingDocumentsDialog = (ShowAddExistingDocumentsDialog, selectedDocuments) => {
    this.setState({ShowAddExistingDocumentsDialog});

    if (!ShowAddExistingDocumentsDialog && selectedDocuments && selectedDocuments.length) {
      this.setState({ShowProgressIndicatorImmediately:true});
      AddTaskDocuments(this.props.organizationId, this.props.projectId, this.props.Task.ID, selectedDocuments)
        .then(resp => {
          this.forceTaskDocumentsRefresh();
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
        });
    }
  }

  handlePreCaptureFieldGathering = () => {
    const handlePreGatherFields = () => {
    }

    const handleCapture = fieldIDsAndValues => {
      this.PostFieldGatheringCaptureFunc(fieldIDsAndValues);
    }

    const {
      organizationId,
      projectId,
      isWorkspace,
      workspaceApprovalId,
      workspaceApprovalAssetItem,
    } = this.props;
    const {
      Task,
    } = this.state;

    HandlePreCaptureFieldGathering(
      organizationId,
      projectId,
      this.handleApiError,
      () => this.state,
      state => this.setState(state),
      1,
      handlePreGatherFields,
      handleCapture,
      null,
      isWorkspace && Task?.ID,
      workspaceApprovalId,
      workspaceApprovalAssetItem,
    );
  }

  handleBeginDocumentCreation = (fieldIDsAndValuesForCapture, tagsForCapture, assetItemTagsForCapture) => {
    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = (fieldIDsAndValues, tags, assetItemTags) => this.handleBeginDocumentCreation(fieldIDsAndValues, tags, assetItemTags);
    this.AdditionalBodyTextForFieldGatheringDialog = null;

    const newDocument = {
      Name: "Untitled document",
      Origin: "Editor",
    };
    const params = {
      fieldIDsAndValues_json: (fieldIDsAndValuesForCapture) ? JSON.stringify(fieldIDsAndValuesForCapture) : undefined,
      tags_json: (tagsForCapture) ? JSON.stringify(tagsForCapture) : undefined,
      assetItemTags_json: (assetItemTagsForCapture) ? JSON.stringify(assetItemTagsForCapture) : undefined,
    };

    const beginDocumentCreation = () => {
      this.setState({ShowProgressIndicatorImmediately: true});
      API.post(GetDocumentsPathForApi(this.props.organizationId, this.props.projectId), [newDocument], { params })
        .then(newDocResp => {
          if (newDocResp && newDocResp.data && newDocResp.data.length) {
            const newDoc = newDocResp.data[0];
            AddTaskDocuments(this.props.organizationId, this.props.projectId, this.props.Task.ID, [newDoc])
              .then(addTaskDocumentResp => {
                this.forceTaskDocumentsRefresh();
                OpenDocumentDetailTab(this.props.organizationId, this.props.projectId, newDoc, false, 
                  { taskID: this.props.Task.ID }, false,
                  (this.props.isWorkspace || 
                    (this.getIsUserRestrictedToAssignedDocumentFolders()
                    && this.props.Task.AssignmentUserEmail === this.context.UserPreferences.UserEmail)
                  )
                    ? "task" : undefined);
              });
          }
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
        });
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginDocumentCreation();
    }
  }

  handleBeginImageCapture = (fieldIDsAndValuesForCapture, tagsForCapture, assetItemTagsForCapture) => {
    if (IsMobile() || !IsMicrosoftWindows()) {
      return;
    }
    
    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = (fieldIDsAndValues, tags, assetItemTags) => this.handleBeginImageCapture(fieldIDsAndValues, tags, assetItemTags);
    this.AdditionalBodyTextForFieldGatheringDialog = "All documents will receive these values.";

    let extraState = {
      FieldIDsAndValuesForCapture: fieldIDsAndValuesForCapture || null,
      TagsForCapture: tagsForCapture || null,
      AssetItemTagsForCapture: assetItemTagsForCapture || null,
    };

    const beginImageCapture = () => {
      this.handleSetShowDeviceCaptureDialog(true, extraState);
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginImageCapture();
    }
  }

  setDocumentsCollectionWhenReady = () => {
    if (!this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setDocumentsCollectionWhenReady(), 250);
      return;
    }

    const loadByTaskAssignment = (this.props.isWorkspace && !this.props.workspaceApprovalId)
      || (!this.props.isWorkspace
        && (this.getIsUserRestrictedToAssignedTasks()
        || this.getIsUserRestrictedToAssignedDocumentFolders())
      );

    this.TaskDocumentsCollection = new TaskDocuments_Collection(
      this.props,
      this.context,
      state => this.setState(state),
      this.handleApiError,
      this.props.isWorkspace,
      !this.props.isWorkspace,
      
      loadByTaskAssignment,
      Boolean(this.props.workspaceApprovalId),
      Boolean(this.props.workspaceApprovalAssetItem),

      this.props.organizationId,
      this.props.projectId,
      this.props.Task,

      this.props.workspaceApprovalId,
      this.props.workspaceApprovalAssetItem,

      this.getIsProjectMember(),
      () => { if (this.BeginFileUploadFunc) { this.BeginFileUploadFunc(); }},
      (!IsMobile() && IsMicrosoftWindows()) ? this.handleBeginImageCapture : null,
      (!this.props.isWorkspace && this.getIsProjectMember() && !this.getIsUserRestrictedToAssignedDocumentFolders())
        ? this.handleBeginDocumentCreation
        : null,
      () => this.handleSetShowAddExistingDocumentsDialog(true),
      false, //!this.props.isWorkspace,
      this.getTaskIsInactive() || (!this.getIsProjectMember() && this.props.isWorkspace),
    );
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({TaskDocumentsCollectionUpdateID: new Date()});
  }

  saveCallerFilterState = () => {
    this.CallerFilterState = GetSavedFilterState(this.props,
      (this.props.location && this.props.location.state)
        ? this.props.location.state.Fields
        : [],
      "", true);
  }

  restoreCallerFilterState = () => {
    if (!this.props.pathnameForFilterStateRestore) {
      return;
    }
    SaveFilterStateToHistory(this.props.pathnameForFilterStateRestore,
      this.props, this.CallerFilterState);
  }

  getProjectMemberPackage = () => {
    return this.context.ProjectMembershipPackages.find(p => p.Project.ID === this.props.projectId);
  }

  getIsProjectMember = () => {
    return Boolean(this.getProjectMemberPackage());
  }

  getIsProjectAdmin = () => {
    const projectMemberPkg = this.getProjectMemberPackage();
    return Boolean(projectMemberPkg)
      && projectMemberPkg.IsAdmin;
  }

  getIsUserRestrictedToAssignedTasks = () => {
    const projectMemberPkg = this.getProjectMemberPackage();
    return Boolean(projectMemberPkg)
      && !projectMemberPkg.IsAdmin
      && projectMemberPkg.Project.Access.MemberAccess.Tasks === "Assigned";
  }

  getIsUserRestrictedToAssignedDocumentFolders = () => {
    const projectMemberPkg = this.getProjectMemberPackage();
    return Boolean(projectMemberPkg)
      && !projectMemberPkg.IsAdmin
      && projectMemberPkg.Project.Access.MemberAccess.DocumentFolders === "Assigned";
  }
  
  handleTaskSubscriptionUpdate = TaskSubscription => {
    this.setState({TaskSubscription});
    if (this.props.onTaskRevised) {
      this.props.onTaskRevised({...this.state.Task, RefreshID: new Date()});
    }
  }

  handleStakeholdersValueChange = selectedListValues => {
    return HandleStakeholdersValueChange
      (this.state.Task, this.state.StakeholderProjectMembers, this.updateTask)
      (selectedListValues);
  }

  componentDidMount() {
    this.saveCallerFilterState();

    if (this.props.isCreateNew) {
      this.setNewTaskProperties();
    } else {
      this.setDocumentsCollectionWhenReady();
      this.loadTaskSubscription();
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        ...this.InitialState,
        open: this.props.open !== false,
      }
      if (this.props.open) {
        this.saveCallerFilterState();

        if (this.props.Task) {
          stateToUpdate.Task = this.props.Task;
          stateToUpdate.TagListValues = GetTagListValuesFromTagsObject(this.props.Task);
          this.setDocumentsCollectionWhenReady();
          this.loadTaskSubscription();
        } else if (this.props.isCreateNew) {
          this.setNewTaskProperties();
        } else {
          stateToUpdate.Task = null;
          stateToUpdate.TagListValues = [];
        }
      } else {
        stateToUpdate.Task = null;
        stateToUpdate.TaskSubscription = null;
        stateToUpdate.TagListValues = [];
      }
      this.setState(stateToUpdate);
    }
  }

  render() {
    const {
      open,
      Task,
      // SensitiveFields,
      SelectedTab,
      // DocumentThumbnailUrlsByDocumentId,
      ShowCloseNewTaskConfirmationDialog,
      ShowCloseWhilePersistingConfirmationDialog,
      ServerUpdateActive,
      ShowProgressIndicatorImmediately,
      FabMenu_MouseY,
      FabMenu_MouseX,
      ShowDeviceCaptureDialog,
      ForceTaskDocumentsRefresh,
      TaskDocumentsCollectionUpdateID,
      ShowAddExistingDocumentsDialog,
      TagListValues,
      FieldIDsAndValuesForCapture,
      TaskSubscription,
      StakeholderProjectMembers,
      TagsForCapture,
      AssetItemTagsForCapture,
    } = this.state;
    const {
      organizationId,
      projectId,
      additionalTaskPropertiesForCreation,
      isCreateNew,
      assetItemForNewTask,
      onApprove,
      onDecline,
      onMarkComplete,
      onRestore,
      classes,
      theme,
      showProgressIndicatorImmediately,
      isWorkspace,
      workspaceApprovalId,
      workspaceApprovalAssetItem,
      collectionNameForTitle,
      onAlert,
      onTaskMilestoneCreated,
      onTaskStateCreated,
      ...restProps
    } = this.props;
    const {
      UserEmail,
      UserName,
    } = this.context.UserPreferences;

    const closeNewTaskConfirmationDialogDetails = {
      Open:ShowCloseNewTaskConfirmationDialog,
      IsConfirmation:true,
      Title:"Abort new task?",
      BodyText:"This task has not been started.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetShowCloseNewTaskConfirmationDialog(false),
      CloseCallback:() => this.handleSetShowCloseNewTaskConfirmationDialog(false),
      ConfirmCallback:() => this.handleClose(true),
    };

    const closeWhilePersistingConfirmationDialogDetails = {
      Open:ShowCloseWhilePersistingConfirmationDialog,
      IsConfirmation:true,
      Title:"Close task?",
      BodyText:"Changes are being sent to the server. If you close now you may lose your changes.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetShowCloseWhilePersistingConfirmationDialog(false),
      CloseCallback:() => this.handleSetShowCloseWhilePersistingConfirmationDialog(false),
      ConfirmCallback:() => this.handleClose(true),
    };

    const approveButton = (onApprove)
      ? (
        <Button className={classes.buttonWithColor} 
          style={{
            backgroundColor: green[700]
          }}
          onClick={() => onApprove()}>
          APPROVE
        </Button>
      ) : null;
    const declineButton = (onDecline)
      ? (
        <Button className={classes.buttonWithColor}
          style={{
            backgroundColor: red[700]
          }}
          onClick={() => onDecline()}>
          DECLINE
        </Button>
      ) : null;
    const markCompleteButton = (!isCreateNew && (Task && !Task.Result)
      && onMarkComplete && SelectedTab === "task")
      ? (
        <Button onClick={() => onMarkComplete()}>
          MARK COMPLETE
        </Button>
      ) : null;
    const restoreButton = (!isCreateNew && (Task && Task.Result)
      && onRestore && SelectedTab === "task")
      ? (
        <Button onClick={() => onRestore()}>
          RESTORE
        </Button>
      ) : null;
    const createButtonDisabled = !(Task && Task.Name && Task.AssignmentUserEmail);
    const createButton = (isCreateNew)
      ? (
        <Button
          style={{
            backgroundColor: (createButtonDisabled) ? green[100] : green[700],
            color: (createButtonDisabled) ? "#999" : theme.palette.primary.contrastText,
          }}
          onClick={this.handleCreateNew}
          disabled={createButtonDisabled}
        >
          CREATE
        </Button>
      ) : null;
    const closeButton = (true)//IsMobile())
      ? (
        <Tooltip title="Close dialog">
          <IconButton
            color="inherit" aria-label="Close dialog"
            onClick={() => this.handleClose()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    const subscriptionIconButton = (TaskSubscription && !isWorkspace && !this.getTaskIsInactive())
      ? GetTaskSubscriptionIconButton(TaskSubscription, null, this.handleTaskSubscriptionUpdate)
      : null;
    const dialogActions = (
      <DialogActions className={classes.dialogActions}>
        {subscriptionIconButton}
        {approveButton}
        {declineButton}
        {markCompleteButton}
        {restoreButton}
        {closeButton}
        {createButton}
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately || showProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately />
      );
    }

    const taskDialogGrid = (
      <TaskDialogGrid
        organizationId={organizationId}
        projectId={projectId}
        Task={Task}
        userName={UserName}
        userEmail={UserEmail}
        workspaceApprovalId={workspaceApprovalId}
        additionalTaskPropertiesForCreation={additionalTaskPropertiesForCreation}
        onStringChange={this.handleStringChange}
        onRichTextChange={this.handleRichTextChange}
        onNumberChange={this.handleNumberChange}
        onDurationChange={this.handleDurationChange}
        onDateTimeChange={this.handleDateTimeChange}
        onAssignmentValueChange={this.handleAssignmentValueChange}
        onAssignmentCreateOption={this.handleAssignmentCreateOption}
        onSubTaskAdded={this.handleSubTaskAdded}
        onSubTaskChanged={this.handleSubTaskChanged}
        onSubTaskAddedAfter={this.handleSubTaskAddedAfter}
        onStartMoveSubTask={this.handleStartMoveSubTask}
        onMoveSubTask={this.handleMoveSubTask}
        onAbortMoveSubTask={this.handleAbortMoveSubTask}
        onEndMoveSubTask={this.handleEndMoveSubTask}
        onTaskMilestoneCreated={onTaskMilestoneCreated}
        onTaskStateCreated={onTaskStateCreated}
        onApiError={this.handleApiError}
        showDueOn
        isCreateNew={isCreateNew}
        isWorkspace={isWorkspace}
        isProjectAdmin={this.getIsProjectAdmin()}
        isUserRestrictedToAssignedTasks={this.getIsUserRestrictedToAssignedTasks()}
      />
    );

    const deviceCaptureDialog = (!isCreateNew && Task && !IsMobile() && IsMicrosoftWindows()) ? (
        <DeviceCaptureDialog
          open={ShowDeviceCaptureDialog}
          organizationId={organizationId}
          projectId={projectId}
          approvalId={workspaceApprovalId}
          assetId={workspaceApprovalAssetItem?.AssetID}
          assetItemId={workspaceApprovalAssetItem?.ID}
          taskId={Task.ID}
          isWorkspace={isWorkspace}
          fieldIDsAndValues={FieldIDsAndValuesForCapture}
          tags={TagsForCapture}
          assetItemTags={AssetItemTagsForCapture}
          closeAfterFirstSubmit
          onApiError={this.handleApiError}
          onAlert={onAlert}
          onClose={() => this.handleSetShowDeviceCaptureDialog(false)}
        />
      ) : null;

    const addExistingDocumentsDialog = (this.getIsProjectMember() && ShowAddExistingDocumentsDialog) ? (
      <ItemSearchDialog
        {...restProps}
        open
        itemType="document"
        dialogTitle="Add existing document(s) to task"
        confirmButtonText="ADD TO TASK"
        onClose={selectedDocuments => this.handleSetShowAddExistingDocumentsDialog(false, selectedDocuments)}
        onAlert={onAlert}
        organizationId={organizationId}
        projectId={projectId}
        isProjectMember={this.getIsProjectMember()}
        isWorkspace={isWorkspace}
      />
    ) : null;

    const itemCollectionBase = (!isCreateNew && TaskDocumentsCollectionUpdateID) ? (
      <ItemCollectionBase
        {...restProps}

        returnContentOnly

        contentUri={this.TaskDocumentsCollection.ContentUri}
        contentUriParams={this.TaskDocumentsCollection.ContentUriParams}
        collectionName={this.TaskDocumentsCollection.CollectionName}
        itemsName={this.TaskDocumentsCollection.ItemsName}
        itemName={this.TaskDocumentsCollection.ItemName}
        defaultViewType={this.TaskDocumentsCollection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.TaskDocumentsCollection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.TaskDocumentsCollection.HandleGetHeadCells}
        onGetCardGridItems={this.TaskDocumentsCollection.HandleGetCardGridItems}
        onGetTableRows={this.TaskDocumentsCollection.HandleGetTableRows}
        singleLineTableCells={this.TaskDocumentsCollection.SingleLineTableCells}

        hideSensitiveFields={this.TaskDocumentsCollection.HideSensitiveFields}
        hideFilterSortDrawer={this.TaskDocumentsCollection.HideFilterSortDrawer}
        skipFilterSavedState={true}
        hideSearchAllFilter

        loadItemsImmediately
        organizationId={organizationId}
        projectId={projectId}
        // apiError={ApiError}
        // alert={Alert}
        onAlert={onAlert}
        onApiError={this.handleApiError}
        // onRefresh={this.TaskDocumentsCollection.HandleRefresh}
        // onItemsChanged={this.TaskDocumentsCollection.HandleItemsChanged}
        // forcePrependItems={ForcePrependItems}
        forceRefresh={ForceTaskDocumentsRefresh}
        // includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
        allowSelect={this.TaskDocumentsCollection.AllowSelect}
        canSelectItem={this.TaskDocumentsCollection.CanSelectItem}
        allowFabOnDesktop
        fabTop={(IsMobile()) ? 76 : 64}
        fabLeft={theme.spacing(3)}
        fabSize={(IsMobile()) ? "small" : "medium"}
        fabPosition={(!IsMobile()) ? "absolute" : undefined}
        onFabClick={this.TaskDocumentsCollection.HandleCreateNew}
        onGetFabMenu={this.TaskDocumentsCollection.HandleGetFabMenu}
        fabMenuCoords={{
          FabMenu_MouseY,
          FabMenu_MouseX,
        }}
        toolHeaderStyle={{
          paddingRight:0,
        }}
        itemListContainerStyle={{overflowY:(IsMobile()) ? "scroll" : undefined}}
        itemListStyle={{paddingLeft:0}}
        captureReservationParams={{taskId: (Task) ? Task.ID : undefined}}
        taskIdForTaskAssignmentContext={(Task && isWorkspace) ? Task.ID : undefined}
        approvalIdForApprovalAssignmentContext={workspaceApprovalId}
        assetItemForAssetItemContext={workspaceApprovalAssetItem}
        onSetBeginFileUploadFunc={f => this.BeginFileUploadFunc = f}
        // showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    ) : null;

    const preCaptureFieldGatheringContent = GetPreCaptureFieldGatheringContent(
      organizationId,
      projectId,
      () => this.state,
      state => this.setState(state),
      this.handleApiError,
      onAlert,
      this.PostFieldGatheringCaptureFunc,
      this.AdditionalBodyTextForFieldGatheringDialog,
      null,
      isWorkspace && Task?.ID,
      workspaceApprovalId,
      workspaceApprovalAssetItem,
    );

    const documentsContent = (
      <React.Fragment>
        {itemCollectionBase}
        {deviceCaptureDialog}
        {addExistingDocumentsDialog}
        {preCaptureFieldGatheringContent}
      </React.Fragment>
    );

    const comments = (!isCreateNew && this.props.Task)
      ? (
        <Comments
          organizationId={organizationId}
          projectId={projectId}
          newCommentBottomFixed={!IsMobile()}
          commentsUri={
            (isWorkspace)
              ? (workspaceApprovalId)
                ? (workspaceApprovalAssetItem)
                  ? GetUserOrganizationProjectApprovalAssetItemTaskCommentsPathForApi(organizationId, projectId,
                      workspaceApprovalId, workspaceApprovalAssetItem.AssetID,
                      workspaceApprovalAssetItem.ID, this.props.Task.ID)
                  : GetUserOrganizationProjectApprovalTaskCommentsPathForApi(organizationId, projectId,
                      workspaceApprovalId, this.props.Task.ID)
                : GetUserOrganizationProjectTaskCommentsPathForApi(organizationId, projectId, this.props.Task.ID)
              : GetTaskCommentsPathForApi(organizationId, projectId, this.props.Task.ID)
          }
          collectionName="TaskEvents"
          autoFocus
          newCommentAdditionalProperties={{TaskID: this.props.Task.ID}}
          onApiError={this.handleApiError}
        />
      )
      : null;

    const gridItemSize = (IsMobile()) ? 12 : 4;
    const completedOnGridItem = (Task && Task.Result === "Complete" && new Date(Task.CompletedOn) > new Date("2000-01-01"))
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Completed On</div>{GetDateValue(Task.CompletedOn)}</Grid>
      ) : null;
    const completedByGridItem = (Task && Task.Result === "Complete" && new Date(Task.CompletedOn) > new Date("2000-01-01"))
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Completed By</div>{GetUserValue(Task.CompletedByUserEmail, Task.CompletedByUserName)}</Grid>
      ) : null;
    const createdByGridItem = (Task && Task.CreatedByUserEmail)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Created By</div>{GetUserValue(Task.CreatedByUserEmail, Task.CreatedByUserName)}</Grid>
      ) : null;
    const dueOnGridItem = (Task && new Date(Task.DueOn) > new Date("2000-01-01"))
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Due On</div>{GetDateValue(Task.DueOn)}</Grid>
      ) : null;
    const durationGridItem = (Task && Task.DurationMultiplier > 0)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Duration</div>{GetTaskDurationAsString(Task.DurationMultiplier, Task.DurationInterval)}</Grid>
      ) : null;
    const processNameGridItem = (Task && Task.ProcessName)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Process</div>{Task.ProcessName}</Grid>
      ) : null;
    const processElementNameGridItem = (Task && Task.ProcessElementName)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Process Action</div>{Task.ProcessElementName}</Grid>
      ) : null;
    const revisionGridItem = (Task && Task.Revision)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Revision</div>{NumberWithSeparators(Task.Revision)}</Grid>
      ) : null;
    const milestoneGridItem = (Task && Task.TaskMilestoneID)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Milestone</div>{Task.TaskMilestoneName}</Grid>
      ) : null;
    const stateGridItem = (Task && Task.TaskStateID)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Task State</div>{Task.TaskStateName}</Grid>
      ) : null;
    const tagsGridItem = (Task && Task.Tags && Task.Tags.length)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Tags</div>{Task.Tags.join(", ")}</Grid>
      ) : null;
    const stakeholdersGridItem = (Task && Task.Stakeholders && Task.Stakeholders.length)
      ? (
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Stakeholders</div>{
          Task.Stakeholders.map((s, i) => (<span key={`sh_${i}`}>{GetUserValue(s.Email,s.Name)}</span>))
        }</Grid>
      ) : null;
    const propertiesGrid = (Task && Task.CreatedOn) ? (
      <Grid container spacing={2}>
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Assigned To</div>{GetUserValue(Task.AssignmentUserEmail, Task.AssignmentUserName)}</Grid>
        {completedByGridItem}
        {completedOnGridItem}
        {createdByGridItem}
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Created On</div>{GetDateValue(Task.CreatedOn)}</Grid>
        {dueOnGridItem}
        {durationGridItem}
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Last Modified By</div>{GetUserValue(Task.ModifiedByUserEmail, Task.ModifiedByUserName)}</Grid>
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Last Modified On</div>{GetDateValue(Task.ModifiedOn)}</Grid>
        {milestoneGridItem}
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Name</div>{Task.Name}</Grid>
        <Grid item xs={gridItemSize}><div className={classes.propertyName}>Priority</div>{GetTaskPriorityLabel(Task.Priority)}</Grid>
        {processNameGridItem}
        {processElementNameGridItem}
        {revisionGridItem}
        {stakeholdersGridItem}
        {tagsGridItem}
        {stateGridItem}
      </Grid>
    ) : null;

    // const closeIconButton = (
    //   <Tooltip title="Close dialog">
    //     <IconButton
    //       edge="end"
    //       color="inherit" aria-label="Close dialog"
    //       onClick={() => this.handleClose()}>
    //       <CloseIcon />
    //     </IconButton>
    //   </Tooltip>
    // );

    const taskChecklistForMobile = (IsMobile())
      ? (
        <div className={classes.mobileTaskListContainer}>
          <TaskChecklist
            Task={Task}
            onSubTaskAdded={this.handleSubTaskAdded}
            onSubTaskChanged={this.handleSubTaskChanged}
            onSubTaskAddedAfter={this.handleSubTaskAddedAfter}
            onStartMoveSubTask={this.handleStartMoveSubTask}
            onMoveSubTask={this.handleMoveSubTask}
            onAbortMoveSubTask={this.handleAbortMoveSubTask}
            onEndMoveSubTask={this.handleEndMoveSubTask}
            disabled={this.getTaskIsInactive()}
          />
        </div>
      ) : null;

    const tagsControl = GetTagsControl(organizationId, projectId, (Task) ? Task.ID : null, 
      (isWorkspace && !workspaceApprovalId),
      (isWorkspace && Boolean(workspaceApprovalId)), workspaceApprovalId, true,
      TagListValues, TagListValues => this.setState({TagListValues}),
      this.handleTagListValuesChanged, (Task && Boolean(Task.Result)), -1, null, true);

    const stakeholdersControl = GetStakeholdersControl(organizationId, projectId, Task, 
      this.handleStakeholdersValueChange,
      StakeholderProjectMembers, StakeholderProjectMembers => this.setState({StakeholderProjectMembers}),
      (this.getTaskIsInactive() || isWorkspace)
    );

    const tagsAndStakeholdersContent = (
      <Grid container direction="column" spacing={2}>
        <Grid item key="tags">
          {tagsControl}
        </Grid>
        <Grid item key="stakeholders">
          {stakeholdersControl}
        </Grid>
      </Grid>
    );

    let tabContent;
    switch (SelectedTab) {
      case "task":
        tabContent = taskDialogGrid;
        break;
      case "checklist":
        tabContent= taskChecklistForMobile;
        break;
      case "documents":
        tabContent = documentsContent;
        break;
      case "comments":
        tabContent = (
          <div
            style={{
              padding:theme.spacing(3),
              overflowY:"auto",
              height:"100%",
            }}
          >
            {comments}
          </div>
        );
        break;
      case "tagsAndStakeholders":
        tabContent = tagsAndStakeholdersContent;
        break;
      case "properties":
        tabContent = propertiesGrid;
        break;
      default:
        break;
    }

    const checklistTab = (IsMobile())
      ? (
        <Tab label="Checklist" value="checklist" className={classes.tab} />
      ) : null;

    const commentsTab = (IsMobile())
      ? (
        <Tab label="Comments" value="comments" className={classes.tab} />
      ) : null;

    const canShowDocuments = (Task && (this.getIsProjectAdmin() || !this.getIsUserRestrictedToAssignedTasks() || Task.AssignmentUserEmail === UserEmail));
    const documentsTab = (canShowDocuments)
      ? (
        <Tab label="Documents" value="documents" className={classes.tab} />
      ) : null;
    const tabs = (!isCreateNew && this.props.Task)
      ? (
        <Tabs
          variant={(IsMobile()) ? "scrollable" : "fullWidth"}
          className={classes.tabs}
          value={SelectedTab}
          onChange={this.handleTabChange}
        >
          <Tab label={GetTaskLabelByResult(Task && Task.Result)} value="task" className={classes.tab}
            style={{
              marginLeft:(IsMobile()) ? 32 : undefined,
            }}
          />
          {checklistTab}
          <Tab label="Tags & Stakeholders" value="tagsAndStakeholders" className={classes.tab} />
          {commentsTab}
          {documentsTab}
          <Tab label="Properties" value="properties" className={classes.tab} />
        </Tabs>
      ) : null;

    const desktopCommentsGridItem = (!IsMobile())
      ? (
        <Grid item xs={4} sm={3}
          style={{
            height:"100%",
          }}
        >
          <div style={{
            display:"flex",
            flexDirection:"column",
            height:"100%",
          }}>
            <div style={{
              textAlign:"right",
              marginBottom:theme.spacing(3),
            }}>
              {/*{closeIconButton}*/}
            </div>
            {comments}
          </div>
        </Grid>
      ) : null;

    let content = (
      <div className={classes.contentContainer}>
        {tabs}
        <Paper elevation={0} className={classes.paper}
          style={{
            paddingTop:
              (isCreateNew || SelectedTab === "documents")
                ? theme.spacing(1)
                : theme.spacing(2),
            margin:(SelectedTab === "comments") ? -theme.spacing(3) : undefined,
          }}>
          {tabContent}
        </Paper>
      </div>
    );
    if (!IsMobile() && !isCreateNew) {
      content = (
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={(IsMobile()) ? 12 : 8} sm={(IsMobile()) ? 12 : 9} style={{height:"100%"}}>
              {content}
          </Grid>
          {desktopCommentsGridItem}
        </Grid>
      );
    }

    const dialogTitle = (isCreateNew)
      ? (
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          <div style={{
            display:"flex",
          }}>
            <div style={{
                flexGrow:1,
                display:"flex",
                whiteSpace:"nowrap",
              }}
            >
              <div style={{marginRight:6}}>
                {`Create a task${(collectionNameForTitle || assetItemForNewTask) ? " for" : ""}`}
              </div>
              {(collectionNameForTitle) ? collectionNameForTitle : undefined}
              {(assetItemForNewTask)
                ? `${assetItemForNewTask.Name} (${assetItemForNewTask.AssetName})`
                : undefined
              }
            </div>
            {/*{(!IsMobile()) ? closeIconButton : null}*/}
          </div>
        </DialogTitle>
      ) : null;

    const beforeUnload = (
      <Beforeunload
        onBeforeunload={e => {
          if (ServerUpdateActive) {
            e.preventDefault();
          }
        }}
      />
    );

    return (
      <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        maxWidth="xl"
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        {dialogTitle}
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          {beforeUnload}
          <MultiUseDialog Details={closeNewTaskConfirmationDialogDetails} />
          <MultiUseDialog Details={closeWhilePersistingConfirmationDialogDetails} />

          {content}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

TaskDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  collectionNameForTitle: PropTypes.any,
  additionalTaskPropertiesForCreation: PropTypes.object,
  pathnameForFilterStateRestore: PropTypes.string,
  Task: PropTypes.object,
  onClose: PropTypes.func,
  onTaskRevised: PropTypes.func,
  isCreateNew: PropTypes.bool,
  assetItemForNewTask: PropTypes.object,
  onCreated: PropTypes.func,
  primaryDocumentIdForCreateNew: PropTypes.string,
  title: PropTypes.string,
  isWorkspace: PropTypes.bool,
  workspaceApprovalId: PropTypes.string,
  workspaceApprovalAssetItem: PropTypes.object,
  onTaskMilestoneCreated: PropTypes.func,
  onTaskStateCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(TaskDialog);